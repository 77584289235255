import React, { FC, useState, useEffect, useReducer, useMemo } from 'react';
import { Link, RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, isEqual, merge } from 'lodash';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { Label } from '@mui/icons-material';
import {
  CampaignDetailPageStep,
  CampaignFormMode,
  CampaignItem,
  CampaignStructureItem,
} from '../../../types/staff-campaign-types';
import {
  createNewCampaign,
  cancelCampaignSubmission,
  fetchCampaignItem,
  fetchCampaignTypeStructure,
  removeParticipant,
  submitCampaign,
  updateCampaign,
  uploadLead,
  sendInvitation,
  checkAllRsvpResponded,
  fetchParticipantList,
} from '../../../network/staffCampaignCrud';
import { convertArrayToObject, pathExtractor, fileUpload } from 'src/app/common/utils';
import CampaignDetailPageLeftBar from './DetailPageLeftBar/CampaignDetailPageLeftBar';
import CampaignInfo from './application/CampaignInfo';
import Participant from './application/Participant';
import Attachment from './application/Attachment';
import Approval from './approval/Approval';
import Lead from './lead/Lead';
import { useIntl } from 'react-intl';
import { staffCampaignPath } from '../CampaignRoutes';
import { AlertType, appendAlertItem } from '../../../../../../redux/common/commonSlice';
import { ApiErrorResponse } from 'src/app/common/network';
import { ErrorFieldType, useErrorHandler } from '../../../utils/staff-campaign-form-error-utils';
import {
  AgencyCampaignTypeStatusEnum,
  AGENCY_CAMPAIGN_BASE_PATH,
  AGENCY_CAMPAIGN_STAFF_CAMPAIGN_PATH,
} from '../../../constants';
import { useLang } from '../../../../../i18n';
import moment from 'moment';
import { createBlobAsAgent, getBlobAsAgent } from 'src/app/common/network';
import {
  validationCompareChecking,
  validationLengthChecking,
  validationMandatoryDependsChecking,
  validationCurrencyChecking,
} from '../../../utils/validations';
import { InvitePreview } from './InviteDialog/InvitePreview';
import { PruToast } from 'src/app/common/components/pru-toast';
import LeadV2 from './lead/lead-v2';
import EventDocument from './post-event-documents/post-document';
import { addBusinessDays } from 'date-fns';
import { checkAgentImportFail, renderfailImportList } from './application/components/ImportParticipantPopup';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  rightContentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto',
  },
  rightContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  bottomButtonContainer: {
    marginTop: 5,
    backgroundColor: 'white',
    height: 86,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomWhiteButton: {
    // backgroundColor: "white",
    // color: "#333333",
    width: '200px',
    // height: 46,
    // borderRadius: 12,
    // border: "2px solid #F0F0F0",
    margin: 10,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  bottomRedButton: {
    // backgroundColor: "#E8192C",
    // color: "white",
    width: '200px',
    // height: 46,
    borderRadius: 12,
    margin: 10,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  whiteButton: {
    backgroundColor: 'white',
    color: '#333333',
    width: '150px',
    height: 46,
    borderRadius: 12,
    border: '2px solid #F0F0F0',
    margin: 10,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  redButton: {
    backgroundColor: '#E8192C',
    color: 'white',
    width: '150px',
    height: 46,
    borderRadius: 12,
    margin: 10,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  dialogContainer: {
    padding: '20px',
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnRemoveWrap: {
    margin: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    section: string;
    field: keyof any;
    value: any;
  };
};

type StepAccessAction = {
  type: 'STEP_ACCESS';
  payload: number;
};

type StepPopulateAction = {
  type: 'STEP_POPULATE';
  payload: number | number[];
};

type InitFieldAction = {
  type: 'INIT_FORM_DATA';
  payload: {
    value: any;
  };
};

type infoDataFormAction = ModifyFieldAction | InitFieldAction | StepAccessAction | StepPopulateAction;

const initialState: any = {
  stepAccessed: [],
  stepPopulated: [],
  count: 0,
};

const infoDataFormReducer = (state: any, action: infoDataFormAction): any => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.section]: {
          ...state[action.payload.section],
          [action.payload.field]: action.payload.value,
        },
        count: state.count + 1,
      };
    case 'INIT_FORM_DATA':
      let formState = {
        ...state,
        ...action.payload.value,
        count: state.count + 1,
      };
      return formState;
    case 'STEP_ACCESS':
      return {
        ...state,
        stepAccessed: [...new Set(state.stepAccessed.concat(action.payload))],
      };
    case 'STEP_POPULATE':
      const stepPopulated = [...new Set(state.stepPopulated.concat(action.payload))];
      return {
        ...state,
        stepPopulated,
      };
  }
};

const StateContext = React.createContext({});
const DispatchContext = React.createContext({});

const CampaignDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });

  const { typeId } = useParams<{ typeId: string }>();
  const { id } = useParams<{ id: string }>();
  const { code } = useMemo(() => ({ code: id ? id : undefined }), [id]);
  const { formMode } = pathExtractor(
    location.pathname,
    `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_STAFF_CAMPAIGN_PATH}`,
  );

  const [currentCampaign, setCurrentCampaign] = useState<any>({ campaignTypeId: typeId ? typeId : '' });
  const [campaignTypeStructureData, setCampaignTypeStructureData] = useState<CampaignStructureItem>();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [step1Warning, setStep1Warning] = useState<boolean>(true);
  const [step2Warning, setStep2Warning] = useState<boolean>(true);
  const [step3Warning, setStep3Warning] = useState<boolean>(true);
  const [formState, formDispatch] = useReducer(infoDataFormReducer, initialState);
  const [previousFormState, setPreviousFormState] = useState<any>({});
  const [participantSelected, setParticipantSelected] = useState<any[]>([]);
  const [validationArray, setValidationArray] = useState<any[]>([]);
  const [leadUploadList, setLeadUploadList] = useState<any>();
  const [leadUploadFile, setLeadUploadFile] = useState<any>();
  const [submitDateShouldGreaterThan, setSubmitDateShouldGreaterThan] = useState<any>();
  const [campaignEndDateEqualToStartDate, setCampaignEndDateEqualToStartDate] = useState<any>();
  const [campaignEndDateGreaterThanTarget, setCampaignEndDateGreaterThanTarget] = useState<any>();
  const [RSVPMandatory, setRSVPMandatory] = useState<boolean>(false);
  const [formStateUpdated, setFormStateUpdated] = useState<boolean>(false);
  const [showDiscradPrompt, setShowDiscardPrompt] = useState<boolean>(false);
  const [participantNameRules, setParticipantNameRules] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const lang = useLang();
  /** remove participant confirm dialog start */
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(!open);
  const handleOk = () => {
    setOpen(false);
    removeSelectedParticipant();
  };
  /** remove participant confirm dialog end */

  const [approvalData, setApprovalData] = useState<any>([]);
  const [previousApprovalData, setPreviousApprovalData] = useState<any>([]);

  // startDate
  const [startDate, setStartDate] = useState<any>();

  /** invite dialog start */
  // Invitation sent or not
  const [sentInvite, setSentInvite] = useState<boolean>(false);
  const [inviteDialog, setInviteDialog] = useState<boolean>(false);
  const toggleInviteDialog = () => setInviteDialog(!inviteDialog);
  const onClickInvite = async () => {
    const max = get(participantNameRules, 'lte', 0);
    if (max) {
      setLoading(true);
      const getParticipantList = await fetchParticipantList(
        {
          limit: 10000,
          page: 1,
          search: '',
          id: currentCampaign.campaignObjId,
        },
        [],
        dispatch,
      );
      setLoading(false);
      let invitedCount = 0;
      getParticipantList?.docs.map((item) => {
        if (item.invitationTime) {
          invitedCount++;
        }
      });
      const totalCount = participantSelected.length + invitedCount;
      if (totalCount > max) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('agencyCampaign.create.submitFail'),
              content: `${max} ${Translation('agencyCampaign.create.participantMax')}`,
            },
          ]),
        );
        return;
      }
    }
    toggleInviteDialog();
  };
  /** invite dialog end */

  const disabledEdit = useMemo(() => {
    return (
      (currentCampaign.campaignStatus == AgencyCampaignTypeStatusEnum.PENDING ||
        currentCampaign.campaignStatus == AgencyCampaignTypeStatusEnum.APPROVED ||
        currentCampaign.campaignStatus == AgencyCampaignTypeStatusEnum.ACTIVE ||
        currentCampaign.campaignStatus == AgencyCampaignTypeStatusEnum.COMPLETED) &&
      currentStep !== CampaignDetailPageStep.EVNET_DOCUMENT
    );
  }, [currentCampaign.campaignStatus, currentStep]);

  const reloadData = async () => {
    if (currentCampaign.campaignId) {
      await fetchCampaignItem(currentCampaign.campaignId, dispatch)
        .then((result) => {
          formDispatch({
            type: 'INIT_FORM_DATA',
            payload: {
              value: merge(result.agencyCampaign, {
                applicant: {
                  applicantAgentCode:
                    result.agencyCampaign?.applicant?.applicantAgentCode ||
                    result.agencyCampaign?.applicant?.applicantName,
                },
              }),
            },
          });
          const _currentCampaign = {
            ...currentCampaign,
            campaignTypeId: result.agencyCampaign.typeId,
            campaignStatus: result.status,
            approvalHistoryList: result.agencyCampaign.approvalHistoryList,
            remarksList: result.agencyCampaign.remark,
            campaignObjId: result._id,
            previousApprovalData: result.agencyCampaign.previousApprovalHistories,
            campaignTypeStructureData: result.agencyCampaign._campaignType,
          };
          if (result.agencyCampaign.submitDate) {
            const { submitDate, cancelDate, canceledBy, cancelReason } = result.agencyCampaign;
            _currentCampaign.submitData = {
              submitDate: submitDate,
              submitBy: result.agencyCampaign.applicant.applicantName,
              cancelDate: cancelDate,
              canceledBy: canceledBy,
              cancelReason,
            };
          }
          _currentCampaign.startDate = moment(result.startDate).toDate();
          _currentCampaign.endDate = moment(result.endDate).toDate();
          setCurrentCampaign(_currentCampaign);
        })
        .catch((err: ApiErrorResponse) => {
          // history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {});
    }
  };

  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  useEffect(() => {
    setCurrentCampaign({ ...currentCampaign, campaignId: code });
    // eslint-disable-next-line
  }, [code]);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [currentCampaign.campaignId]);

  useEffect(() => {
    loadCampaignTypeStructure();
    // eslint-disable-next-line
  }, [currentCampaign.campaignTypeId]);

  useEffect(() => {
    autoPopulatedField();
  }, [campaignTypeStructureData]);

  const autoPopulatedField = () => {
    if (!campaignTypeStructureData) {
      return;
    }
    campaignTypeStructureData.sections
      .filter(
        (item: any) => item.key != 'participant',
        //  && item.key != "attachments"
      )
      .map((sectionItem: any) => {
        sectionItem.sectionFields.map((fieldsItem: any) => {
          if (!(formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key])) {
            switch (fieldsItem.key) {
              case 'applicantName':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: user?.username || '-' },
                });
                break;
              case 'applicantAgentCode':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: user?.username || '-' },
                });
                break;
              case 'applicantEmail':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: user?.sub || '-' },
                });
                break;
              case 'totalApe':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: '$0' },
                });
                break;
              default:
                break;
            }
          }
        });
      });
  };

  const loadCampaignTypeStructure = () => {
    if (currentCampaign.campaignTypeStructureData) {
      const result = currentCampaign.campaignTypeStructureData;
      setCampaignTypeStructureData(result);
      handleValidationArray(result);
      setApprovalData(result.approvalSetting);
      return;
    }
    if (currentCampaign.campaignTypeId) {
      fetchCampaignTypeStructure(currentCampaign.campaignTypeId, dispatch)
        .then((result) => {
          setCampaignTypeStructureData(result);
          handleValidationArray(result);
          setApprovalData(result.approvalSetting);
        })
        .catch((err: ApiErrorResponse) => {
          // history.push(NOT_FOUND_ERROR_PAGE_PATH);
        });
    }
  };

  const validationHandler = useErrorHandler(formState, validationArray);
  useEffect(() => {
    if (validationArray?.length && currentCampaign.campaignId) {
      checkStepHaveError();
    }
  }, [validationArray]);

  const handleValidationArray = (dataStructure: any) => {
    const mandatoryArray: any[] = [];
    dataStructure.sections
      .filter((item: any) => !['participant'].includes(item.key))
      .map((sectionItem: any) => {
        sectionItem.sectionFields.map((fieldItem: any) => {
          let ruleObject = convertArrayToObject(fieldItem.rules, 'title');
          if (
            fieldItem.key !== 'applicantAgentCode' &&
            ruleObject.Mandatory &&
            ruleObject.Mandatory.key == 'mandatory' &&
            ruleObject.Mandatory.value
          ) {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.MANDATORY,
            });
          }
          if (ruleObject.Mandatory && ruleObject.Mandatory.key == 'mandatoryDepends') {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.MANDATORY,
              condition: (updatedFormState: any) => {
                return validationMandatoryDependsChecking(
                  sectionItem.key,
                  fieldItem.key,
                  updatedFormState,
                  ruleObject.Mandatory,
                );
              },
            });
          }

          if (ruleObject.Validation) {
            switch (ruleObject.Validation.key) {
              case 'length':
                mandatoryArray.push({
                  section: sectionItem.key,
                  name: fieldItem.key,
                  fieldType: ErrorFieldType.IMMEDIATE,
                  condition: (updatedFormState: any) => {
                    return validationLengthChecking(
                      sectionItem.key,
                      fieldItem.key,
                      updatedFormState,
                      ruleObject.Validation,
                    );
                  },
                });
                break;
              case 'compare':
                if (ruleObject.Validation.dependsOn && ruleObject.Validation.dependsOn.indexOf('submitDate') >= 0) {
                  setSubmitDateShouldGreaterThan({
                    section: sectionItem.key,
                    field: fieldItem.key,
                    value: ruleObject.Validation.value,
                  });
                } else if (
                  fieldItem.key == 'campaignEndDate' &&
                  ruleObject.Validation.dependsOn &&
                  ruleObject.Validation.dependsOn.indexOf('campaignStartDate') >= 0
                ) {
                  if (ruleObject.Validation.operator == 'eq' && ruleObject.Validation.value == 0) {
                    setCampaignEndDateEqualToStartDate({
                      setEqual: true,
                      sectionKey: sectionItem.key,
                      startDateItem: 'campaignStartDate',
                      fieldKey: fieldItem.key,
                    });
                  }
                  if (ruleObject.Validation.operator == 'gte' && ruleObject.Validation.value > 0) {
                    setCampaignEndDateGreaterThanTarget({
                      setGreateThan: true,
                      sectionKey: sectionItem.key,
                      startDateItem: 'campaignStartDate',
                      fieldKey: fieldItem.key,
                      targetDays: Number(ruleObject.Validation.value),
                    });
                  }
                } else {
                  mandatoryArray.push({
                    section: sectionItem.key,
                    name: fieldItem.key,
                    fieldType: ErrorFieldType.IMMEDIATE,
                    condition: (updatedFormState: any) => {
                      return validationCompareChecking(
                        sectionItem.key,
                        fieldItem.key,
                        updatedFormState,
                        ruleObject.Validation,
                      );
                    },
                  });
                  break;
                }
                break;
              default:
                break;
            }
          }

          if (fieldItem.type == 'currency') {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.IMMEDIATE,
              condition: (updatedFormState: any) => {
                return validationCurrencyChecking(sectionItem.key, fieldItem.key, updatedFormState);
              },
            });
          }
        });
      });

    const participantSection =
      dataStructure.sections.filter((item: any) => item.key == 'participant').length > 0
        ? dataStructure.sections.filter((item: any) => item.key == 'participant')[0]
        : undefined;

    if (participantSection) {
      const participantSectionField = convertArrayToObject(participantSection.sectionFields, 'key');
      if (participantSectionField['tnc']) {
        let ruleObject = convertArrayToObject(participantSectionField['tnc'].rules, 'title');
        if (ruleObject.Mandatory && ruleObject.Mandatory.value == true) {
          mandatoryArray.push({
            section: 'participant',
            name: 'tnc',
            fieldType: ErrorFieldType.MANDATORY,
          });
        }
      }
      if (participantSectionField['rsvpResponse']) {
        let ruleObject = convertArrayToObject(participantSectionField['rsvpResponse'].rules, 'title');
        if (ruleObject.Mandatory && ruleObject.Mandatory.value == true) {
          setRSVPMandatory(true);
        }
      }
      if (participantSectionField['participantName']) {
        let compareRules = participantSectionField['participantName'].rules.filter(
          (item: any) => item.key == 'compare',
        );
        let compareRulesObject = convertArrayToObject(compareRules, 'operator');
        let setRules = {
          ...(compareRulesObject.gte && compareRulesObject.gte.value && { gte: compareRulesObject.gte.value }),
          ...(compareRulesObject.lte && compareRulesObject.lte.value && { lte: compareRulesObject.lte.value }),
        };
        setParticipantNameRules(setRules);
      }
    }
    setValidationArray(mandatoryArray);
  };

  useEffect(() => {
    validationHandler.immediateErrorValidator();

    // Set campaignEndDate be same as campaignStartDate if validation rule applied
    if (campaignEndDateEqualToStartDate && campaignEndDateEqualToStartDate.setEqual) {
      const sectionKey = campaignEndDateEqualToStartDate.sectionKey;
      const fieldKey = campaignEndDateEqualToStartDate.fieldKey;
      const startDayKey = campaignEndDateEqualToStartDate.startDateItem;
      const currentEndDay =
        formState[sectionKey] && formState[sectionKey][fieldKey]
          ? formState[sectionKey] && formState[sectionKey][fieldKey]
          : null;
      const dayEndOfStartDay =
        formState[sectionKey] && formState[sectionKey][startDayKey]
          ? moment(formState[sectionKey][startDayKey]).endOf('day').toDate()
          : null;
      if (dayEndOfStartDay && !moment(currentEndDay).isSame(dayEndOfStartDay)) {
        formDispatch({
          type: 'MODIFY_FIELD',
          payload: { section: sectionKey, field: `${fieldKey}`, value: dayEndOfStartDay },
        });
      }
    }

    if (campaignEndDateGreaterThanTarget?.setGreateThan) {
      const sectionKey = campaignEndDateGreaterThanTarget.sectionKey;
      const fieldKey = campaignEndDateGreaterThanTarget.fieldKey;
      const startDayKey = campaignEndDateGreaterThanTarget.startDateItem;
      const currentEndDay =
        formState[sectionKey] && formState[sectionKey][fieldKey]
          ? formState[sectionKey] && formState[sectionKey][fieldKey]
          : null;
      const dayEndDate =
        formState[sectionKey] && formState[sectionKey][startDayKey]
          ? moment(formState[sectionKey][startDayKey]).add(campaignEndDateGreaterThanTarget.targetDays, 'days').toDate()
          : null;
      if (dayEndDate && !moment(currentEndDay).isSame(dayEndDate)) {
        validationHandler.onDismissErrorHandler(`${sectionKey}_${fieldKey}`, dayEndDate);
        formDispatch({
          type: 'MODIFY_FIELD',
          payload: { section: sectionKey, field: `${fieldKey}`, value: dayEndDate },
        });
      }
    }
    if (currentCampaign.campaignId) {
      if (currentStep === CampaignDetailPageStep.CAMPAIGN_INFO) {
        if (
          (!isEmpty(previousFormState.campaign) &&
            !isEmpty(formState.campaign) &&
            !isEqual(previousFormState.campaign, formState.campaign)) ||
          (!isEmpty(previousFormState.applicant) &&
            !isEmpty(formState.applicant) &&
            !isEqual(previousFormState.applicant, formState.applicant))
        ) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.PARTICIPANT) {
        if (
          !isEmpty(previousFormState.participant) &&
          !isEmpty(formState.participant) &&
          !isEqual(previousFormState.participant, formState.participant)
        ) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.ATTACHMENT) {
        if (!isEmpty(formState.attachments) && !isEqual(previousFormState.attachments, formState.attachments)) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.EVNET_DOCUMENT) {
        if (
          !isEmpty(previousFormState.postDocuments) &&
          !isEmpty(formState.postDocuments) &&
          !isEqual(previousFormState.postDocuments, formState.postDocuments)
        ) {
          setFormStateUpdated(true);
        }
      }
    } else {
      if (currentStep === CampaignDetailPageStep.CAMPAIGN_INFO) {
        if (!isEqual(previousFormState.campaign, formState.campaign)) {
          setFormStateUpdated(true);
        }
        if (!isEmpty(previousFormState.applicant) && isEqual(previousFormState.applicant, formState.applicant)) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.PARTICIPANT) {
        if (!isEqual(previousFormState.participant, formState.participant)) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.ATTACHMENT) {
        if (!isEqual(previousFormState.attachments, formState.attachments)) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.EVNET_DOCUMENT) {
        if (!isEqual(previousFormState.postDocuments, formState.postDocuments)) {
          setFormStateUpdated(true);
        }
      }
    }
    setPreviousFormState(formState);

    // eslint-disable-next-line
  }, [formState.count]);

  /** if step one has error */
  const checkStepHaveError = (currentStep: number = 0): Record<string, boolean> => {
    let isInfoValid = true;
    let isParticipantValid = true;
    let isAttachmentValid = true;
    if (campaignTypeStructureData) {
      const infoSection: string[] = [];
      const participantSection = [];
      const attachmentSection = [];
      for (let index = 0; index < campaignTypeStructureData.sections.length; index++) {
        const section = campaignTypeStructureData.sections[index];
        if ('postDocuments' !== section.key) {
          if (section.key === 'participant') {
            participantSection.push(section.key);
          } else if (section.key === 'attachments') {
            attachmentSection.push(section.key);
          } else {
            infoSection.push(section.key);
          }
        }
      }
      const { currentErrorState, formState } = validationHandler.onSubmitErrorValidator();
      const mandatoryErrorState = { ...currentErrorState.mandatory };
      const immediateErrorState = { ...currentErrorState.immediate };

      for (const key in mandatoryErrorState) {
        if (Object.prototype.hasOwnProperty.call(mandatoryErrorState, key)) {
          const status = mandatoryErrorState[key];
          const sectionKey = key.toString().split('_')[0];
          if (infoSection.includes(sectionKey) && status && isInfoValid) {
            isInfoValid = false;
          }
          if (participantSection.includes(sectionKey) && status) {
            isParticipantValid = false;
          }
          if (attachmentSection.includes(sectionKey) && status) {
            isAttachmentValid = false;
          }
        }
      }

      for (const key in immediateErrorState) {
        if (Object.prototype.hasOwnProperty.call(immediateErrorState, key)) {
          const status = immediateErrorState[key];
          const sectionKey = key.toString().split('_')[0];
          if (infoSection.includes(sectionKey) && status && isInfoValid) {
            isInfoValid = false;
          }
        }
      }

      setStep1Warning(!isInfoValid);
      setStep2Warning(!isParticipantValid);
      setStep3Warning(!isAttachmentValid);
    }
    return { isInfoValid, isParticipantValid, isAttachmentValid };
  };

  const onSubmitDocuments = async () => {
    const postDocs = formState.postDocuments;
    postDocs.triggerSubmit = true;
    const details = {
      postDocuments: formState.postDocuments,
    };

    const { hasError } = validationHandler.onSubmitErrorValidator({
      include: {
        postDocuments: { invoice: true, expenseListForm: true, attendanceList: true },
      },
    });
    if (hasError && campaignTypeStructureData) {
      const submitFail = Translation('agencyCampaign.create.submitFail');
      checkStepHaveError();
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: submitFail,
            content: Translation('agencyCampaign.create.fillAll'),
          },
        ]),
      );
      return;
    }
    await updateCampaign(details, currentCampaign.campaignId, dispatch);
    await reloadData();
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Event document saved successfully`,
        },
      ]),
    );
    setFormStateUpdated(false);
  };

  const onSaveCampaign = async ({ quite }: any): Promise<any> => {
    const details = {
      ...formState,
      typeId: campaignTypeStructureData?._id || '',
      typeCode: campaignTypeStructureData?.code || '',
      ...(!formState.participant && { participant: { list: [] } }),
    };
    const fromSubmit = quite;
    if (!formState.campaign && !fromSubmit) {
      setFormStateUpdated(false);
      return;
    } else {
      formDispatch({
        type: 'STEP_POPULATE',
        payload: currentStep,
      });
    }

    // check step1 error only
    const { isInfoValid, isParticipantValid, isAttachmentValid } = checkStepHaveError(currentStep);

    if (!currentCampaign.campaignId) {
      if (!isInfoValid) {
        setFormStateUpdated(false);
        return;
      }
      const saveResult = await createNewCampaign(details, dispatch);
      if (saveResult.campaignId) {
        window.history.replaceState(null, '', `${staffCampaignPath}/detail/${saveResult.campaignId}`);
        setCurrentCampaign({ ...currentCampaign, campaignId: saveResult.campaignId, campaignObjId: saveResult._id });
      }
      reloadData();
    } else {
      if (!isInfoValid && fromSubmit) {
        setCurrentStep(CampaignDetailPageStep.CAMPAIGN_INFO);
      }
      if (!isParticipantValid) {
        if (fromSubmit) {
          setCurrentStep(CampaignDetailPageStep.PARTICIPANT);
        } else {
          if (currentStep === CampaignDetailPageStep.PARTICIPANT) {
            const saveFail = Translation('component.status.failed');
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: saveFail,
                  content: Translation('agencyCampaign.create.fillAll'),
                },
              ]),
            );
          }
        }
      }
      if (!isAttachmentValid && fromSubmit) {
        setCurrentStep(CampaignDetailPageStep.ATTACHMENT);
      }
      if (
        (!isInfoValid && currentStep === CampaignDetailPageStep.CAMPAIGN_INFO) ||
        (!isParticipantValid && currentStep === CampaignDetailPageStep.PARTICIPANT) ||
        (!isAttachmentValid && currentStep === CampaignDetailPageStep.ATTACHMENT)
      ) {
        return;
      }
      await updateCampaign(details, currentCampaign.campaignId, dispatch);
    }
    // reset form status after saving
    setFormStateUpdated(false);
    if (!quite) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Campaign saved successfully`,
          },
        ]),
      );
    }
    if (!quite && currentStep < CampaignDetailPageStep.ATTACHMENT) {
      setCurrentStep(currentStep + 1);
    }
    return true;
  };

  const onCancelCampaign = async () => {
    const data = {
      id: currentCampaign.campaignObjId,
    };
    await cancelCampaignSubmission(data, dispatch).then(() => {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Cancel submission successfully`,
          },
        ]),
      );
      reloadData();
    });
  };
  const submitCampaignRequest = async (
    details: any,
    campaignId: string,
    campaignObjId: string,
    importList: any,
    // eslint-disable-next-line @typescript-eslint/ban-types
    validateParticipantRules: Function,
  ): Promise<any> => {
    const participantSectionTypeStructureData: any = campaignTypeStructureData?.sections.filter(
      (item) => item.key == 'participant',
    )[0];
    const participantNameRules =
      participantSectionTypeStructureData.sectionFields.filter((item: any) => item.key == 'participantName').length > 0
        ? participantSectionTypeStructureData.sectionFields.filter((item: any) => item.key == 'participantName')[0]
            .rules
        : [];
    const eligibilityRules: string[] =
      participantNameRules.filter((item: any) => item.key == 'eligibility').length > 0
        ? participantNameRules.filter((item: any) => item.key == 'eligibility')[0].value
        : [];
    const needEligibilityCheck = (eligibilityRules as string[]).length > 0;
    if (needEligibilityCheck && importList) {
      const { successList, failList } = await checkAgentImportFail(
        importList,
        campaignTypeStructureData,
        campaignObjId,
        eligibilityRules,
        dispatch,
        lang,
      );
      if (failList?.length) {
        await ModalService.open({
          title: Translation('agencyCampaign.create.eligibilityCheck'),
          children: renderfailImportList(failList, async () => {
            const agentCodeList = failList.map(({ agentCode }: any) => {
              return agentCode;
            });
            const data = {
              id: currentCampaign.campaignObjId,
              agentList: agentCodeList,
            };
            await removeParticipant(data, dispatch).then(async () => {
              const passValidator = await validateParticipantRules(successList);
              if (!passValidator) {
                return;
              }
              return submitCampaign(details, campaignId, dispatch);
            });
          }),
          maxWidth: 'md',
          fullWidth: false,
          contentContainerStyle: { padding: '0 20px' },
        });
      } else {
        await submitCampaign(details, campaignId, dispatch);
      }
    } else {
      await submitCampaign(details, campaignId, dispatch);
    }
  };

  const onSubmitCampaign = async () => {
    formDispatch({
      type: 'STEP_POPULATE',
      payload: [
        CampaignDetailPageStep.CAMPAIGN_INFO,
        CampaignDetailPageStep.PARTICIPANT,
        CampaignDetailPageStep.ATTACHMENT,
      ],
    });
    const result = await onSaveCampaign({ quite: true });
    if (!result) {
      return;
    }
    setFormStateUpdated(false);
    const submitFail = Translation('agencyCampaign.create.submitFail');
    const startDate = get(formState, `${submitDateShouldGreaterThan.section}.${submitDateShouldGreaterThan.field}`);

    if (startDate) {
      const currentDate = new Date();
      const minStartDate = addBusinessDays(currentDate, submitDateShouldGreaterThan.value);
      if (moment(startDate).diff(minStartDate, 'days') < 0) {
        const msg =
          submitDateShouldGreaterThan.value === 0
            ? Translation('agencyCampaign.create.submit')
            : TranslationWithVariable('agencyCampaign.create.submit.days', submitDateShouldGreaterThan.value);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: submitFail,
              content: msg,
            },
          ]),
        );
        setCurrentStep(CampaignDetailPageStep.CAMPAIGN_INFO);
        return;
      }
    }

    let { hasError, currentErrorState } = validationHandler.onSubmitErrorValidator({
      exclude: {
        postDocuments: true,
      },
    });
    if (hasError && campaignTypeStructureData) {
      checkStepHaveError(currentStep);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: submitFail,
            content: Translation('agencyCampaign.create.fillAll'),
          },
        ]),
      );
      return;
    }

    //Check amount of participant
    let getParticipantList: any;
    const validateParticipantRules = async (participants?: any[]) => {
      if (participantNameRules) {
        getParticipantList = participants
          ? { docs: participants, totalDocs: participants.length }
          : await fetchParticipantList(
              {
                limit: 10000,
                page: 1,
                search: '',
                id: currentCampaign.campaignObjId,
              },
              [],
              dispatch,
            );
        const { gte: min, lte: max } = participantNameRules;
        if (min) {
          if (getParticipantList.totalDocs < min) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: submitFail,
                  content: `${min} ${Translation('agencyCampaign.create.participantMin')}`,
                },
              ]),
            );
            setCurrentStep(CampaignDetailPageStep.PARTICIPANT);
            return;
          }
        }
        if (max) {
          if (getParticipantList.totalDocs > max) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: submitFail,
                  content: `${max} ${Translation('agencyCampaign.create.participantMax')}`,
                },
              ]),
            );
            setCurrentStep(CampaignDetailPageStep.PARTICIPANT);
            return;
          }
        }
      }
      return true;
    };
    const passValidator = await validateParticipantRules();
    if (!passValidator) {
      return;
    }

    // Check if RSVP respond is needed
    if (RSVPMandatory && currentCampaign.campaignObjId) {
      const isAllRsvpResponsed = await checkAllRsvpResponded(currentCampaign.campaignObjId, dispatch);
      if (!isAllRsvpResponsed.allResponded) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: submitFail,
              content: `Please collect all the RSVP response before submit`,
            },
          ]),
        );
        setCurrentStep(CampaignDetailPageStep.PARTICIPANT);
        return;
      }
    }

    const details = {
      ...formState,
      typeId: campaignTypeStructureData?._id || '',
      typeCode: campaignTypeStructureData?.code || '',
    };

    if (!currentCampaign.campaignId) {
      const saveResult = await createNewCampaign(details, dispatch);
      if (saveResult.campaignId) {
        setCurrentCampaign({ ...currentCampaign, campaignId: saveResult.campaignId });
        await submitCampaignRequest(
          details,
          saveResult.campaignId,
          saveResult._id,
          getParticipantList?.docs,
          validateParticipantRules,
        );
        reloadData();
      }
    } else {
      await submitCampaignRequest(
        details,
        currentCampaign.campaignId,
        currentCampaign.campaignObjId,
        getParticipantList?.docs,
        validateParticipantRules,
      );
      reloadData();
    }

    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Submitted successfully`,
        },
      ]),
    );

    setCurrentStep(CampaignDetailPageStep.APPROVAL);
  };

  const onImportLead = async () => {
    if (currentCampaign.campaignId) {
      const file = leadUploadFile;
      try {
        setLoading(true);
        const createBlobRes = await createBlobAsAgent(
          { mimeType: file.type, accessLevel: 'anonymous', module: 'agencyCampaign', originalFilename: file.name },
          dispatch,
        );
        await fileUpload(createBlobRes[0].url, file, () => {});
        const blobDetail = await getBlobAsAgent({ resourceIds: createBlobRes[0].blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          const details = {
            campaignId: currentCampaign.campaignId,
            resourceId: result.blobId,
            filename: file.name,
          };

          await uploadLead(details, dispatch);
          // clear state when import done
          setLeadUploadList(undefined);
          setLeadUploadFile(undefined);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('agencyCampaign.lead.processing'),
                content: Translation('agencyCampaign.lead.uploadDone'),
              },
            ]),
          );
        }
      } catch (err) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Fail',
              content: `Lead upload failed`,
            },
          ]),
        );
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Fail',
            content: `Lead upload failed`,
          },
        ]),
      );
    }
  };

  const backToList = () => {
    if (displaySaveBtn && !disabledEdit && formStateUpdated) {
      PruToast({
        message: Translation('agencyCampaign.common.saveToast'),
      });
      return;
    }
    history.push(`${staffCampaignPath}`);
  };

  const removeSelectedParticipant = () => {
    const agentCodeList = participantSelected.map(({ agentCode }: any) => {
      return agentCode;
    });

    if (currentCampaign.campaignObjId) {
      const data = {
        id: currentCampaign.campaignObjId,
        agentList: agentCodeList,
      };
      removeParticipant(data, dispatch).then(() => {
        setParticipantSelected([]);
      });
    }
  };

  const sendInvitationToParticipant = () => {
    const agentCodeList = participantSelected.map(({ agentCode }) => {
      return agentCode;
    });

    if (currentCampaign.campaignObjId) {
      const data = {
        id: currentCampaign.campaignObjId,
        agentList: agentCodeList,
      };
      sendInvitation(data, dispatch)
        .then(() => {
          setParticipantSelected([]);
          setSentInvite(true);
        })
        .catch((err) => {
          console.log('err===', err);
        });
    }
  };

  const invitePreviewData = {
    campaignType: campaignTypeStructureData?.agentLeadSource || '--',
    campaignName: formState?.campaign?.campaignName,
    startDate: formState?.campaign?.campaignStartDate,
    endDate: formState?.campaign?.campaignEndDate,
  };
  let submitDateTips = '';
  if (submitDateShouldGreaterThan?.value) {
    submitDateTips = TranslationWithVariable('agencyCampaign.create.submit.days', submitDateShouldGreaterThan.value);
  }

  const displaySaveBtn =
    currentStep === CampaignDetailPageStep.CAMPAIGN_INFO ||
    currentStep <= CampaignDetailPageStep.ATTACHMENT ||
    currentStep === CampaignDetailPageStep.EVNET_DOCUMENT;

  const onStepChange = (stepNumber: number) => {
    formDispatch({
      type: 'STEP_ACCESS',
      payload: stepNumber,
    });
    if (currentStep === stepNumber) {
      return;
    }
    if (displaySaveBtn && !disabledEdit && formStateUpdated) {
      PruToast({
        message: Translation('agencyCampaign.common.saveToast'),
      });
      return;
    }
    setCurrentStep(stepNumber);
  };

  return (
    <>
      <DispatchContext.Provider value={formDispatch}>
        <StateContext.Provider value={formState}>
          <div className={classes.container}>
            <CampaignDetailPageLeftBar
              campaignTypeStructureData={campaignTypeStructureData}
              onCancel={onCancelCampaign}
              onSubmit={onSubmitCampaign}
              submitDateTips={submitDateTips}
              onBack={backToList}
              formState={formState}
              currentStep={currentStep}
              step1Warning={step1Warning}
              step2Warning={step2Warning}
              step3Warning={step3Warning}
              onStepChange={(stepNumber) => {
                onStepChange(stepNumber);
              }}
              disabledEdit={disabledEdit}
              startDate={currentCampaign.startDate}
              campaginStatus={currentCampaign.campaignStatus ?? AgencyCampaignTypeStatusEnum.DRAFT}
            />
            <div className={classes.rightContentContainer}>
              <div className={classes.rightContent}>
                {campaignTypeStructureData ? (
                  currentStep == CampaignDetailPageStep.CAMPAIGN_INFO ? (
                    <CampaignInfo
                      campaignTypeStructureData={campaignTypeStructureData}
                      formDispatch={formDispatch}
                      formState={formState}
                      errorState={validationHandler.errorState}
                      campaignStatus={currentCampaign.campaignStatus}
                      disabledEdit={disabledEdit}
                      campaignId={currentCampaign.campaignId}
                      validationHandler={validationHandler}
                      campaignEndDateEqualToStartDate={campaignEndDateEqualToStartDate}
                      campaignEndDateGreaterThanTarget={campaignEndDateGreaterThanTarget}
                    />
                  ) : currentStep == CampaignDetailPageStep.PARTICIPANT ? (
                    <Participant
                      campaignTypeStructureData={campaignTypeStructureData}
                      participantRange={participantNameRules}
                      formDispatch={formDispatch}
                      formState={formState}
                      sectionKey={'participant'}
                      setParticipantSelected={setParticipantSelected}
                      errorState={validationHandler.errorState}
                      disabledEdit={disabledEdit}
                      campaignObjId={currentCampaign.campaignObjId}
                      participantSelected={participantSelected}
                      validationHandler={validationHandler}
                      startDate={startDate}
                    />
                  ) : currentStep == CampaignDetailPageStep.ATTACHMENT ? (
                    <Attachment
                      campaignTypeStructureData={campaignTypeStructureData}
                      formDispatch={formDispatch}
                      formState={formState}
                      sectionKey={'attachments'}
                      errorState={validationHandler.errorState}
                      disabledEdit={disabledEdit}
                      validationHandler={validationHandler}
                    />
                  ) : currentStep == CampaignDetailPageStep.APPROVAL ? (
                    <Approval
                      campaignTypeStructureData={campaignTypeStructureData}
                      formDispatch={formDispatch}
                      approvalHistoryList={currentCampaign.approvalHistoryList}
                      remarkList={currentCampaign.remarksList}
                      sectionKey={'approval'}
                      submitData={currentCampaign.submitData}
                      campaignObjId={currentCampaign.campaignObjId}
                      approvalData={approvalData}
                      previousApprovalData={currentCampaign.previousApprovalData}
                    />
                  ) : currentStep === CampaignDetailPageStep.EVNET_DOCUMENT ? (
                    <EventDocument
                      campaignTypeStructureData={campaignTypeStructureData}
                      formDispatch={formDispatch}
                      formState={formState}
                      sectionKey={'postDocuments'}
                      onSubmitDocuments={onSubmitDocuments}
                      errorState={validationHandler.errorState}
                      // disabledEdit={disabledEdit}
                      validationHandler={validationHandler}
                      campaign={currentCampaign}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                {/* use hide or show to keep the Lead data */}
                {campaignTypeStructureData &&
                  (campaignTypeStructureData.addLeadSetting ? (
                    <div style={{ display: currentStep !== CampaignDetailPageStep.LEAD ? 'none' : '' }}>
                      <LeadV2
                        campaignTypeStructureData={campaignTypeStructureData}
                        campaign={currentCampaign}
                        formDispatch={formDispatch}
                        formState={formState}
                        sectionKey={'approval'}
                        onLeadUploadList={(data) => {
                          setLeadUploadList(data);
                        }}
                        onLeadFileUpload={(data) => {
                          setLeadUploadFile(data);
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ display: currentStep !== CampaignDetailPageStep.LEAD ? 'none' : '' }}>
                      <Lead
                        campaignTypeStructureData={campaignTypeStructureData as any}
                        campaignStatus={currentCampaign.campaignStatus}
                        formDispatch={formDispatch}
                        formState={formState}
                        sectionKey={'approval'}
                        onLeadUploadList={(data) => {
                          setLeadUploadList(data);
                        }}
                        onLeadFileUpload={(data) => {
                          setLeadUploadFile(data);
                        }}
                        campaignId={currentCampaign.campaignId}
                      />
                    </div>
                  ))}
              </div>
              <div className={classes.bottomButtonContainer}>
                {participantSelected.length > 0 && currentStep === CampaignDetailPageStep.PARTICIPANT ? (
                  <>
                    {campaignTypeStructureData &&
                    campaignTypeStructureData.sections
                      .filter((item) => item.key == 'participant')[0]
                      .sectionFields.filter((item) => item.key == 'rsvpResponse').length > 0 ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        disabled={loading}
                        className={classes.bottomRedButton}
                        onClick={onClickInvite}
                      >
                        {Translation('app.button.invite')}
                      </Button>
                    ) : null}

                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      className={classes.bottomWhiteButton}
                      onClick={toggleOpen}
                    >
                      {Translation('app.button.remove')}
                    </Button>
                  </>
                ) : displaySaveBtn ? (
                  <>
                    {!disabledEdit && formStateUpdated && (
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          className={classes.bottomRedButton}
                          onClick={onSaveCampaign}
                        >
                          {Translation('app.button.save')}
                        </Button>

                        <Button
                          variant="outlined"
                          color="inherit"
                          className={classes.bottomWhiteButton}
                          onClick={() => {
                            setShowDiscardPrompt(true);
                          }}
                        >
                          {Translation('app.button.discard')}
                        </Button>
                      </>
                    )}
                  </>
                ) : currentStep == CampaignDetailPageStep.LEAD && leadUploadList && leadUploadFile ? (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      disabled={loading}
                      className={classes.bottomRedButton}
                      onClick={onImportLead}
                    >
                      {Translation('app.button.import')}
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </StateContext.Provider>
      </DispatchContext.Provider>

      {/* remove participant dialog start */}
      <Dialog
        open={open}
        onClose={toggleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{Translation('agencyCampaign.create.removeTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Translation('agencyCampaign.create.removeText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btnRemoveWrap}>
          <Button onClick={toggleOpen} variant="outlined" color="inherit">
            {Translation('app.button.cancel')}
          </Button>
          <Button onClick={handleOk} variant="contained" color="secondary" autoFocus>
            {Translation('app.button.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* remove participant dialog end */}
      {/* invite dialog start */}
      <InvitePreview
        open={inviteDialog}
        sentInvite={sentInvite}
        onOk={sendInvitationToParticipant}
        onClose={toggleInviteDialog}
        data={invitePreviewData}
      />
      {/* invite dialog end */}
      <Dialog open={!!showDiscradPrompt} onClose={() => setShowDiscardPrompt(false)}>
        <div className={classes.dialogContainer}>{Translation('agencyCampaign.create.confirmDiscard')}</div>
        <div className={classes.btnContainer}>
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowDiscardPrompt(false);
              window.location.reload();
            }}
          >
            {Translation('global.text.yes')}
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              setShowDiscardPrompt(false);
            }}
          >
            {Translation('global.text.cancel')}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default CampaignDetailPage;
