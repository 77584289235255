import { FC, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { LayoutSplashScreen } from 'src/app/layout';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { fetchIncentiveItem } from '../../../network/incentiveCrud';
import { IncentiveFormMode, IncentiveDetail } from '../../../types/incentive-types';
import { fetchKpiList } from '../../../network/kpiCrud';
import { KpiTypeEnum, KpiItem } from '../../../types/kpi-types';
import IncentiveDetailForm from './components/IncentiveDetailForm';

const IncentiveDetailPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { formMode, code } = useMemo(
    () => ({
      formMode: id ? IncentiveFormMode.EDIT : IncentiveFormMode.CREATE,
      code: id ? id : undefined,
    }),
    [id],
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [incentiveDetail, setIncentiveDetail] = useState<IncentiveDetail>();
  const [kpiList, setKpiList] = useState<KpiItem[]>([]);
  const [nonRankKpiList, setNonRankKpiList] = useState<KpiItem[]>([]);
  const [categoryKpiList, setCategoryKpiList] = useState<KpiItem[]>([]);

  const reloadKpiList = async () => {
    try {
      const res = await fetchKpiList({ pagination: false }, dispatch);
      setKpiList(res.docs);
      setNonRankKpiList(res.docs.filter((item) => item.type !== KpiTypeEnum.RANK));
      setCategoryKpiList(res.docs.filter((item) => item.type === KpiTypeEnum.CATEGORY));
    } catch (err) {}
  };

  const reloadData = async () => {
    setIsLoading(true);
    await reloadKpiList();
    if (code) {
      fetchIncentiveItem(code, dispatch)
        .then((result) => {
          setIncentiveDetail(result);
        })
        .catch(() => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    reloadData();
  }, [code]);

  return (
    <>
      {formMode === IncentiveFormMode.CREATE &&
        (isLoading ? (
          <LayoutSplashScreen />
        ) : (
          <IncentiveDetailForm
            formMode={formMode}
            kpiList={kpiList}
            nonRankKpiList={nonRankKpiList}
            categoryKpiList={categoryKpiList}
            onRouteTo={(route) => history.push(route)}
          />
        ))}
      {formMode === IncentiveFormMode.EDIT &&
        (isLoading || !incentiveDetail ? (
          <LayoutSplashScreen />
        ) : (
          <IncentiveDetailForm
            code={code}
            formMode={formMode}
            kpiList={kpiList}
            nonRankKpiList={nonRankKpiList}
            categoryKpiList={categoryKpiList}
            incentiveDetail={incentiveDetail}
            onRouteTo={(route) => history.push(route)}
          />
        ))}
    </>
  );
};

export default IncentiveDetailPage;
