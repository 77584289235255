import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { regionLocale } from 'src/app/i18n';
import { PaginateList } from 'src/app/common/types';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { uploadStatusPath } from '../../UploadStatusRoutes';
import { UploadStatusEnum, UploadStatusItem } from '../../../../types/upload-status-types';

type UploadStatusListProps = {
  isLoading: boolean;
  uploadStatusList?: PaginateList<UploadStatusItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const UploadStatusList: FC<UploadStatusListProps> = ({ isLoading, uploadStatusList, onRefresh, onChangePage }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();

  return (
    <PruTable
      title={Translation('incentive.upload_status.list')}
      disableBulkSelect
      operationDef={[]}
      columnDef={[
        {
          isId: true,
          hidden: true,
          keyIndex: '_id',
          displayName: '',
          renderData: (row) => '',
        },
        {
          keyIndex: 'filename',
          displayName: Translation('incentive.upload_status.common.filename'),
          renderData: (row) => row.filename || '-',
        },
        ...(() =>
          regionLocale.map((locale) => ({
            keyIndex: `name-${locale}`,
            displayName: Translation(`incentive.common.name.${locale}`),
            renderData: (row: UploadStatusItem) => row.name[locale] || '-',
          })))(),
        {
          keyIndex: 'kpiMatch',
          displayName: Translation('incentive.upload_status.common.kpi_matched'),
          renderData: (row) => row.kpiMatch.length.toString(),
        },
        {
          keyIndex: 'kpiMiss',
          displayName: Translation('incentive.upload_status.common.kpi_missing'),
          renderData: (row) =>
            row.kpiMiss.length > 0 ? (
              <a style={{ color: 'red' }} onClick={() => history.push(`${uploadStatusPath}/${row._id}/failed/list`)}>
                {row.kpiMiss.length.toString()}
              </a>
            ) : (
              row.kpiMiss.length.toString()
            ),
        },
        {
          keyIndex: 'status',
          displayName: Translation('incentive.upload_status.common.upload_status'),
          renderData: (row) =>
            row.status !== UploadStatusEnum.SUCCESS ? <span style={{ color: 'red' }}>{row.status}</span> : row.status,
        },
        {
          keyIndex: 'reason',
          displayName: Translation('incentive.upload_status.common.reason'),
          renderData: (row) =>
            row.status !== UploadStatusEnum.SUCCESS ? <span style={{ color: 'red' }}>{row.reason}</span> : row.reason,
        },
        {
          keyIndex: 'updatedAt',
          align: 'center',
          displayName: Translation('incentive.upload_status.common.updated_at'),
          renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(new Date(row.updatedAt), 'datetime') : '-'),
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={uploadStatusList?.docs}
      totalPages={uploadStatusList?.totalPages}
      totalRecords={uploadStatusList?.totalDocs}
      onChangePage={onChangePage}
    />
  );
};

export default UploadStatusList;
