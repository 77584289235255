import { FilterState, ReturnCategories } from 'src/app/modules/Recruitment/types/approval-types';
import { DateFormat, formatDate } from './date.util';
import { CATEGORY_MAP, REMARK_OTHERS, SELECT_ASSIGNEE_MODAL_I18N } from './constant';
import { Translation } from 'src/app/i18n';
import { getI18nData } from './i18n.util';

export const formatListParams = (filterState: FilterState, isWorkflowMgr?: boolean) => {
  const {
    submittedDateFrom: initSubmittedDateFrom,
    submittedDateTo: initSubmittedDateTo,
    applicationId: applicationIds,
    applicantId: applicantIds,
    applicationType: applicationTypes,
    formType: formTypes,
    sort,
    taskStatus: assignTaskStatus,
    taskAssignee: approvalTaskAssignees,
    page,
    ...rest
  } = filterState;

  const submittedDateFrom = initSubmittedDateFrom
    ? formatDate(new Date(initSubmittedDateFrom).toISOString(), DateFormat.yearMonthDay)
    : initSubmittedDateFrom;
  const submittedDateTo = initSubmittedDateTo
    ? formatDate(new Date(initSubmittedDateTo).toISOString(), DateFormat.yearMonthDay)
    : initSubmittedDateTo;

  // new add sort
  const reqParams = {
    ...rest,
    applicationIds,
    applicantIds,
    page,
    assignTaskStatus,
    submissionDateFrom: submittedDateFrom,
    submissionDateTo: submittedDateTo,
    approvalTaskAssignees,
    applicationTypes,
    formTypes,
    sortBy: sort?.key,
    orderBy: sort?.value,
    ...(isWorkflowMgr ? {} : { taskAssignee: undefined }),
  };

  for (const key in reqParams) {
    if (!reqParams[key as keyof typeof reqParams]) {
      delete reqParams[key as keyof typeof reqParams];
    }
  }

  return reqParams;
};

export const getReturnValue = (selected: any, type: ReturnCategories) => {
  const role = Translation(SELECT_ASSIGNEE_MODAL_I18N[type]);
  const getRemark = (remark: string) => `${role}: ${remark}`;
  return selected[type]?.checked
    ? {
        remark:
          selected[type].remark?.key === REMARK_OTHERS
            ? getRemark(selected[type].remark?.remark)
            : getRemark(Translation(selected[type].remark?.key)),
        assigneeRole: CATEGORY_MAP[type],
      }
    : undefined;
};

export const formatReturnParams = (selected: any, showAgentLeader?: boolean) => {
  const result: any = [];

  [ReturnCategories.applicant, ReturnCategories.recruiter, ReturnCategories.agentLeader].forEach((type) => {
    if (selected) {
      const res = getReturnValue(selected, type);
      if (res) {
        result.push(
          !showAgentLeader && type === ReturnCategories.recruiter
            ? { ...res, assigneeRole: CATEGORY_MAP[ReturnCategories.agentLeader] }
            : res,
        );
      }
    }
  });

  return {
    result,
    remark: result.map((i: any) => i.remark).join('; '),
  };
};
