import React, { useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  FormControlLabel,
  Radio,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { get } from 'lodash';
import { CampaignStructureItem, campaignStructureSection } from '../../../../types/staff-campaign-types';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { convertArrayToObject } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import {
  MANDATORY_FIELD_ERROR_TEXT,
  VALIDATION_FIELD_ERROR_TEXT,
  INVALID_CURRENCY_ERROR_TEXT,
} from '../../../../../../common/constants';
import { validationMandatoryDependsChecking } from '../../../../utils/validations';
import moment from 'moment';

const useStyles = makeStyles()((theme) => ({
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: 'white',
    height: 90,
    marginBottom: 12,
  },
  valueBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 5,
  },
  value: {},
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    width: '20%',
    minWidth: '200px',
    paddingRight: 15,
  },
  fieldContainer: {
    width: '40%',
    minWidth: 200,
    boxSizing: 'border-box',
    marginRight: 15,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  fieldSectionContainer: {
    flex: 1,
    backgroundColor: 'white',
    padding: 20,
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    paddingLeft: 14,
  },
  formInput: { width: '100%', height: 45, marginBottom: 8 },
}));

interface CampaignInfoProps {
  campaignTypeStructureData: CampaignStructureItem;
  formDispatch: (data: any) => void;
  formState: any;
  errorState: any;
  campaignStatus?: string;
  disabledEdit?: boolean;
  campaignId?: string;
  validationHandler: any;
  campaignEndDateEqualToStartDate?: any;
  campaignEndDateGreaterThanTarget?: any;
}

const CampaignInfo: React.FC<CampaignInfoProps> = ({
  campaignTypeStructureData,
  formDispatch,
  formState,
  errorState,
  campaignStatus,
  disabledEdit,
  campaignId,
  validationHandler,
  campaignEndDateEqualToStartDate,
  campaignEndDateGreaterThanTarget,
}) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const handleChange = (val: string | string[], sectionItemKey: string, fieldsItemKey: string) => {
    const value = typeof val === 'string' ? val.split(',') : val;
    validationHandler.onDismissErrorHandler(`${sectionItemKey}_${fieldsItemKey}`, value);
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: { section: sectionItemKey, field: fieldsItemKey, value: value },
    });
  };

  const renderOptions = (fieldsItem: any) => {
    return fieldsItem.options.map((item: { key: string; label: string }) => {
      const { key, label } = item;
      return (
        <option key={key} value={key}>
          {label ? label : key}
        </option>
      );
    });
  };

  const renderMultiSelectOptions = (sectionItem: any, fieldsItem: any) => {
    const arrSelectedItem = get(formState, `${sectionItem.key}.${fieldsItem.key}`, []);
    return fieldsItem.options.map((item: { key: string; label: string }) => {
      const { key, label } = item;
      return (
        <MenuItem key={key} value={key}>
          <Checkbox checked={arrSelectedItem.indexOf(label) > -1} />
          <ListItemText primary={label} />
        </MenuItem>
      );
    });
  };

  const checkIsAutoPopulatedField = (sectionItem: any, fieldsItem: any) => {
    let isAutoPopulatedField = false;
    switch (fieldsItem.key) {
      case 'applicantName':
      case 'applicantAgentCode':
      case 'applicantEmail':
        isAutoPopulatedField = true;
        break;
      case 'campaignEndDate':
        if (
          (campaignEndDateEqualToStartDate && campaignEndDateEqualToStartDate.setEqual) ||
          (campaignEndDateGreaterThanTarget && campaignEndDateGreaterThanTarget.setEqual)
        ) {
          isAutoPopulatedField = true;
        }
        break;
      default:
        break;
    }

    return isAutoPopulatedField;
  };

  const handleNumberError = (errorState: any, sectionItem: campaignStructureSection, fieldItem: any) => {
    const path = `${sectionItem.key}_${fieldItem.key}`;
    let errMsg = '';
    if (errorState.mandatory[path]) {
      errMsg = MANDATORY_FIELD_ERROR_TEXT;
    }
    if (errorState.immediate[path]) {
      errMsg = VALIDATION_FIELD_ERROR_TEXT;
      const arr = fieldItem.rules?.filter((item: any) => item.key === 'compare');
      if (arr.length > 0) {
        // rules format:
        // { "key": "compare", "operator": "gte","value": 30, "dependsOn": "submitDate" }
        const { operator, value, dependsOn } = arr[0];
        let fieldTitle = '';
        if (dependsOn) {
          const dependsOnField = sectionItem.sectionFields.filter((field) => field.key === dependsOn);
          if (dependsOnField.length > 0) {
            fieldTitle = dependsOnField[0].title || dependsOn;
          }
        }
        errMsg = TranslationWithVariable(`common.number.${operator}`, value) + fieldTitle;
      }
    }

    return errMsg;
  };

  const handleDateError = (errorState: any, sectionItem: campaignStructureSection, fieldItem: any) => {
    const path = `${sectionItem.key}_${fieldItem.key}`;
    let errMsg = '';
    if (errorState.mandatory[path]) {
      errMsg = MANDATORY_FIELD_ERROR_TEXT;
    }
    if (errorState.immediate[path]) {
      errMsg = VALIDATION_FIELD_ERROR_TEXT;
      const arr = fieldItem.rules?.filter((item: any) => item.key === 'compare');
      if (arr.length > 0) {
        // rules format:
        // { "key": "compare", "operator": "gte","value": 30, "dependsOn": "submitDate" }
        const { operator, value, dependsOn } = arr[0];
        let fieldTitle = '';
        if (dependsOn) {
          const dependsOnField = sectionItem.sectionFields.filter((field) => field.key === dependsOn);
          if (dependsOnField.length > 0) {
            fieldTitle = dependsOnField[0].title || dependsOn;
          }
        }
        errMsg = TranslationWithVariable(`common.date.${operator}`, value) + fieldTitle;
      }
    }

    return errMsg;
  };

  const _renderMandatory = (ruleObject: any, sectionItemKey: string, fieldItemKey: string) => {
    const mandatoryKey = get(ruleObject, 'Mandatory.key');
    const mandatoryValue = get(ruleObject, 'Mandatory.value');
    if (mandatoryKey === 'mandatory' && mandatoryValue) {
      return <span className={classes.mandatory}>*</span>;
    } else if (mandatoryKey === 'mandatoryDepends') {
      const isMandatory = validationMandatoryDependsChecking(
        sectionItemKey,
        fieldItemKey,
        formState,
        ruleObject?.Mandatory,
        false,
      );
      if (isMandatory) {
        return <span className={classes.mandatory}>*</span>;
      }
    }
    return null;
  };

  const _renderSection = (sectionItem: campaignStructureSection) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }} key={sectionItem.key}>
        <div className={classes.sectionTitle}>{Translation(`agencyCampaign.section.${sectionItem.key}`)}</div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {sectionItem.sectionFields.map((fieldsItem) => {
            let ruleObject = convertArrayToObject(fieldsItem.rules, 'title');
            //Auto-populate current agent info
            let isAutoPopulatedField = checkIsAutoPopulatedField(sectionItem, fieldsItem);
            const hasError =
              errorState.mandatory[`${sectionItem.key}_${fieldsItem.key}`] ||
              errorState.immediate[`${sectionItem.key}_${fieldsItem.key}`];
            return (
              <div className={classes.fieldContainer} key={fieldsItem.title}>
                <span className={classes.field}>
                  {Translation(`agencyCampaign.field.${fieldsItem.key}`)}
                  {_renderMandatory(ruleObject, sectionItem.key, fieldsItem.key)}
                </span>
                <div>
                  {fieldsItem.type === 'number' ? (
                    <TextField
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      disabled={disabledEdit || isAutoPopulatedField}
                      error={hasError}
                      margin="normal"
                      fullWidth
                      helperText={handleNumberError(errorState, sectionItem, fieldsItem)}
                      value={
                        formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
                          ? formState[sectionItem.key][fieldsItem.key]
                          : ''
                      }
                      onChange={(e) => {
                        validationHandler.onDismissErrorHandler(`${sectionItem.key}_${fieldsItem.key}`, e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: e.target.value },
                        });
                      }}
                    />
                  ) : fieldsItem.type == 'text' ? (
                    <TextField
                      disabled={disabledEdit || isAutoPopulatedField}
                      error={hasError}
                      margin="normal"
                      fullWidth
                      helperText={
                        errorState.mandatory[`${sectionItem.key}_${fieldsItem.key}`] && MANDATORY_FIELD_ERROR_TEXT
                      }
                      value={
                        formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
                          ? formState[sectionItem.key][fieldsItem.key]
                          : ''
                      }
                      onChange={(e) => {
                        validationHandler.onDismissErrorHandler(`${sectionItem.key}_${fieldsItem.key}`, e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: e.target.value },
                        });
                      }}
                    />
                  ) : fieldsItem.type == 'date' ? (
                    <PruDateTimePicker
                      disabled={disabledEdit || isAutoPopulatedField}
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          margin: 'normal',
                          variant: 'standard',
                          error: hasError,
                          helperText: handleDateError(errorState, sectionItem, fieldsItem),
                        },
                      }}
                      value={
                        formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
                          ? formState[sectionItem.key][fieldsItem.key]
                          : null
                      }
                      onChange={(date) => {
                        if (moment(date).isValid()) {
                          validationHandler.onDismissErrorHandler(`${sectionItem.key}_${fieldsItem.key}`, date);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: date },
                          });
                        }
                      }}
                    />
                  ) : fieldsItem.type == 'dropdown' ? (
                    <>
                      <Select
                        variant="standard"
                        disabled={disabledEdit}
                        native
                        error={hasError}
                        value={
                          formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
                            ? formState[sectionItem.key][fieldsItem.key]
                            : ''
                        }
                        className={classes.formInput}
                        onChange={(e) => {
                          validationHandler.onDismissErrorHandler(
                            `${sectionItem.key}_${fieldsItem.key}`,
                            e.target.value,
                          );
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              section: sectionItem.key,
                              field: `${fieldsItem.key}`,
                              value: e.target.value as string,
                            },
                          });
                        }}
                      >
                        <option aria-label="None" value="" />
                        {fieldsItem.options ? renderOptions(fieldsItem) : null}
                      </Select>
                      {errorState.mandatory[`${sectionItem.key}_${fieldsItem.key}`] && (
                        <span className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</span>
                      )}
                    </>
                  ) : fieldsItem.type == 'multipleChoice' ? (
                    <>
                      <Select
                        variant="standard"
                        labelId="multiple-name-label"
                        id="multiple-name"
                        className={classes.formInput}
                        multiple
                        value={get(formState, `${sectionItem.key}.${fieldsItem.key}`, [])}
                        onChange={(e: any) => handleChange(e?.target?.value, sectionItem.key, fieldsItem.key)}
                        renderValue={(selected: any) => selected.join(', ')}
                      >
                        {/* <option aria-label="None" value="" /> */}
                        {fieldsItem.options ? renderMultiSelectOptions(sectionItem, fieldsItem) : null}
                      </Select>
                      {errorState.mandatory[`${sectionItem.key}_${fieldsItem.key}`] && (
                        <span className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</span>
                      )}
                    </>
                  ) : fieldsItem.type == 'currency' ? (
                    <TextField
                      disabled={disabledEdit || isAutoPopulatedField}
                      error={hasError}
                      margin="normal"
                      fullWidth
                      helperText={
                        errorState.immediate[`${sectionItem.key}_${fieldsItem.key}`]
                          ? INVALID_CURRENCY_ERROR_TEXT
                          : errorState.mandatory[`${sectionItem.key}_${fieldsItem.key}`] && MANDATORY_FIELD_ERROR_TEXT
                      }
                      value={
                        formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
                          ? formState[sectionItem.key][fieldsItem.key]
                          : ''
                      }
                      onChange={(e) => {
                        validationHandler.onDismissErrorHandler(`${sectionItem.key}_${fieldsItem.key}`, e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: e.target.value },
                        });
                      }}
                    />
                  ) : (
                    <span className={classes.errorText}> {`${Translation('menu.exception')}: ${fieldsItem.type}`}</span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.infoBox}>
        <div className={classes.valueBox}>
          <div>{Translation('agencyCampaign.common.campaignType')}</div>
          <div className={classes.value}>{campaignTypeStructureData.name}</div>
        </div>
        <div className={classes.valueBox}>
          <div>{Translation('agencyCampaign.common.campaignId')}</div>
          <div className={classes.value}>{campaignId ? campaignId : `--`}</div>
        </div>
        <div className={classes.valueBox}>
          <div>{Translation('agencyCampaign.common.status')}</div>
          <div className={classes.value}>
            {campaignStatus
              ? Translation(`agencyCampaign.common.status.${campaignStatus}`)
              : Translation(`agencyCampaign.common.status.draft`)}
          </div>
        </div>
        <div className={classes.valueBox}>
          <div>{Translation('agencyCampaign.common.leadSource')}</div>
          <div className={classes.value}>{campaignTypeStructureData.agentLeadSource ?? '-'}</div>
        </div>
        <div className={classes.valueBox}>
          <div>{Translation('agencyCampaign.common.leadSubSource')}</div>
          <div className={classes.value}>
            {campaignTypeStructureData.agentLeadSubSource
              ? campaignTypeStructureData.agentLeadSubSource.join(', ')
              : '-'}
          </div>
        </div>
      </div>

      <div className={classes.fieldSectionContainer}>
        {campaignTypeStructureData.sections
          .filter((item) => item.key != 'participant' && item.key != 'attachments' && item.key != 'postDocuments')
          .map((sectionItem) => {
            return _renderSection(sectionItem);
          })}
      </div>
    </>
  );
};

export default CampaignInfo;
