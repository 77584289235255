import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { REPORT_BASE_PATH, REPORT_INCENTIVE_PATH, REPORT_INCENTIVE_RESULT_PATH } from '../../../../constants';
import { PaginateList } from '../../../../../../common/types/common-types';
import { ReportItem } from '../../../../types/report-types';
import { useDataProvider, getDayStart, getDayEnd, getDefaultDisplayDate } from 'src/app/common/utils';
import {
  ReportListParams,
  fetchIncentiveResultReportList,
  fetchReportDownloadLink,
} from '../../../../network/reportCrud';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../../common/components/ParamsProvider';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';

type IncentiveResultListingPageProps = {} & ParamsProps;

const initialState: ReportListParams = {
  requestedDateFrom: null,
  requestedDateTo: null,
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : null,
        requestedDateTo: initialParams.requestedDateTo ? getDayEnd(new Date(initialParams.requestedDateTo)) : null,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const IncentiveResultListingPage: FC<IncentiveResultListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<ReportListParams>(paramsInitiator(initialParams));

  const [reportList, setReportList] = useState<PaginateList<ReportItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<ReportItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchIncentiveResultReportList(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvFile = async (reportId: string) => {
    try {
      const reportItem = await fetchReportDownloadLink(reportId, dispatch);
      if (reportItem) {
        window.open(reportItem.url);
      }
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('incentiveResult.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            displayName: Translation('report.common.requestDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('incentiveResult.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.requestReport'),
            onClick: () =>
              history.push(`${REPORT_BASE_PATH}${REPORT_INCENTIVE_PATH}${REPORT_INCENTIVE_RESULT_PATH}/create`),
          },
        ]}
        operationDef={[
          {
            title: Translation('section.common.operation.download'),
            tooltipText: 'Download Report',
            onClick: (row) => fetchCsvFile(row._id),
            condition: (row) => row.status === 'DONE',
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'requestedDate',
            displayName: Translation('report.common.requestDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.requestedDate), 'datetime'),
          },
          {
            keyIndex: 'parameters',
            align: 'center',
            displayName: Translation('incentiveResult.common.code'),
            renderData: (row) => row.parameters.code || '-',
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('report.common.status'),
            renderData: (row) => row.status,
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.total}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default IncentiveResultListingPage;
