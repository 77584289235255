import { PruFilterDateType } from 'src/app/common/components/PruTable/PruFilter';
import { TypeGapPeriod } from '../pages/onboarding-application/form-template/list/type';

export type FormTemplateItem = any;
export type FormTemplateListParam = {
  page: number;
  limit: number;
  formType?: string;
  validateStartDate?: Date;
  validateEndDate?: Date;
  formStatus?: string;
  publishStatus?: string;
  version?: string;
  sortBy?: string;
  // sort?: string;
  orderBy?: string;
  orders?: Order[];
  validDateFrom?: Date;
  validDateTo?: Date;
  createdAtType?: PruFilterDateType;
  createdFrom?: Date;
  createdTo?: Date;
  createdBy?: string;
  lastUpdatedFrom?: Date;
  lastUpdatedTo?: Date;
  lastUpdatedBy?: string;
  publishedFrom?: Date;
  publishedTo?: Date;
  publishedBy?: string;
};
export type PaginateList<T> = {
  totalNumbers: number;
  totalPages: number;
  limit: number;
  data: T[];
  extraData: TypeGapPeriod[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  nextPage: number | null;
  page: number;
};

export enum FormTemplatePublishStatus {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
}

export enum FormTemplateStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  EXPIRED = 'Expired',
}

interface Order {
  direction: string;
  property: string;
}

export interface OnboardingTemplate {
  type: string;
  pdfTemplate: {
    types: string[];
    languages: string[];
  };
}

export interface PDFFile {
  size: number;
  id: string;
  fileName: string;
  type: string; //'application/pdf';
}
