import { Rule } from 'rc-field-form/es/interface';
import { CreateScheduleFields } from 'src/app/modules/Recruitment/types/license-exam-types';
import {
  endTimeValidator,
  lastRegistrationValidator,
  quotaValidator,
  minimumQuotaValidator,
  startTimeValidator,
  examDateValidator,
  resultValidator,
  scoreValidator,
} from './form.util';
import { Translation, TranslationWithParams } from 'src/app/i18n';
import { getLastRegistrationDateLimitDays } from './configs.util';
import { Results } from '../results/results-template';
import dayjs from 'dayjs';

export const createScheduleConfigs = (options?: { emptyMsgKey?: string }) => {
  const placeEnter = Translation('app.input.placeholder.please-enter');
  const getEmptyMsg = (label: string) =>
    TranslationWithParams(options?.emptyMsgKey ?? 'component.form-required-text', { field: label });

  return [
    {
      name: CreateScheduleFields.examCode,
      label: Translation('recruitment.exam.examCode'),
      rules: [{ required: true, message: getEmptyMsg(Translation('recruitment.exam.examCode')) }],
      placeholder: Translation('recruitment.exam.schedule.examCode_placeholder'),
    },
    {
      name: CreateScheduleFields.examNameEnglish,
      label: Translation('recruitment.exam.examName'),
      rules: [{ required: true, message: getEmptyMsg(Translation('recruitment.exam.examName')) }],
      placeholder: placeEnter,
    },
    {
      name: CreateScheduleFields.examNameLocal,
      label: Translation('recruitment.exam.examNameLocal'),
      rules: [{ required: true, message: getEmptyMsg(Translation('recruitment.exam.examNameLocal')) }],
      placeholder: placeEnter,
    },
    {
      name: CreateScheduleFields.examType,
      label: Translation('recruitment.exam.examType'),
      rules: [{ required: true, message: getEmptyMsg(Translation('recruitment.exam.examType')) }],
    },
    {
      name: CreateScheduleFields.examDate,
      label: Translation('recruitment.exam.examDate'),
      rules: [
        {
          required: true,
          validator(rule, value, callback) {
            const invalidMsg = TranslationWithParams('recruitment.exam.schedule.date_time_do_not_follow_validator', {
              field: Translation('recruitment.exam.examDate'),
            });

            const errorMsg = TranslationWithParams('recruitment.exam.schedule.exam_date_future_validator', {
              field: Translation('recruitment.exam.examDate'),
            });
            const msg = examDateValidator(
              {
                emptyMsg: getEmptyMsg(Translation('recruitment.exam.examDate')),
                rule,
                invalidMsg,
                errorMsg,
              },
              value,
            );
            callback(msg);
          },
        },
      ],
    },
    {
      name: CreateScheduleFields.startTime,
      label: Translation('recruitment.exam.examDateStartTime'),
      rules: [
        {
          required: true,
          validator(rule, value, callback) {
            const invalidMsg = TranslationWithParams('recruitment.exam.schedule.time_validator', {
              field: Translation('recruitment.exam.examDateStartTime'),
            });
            const msg = startTimeValidator(
              {
                emptyMsg: getEmptyMsg(Translation('recruitment.exam.examDateStartTime')),
                rule,
                invalidMsg,
              },
              value,
            );
            callback(msg);
          },
        },
      ],
    },
    {
      name: CreateScheduleFields.endTime,
      label: Translation('recruitment.exam.examDateEndTime'),
      rules: [
        (form: any) => ({
          required: true,
          validator(rule, value, callback) {
            const startTime = form.getFieldValue(CreateScheduleFields.startTime);
            const msg = endTimeValidator(
              {
                emptyMsg: getEmptyMsg(Translation('recruitment.exam.examDateEndTime')),
                errorMsg: Translation('recruitment.exam.schedule.endTime_validator'),
                rule,
              },
              startTime,
              value,
            );
            callback(msg);
          },
        }),
      ],
    },
    {
      name: CreateScheduleFields.examCentre,
      label: Translation('recruitment.exam.venue'),
      rules: [{ required: true, message: getEmptyMsg(Translation('recruitment.exam.venue')) }],
      placeholder: placeEnter,
    },
    {
      name: CreateScheduleFields.address,
      label: Translation('recruitment.exam.address'),
      rules: [{ required: true, message: getEmptyMsg(Translation('recruitment.exam.address')) }],
      placeholder: placeEnter,
    },
    {
      name: CreateScheduleFields.lastRegistrationDate,
      label: Translation('recruitment.exam.last_register_time'),
      rules: [
        (form: any) => ({
          required: true,
          validator(rule, value, callback) {
            const examDate = form.getFieldValue(CreateScheduleFields.examDate);
            const day = getLastRegistrationDateLimitDays();
            const errorMsg = TranslationWithParams('recruitment.exam.schedule.last_register_time_validator', { day });
            const invalidMsg = TranslationWithParams('recruitment.exam.schedule.time_validator', {
              field: Translation('recruitment.exam.last_register_time'),
            });
            const msg = lastRegistrationValidator(
              {
                rule,
                emptyMsg: getEmptyMsg(Translation('recruitment.exam.last_register_time')),
                errorMsg,
                day,
                invalidMsg,
                futureDayErrorMsg: TranslationWithParams('recruitment.exam.schedule.exam_date_future_validator', {
                  field: Translation('recruitment.exam.last_register_time'),
                }),
              },
              examDate,
              value,
            );

            callback(msg);
          },
        }),
      ],
    },
    {
      name: CreateScheduleFields.quota,
      label: Translation('recruitment.exam.examQuota'),
      rules: [
        {
          required: true,
          validator(rule, value, callback) {
            const msg = quotaValidator(
              {
                rule,
                emptyMsg: getEmptyMsg(Translation('recruitment.exam.examQuota')),
                errorMsg: Translation('recruitment.exam.schedule.quota_validator'),
              },
              value,
            );

            callback(msg);
          },
        },
      ],
      placeholder: placeEnter,
    },
    {
      name: CreateScheduleFields.remainingQuota,
      label: Translation('recruitment.exam.minimum_quota'),
      rules: [
        (form: any) => ({
          required: false,
          validator(rule, value, callback) {
            const quota = form.getFieldValue(CreateScheduleFields.quota);
            const msg = minimumQuotaValidator(
              {
                rule,
                errorMsg: Translation('recruitment.exam.min_quota_quota_validator'),
                integerError: Translation('recruitment.exam.min_quota_quota_validator'),
              },
              quota,
              value,
            );

            callback(msg);
          },
        }),
      ],
      placeholder: placeEnter,
    },
    {
      name: CreateScheduleFields.remark,
      label: Translation('recruitment.exam.remark'),
      rules: [{ required: false }],
      placeholder: placeEnter,
    },
  ] as {
    name: CreateScheduleFields;
    label: string;
    rules: Rule[];
    placeholder?: string;
  }[];
};

export const createResultConfigs = (options?: { emptyMsgKey?: string }) => {
  const isMandatoryFields = (field: string) =>
    ['firstName', 'lastName', 'examCode', 'examDate', 'startTime', 'endTime', 'examCentre', 'result', 'score'].includes(
      field,
    );

  const scheduleConfigs = createScheduleConfigs(options)
    .filter((i) =>
      [
        'examCode',
        'examType',
        'examNameLocal',
        'examNameEnglish',
        // 'examDate',
        'examCentre',
        'startTime',
        'endTime',
        'address',
      ].includes(i.name),
    )
    .map(({ rules, ...rest }) => {
      return {
        ...rest,
        rules: rules.map((r) => {
          if (typeof r === 'function') {
            return (form: any) => ({ ...r(form), required: isMandatoryFields(rest.name) });
          } else {
            return { ...r, required: isMandatoryFields(rest.name) };
          }
        }),
      };
    }); //
  const getEmptyMsg = (label: string) =>
    TranslationWithParams(options?.emptyMsgKey ?? 'component.form-required-text', { field: label });

  return [
    ...scheduleConfigs,
    {
      name: CreateScheduleFields.examDate,
      label: Translation('recruitment.exam.examDate'),
      rules: [
        {
          required: isMandatoryFields(CreateScheduleFields.examDate),
          validator(rule, value, callback) {
            const invalidMsg = TranslationWithParams('recruitment.exam.schedule.date_time_do_not_follow_validator', {
              field: Translation('recruitment.exam.examDate'),
            });

            const errorMsg = TranslationWithParams('recruitment.exam.schedule.exam_date_passed_validator', {
              field: Translation('recruitment.exam.examDate'),
            });
            const msg = examDateValidator(
              {
                emptyMsg: getEmptyMsg(Translation('recruitment.exam.examDate')),
                rule,
                invalidMsg,
                errorMsg,
              },
              value,
              dayjs(),
              (examDate: any, dayNow: any) => dayjs(examDate).isAfter(dayNow),
            );
            callback(msg);
          },
        },
      ],
    },
    {
      name: 'registrationId',
      label: Translation('recruitment.exam.registration.id'),
      rules: [
        {
          required: isMandatoryFields('registrationId'),
          message: getEmptyMsg(Translation('recruitment.exam.registration.id')),
        },
      ],
    },
    {
      name: 'applicantId',
      label: Translation('recruitment.exam.registration.applicantId'),
      rules: [
        {
          required: isMandatoryFields('applicantId'),
          message: getEmptyMsg(Translation('recruitment.exam.registration.applicantId')),
        },
      ],
    },
    {
      name: 'firstname',
      label: Translation('recruitment.exam.registration.firstName'),
      rules: [
        {
          required: isMandatoryFields('firstName'),
          message: getEmptyMsg(Translation('recruitment.exam.registration.firstName')),
        },
      ],
    },
    {
      name: 'lastname',
      label: Translation('recruitment.exam.registration.lastName'),
      rules: [
        {
          required: isMandatoryFields('lastName'),
          message: getEmptyMsg(Translation('recruitment.exam.registration.lastName')),
        },
      ],
    },
    {
      name: 'result',
      label: Translation('recruitment.exam.registration.result'),
      rules: [
        {
          required: isMandatoryFields('result'),
          validator(rule, value, callback) {
            const msg = resultValidator(
              {
                rule,
                emptyMsg: getEmptyMsg(Translation('recruitment.exam.registration.result')),
                errorMsg: TranslationWithParams('recruitment.exam.result_value_validator', {
                  text: [Results.PASSED, Results.FAILED].join('/'),
                }),
              },
              value,
            );

            callback(msg);
          },
        },
      ],
    },
    {
      name: 'score',
      label: Translation('recruitment.exam.registration.score'),
      rules: [
        {
          required: isMandatoryFields('score'),
          validator(rule, value, callback) {
            const msg = scoreValidator(
              {
                rule,
                emptyMsg: getEmptyMsg(Translation('recruitment.exam.registration.score')),
                errorMsg: Translation('recruitment.exam.score_value_validator'),
              },
              value,
            );

            callback(msg);
          },
        },
      ],
    },
  ] as {
    name: CreateScheduleFields;
    label: string;
    rules: Rule[];
    placeholder?: string;
  }[];
};
