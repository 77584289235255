import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LayoutSplashScreen } from 'src/app/layout';
import LessonFormList from './lesson-main-form';
import { OnboardingLessonFormState } from '../../../../types/courses-types';
import { createOnboardingTrainingLesson, deleteOnboardingTrainingCourse, deleteOnboardingTrainingLesson, fetchOnboardingTrainingLessonDetail, updateOnboardingTrainingLesson } from 'src/app/modules/Recruitment/network/onboarding-course-crud';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { useIntl } from 'react-intl';

const OnboardingLessonDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { courseId, id } = useParams<{ courseId: string; id: string }>();
  const dispatch = useDispatch();
  const { courseName } = (location.state || {}) as {
    courseName: {
      [key: string]: string
    }
  }
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({})

  const pageType = useMemo(() => {
    if (location.pathname.includes('create')) {
      return 'create';
    } else if (location.pathname.includes('edit')) {
      return 'edit'
    } else {
      return 'view'
    }

  }, [location.pathname])

  const renameProperties = useCallback((obj: any, oldNames: string[], newNames: string[]) => {
    const newObj: any = {};
    for (var i = 0; i < oldNames.length; i++) {
      var oldName = oldNames[i];
      var newName = newNames[i];
      if (obj.hasOwnProperty(oldName)) {
        newObj[newName] = obj[oldName];
      }
    }
    return newObj;
  }, []);

  const formatData = (data: OnboardingLessonFormState) => {
    data.systemReferral = JSON.parse(data.systemReferral as string);
    const oldNames = ["blobId", "fileName", "url"]
    const newNames = ["materialId", "materialName", "materialLink"]
    for (const key in data.material) {
      data.material[key] = renameProperties(data.material[key], oldNames, newNames);
    }
    return data
  }

  const formatServerToLocal = (data: any) => {
    const newNames = ["blobId", "fileName", "url"]
    const oldNames = ["materialId", "materialName", "materialLink"]
    for (const key in data.material) {
      data.material[key] = renameProperties(data.material[key], oldNames, newNames);
    }
    data.systemReferral = data.systemReferral.toString()
    return data
  }

  const onSave = useCallback(async (data: OnboardingLessonFormState, type: string) => {
    const formData = formatData(data)
    if (type === 'create') {
      await createOnboardingTrainingLesson(formData, courseId, dispatch)
    } else if (type === 'edit') {
      await updateOnboardingTrainingLesson(formData, courseId, id, dispatch)
    }
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: ``,
          },
        ]),
      );
    },
    [dispatch, courseId],
  );

  useEffect(() => {
    if (id) {
      refreshData();
    }
  }, [id]);

  const refreshData = useCallback(async () => {
    if (id) {
      try {
        setIsLoading(true);
        const res = await fetchOnboardingTrainingLessonDetail(courseId, id)
        const data = formatServerToLocal(res)
        setInitialValues(data);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  }, [id, courseId])

  const onDelete = useCallback(async () => {
    await deleteOnboardingTrainingLesson(courseId, id)
    history.goBack()
  }, [courseId, id])


  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <LessonFormList
          id={id}
          initialValues={initialValues}
          onSave={onSave}
          isEdit={false}
          courseId={courseId}
          history={history}
          type={pageType}
          disabled={pageType === 'view'}
          linkedCourse={courseName}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

export default OnboardingLessonDetailPage;
