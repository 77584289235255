import { AttachmentDef } from "src/app/common/types/common-types";
//import { AgentLeadSourceItem } from "../../AgencyCampaign/types/approval-types";
import { CampaignTypeItem } from "../../AgencyCampaign/types/campaign-type-types";

export enum CampaignTypeEnum {
  NORMAL = 'normal',
  MASS = 'mass',
  SERVICING = 'servicing',
}

export enum ContentEnum {
  FIXED = "fixedContent",
  VARIABLE = "variableContent"
}

export enum SendTypesEnum {
  APPLICANT = "applicant",
  PARTICIPANT = "participant"
}

export enum RsvpEnum {
  NOT_INVITE = "Not invite",
  NO_RESPONSE = "No response",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected"
}

export enum LevelOfApprovalEnum {
  'Level1Approval',
  'Level2Approval',
  'Level3Approval'
}

export enum WorkflowApprovalStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export enum WorkflowTypeEnum {
  campaign = 'campaign'
}

export enum CampaignStatusEnum {
  // PENDING_APPROVAL = 'pendingApproval',
  // APPROVED = 'approved',
  // REJECTED = 'rejected',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PAUSED = "paused",
  CANCELED = 'canceled',
  DRAFT = 'draft'
}

export enum FollowUpStatusEnum {
  CONTACT_SUCCESS = 'contactSuccess',
  MET = 'met',
  QUOTE = 'quote',
  PROPOSAL = 'proposal',
  POLICY = 'policy',
  CONTACT_FAILURE = 'contactFailure',
  DEAD = 'dead',
}

export enum LeadStatusEnum {
  WAITING_FOR_ACCEPT = 'waitingForAccept',
  PENDING_FOR_CONTACT = 'pendingForContact',
  CONTACT_SUCCESS = 'contactSuccess',
  MET = 'met',
  QUOTE = 'quote',
  PROPOSAL = 'proposal',
  POLICY = 'policy',
  ACCEPT_EXPIRED = 'acceptExpired',
  CONTACT_EXPIRED = 'contactExpired',
  DEAD = 'dead',
  REJECTED = 'rejected',
  PENDING_FOR_SERVICE = 'pendingForService',
}

export enum LeadAgentStatusEnum {
  accepted = 'accepted',
  rejected = 'rejected',
  pending = 'pending',
  pendingServicing = 'pendingServicing'
}

export type CampaignFaq = {
  question: string;
  answer: string;
}

export type CampaignLink = {
  title: string;
  link: string;
}

export type CampaignPriority = {
  step: string;
}

export type CampaignFlyer = {
  title: string;
  attachments?: AttachmentDef[];
}

export type CampaignMessage = {
  title: string;
  body: string;
}

export type AgencyCampaignDetailMessage = {
  typeId: CampaignTypeItem;
  agentLeadSource: AgentLeadSourceItem;
  agentLeadSubSource: AgentLeadSourceItem[];
  applicant: ApplicantDetailItem;
  campaign: CampaignDetailItem;
  participant: ParticipantDetailItem;
  attachments: AttachmentDetailItem;
  approvalHistoryList: ApprovalHistoryItem[];
  remark: RemarkItem[];
}

export type AgentLeadSourceItem = {
  _id: string;
  sourceName: string;
}

export type ApplicantDetailItem = {
  applicantName: string;
  applicantAgentCode: string;
  applicantPhoneNumber: string;
  applicantEmail: string;
}

export type CampaignDetailItem = {
  campaignName: string;
  campaignDescription: string;
  campaignPurpose: string;
  campaignStartDate: Date;
  campaignEndDate: Date;
}

export type ParticipantDetailItem = {
  tnc: boolean;
  list: ParticipantIdItem[];
}

export type ParticipantIdItem = {
  participantId: ParticipantIndividualItem;
}

export type ParticipantIndividualItem = {
  agentCode: string;
  unit: string;
  name: any;
  displayName: DisplayNameItem;
  designation: DesignationItem;
  rsvpIndicator: RsvpEnum;
}

export type DisplayNameItem = {
  zhHk: string;
  enUs: string;
}

export type DesignationItem = {
  code: string;
  name: string;
}

export type AttachmentDetailItem = {
  proofOfLocation: BlobItem;
  attachment?: BlobItem;
  others?: BlobItem;
}

export type BlobItem = {
  resourceIds: string[];
}

export type ApprovalHistoryItem = {
  assignee: string;
  assigneeName: string;
  role: string[];
  levelOfApproval: LevelOfApprovalEnum;
  assigneeType: WorkflowTypeEnum;
  status: WorkflowApprovalStatusEnum;
  comment?: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}

export type RemarkItem = {
  type: ContentEnum;
  resourceIds: string[];
  templateId: string;
  sendTypes: SendTypesEnum[];
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}

export type CampaignItem = {
  _id: string;
  agencyCampaign: AgencyCampaignDetailMessage;
  campaignId: string;
  name: string;
  campaignType?: string;
  description: string;
  regionalCampaignName?: string;
  type: string;
  sort?: string;
  startDate: Date;
  endDate: Date;
  launchDate?: Date;
  emailMessage?: CampaignMessage;
  notificationMessage?: CampaignMessage;
  mandatoryData: string[];
  image: AttachmentDef;
  maxLeadPerAgent?: number;
  marketingOwnerName: string;
  marketingOwnerEmail: string;
  status: string;
  isNewLeadNotificationEnabled: boolean;
  callScripts?: string;
  saleTipsInsight?: string;
  faq: CampaignFaq[];
  links: CampaignLink[];
  priorities: CampaignPriority[];
  flyers: CampaignFlyer[];
  source: string;
  subSource?: string[];
  acceptSLA?: any;
  contactSLA?: any;
  customerIncentive?: string;
  agentIncentive?: string;
  isSelfCreateLeadEnabled: boolean;
  maximumMarketingLeads?: MaximumLeadsItem;
  maximumLeadSourceLeads?: MaximumLeadsItem;
  distributionRule?: any;
  eligibilityRule?: any;
  leadDedupRule?: any;
  customerDedup?: string;
  customerDedupRule?: any | any[];
  exclusionRule?: any;
  isEngagementRequired: boolean;
  isStaging: boolean;
  distributionChannel?: string;
  targetProductType?: string[];
  relativeSourceName?: string;
  feedbacks?: string[];
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}

export type MaximumLeadsItem = {
  isMaximum: boolean;
  limit?: number;
  isEngage: boolean;
}

export type LeadSource = {
  _id: string;
  sourceId?: string;
  sourceName: string;
  regionalLeadSource: string;
  channelCode?: string;
  image: AttachmentDef;
  parent?: string | LeadSource;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}

export const InsightsLibraryDEV = {
  leadsChartId: "62831715-b2ce-418e-8067-b571d6745f9a",
  missedSLAChart: "6285b4ce-02d2-46d6-8326-561032e2d388",
  progressChart:"62830f5c-dc0a-44b9-8cad-58eadba762ed",
  closedChart:"6295ba25-7d6f-4da0-89d4-5694f6896918"
}

export const InsightsLibraryUAT = {
  leadsChartId: "62831715-b2ce-418e-8067-b571d6745f9a",
  missedSLAChart: "6285b4ce-02d2-46d6-8326-561032e2d388",
  progressChart: "62830f5c-dc0a-44b9-8cad-58eadba762ed",
  closedChart: "6295ba25-7d6f-4da0-89d4-5694f6896918"
}

export const InsightsLibraryPROD = {
  leadsChartId: "62831715-b2ce-418e-8067-b571d6745f9a",
  missedSLAChart: "6285b4ce-02d2-46d6-8326-561032e2d388",
  progressChart: "62830f5c-dc0a-44b9-8cad-58eadba762ed",
  closedChart: "6295ba25-7d6f-4da0-89d4-5694f6896918"
}