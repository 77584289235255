import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Checkbox, TextField } from '@mui/material';

import { useIntl } from 'react-intl';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { useStyles } from '../template-detail-page.style';
import { RecruitmentStepProps } from '../template-detail-page.type';
import { useTemplateDetailStep1Hook } from './template-detail-step1.hook';
import { getNextDay } from '../../../utils';
import { useMap } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { is } from 'date-fns/locale';
import { RecruitmentTemplatePublishStatusEnum, RecruitmentTemplateStatusEnum } from '../template-detail-page.enum';
import { on } from 'events';

export function TemplateDetailStep1(props: RecruitmentStepProps) {
  const { classes } = useStyles();
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  const {
    validateText,
    formType,
    formTypeOptions,

    validDateFrom,
    validDateTo,
    formTypeChangeHandler,
    startDateChangeHandler,
    endDateChangeHandler,
    isView,
    fromDateDisabled,
    noEndDate,
    setNoEndDate,
  } = useTemplateDetailStep1Hook(props);
  // allow to use past-date for view mode
  const minDate = isView ? null : getNextDay();
  useEffect(() => {
    if (!validDateTo) {
      setNoEndDate(true);
    } else {
      setNoEndDate(false);
    }
  }, [validDateTo]);
  return (
    <div className={`tw-text-black tw-mb-20 ${classes.step1}`}>
      <div className="step1-row">
        <div className="step1-label required">{t('form_type')}</div>
        <div>
          <FormControl variant="standard" sx={{ width: 456 }}>
            <Select
              value={formType}
              onChange={formTypeChangeHandler}
              displayEmpty
              disabled={props.queryAction !== 'create'}
            >
              {formTypeOptions.map((i: string) => (
                <MenuItem key={i} value={i}>
                  {t(i)}
                </MenuItem>
              ))}
            </Select>
            <div className="error-date">
              <FormHelperText>{props.version ? '' : t('unable_to_duplicate_desc')}</FormHelperText>
            </div>
          </FormControl>
        </div>
      </div>
      <div className="step1-row">
        <div className="step1-label required">{t('form_version')}</div>
        <div className={isView ? 'tw-text-gray-400' : ''}>{props.version}</div>
      </div>
      <div className="step1-row col-top">
        <div className="step1-label">{t('validity_period')}</div>
        <div className="step1-content">
          <div className={`${validateText.fromError ? 'error-date' : ''}`}>
            <div className="required valid-from-label">{t('valid_from')}</div>
            <FormControl variant="standard" sx={{ width: 456 }}>
              <PruDatePicker
                minDate={minDate}
                value={validDateFrom}
                format="DD/MM/YYYY 00:00"
                onChange={startDateChangeHandler}
                slotProps={{ textField: { readOnly: true } as any }}
                disabled={fromDateDisabled}
              />
              <FormHelperText>{validateText.fromError}</FormHelperText>
            </FormControl>
          </div>
          <div className={`${validateText.toError ? 'error-date' : ''}`}>
            <div className="tw-mt-5 valid-from-label">{t('valid_to')}</div>
            <FormControl variant="standard" sx={{ width: 456 }}>
              <PruDatePicker
                minDate={validDateFrom}
                value={validDateTo}
                format="DD/MM/YYYY 23:59"
                onChange={endDateChangeHandler}
                slotProps={{ textField: { readOnly: true } as any }}
                disabled={isView && noEndDate}
              />
              <FormHelperText>{validateText.toError}</FormHelperText>
            </FormControl>
            {/* default check the box if the end date is not set, but allow the user to change it, and set the checkbox's check status to the state */}
          </div>
          <Checkbox
            checked={noEndDate}
            onChange={() => {
              setNoEndDate((pre) => {
                const checked = !pre;
                if (checked) {
                  endDateChangeHandler(null);
                }
                return checked;
              });
            }}
            disabled={isView}
          />

          {/* add text "No end date" here */}
          <span className="tw-text-gray-400 tw-ml-2">{t('Recruitment.template_no_end_date')}</span>
        </div>
      </div>
    </div>
  );
}
