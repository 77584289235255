import { Button, FormControl, MenuItem, Select, TextField } from '@mui/material';
import moment from 'moment';
import React, { CSSProperties, FC, Fragment, useEffect, useMemo, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { PruDatePicker, PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { DATE_ERROR_TEXT } from '../../constants';
import { useCommonStyles } from '../../styles/common-styles';
import useFilterStyles from '../../styles/filter-styles';
import { ErrorFieldDef, ErrorFieldType, getDayEnd, useErrorHandler } from '../../utils';
import AsyncAutocomplete from '../AsyncAutocomplete';
import { ParamsProps } from '../ParamsProvider';

export enum PruFilterItemType {
  FREE_TEXT = 'FREE_TEXT',
  DATE_RANGE = 'DATE_RANGE',
  DATE_TIME_RANGE = 'DATE_TIME_RANGE',
  DROPDOWN = 'DROPDOWN',
  MULTIPLE_DROPDOWN = 'MULTIPLE_DROPDOWN',
  ASYNC_DROPDOWN = 'ASYNC_DROPDOWN',
  ASYNC_MULTIPLE_DROPDOWN = 'ASYNC_MULTIPLE_DROPDOWN',
  DATE = 'DATE',
  DATE_TYPE_RANGE = 'DATE_TYPE_RANGE',
}

export enum PruFilterDateType {
  YTD = 'YTD',
  Last3Months = 'Last 3 Months',
  Last30Days = 'Last 30 days',
  Custom = 'Customised',
}

const ITEM_LENGTH = {
  [PruFilterItemType.FREE_TEXT]: 1,
  [PruFilterItemType.DATE_RANGE]: 2,
  [PruFilterItemType.DATE_TIME_RANGE]: 2,
  [PruFilterItemType.DROPDOWN]: 1,
  [PruFilterItemType.MULTIPLE_DROPDOWN]: 1,
  [PruFilterItemType.ASYNC_DROPDOWN]: 1,
  [PruFilterItemType.ASYNC_MULTIPLE_DROPDOWN]: 1,
  [PruFilterItemType.DATE]: 1,
  [PruFilterItemType.DATE_TYPE_RANGE]: 2,
};

type PruFilterProps = {
  title: string;
  titleClassName?: string;
  filterClassName?: string;
  style?: CSSProperties;
  disableReset?: boolean;
  disableKeydownEvent?: boolean;
  updateFilterOnChange?: boolean;
  enableBack?: boolean;
  itemDef: PruFilterItemDef[];
  onChangeFilter: (filterState: PruFilterState) => void;
  fetchData: () => void;
  onBack?: () => void;
  downloadExcel?: () => void;
} & ParamsProps;

export type PruFilterFreeTextDef = {
  type: PruFilterItemType.FREE_TEXT;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  enableIfExist?: string[];
  length?: number;
};

export type PruFilterDateRangeDef = {
  type: PruFilterItemType.DATE_RANGE;
  fieldFrom: string;
  fieldTo: string;
  displayName: string;
  initialDateFrom?: Date | null;
  initialDateTo?: Date | null;
  defaultDateFrom?: Date | null;
  defaultDateTo?: Date | null;
  enableIfExist?: string[];
  length?: number;
};

export type PruFilterDateTimeRangeDef = {
  type: PruFilterItemType.DATE_TIME_RANGE;
  fieldFrom: string;
  fieldTo: string;
  displayName: string;
  initialDateFrom?: Date | null;
  initialDateTo?: Date | null;
  defaultDateFrom?: Date | null;
  defaultDateTo?: Date | null;
  enableIfExist?: string[];
  length?: number;
};
export type PruFilterDateTypeRangeDef = {
  defaultDateFrom?: any;
  initialDateFrom?: any;
  type: PruFilterItemType.DATE_TYPE_RANGE;
  displayName: string;
  enableIfExist?: string[];
  initialValue?: string;
  defaultValue?: string;
  fieldFrom: string;
  fieldTo: string;
  length?: number;
  defaultDateType?: PruFilterDateType;
  dateType: string;
};

export type PruFilterDropdownItem = {
  displayName: string;
  value: string;
};

export type PruFilterDropdownDef = {
  type: PruFilterItemType.DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  enableIfExist?: string[];
  choices: PruFilterDropdownItem[];
  length?: number;
};

export type PruFilterMultipleDropdownDef = {
  type: PruFilterItemType.MULTIPLE_DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string[];
  defaultValue?: string[];
  enableIfExist?: string[];
  choices: PruFilterDropdownItem[];
  length?: number;
};

export type PruFilterAsyncDropdownDef = {
  type: PruFilterItemType.ASYNC_DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  enableIfExist?: string[];
  initialOptions?: PruFilterDropdownItem[];
  fetchList: () => Promise<PruFilterDropdownItem[]>;
  length?: number;
};

export type PruFilterAsyncMultipleDropdownDef = {
  type: PruFilterItemType.ASYNC_MULTIPLE_DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string[];
  defaultValue?: string[];
  enableIfExist?: string[];
  initialOptions?: PruFilterDropdownItem[];
  fetchList: () => Promise<PruFilterDropdownItem[]>;
  length?: number;
};

export type PruFilterDateDef = {
  type: PruFilterItemType.DATE;
  field: string;
  displayName: string;
  initialDate?: Date | null;
  defaultDate?: Date | null;
  enableIfExist?: string[];
  length?: number;
};

export type PruFilterItemDef =
  | PruFilterFreeTextDef
  | PruFilterDateRangeDef
  | PruFilterDateTimeRangeDef
  | PruFilterDropdownDef
  | PruFilterMultipleDropdownDef
  | PruFilterAsyncDropdownDef
  | PruFilterAsyncMultipleDropdownDef
  | PruFilterDateDef
  | PruFilterDateDef
  | PruFilterDateTypeRangeDef;

type PruFilterState = {
  [id: string]: any;
};

type ModifyFilterAction = {
  type: 'CHANGE_FILTER';
  payload: {
    field: keyof PruFilterState;
    value: Array<Date | null> | string | Date | null;
  };
};

type ResetFilterAction = {
  type: 'RESET_FILTER';
  payload: {
    itemDef: PruFilterItemDef[];
  };
};

type PruFilterAction = ModifyFilterAction | ResetFilterAction;

const paramsInitiator = (mode: 'INIT' | 'DEFAULT', itemDef: PruFilterItemDef[]): PruFilterState => {
  const state: PruFilterState = {};
  itemDef.forEach((item) => {
    switch (item.type) {
      case PruFilterItemType.FREE_TEXT:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || '';
        break;
      case PruFilterItemType.DATE_RANGE:
        state[item.fieldFrom] =
          (mode === 'INIT' ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        state[item.fieldTo] = (mode === 'INIT' ? item.initialDateTo || item.defaultDateTo : item.defaultDateTo) || null;
        break;
      case PruFilterItemType.DATE_TIME_RANGE:
        state[item.fieldFrom] =
          (mode === 'INIT' ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        state[item.fieldTo] = (mode === 'INIT' ? item.initialDateTo || item.defaultDateTo : item.defaultDateTo) || null;
        break;
      case PruFilterItemType.DROPDOWN:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || '';
        break;
      case PruFilterItemType.MULTIPLE_DROPDOWN:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || [];
        break;
      case PruFilterItemType.ASYNC_DROPDOWN:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || '';
        break;
      case PruFilterItemType.ASYNC_MULTIPLE_DROPDOWN:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || [];
        break;
      case PruFilterItemType.DATE:
        state[item.field] = (mode === 'INIT' ? item.initialDate || item.defaultDate : item.defaultDate) || null;
        break;
      case PruFilterItemType.DATE_TYPE_RANGE:
        state[item.fieldFrom] =
          (mode === 'INIT' ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        state[item.fieldTo] =
          (mode === 'INIT' ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        state[item.dateType] = item.defaultDateType || PruFilterDateType.Custom;
        break;
    }
  });
  return state;
};

const filterReducer = (state: PruFilterState, action: PruFilterAction) => {
  switch (action.type) {
    case 'CHANGE_FILTER': {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case 'RESET_FILTER': {
      return paramsInitiator('DEFAULT', action.payload.itemDef);
    }
  }
};

type ErrorState = {
  mandatory: Record<string, boolean>;
  immediate: Record<string, boolean>;
};

const PruFilter: FC<PruFilterProps> = ({
  title,
  titleClassName,
  filterClassName,
  style,
  itemDef,
  disableReset,
  disableKeydownEvent,
  updateFilterOnChange,
  enableBack,
  onChangeFilter,
  fetchData,
  onBack,
  downloadExcel,
}) => {
  const { classes: commonClasses, cx } = useCommonStyles();
  const { classes: filterClasses } = useFilterStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [filterState, filterDispatch] = useReducer(filterReducer, paramsInitiator('INIT', itemDef));
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [textDate, setTextDate] = useState<any>();

  const errorDefinition: ErrorFieldDef[] = useMemo(() => {
    const errorDef: ErrorFieldDef[] = [];
    itemDef.forEach((item) => {
      if (
        item.type === PruFilterItemType.DATE_RANGE ||
        item.type === PruFilterItemType.DATE_TIME_RANGE ||
        item.type === PruFilterItemType.DATE_TYPE_RANGE
      ) {
        errorDef.push({
          name: `${item.fieldTo}Before${item.fieldFrom}`,
          fieldType: ErrorFieldType.IMMEDIATE,
          condition: () => {
            const startDate = filterState[item.fieldFrom];
            const endDate = filterState[item.fieldTo];
            if (startDate && endDate) {
              return !!moment(new Date(startDate)).isAfter(moment(new Date(endDate)));
            } else {
              return false;
            }
          },
        });
      }
    });
    return errorDef;
  }, [itemDef, filterState]);

  const checkIfFilterEnable = (enableIfExist?: string[]) => {
    if (enableIfExist) {
      let enable = true;
      enableIfExist.forEach((item) => {
        if (filterState[item] === '' || filterState[item] === null || filterState[item] === undefined) {
          enable = false;
        }
      });
      return enable;
    } else {
      return true;
    }
  };

  const filterItemRender = (item: PruFilterItemDef) => {
    switch (item.type) {
      case PruFilterItemType.FREE_TEXT:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            {showDatePicker ? (
              textDate ? (
                <>
                  <PruDatePicker
                    disabled={!checkIfFilterEnable(item.enableIfExist)}
                    format="DD/MM/YYYY"
                    value={textDate.startDate}
                    onChange={(date) => {
                      if (textDate) {
                        setTextDate({
                          ...textDate,
                          startDate: date ? date : moment(new Date()).format('MM/DD/YYYY'),
                        });
                        filterDispatch({
                          type: 'CHANGE_FILTER',
                          payload: { field: item.field, value: [date, textDate.endDate] },
                        });
                      } else {
                        filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: date } });
                      }
                    }}
                  />
                  <div className="PruFilter-date-divider" />
                  <PruDatePicker
                    disabled={!checkIfFilterEnable(item.enableIfExist)}
                    slotProps={{
                      textField: {
                        style: { marginRight: 20 },
                      },
                    }}
                    format="DD/MM/YYYY"
                    value={textDate.endDate}
                    onChange={(date) => {
                      setTextDate({
                        ...textDate,
                        endDate: date ? date : moment(new Date()).format('MM/DD/YYYY'),
                      });
                      filterDispatch({
                        type: 'CHANGE_FILTER',
                        payload: { field: item.field, value: [textDate.startDate, date] },
                      });
                    }}
                  />
                </>
              ) : (
                <PruDatePicker
                  disabled={!checkIfFilterEnable(item.enableIfExist)}
                  format="DD/MM/YYYY"
                  value={filterState[item.field]}
                  onChange={(date) =>
                    filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: date } })
                  }
                />
              )
            ) : (
              <TextField
                disabled={!checkIfFilterEnable(item.enableIfExist)}
                style={{
                  ...item.style,
                  marginRight: 20,
                }}
                margin="dense"
                variant="outlined"
                value={filterState[item.field]}
                onChange={(e) =>
                  filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: e.target.value } })
                }
              />
            )}
          </>
        );
      case PruFilterItemType.DATE_RANGE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDatePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                },
              }}
              format="DD/MM/YYYY"
              value={filterState[item.fieldFrom]}
              onChange={(date) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: date } })
              }
            />
            <div className="PruFilter-date-divider" />
            <PruDatePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                  helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] && DATE_ERROR_TEXT,
                  style: { marginRight: 20 },
                },
              }}
              format="DD/MM/YYYY"
              value={filterState[item.fieldTo]}
              onChange={(date) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldTo, value: date } })
              }
            />
          </>
        );
      case PruFilterItemType.DATE_TIME_RANGE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDateTimePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                },
              }}
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              value={filterState[item.fieldFrom]}
              onChange={(date) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: date } })
              }
            />
            <div className="PruFilter-date-divider" />
            <PruDateTimePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                  helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] && DATE_ERROR_TEXT,
                  style: { marginRight: 20 },
                },
              }}
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              value={filterState[item.fieldTo]}
              onChange={(date) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldTo, value: date } })
              }
            />
          </>
        );
      case PruFilterItemType.DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <FormControl
              style={{
                ...item.style,
                marginRight: 20,
              }}
              margin="dense"
              variant="outlined"
            >
              <Select
                native
                disabled={!checkIfFilterEnable(item.enableIfExist)}
                value={filterState[item.field]}
                onChange={(e) => {
                  if (
                    e.target.value == 'Start Date' ||
                    e.target.value == 'Campaign Start Date' ||
                    e.target.value == 'Create Date' ||
                    e.target.value == 'Assigned Date'
                  ) {
                    setShowDatePicker(true);
                    setTextDate({
                      startDate: moment(new Date()).format('MM/DD/YYYY'),
                      endDate: moment(new Date()).format('MM/DD/YYYY'),
                    });
                  } else {
                    setShowDatePicker(false);
                    setTextDate(undefined);
                  }
                  filterDispatch({
                    type: 'CHANGE_FILTER',
                    payload: { field: item.field, value: e.target.value as string },
                  });
                }}
              >
                {item.choices.map((choice, index) => (
                  <option key={`dropdown-choice-${choice.value}-${index}`} value={choice.value}>
                    {choice.displayName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        );
      case PruFilterItemType.MULTIPLE_DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <FormControl
              style={{
                ...item.style,
                marginRight: 20,
              }}
              margin="dense"
              variant="outlined"
            >
              <Select
                multiple
                disabled={!checkIfFilterEnable(item.enableIfExist)}
                value={filterState[item.field]}
                onChange={(e) => {
                  filterDispatch({
                    type: 'CHANGE_FILTER',
                    payload: { field: item.field, value: e.target.value as string },
                  });
                }}
              >
                {item.choices.map((choice, index) => (
                  <MenuItem key={`dropdown-choice-${choice.value}-${index}`} value={choice.value}>
                    {choice.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        );
      case PruFilterItemType.ASYNC_DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <AsyncAutocomplete
              style={{
                ...item.style,
                marginRight: 20,
              }}
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              value={String(filterState[item.field])}
              onChange={(value) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: value } })
              }
              initialOptions={item.initialOptions}
              getData={item.fetchList}
            />
          </>
        );
      case PruFilterItemType.ASYNC_MULTIPLE_DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <AsyncAutocomplete
              multiple
              style={{
                ...item.style,
                marginRight: 20,
              }}
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              value={filterState[item.field]}
              onChange={(value) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: value } })
              }
              initialOptions={item.initialOptions}
              getData={item.fetchList}
            />
          </>
        );
      case PruFilterItemType.DATE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDatePicker
              slotProps={{
                textField: {
                  style: { marginRight: 20 },
                },
              }}
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              format="DD/MM/YYYY"
              value={filterState[item.field]}
              onChange={(date) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: date } })
              }
            />
          </>
        );
      case PruFilterItemType.DATE_TYPE_RANGE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <Select
              defaultValue={filterState[item.dateType]}
              style={{
                marginRight: 20,
                marginTop: 4,
              }}
              native
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              onChange={(e) => {
                const currentYear = moment().year();
                const ytdTime = moment(`${currentYear}-01-01`).startOf('day').valueOf();
                const past30DaysTime = moment().subtract(30, 'days').valueOf();
                const past3MonthsTime = moment().subtract(2, 'months').startOf('month').valueOf();
                filterDispatch({
                  type: 'CHANGE_FILTER',
                  payload: { field: item.dateType, value: e.target.value as PruFilterDateType.Last3Months },
                });
                switch (e.target.value) {
                  case PruFilterDateType.Last3Months:
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldFrom, value: new Date(past3MonthsTime) },
                    });
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldTo, value: getDayEnd(new Date()) },
                    });
                    break;
                  case PruFilterDateType.Last30Days:
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldFrom, value: new Date(past30DaysTime) },
                    });
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldTo, value: getDayEnd(new Date()) },
                    });
                    break;
                  case PruFilterDateType.YTD:
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldFrom, value: new Date(ytdTime) },
                    });
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldTo, value: getDayEnd(new Date()) },
                    });
                    break;
                  case PruFilterDateType.Custom:
                    filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: null } });
                    filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldTo, value: null } });
                    break;
                }
              }}
            >
              {Object.values(PruFilterDateType).map((type, index) => (
                <option key={`dropdown-choice-${type}-${index}`} value={type}>
                  {type}
                </option>
              ))}
            </Select>
            {filterState[item.dateType] === PruFilterDateType.Custom && (
              <>
                <PruDatePicker
                  disabled={!checkIfFilterEnable(item.enableIfExist)}
                  slotProps={{
                    textField: {
                      error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={filterState[item.fieldFrom]}
                  onChange={(date) => {
                    filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: date } });
                  }}
                />
                <div className="PruFilter-date-divider" />
                <PruDatePicker
                  disabled={!checkIfFilterEnable(item.enableIfExist)}
                  slotProps={{
                    textField: {
                      error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                      helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] && DATE_ERROR_TEXT,
                      style: { marginRight: 20 },
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={filterState[item.fieldTo]}
                  onChange={(date) => {
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldTo, value: getDayEnd(date as Date) },
                    });
                  }}
                />
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  const renderFilter = () => {
    const filterRows: React.ReactNode[] = [];
    let tempArr: React.ReactNode[] = [];
    let lengthCount = 0;
    itemDef.forEach((item, index) => {
      const itemLength = item.length ?? ITEM_LENGTH[item.type];
      if (lengthCount + itemLength <= 4) {
        lengthCount += itemLength;
        tempArr.push(<Fragment key={`filter-item-${index}`}>{filterItemRender(item)}</Fragment>);
        if (index >= itemDef.length - 1) {
          filterRows.push([...tempArr]);
        }
      } else {
        filterRows.push([...tempArr]);
        lengthCount = 0;
        tempArr = [];

        lengthCount += itemLength;
        tempArr.push(<Fragment key={`filter-item-${index}`}>{filterItemRender(item)}</Fragment>);
        if (!itemDef[index + 1]) {
          filterRows.push([...tempArr]);
        }
      }
    });
    return filterRows.map((filterRow, index) => (
      <div className="PruFilter-row" key={`PruFilter-row-${index}`}>
        {filterRow}
      </div>
    ));
  };

  const { errorState, onSubmitErrorValidator, immediateErrorValidator } = useErrorHandler<ErrorState>(
    filterState,
    errorDefinition,
  );

  useEffect(() => {
    if (updateFilterOnChange) {
      onChangeFilter(filterState);
    }
    immediateErrorValidator();
  }, [filterState]);

  const getFilterSearch = () => {
    onChangeFilter(filterState);
    immediateErrorValidator();
  };

  const filterList = () => {
    const { hasError } = onSubmitErrorValidator();
    getFilterSearch();
    if (!hasError) {
      fetchData();
    }
  };

  const keyPressSearch = (e: KeyboardEvent) => {
    if (!disableKeydownEvent && e.key === 'Enter') {
      filterList();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyPressSearch);

    return () => {
      window.removeEventListener('keydown', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterState, disableKeydownEvent]);

  return (
    <div style={{ ...style, marginBottom: 20 }} className={filterClasses.root}>
      <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
        <div className={cx(commonClasses.header, titleClassName)}>{title}</div>
        <div className="PruFilter-row">
          {enableBack && (
            <Button
              style={{ marginRight: 20 }}
              variant="contained"
              color="inherit"
              onClick={() => {
                if (onBack) onBack();
              }}
            >
              {Translation('app.button.back')}
            </Button>
          )}
          {downloadExcel && (
            <Button style={{ marginRight: 20 }} variant="contained" color="inherit" onClick={downloadExcel}>
              {Translation('app.button.download')}
            </Button>
          )}
          {!disableReset && (
            <Button
              style={{ marginRight: 20 }}
              variant="contained"
              color="inherit"
              onClick={() => filterDispatch({ type: 'RESET_FILTER', payload: { itemDef } })}
            >
              {Translation('app.button.reset')}
            </Button>
          )}
          <Button variant="contained" color="secondary" onClick={filterList}>
            {Translation('app.button.search')}
          </Button>
        </div>
      </div>
      <div className={filterClassName}>{renderFilter()}</div>
    </div>
  );
};

export default PruFilter;
