import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useRequest } from 'ahooks';
import { useHistory, useParams } from 'react-router-dom';
import { ApprovalDetail, ApprovalEvents } from 'src/app/modules/Recruitment/types/approval-types';
import { getApprovalDetail } from '../util/api.util';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { copyRecruiterToAgentLeader, formatData } from '../util/surveyjs.util';
import { useLang } from 'src/app/i18n';
import { getI18nData } from '../util/i18n.util';

type HookProps = ModulePermissionProps & {};

export const useDetail = (props: HookProps) => {
  const Translation = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [dialogType, setDialogType] = useState<ApprovalEvents>();
  const history = useHistory();
  const [dataWithFile, setDataWithFile] = useState<ApprovalDetail>();
  const locale = useLang();

  // redux
  const dispatch = useDispatch();

  // useRequest to wrap api request.
  // DO NOT use useDataProvider except for <PruFilter />
  const {
    data: result,
    error,
    loading,
  } = useRequest<[ApprovalDetail, any, boolean], any>(() => getApprovalDetail(id, dispatch), { manual: false });

  const [data, specificFields, hasFile] = result ?? [];

  useEffect(() => {
    if (dataWithFile || !hasFile || !data) return;
    // use lazy load file to reduce the time to display the form
    formatData(
      specificFields,
      data.modules,
      {
        applicationId: data.applicationId,
        content: data.content,
        firstname: data.firstname,
        lastname: data.lastname,
      },
      true,
    ).then((modules) => {
      copyRecruiterToAgentLeader(
        modules,
        data.recruiterIsAgentLeader,
        {
          applicationId: data.applicationId,
          content: data.content,
          firstname: data.firstname,
          lastname: data.lastname,
        },
        locale,
      );
      setDataWithFile({ ...data!, modules });
    });
  }, [hasFile, specificFields, data, dataWithFile]);

  const onRefresh = useCallback(() => {
    // runAsync();
    history.goBack();
  }, [history]);

  const onClose = useCallback(() => {
    setDialogType(undefined);
  }, []);

  const { id: applicationId } = useParams<{ id: string }>();

  const onboardingTemplate = useSelector((state: any) => get(state.config.data, 'Recruitment.onboardingTemplate', []));
  const formTypeOptions = onboardingTemplate.map((item: any) => item.type);

  const formType = useMemo(() => {
    const t = formTypeOptions.find((i: any) => i === data?.formType);
    return t ? Translation(`Recruitment.onboarding_type_${t}`) : null;
  }, [formTypeOptions, data?.formType, Translation]);

  const agentLeaderName = useMemo(
    () => (data?.agentLeaderName ? getI18nData(data?.agentLeaderName, locale) : undefined),
    [data?.agentLeaderName, locale],
  );

  return {
    dialogType,
    setDialogType,
    loading,
    data: dataWithFile ?? data,
    fileLoaded: hasFile && !!dataWithFile,
    error,
    onRefresh,
    onClose,
    formType,
    applicationId,
    agentLeaderName,
  };
};
