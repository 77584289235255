import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  loadingContainer: {
    width: '100%',
    padding: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: '#ffffff',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  contentContainer: {
    paddingLeft: 32,
  },
  textWidth: {
    width: 437,
  },
  tips: {
    color: '#666',
    marginLeft: 24,
  },
  imageContainer: {
    width: 174,
    overflow: 'hidden',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: 3 / 2,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
  },
  button: {
    marginLeft: 20,
  },
}));
