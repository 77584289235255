export const SALESKIT_BASE_PATH = '/saleskit';
export const RECRUIT_BASE_PATH = '/recruit';
export const SALES_MATERIAL_BASE_PATH = '/sales-material';
export const PROMOTION_MATERIAL_BASE_PATH = '/promotion-material';

export const RECRUIT_LIBRARY_BASE_PATH = '/library';
export const RECRUIT_CATEGORY_BASE_PATH = '/category';
export const RECRUIT_COLUMN_BASE_PATH = '/column';

export const SALES_LIBRARY_BASE_PATH = '/library';
export const SALES_CATEGORY_BASE_PATH = '/category';
export const SALES_COLUMN_BASE_PATH = '/column';

export const PROMOTION_LIBRARY_BASE_PATH = '/library';
export const PROMOTION_CATEGORY_BASE_PATH = '/category';
export const PROMOTION_COLUMN_BASE_PATH = '/column';

export const SURVEY_CUSTOMER_PATH = '/customer';
export const SURVEY_CUSTOMER_RESOURCE = 'customerSurvey';
export const CUSTOMER_SURVEY_TITLE = 'title.ci.survey';
export const CUSTOMER_SURVEY_NAMESPACE = 'SALESKIT-CUSTOMER-SURVEY';

export const INSURANCE_SUMMARY_PATH = '/insurance-summary';
export const INSURANCE_SUMMARY_CONFIG_PATH = '/config';
export const INSURANCE_SUMMARY_FEEDBACK_PATH = '/feedback';
export const INSURANCE_SUMMARY_RESOURCE = 'insuranceSummary';
export const INSURANCE_SUMMARY_TITLE = 'saleskit.insuranceSummary.title';
export const INSURANCE_SUMMARY_CONFIG = 'saleskit.insuranceSummary.config.title';
export const INSURANCE_SUMMARY_FEEDBACK = 'saleskit.insuranceSummary.feedback.title';
export const INSURANCE_SUMMARY_CONFIG_NAMESPACE = 'SALESKIT-INSURANCE-SUMMARY-CONFIG';
export const INSURANCE_SUMMARY_FEEDBACK_NAMESPACE = 'SALESKIT-INSURANCE-SUMMARY-FEEDBACK';

export const COMPANYPICK_PATH = '/company-pick';
export const COMPANYPICK_RESOURCE = 'companyPick';
export const COMPANYPICK_TITLE = 'saleskit.companyPick.title';
export const COMPANYPICK_NAMESPACE = 'SALESKIT-COMPANY-PICK';

export const AGENTLEADFORM_PATH = '/agent-lead-form';
export const AGENTLEADFORM_RESOURCE = 'agentLeadForm';
export const AGENTLEADFORM_TITLE = 'saleskit.agentLeadForm.title';
export const AGENTLEADFORM_NAMESPACE = 'SALESKIT-AGENT-LEAD-FORM';

export const AI_TALKBOT_PATH = '/talkbot';
export const AI_TALKBOT_TITLE = 'title.ai_talkbot';
export const AI_TALKBOT_NAMESPACE = 'SALESKIT-TALKBOT';

//  These constants map to aside menu, header menu to display title
export const SALESKIT_TITLE = 'saleskit.title';
export const SALESKIT_RECRUIT_TITLE = 'title.resource.recruit';
export const SALESKIT_RECRUIT_LIBRARY_TITLE = 'title.resource.recruit.library';
export const SALESKIT_RECRUIT_CATEGORY_TITLE = 'title.resource.recruit.category';
export const SALESKIT_RECRUIT_COLUMN_TITLE = 'title.resource.recruit.column';
export const SALESKIT_SALES_TITLE = 'title.resource.sales';
export const SALESKIT_SALES_LIBRARY_TITLE = 'title.resource.sales.library';
export const SALESKIT_SALES_CATEGORY_TITLE = 'title.resource.sales.category';
export const SALESKIT_SALES_COLUMN_TITLE = 'title.resource.sales.column';
export const SALESKIT_PROMOTION_TITLE = 'title.resource.promotion';
export const SALESKIT_PROMOTION_LIBRARY_TITLE = 'title.resource.promotion.library';
export const SALESKIT_PROMOTION_CATEGORY_TITLE = 'title.resource.promotion.category';
export const SALESKIT_PROMOTION_COLUMN_TITLE = 'title.resource.promotion.column';

//  These constants map to permission control, identical to permission enum defined below
export const SALESKIT_RECRUIT_LIBRARY_NAMESPACE = 'SALESKIT-RECRUIT-LIBRARY';
export const SALESKIT_RECRUIT_CATEGORY_NAMESPACE = 'SALESKIT-RECRUIT-CATEGORY';
export const SALESKIT_RECRUIT_COLUMN_NAMESPACE = 'SALESKIT-RECRUIT-COLUMN';
export const SALESKIT_SALES_LIBRARY_NAMESPACE = 'SALESKIT-SALES-LIBRARY';
export const SALESKIT_SALES_CATEGORY_NAMESPACE = 'SALESKIT-SALES-CATEGORY';
export const SALESKIT_SALES_COLUMN_NAMESPACE = 'SALESKIT-SALES-COLUMN';
export const SALESKIT_PROMOTION_LIBRARY_NAMESPACE = 'SALESKIT-PROMOTION-LIBRARY';
export const SALESKIT_PROMOTION_CATEGORY_NAMESPACE = 'SALESKIT-PROMOTION-CATEGORY';
export const SALESKIT_PROMOTION_COLUMN_NAMESPACE = 'SALESKIT-PROMOTION-COLUMN';

export enum AdminSaleskitPermissionType {
  SALESKIT_RECRUIT_LIBRARY_READ = 'READ_ANY_SALESKIT-RECRUIT-LIBRARY',
  SALESKIT_RECRUIT_LIBRARY_CREATE = 'CREATE_ANY_SALESKIT-RECRUIT-LIBRARY',
  SALESKIT_RECRUIT_LIBRARY_UPDATE = 'UPDATE_ANY_SALESKIT-RECRUIT-LIBRARY',

  SALESKIT_RECRUIT_CATEGORY_READ = 'READ_ANY_SALESKIT-RECRUIT-CATEGORY',
  SALESKIT_RECRUIT_CATEGORY_CREATE = 'CREATE_ANY_SALESKIT-RECRUIT-CATEGORY',
  SALESKIT_RECRUIT_CATEGORY_UPDATE = 'UPDATE_ANY_SALESKIT-RECRUIT-CATEGORY',

  SALESKIT_RECRUIT_COLUMN_READ = 'READ_ANY_SALESKIT-RECRUIT-COLUMN',
  SALESKIT_RECRUIT_COLUMN_CREATE = 'CREATE_ANY_SALESKIT-RECRUIT-COLUMN',
  SALESKIT_RECRUIT_COLUMN_UPDATE = 'UPDATE_ANY_SALESKIT-RECRUIT-COLUMN',

  SALESKIT_SALES_LIBRARY_READ = 'READ_ANY_SALESKIT-SALES-LIBRARY',
  SALESKIT_SALES_LIBRARY_CREATE = 'CREATE_ANY_SALESKIT-SALES-LIBRARY',
  SALESKIT_SALES_LIBRARY_UPDATE = 'UPDATE_ANY_SALESKIT-SALES-LIBRARY',

  SALESKIT_SALES_CATEGORY_READ = 'READ_ANY_SALESKIT-SALES-CATEGORY',
  SALESKIT_SALES_CATEGORY_CREATE = 'CREATE_ANY_SALESKIT-SALES-CATEGORY',
  SALESKIT_SALES_CATEGORY_UPDATE = 'UPDATE_ANY_SALESKIT-SALES-CATEGORY',

  SALESKIT_SALES_COLUMN_READ = 'READ_ANY_SALESKIT-SALES-COLUMN',
  SALESKIT_SALES_COLUMN_CREATE = 'CREATE_ANY_SALESKIT-SALES-COLUMN',
  SALESKIT_SALES_COLUMN_UPDATE = 'UPDATE_ANY_SALESKIT-SALES-COLUMN',

  SALESKIT_PROMOTION_LIBRARY_READ = 'READ_ANY_SALESKIT-PROMOTION-LIBRARY',
  SALESKIT_PROMOTION_LIBRARY_CREATE = 'CREATE_ANY_SALESKIT-PROMOTION-LIBRARY',
  SALESKIT_PROMOTION_LIBRARY_UPDATE = 'UPDATE_ANY_SALESKIT-PROMOTION-LIBRARY',

  SALESKIT_PROMOTION_CATEGORY_READ = 'READ_ANY_SALESKIT-PROMOTION-CATEGORY',
  SALESKIT_PROMOTION_CATEGORY_CREATE = 'CREATE_ANY_SALESKIT-PROMOTION-CATEGORY',
  SALESKIT_PROMOTION_CATEGORY_UPDATE = 'UPDATE_ANY_SALESKIT-PROMOTION-CATEGORY',

  SALESKIT_PROMOTION_COLUMN_READ = 'READ_ANY_SALESKIT-PROMOTION-COLUMN',
  SALESKIT_PROMOTION_COLUMN_CREATE = 'CREATE_ANY_SALESKIT-PROMOTION-COLUMN',
  SALESKIT_PROMOTION_COLUMN_UPDATE = 'UPDATE_ANY_SALESKIT-PROMOTION-COLUMN',

  SALESKIT_CUSTOMER_SURVEY_READ = 'READ_ANY_SALESKIT-CUSTOMER-SURVEY',
  SALESKIT_CUSTOMER_SURVEY_CREATE = 'CREATE_ANY_SALESKIT-CUSTOMER-SURVEY',
  SALESKIT_CUSTOMER_SURVEY_UPDATE = 'UPDATE_ANY_SALESKIT-CUSTOMER-SURVEY',

  SALESKIT_AGENT_LEAD_FORM_READ = 'READ_ANY_SALESKIT-AGENT-LEAD-FORM',
  SALESKIT_AGENT_LEAD_FORM_CREATE = 'CREATE_ANY_SALESKIT-AGENT-LEAD-FORM',
  SALESKIT_AGENT_LEAD_FORM_UPDATE = 'UPDATE_ANY_SALESKIT-AGENT-LEAD-FORM',

  SALESKIT_COMPANY_PICK_READ = 'READ_ANY_SALESKIT-COMPANY-PICK',
  SALESKIT_COMPANY_PICK_CREATE = 'CREATE_ANY_SALESKIT-COMPANY-PICK',
  SALESKIT_COMPANY_PICK_UPDATE = 'UPDATE_ANY_SALESKIT-COMPANY-PICK',

  SALESKIT_INSURANCE_SUMMARY_CONFIG_CREATE = 'CREATE_ANY_SALESKIT-INSURANCE-SUMMARY-CONFIG',
  SALESKIT_INSURANCE_SUMMARY_CONFIG_READ = 'READ_ANY_SALESKIT-INSURANCE-SUMMARY-CONFIG',
  SALESKIT_INSURANCE_SUMMARY_CONFIG_UPDATE = 'UPDATE_ANY_SALESKIT-INSURANCE-SUMMARY-CONFIG',
  SALESKIT_INSURANCE_SUMMARY_FEEDBACK_CREATE = 'CREATE_ANY_SALESKIT-INSURANCE-SUMMARY-FEEDBACK',
  SALESKIT_INSURANCE_SUMMARY_FEEDBACK_READ = 'READ_ANY_SALESKIT-INSURANCE-SUMMARY-FEEDBACK',
  SALESKIT_INSURANCE_SUMMARY_FEEDBACK_UPDATE = 'UPDATE_ANY_SALESKIT-INSURANCE-SUMMARY-FEEDBACK',

  SALESKIT_TALKBOT_READ = 'READ_ANY_SALESKIT-TALKBOT',
  SALESKIT_TALKBOT_CREATE = 'CREATE_ANY_SALESKIT-TALKBOT',
  SALESKIT_TALKBOT_UPDATE = 'UPDATE_ANY_SALESKIT-TALKBOT',
}
