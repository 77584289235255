import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, FormControlLabel } from '@mui/material';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import UploadListButton from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/components/UploadListButton';
import { trimRoleName } from 'src/app/modules/AgencyCampaign/utils/common-utils';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { useWorkflowStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/pages/PageThree/CampaignTypeFormPageThree';
import {
  SelectSettingItem,
  ApprovalRoleOptionList,
  WorkflowRoleTypeEnum,
} from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import { downloadAgentListTemplate } from 'src/app/common/network';

type SelectSettingFormProps = {
  settingFor: string;
  settingFormState: SelectSettingItem;
  approvalRoleOptionList: ApprovalRoleOptionList;
  setSettingFormState: (settingFormState: SelectSettingItem) => void;
};

const SelectSettingForm: FC<SelectSettingFormProps> = ({
  settingFor,
  settingFormState,
  approvalRoleOptionList,
  setSettingFormState,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: workflowClasses } = useWorkflowStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // const [includeList, setIncludeList] = useState<string[]>([]);
  // const [excludeList, setExcludeList] = useState<string[]>([]);
  // const [targetList, setTargetList] = useState<string[]>([]);

  const isChecked = (roleType: WorkflowRoleTypeEnum, roleKey: string) => {
    const roleItem = settingFormState.roleList.find((item) => item.type === roleType);
    return !!roleItem ? roleItem.values.includes(roleKey) : false;
  };

  useEffect(() => {
    if (approvalRoleOptionList.administratorOfficerRoles.length > 0 && settingFor === 'approver') {
      handleSelectRole(WorkflowRoleTypeEnum.STAFF, approvalRoleOptionList.administratorOfficerRoles[0]);
    }
  }, []);

  const handleSelectRole = (roleType: WorkflowRoleTypeEnum, selectedRoleKey: string) => {
    let newRoleList = settingFormState.roleList.map((role) => ({
      type: role.type,
      values: [...role.values],
    }));
    const roleItem = newRoleList.find((item) => item.type === roleType);

    if (roleItem) {
      const foundIndex = roleItem.values.findIndex((role) => role === selectedRoleKey);
      if (foundIndex === -1) {
        roleItem.values.push(selectedRoleKey);
      } else {
        if (roleItem.values.length > 1) {
          roleItem.values.splice(foundIndex, 1);
        }
      }
    }
    setSettingFormState({
      ...settingFormState,
      roleList: newRoleList,
    });
  };

  return (
    <>
      <div>
        <FormControlLabel
          style={{ margin: '2px 12px 2px 0' }}
          control={
            <AcmCheckbox
              className={classes.leftCheckboxPadding}
              checked={settingFormState.selectBy === 'role'}
              onChange={(e) => {
                if (e.target.checked) {
                  setSettingFormState({ ...settingFormState, selectBy: 'role' });
                }
              }}
            />
          }
          label={'By Role'}
          labelPlacement="end"
        />

        <div className={workflowClasses.selectContainer}>
          {settingFor === 'creator' && (
            <div className={workflowClasses.bottomSpacing}>
              <div>{Translation('agencyCampaign.campaignType.workflow.agent')}</div>
              <div>
                {approvalRoleOptionList.agentRoles.map((roleOption, index) => (
                  <FormControlLabel
                    style={{ margin: '2px 24px 2px 0' }}
                    control={
                      <AcmCheckbox
                        className={classes.leftCheckboxPadding}
                        checked={isChecked(WorkflowRoleTypeEnum.AGENT, roleOption)}
                        onChange={(e) => handleSelectRole(WorkflowRoleTypeEnum.AGENT, roleOption)}
                      />
                    }
                    label={trimRoleName(roleOption)}
                    labelPlacement="end"
                  />
                ))}
              </div>
            </div>
          )}

          <div className={workflowClasses.bottomSpacing}>
            <div>{Translation('agencyCampaign.campaignType.workflow.ao')}</div>
            <div>
              {approvalRoleOptionList.administratorOfficerRoles.map((roleOption, index) => (
                <FormControlLabel
                  disabled={settingFor === 'creator' && !['BDM', 'BDD'].includes(trimRoleName(roleOption))}
                  style={{ margin: '2px 24px 2px 0' }}
                  control={
                    <AcmCheckbox
                      className={classes.leftCheckboxPadding}
                      checked={isChecked(WorkflowRoleTypeEnum.STAFF, roleOption)}
                      onChange={(e) => handleSelectRole(WorkflowRoleTypeEnum.STAFF, roleOption)}
                    />
                  }
                  label={trimRoleName(roleOption)}
                  labelPlacement="end"
                />
              ))}
            </div>
          </div>

          {/*
          <div>
            <div className={workflowClasses.uploadRowContainer}>
              <div className={workflowClasses.uploadLabelContainer}>
                <span>
                  {Translation("agencyCampaign.campaignType.workflow.extraInclude")}
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <UploadListButton
                  fileId={"include-agent-list-csv"}
                  setList={setIncludeList}
                />
              </div>
            </div>

            <div className={workflowClasses.uploadRowContainer}>
              <div className={workflowClasses.uploadLabelContainer}>
                <span>
                  {Translation("agencyCampaign.campaignType.workflow.extraExclude")}
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <UploadListButton
                  fileId={"exclude-agent-list-csv"}
                  setList={setExcludeList}
                />
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <AsyncCsvLink
                  isDisabled={true}
                  filename={"agent-list-template.csv"}
                  dataParser={(str) => str}
                  asyncCall={() => downloadAgentListTemplate(dispatch)}
                >
                  <Button disabled={true} className={workflowClasses.downloadTemplateBtn}>
                    {Translation("agencyCampaign.campaignType.workflow.downloadTemplate")}
                  </Button>
                </AsyncCsvLink>
              </div>
            </div>
          </div>
          */}
        </div>
      </div>

      {/*
      <div>
        <FormControlLabel
          style={{ margin: "2px 12px 2px 0" }}
          control={
            <AcmCheckbox
              disabled={true}
              className={classes.leftCheckboxPadding}
              checked={settingFormState.selectBy === 'target'}
              onChange={e => {
                if (e.target.checked) {
                  setSettingFormState({...settingFormState, selectBy: 'target'});
                }
              }}
            />
          }
          label={'By Target'}
          labelPlacement="end"
        />

        <div className={workflowClasses.selectContainer}>
          <div className={workflowClasses.uploadRowContainer}>
            <div style={{ flexGrow: 1 }}>
              <UploadListButton
                fileId={"target-agent-list-csv"}
                setList={setTargetList}
              />
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <AsyncCsvLink
                isDisabled={true}
                filename={"agent-list-template.csv"}
                dataParser={(str) => str}
                asyncCall={() => downloadAgentListTemplate(dispatch)}
              >
                <Button disabled={true} className={workflowClasses.downloadTemplateBtn}>
                  {Translation("agencyCampaign.campaignType.workflow.downloadTemplate")}
                </Button>
              </AsyncCsvLink>
            </div>
          </div>
        </div>
      </div>
       */}
    </>
  );
};

export default SelectSettingForm;
