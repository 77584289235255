import { FC } from 'react';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useLeadGoalListingPage } from './lead-goal-listing-page.hook';
import { LeadGoalList } from './components/lead-goal-list.component';
import { Button, CircularProgress, Dialog, DialogTitle, TextField, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type LeadGoalListingPageProps = ParamsProps;

const useStyles = makeStyles()((theme) => ({
  dialogContainer: {
    padding: '20px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    width: '70px',
    margin: '0 10px',
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    margin: '20px 20px 40px 20px',
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dialogTitle: {
    width: '2px',
    height: '13px',
    backgroundColor: '#FF0202',
    marginRight: '10px',
  },
  formContent: {
    margin: '0px 20px',
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const LeadGoalListingPage: FC<LeadGoalListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const {
    filterState,
    leadGoalList,
    isLoading,
    showDialog,
    isPointValueValid,
    isDaysValueValid,
    isValueValid,
    editTarget,
    setFilterState,
    refreshData,
    onSort,
    onAddClick,
    onEditClick,
    closeDialog,
    onPointInputChange,
    onDaysInputChange,
    onConfirm,
  } = useLeadGoalListingPage({
    initialParams,
    onChangeQueryParams,
  });

  return (
    <>
      <LeadGoalList
        isLoading={isLoading}
        leadGoalList={leadGoalList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
        onAddClick={onAddClick}
        onEditClick={onEditClick}
      />
      <Dialog open={showDialog}>
        <div className={classes.dialogContent}>
          <div className={classes.dialogHeader}>
            <div className={classes.dialogTitle} />
            <Typography fontWeight={500}>
              {editTarget
                ? Translation('pulseleads.activityPoint.leadGoal.editLeadGoal')
                : Translation('pulseleads.activityPoint.leadGoal.addLeadGoal')}
            </Typography>
          </div>
          <Typography marginLeft={'12px'}>{Translation('pulseleads.activityPoint.leadGoal.description')}</Typography>
        </div>
        <div className={classes.formContent}>
          <div>
            <div className={classes.formContainer}>
              <Typography fontWeight={500}>Reach</Typography>
              <TextField
                error={!isPointValueValid}
                onChange={onPointInputChange}
                type="number"
                variant="outlined"
                className={classes.textInput}
                defaultValue={editTarget?.targetPoint}
              />
              <Typography fontWeight={500}>points in last</Typography>
              <TextField
                error={!isDaysValueValid}
                onChange={onDaysInputChange}
                type="number"
                variant="outlined"
                className={classes.textInput}
                defaultValue={editTarget?.timeRange.days}
              />
              <Typography fontWeight={500}>days</Typography>
            </div>
            {(!isPointValueValid || !isDaysValueValid) && (
              <Typography fontWeight={500} color={'#FF0202'} lineHeight={'24px'}>
                {Translation('pulseleads.activityPoint.leadGoal.positive.integer.required')}
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.btnContainer}>
          <Button style={{ marginRight: 20 }} variant="contained" color="inherit" onClick={closeDialog}>
            {Translation('global.text.cancel')}
          </Button>
          <Button disabled={!isValueValid} onClick={onConfirm} variant="contained" color="secondary">
            {Translation('global.text.confirm')}
            {/* {deleteDialog ? <CircularProgress style={{ marginLeft: 8 }} size={15} /> : null} */}
          </Button>
        </div>
      </Dialog>
    </>
  );
};
