import { getContent } from 'src/app/common/network';
import { DateFormat, formatDate } from './date.util';
import { getConfig } from 'src/app/i18n';
import { LOCAL_MAP } from './constant';

type OptionType = {
  applicationId: string;
  content: any;
  firstname: string;
  lastname: string;
};

/**
 * @param  {string} resourceId
 * @param  {string} key
 * @description find file's url by content
 */
export const fetchFile = async (resourceId: string, key: string, mineType: string) => {
  try {
    const base64 = await getContent({
      resourceId,
      encoding: 'base64',
      safeMode: true,
      autoDecrypt: true,
      random: Date.now(),
    });
    if (base64) {
      const resourceUrl = `data:${mineType};base64,${base64}`;
      return [key, resourceUrl];
    } else {
      return [key, undefined];
    }
  } catch (err) {
    console.log('err: ', err);
    return [key, undefined];
  }
};

// format date to YYYY-MM-DD, so that is can correct display, for example,
// change date format for survey to display: "19/07/2006" -> "2006-07-19"
export const formatDates = async (dateKeyArr: string[], res: Record<string, any>, options: OptionType) => {
  (dateKeyArr as string[])?.forEach((val) => {
    res[val] = formatDate(res[val], DateFormat.yearMonthDay);
  });
};

/**
 * @param fileKeyArr: properties of file in the fields
 * @param res: answers of the questions
 * @description: fetch file with blobId, get the url of file. For example,
 * fileKeyArr = ['idCard', 'proofs']
 * res = {
 *  idCard: [{
 *     content: 'xxxxxx',
 *     ...
 *  }],
 *  proofs: [{ // with multiple file
 *     content: 'xxxxx1',
 *     ...
 *  },{
 *     content: 'xxxxx1',
 *     ...
 *  }]
 * }
 */
export const formatFiles = async (fileKeyArr: string[], res: Record<string, any>) => {
  const queries: any[] = [];
  (fileKeyArr as string[])
    ?.filter((val) => res[val]?.length)
    .forEach((val) => {
      queries.push(...res[val].map((item: any) => fetchFile(item.content, val, item.type)));
    });
  const allFile = await Promise.all(queries);
  let currentKey: string;
  let currentIndex = -1;
  allFile.forEach((r) => {
    if (r) {
      const [k, v] = r;
      if (currentKey !== k) {
        currentKey = k;
        currentIndex = 0;
      } else {
        currentIndex = currentIndex + 1;
      }

      if (res[k][currentIndex].content) {
        if (v) {
          res[k][currentIndex].content = v;
        } else {
          console.warn('file url no exist. that can be api error.');
        }
      } else {
        console.warn('file content no exist. that can be error.');
      }
    }
  });
};

export const getI18nLabel = (data: any, lang: string) => {
  const locale = LOCAL_MAP[lang as keyof typeof LOCAL_MAP];
  return data[locale];
};

/**
 * @description find section name that need to generate file name
 */
export const findFieldNameByKey = (key: string, pages: any[], lang: string): string | undefined => {
  for (let i = 0; i < pages.length; i++) {
    const { type, name, title, elements } = pages[i];
    if (type === 'file' && name === key) {
      return typeof title === 'string' ? title : getI18nLabel(title, lang);
    }
    if (elements) {
      const res = findFieldNameByKey(key, elements, lang);
      if (res) return res;
    }
  }
};

export const getFileExtension = (filename: string) => {
  return `.${filename.split('.').pop()}`;
};

/**
 * @description format file name to `${applicationId} ${fieldName} ${firstname} ${lastname} ${index}$.${extension}`
 */
export const formatFileName = async (
  fileKeyArr: string[],
  res: Record<string, any>,
  options: OptionType,
  lang: string,
) => {
  const { applicationId, content, firstname, lastname } = options;
  (fileKeyArr as string[])
    ?.filter((val) => res[val]?.length)
    .forEach((val) => {
      const fieldName = findFieldNameByKey(val, content?.pages ?? [], lang);
      res[val].forEach(
        (item: any, index: number) =>
          (item.name = `${applicationId} ${fieldName} ${firstname} ${lastname} ${index + 1}${getFileExtension(
            item.name,
          )}`),
      );
    });
};

/**
 * @description 1.get file's base64 content; 2. get file name;
 */
export const fileFormatter = async (fileKeyArr: string[], res: Record<string, any>, options: OptionType) => {
  const lang = getConfig().selectedLang;
  await formatFiles(fileKeyArr, res);
  formatFileName(fileKeyArr, res, options, lang);
};

/**
 * @description get signature file's base64 content;
 */
export const signatureFormatter = async (fileKeyArr: string[], res: Record<string, any>, options: OptionType) => {
  const queries: any[] = [];
  (fileKeyArr as string[])
    ?.filter((val) => !!res[val])
    .forEach((val) => {
      queries.push(fetchFile(res[val], val, 'image/jpeg'));
    });
  const allFile = await Promise.all(queries);
  allFile.forEach((r) => {
    if (r) {
      const [k, v] = r;
      v && (res[k] = v);
    }
  });
};

/**
 * @description: fields that need to format
 */
export const FieldsMap = {
  date: {
    isField: ({ type, inputType }: { type: string; inputType: string }) => type === 'text' && inputType === 'date',
    formatter: formatDates,
    lazyLoad: false,
  },
  file: {
    isField: ({ type }: { type: string; inputType: string }) => type === 'file',
    formatter: fileFormatter,
    lazyLoad: true, // will lazy formatter the content(load the file) if lazyLoad = true
  },
  signaturepad: {
    isField: ({ type }: { type: string; inputType: string }) => type === 'signaturepad',
    formatter: signatureFormatter,
    lazyLoad: true,
  },
};

/**
 * @description:
 * data like:
 * {
 *   a: {
 *      b: {
 *        questionAnswers: {
 *          ...aAnswers,
 *        }
 *      }
 *   },
 *   e: {
 *      f: {
 *        questionAnswers: {
 *          ...eAnswers,
 *        }
 *      }
 *   },
 * }
 *
 * to:
 * {
 *   ...aAnswers,
 *   ...eAnswers,
 * }
 */
export const findAnswers = (data: any, result = {}) => {
  Object.values(data).reduce((acc: any, val: any) => {
    if (val.questionAnswers) {
      Object.assign(acc, val.questionAnswers);
    } else if (typeof val === 'object') {
      findAnswers(val, result);
    }
    return acc;
  }, result);

  return result;
};

/**
 * @param  {any[]} data
 * @param  {any={}} result
 * @description find all the keys that need to format and category by type('file'/'date')
 * @returns
 * {
 *    file: ['proofs', 'idCard'],
 *    date: ['dateOfBirth']
 * }
 */
export const findSpecificFields = (data: any[], result: any = {}) => {
  const fields = Object.keys(FieldsMap);
  data.forEach(({ elements, type, inputType, name }) => {
    if (elements) {
      findSpecificFields(elements, result);
    } else {
      const field = fields.find((field) => FieldsMap[field as keyof typeof FieldsMap]?.isField({ type, inputType }));
      if (field) {
        if (!result[field]) result[field] = [];
        result[field].push(name);
      }
    }
  });

  return result;
};

/**
 * @param  {any[]} data
 * @description set all date to 'default', so that expand all the questions with expand icon
 */
export const expendAllElements = (data: any[]) => {
  data.forEach((item) => {
    const { elements } = item;
    if (item.state) {
      item.state = 'default';
    }

    if (elements) {
      expendAllElements(elements);
    }
  });
};

/**
 * @param  {any} specificFields
 * @param  {any} data
 * @description check whether need to execute lazy load according to answers has the value
 */
export const hasFile = (specificFields: any, answers: any) => {
  const fields = Object.entries(FieldsMap)
    .filter(([_, value]) => value.lazyLoad)
    .map(([key]) => key);

  return fields.some((key) => specificFields[key]?.some((key: any) => answers[key]));
};

// if recruiter is also agent leader, need to copy recruiter info into agent leader
export const copyRecruiterToAgentLeader = (
  answers: Record<string, any>,
  recruiterIsAgentLeader: boolean,
  options: OptionType,
  locale: string,
) => {
  if (!recruiterIsAgentLeader) return;
  if (answers.recruiterSignature && !answers.agentLeaderSignature)
    answers.agentLeaderSignature = answers.recruiterSignature;
  if (answers.recruiterIdentityDocumentUpload && !answers.agentLeaderDocumentUpload) {
    answers.agentLeaderDocumentUpload = answers.recruiterIdentityDocumentUpload;
    formatFileName(['agentLeaderDocumentUpload'], answers, options, locale);
  }
  if (answers.recruiterIdentityDocumentType && !answers.agentLeaderDocumentType)
    answers.agentLeaderDocumentType = answers.recruiterIdentityDocumentType;
};

/**
 * @param  {any} specificFields
 * @param  {any} answers
 * @param  {OptionType} options
 * @param  {} isLazyLoad=false
 * @description format the api response data. will execute twice(if hasFile = true): 1. after getting the detail api response;
 * 2. after the detail page load
 */
export const formatData = async (specificFields: any, answers: any, options: OptionType, isLazyLoad = false) => {
  const res: any = { ...answers };

  const entries = Object.entries(specificFields);
  for (let i = 0; i < entries.length; i++) {
    const [field, value] = entries[i];
    const { formatter, lazyLoad } = FieldsMap[field as keyof typeof FieldsMap] ?? {};
    if (lazyLoad === isLazyLoad) {
      await formatter?.((value as string[]) ?? [], res, options);
    }
  }

  return res;
};
