import React, { memo, useEffect, useMemo, useState } from 'react';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import 'survey-core/defaultV2.min.css';
import './application-form-section.css';
import { useLang } from 'src/app/i18n';
import { LOCAL_MAP } from '../../../util/constant';
import { Box } from '@mui/material';

type ComponentProps = {
  content?: Record<string, any>;
  data?: Record<string, any>;
  fileLoaded?: boolean;
};

export const ApplicationFormSection: React.FC<ComponentProps> = memo(
  ({ content, data, fileLoaded }: ComponentProps) => {
    const locale = useLang();
    const [containerStyle, setContainerStyle] = useState('file-container');

    const survey = useMemo(() => {
      const lang = LOCAL_MAP[locale as keyof typeof LOCAL_MAP];
      const survey = new Model(content);
      survey.mode = 'display';
      if (lang) {
        survey.locale = lang;
      }
      survey.data = data;
      return survey;
    }, [content, data]);

    useEffect(() => {
      // close the loading after file loaded
      fileLoaded &&
        setTimeout(() => {
          setContainerStyle('');
        });
    }, [fileLoaded]);

    return (
      <Box className={containerStyle}>
        <Survey id={'applicationPreviewSurvey'} model={survey} />
      </Box>
    );
  },
);
