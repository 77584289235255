import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useLang } from 'src/app/i18n';
import { useRegistrationListingPage } from './registration-listing-page.hook';
import { RegistrationList } from './components/registration-list.component';
import { fetchLicenseExamExamOptions } from '../../../network/license-exam-crud';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { RegistrationApprovalStatesEnum, ResultTypeEnum } from '../../../enum/license-exam.enum';
import { Box } from '@mui/material';

type RegistrationListingPageProps = ParamsProps & ModulePermissionProps;

export const RegistrationListingPage: FC<RegistrationListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
  enableUpdate,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();

  const { filterState, registrationList, isLoading, enableApproval, setFilterState, refreshData, onSort } =
    useRegistrationListingPage({
      initialParams,
      onChangeQueryParams,
    });

  return (
    <Box className="auto-table-height">
      <PruFilter
        title={Translation('recruitment.exam.registration.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'examCodes',
            initialValue: filterState.examCodes,
            displayName: Translation('recruitment.exam.examCode'),
          },
          {
            type: PruFilterItemType.ASYNC_DROPDOWN,
            style: { width: 180 },
            field: 'examType',
            initialValue: filterState.examType,
            displayName: Translation('recruitment.exam.examType'),
            fetchList: async () => {
              const res = await fetchLicenseExamExamOptions('EXAM_TYPE', { lang: locale }, dispatch);
              return res.map((item) => ({
                displayName: item || '-',
                value: item || '-',
              }));
            },
          },
          {
            type: PruFilterItemType.ASYNC_DROPDOWN,
            style: { width: 180 },
            field: 'examName',
            initialValue: filterState.examName as string,
            displayName: Translation('recruitment.exam.examName'),
            fetchList: async () => {
              const res = await fetchLicenseExamExamOptions(
                'EXAM_NAME',
                { lang: locale, examType: filterState.examType },
                dispatch,
              );
              return res.map((item) => ({
                displayName: item || '-',
                value: item || '-',
              }));
            },
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'examDateFrom',
            fieldTo: 'examDateTo',
            initialDateFrom: filterState.examDateFrom,
            initialDateTo: filterState.examDateTo,
            displayName: Translation('recruitment.exam.examDate'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'examCentre',
            initialValue: filterState.examCentre,
            displayName: Translation('recruitment.exam.examCentre'),
          },
          {
            type: PruFilterItemType.MULTIPLE_DROPDOWN,
            style: { width: 180 },
            field: 'registrationApprovalStates',
            initialValue: filterState.registrationApprovalStates,
            displayName: Translation('recruitment.exam.registration.status'),
            choices: Object.entries(RegistrationApprovalStatesEnum).map(([key, value]) => {
              return {
                displayName:
                  enableApproval && value === RegistrationApprovalStatesEnum.REGISTERED
                    ? Translation('recruitment.exam.registration.status.approved')
                    : Translation(`recruitment.exam.registration.status.${value}`),
                value: key,
              };
            }),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'orNumbers',
            initialValue: filterState.orNumbers,
            displayName: Translation('recruitment.exam.registration.orNumber'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'registrationIds',
            initialValue: filterState.registrationIds,
            displayName: Translation('recruitment.exam.registration.id'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'applicantIds',
            initialValue: filterState.applicantIds,
            displayName: Translation('recruitment.exam.registration.applicantId'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'firstnames',
            initialValue: filterState.firstnames,
            displayName: Translation('recruitment.exam.registration.firstName'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'lastnames',
            initialValue: filterState.lastnames,
            displayName: Translation('recruitment.exam.registration.lastName'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 180 },
            field: 'result',
            initialValue: filterState.result,
            displayName: Translation('recruitment.exam.registration.examResult'),
            choices: [
              { displayName: '', value: '' },
              ...Object.entries(ResultTypeEnum).map(([key, value]) => {
                return {
                  displayName: Translation(`recruitment.exam.registration.result.${value}`),
                  value: key,
                };
              }),
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            examCodes: data.examCodes,
            examType: data.examType,
            examName: data.examName,
            examDateFrom: data.examDateFrom,
            examDateTo: data.examDateTo,
            examCentre: data.examCentre,
            registrationApprovalStates: data.registrationApprovalStates,
            orNumbers: data.orNumbers,
            registrationIds: data.registrationIds,
            applicantIds: data.applicantIds,
            firstnames: data.firstnames,
            lastnames: data.lastnames,
            result: data.result,
          })
        }
        fetchData={refreshData}
      />
      <RegistrationList
        isLoading={isLoading}
        registrationList={registrationList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
            lang: locale,
          });
          refreshData();
        }}
        onSort={onSort}
        formState={filterState}
        enableUpdate={enableUpdate}
        enableApproval={enableApproval}
      />
    </Box>
  );
};
