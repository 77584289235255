import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  dialogWrapper: {
    width: 748,
    maxWidth: 748,
    '& .css-kga3m3-MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconColor: {
    color: '#ffffff',
  },
  addIcon: {
    fontSize: 24,
    color: '#ffffff',
  },
  infoIcon: {
    marginLeft: 16,
  },
  popper: {
    whiteSpace: 'pre-wrap',
  },
}));
