import React, { FC, useState, useEffect, useMemo } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";

import { LayoutSplashScreen } from "src/app/layout";
import CustomerSurveyDetailFrom from "./components/CustomerSurveyDetailForm";
import { fetchCustomerSurveyItem } from "../network/api";
import { SurveyFormMode, CustomerSurveyDetail } from "../network/types";

const CustomerSurveyDetailPage: FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const { id } = useParams<{ id: string }>();
  const { formMode, code } = useMemo(
    () => ({
      formMode: id ? SurveyFormMode.EDIT : SurveyFormMode.CREATE,
      code: id ? id : undefined,
    }),
    [id]
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [surveyDetail, setSurveyDetail] = useState<CustomerSurveyDetail>();

  const reloadData = () => {
    if (code) {
      setIsLoading(true);
      fetchCustomerSurveyItem(code, dispatch)
        .then((result) => {
          setSurveyDetail(result);
        })
        .catch(() => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [code]);

  return (
    <>
      {formMode === SurveyFormMode.CREATE && (
        <>
          <CustomerSurveyDetailFrom formMode={formMode} />
        </>
      )}
      {formMode === SurveyFormMode.EDIT &&
        (isLoading || !surveyDetail ? (
          <LayoutSplashScreen />
        ) : (
          <>
            <CustomerSurveyDetailFrom
              formMode={formMode}
              surveyDetail={surveyDetail}
            />
          </>
        ))}
    </>
  );
};

export default CustomerSurveyDetailPage;
