import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { PaginateList } from 'src/app/common/types';
import { getQueryPath } from 'src/app/common/utils';
import { UploadStatusItem } from '../types/upload-status-types';

const uploadStatusEndpoint = 'v2/incentives/results/logs';

export type UploadStatusListParam = {
  filename: string;
  incentiveName: string;
  updatedDateFrom: Date | null;
  updatedDateTo: Date | null;
  page: number;
  limit: number;
};

export const fetchUploadStatusList = async (
  params: UploadStatusListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<UploadStatusItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${uploadStatusEndpoint}?`, params);
  return apiClient
    .get<PaginateList<UploadStatusItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchUploadStatusItem = async (id: string, dispatch?: Dispatch<any>): Promise<UploadStatusItem> => {
  return apiClient
    .get<UploadStatusItem>(`${ADMIN_URL}/${uploadStatusEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
