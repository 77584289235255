import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { regionLocale, regionLocaleMap, useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import {
  EventCategoryMaterialsParams,
  EventMaterialTypeEnum,
} from 'src/app/modules/event-v2/types/event-category-types';
import { fileUpload } from 'src/app/common/utils';
import { createBlob, getBlob } from 'src/app/common/network';
import { useDispatch } from 'react-redux';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { useIntl } from 'react-intl';
import { fetchEventCategoryMaterialsList, updateEventCategoryMaterials } from 'src/app/modules/event-v2/network';
import { ImageAccept, checkFile } from 'src/app/modules/event-v2/utils/event-v2.util';

interface HookProps {
  id?: string;
  onClose: () => void;
}

export type MaterialItemType = {
  _id?: string | number;
  type: EventMaterialTypeEnum;
  lang: string;
  blob?: {
    blobId: string;
    filename: string;
    url: string;
    type: string;
  };
  isActive: boolean;
};

export type MaterialItemKeys = keyof MaterialItemType;

export const imageAccept: Record<string, ImageAccept> = {
  [EventMaterialTypeEnum.IMAGE]: {
    type: 'image/jpg,image/jpeg,image/png',
    maxSize: 300 * 1024,
    errMsgMap: { 'image/jpeg': 'jpg/jpeg', 'image/png': 'png' },
  },
  [EventMaterialTypeEnum.TEMPLATE]: {
    type: 'image/jpg,image/jpeg,image/png,application/pdf',
    maxSize: 20 * 1024 * 1024,
    errMsgMap: { 'image/jpeg': 'jpg/jpeg', 'image/png': 'png', 'application/pdf': 'pdf' },
  },
};

export const useMaterialDialog = ({ id, onClose }: HookProps) => {
  const intl = useIntl();
  const indexRef = useRef(0);
  const local = useLang();
  const [materialListData, setMaterialListData] = useState<Array<MaterialItemType>>([]);
  const deletedMaterialDataRef = useRef<EventCategoryMaterialsParams[]>([]); // Deleted and already created material data

  const dispatch = useDispatch();

  // useRequest to wrap api request.
  // DO NOT use useDataProvider except for <PruFilter />
  const { data, error, loading, runAsync } = useRequest((id) => fetchEventCategoryMaterialsList(id), {
    manual: true,
  });

  const initialData = useMemo(
    () => ({ _id: indexRef.current, type: EventMaterialTypeEnum.IMAGE, lang: local, isActive: true }),
    [local],
  );

  useEffect(() => {
    if (id) {
      runAsync(id).then((res) => {
        if (res?.materials?.length > 0) {
          setMaterialListData(res?.materials);
        } else {
          setMaterialListData([{ ...initialData }]);
        }
      });
    }
  }, [id, initialData, runAsync]);

  const filteredLanguage = useMemo(
    () =>
      regionLocale.map((language) => ({
        lang: language,
        ...regionLocaleMap[language],
      })),
    [],
  );

  const addNewRow = useCallback(() => {
    setMaterialListData((prev) => {
      indexRef.current++;
      prev?.push({ ...initialData, _id: indexRef.current });
      return [...prev];
    });
  }, [initialData]);

  const deleteRow = useCallback((row: MaterialItemType) => {
    if (typeof row._id === 'string') {
      deletedMaterialDataRef.current.push({ ...row, isActive: false } as EventCategoryMaterialsParams);
    }
    setMaterialListData((prev) => {
      return prev.filter((item) => item._id !== row._id);
    });
  }, []);

  const handleChange = useCallback((row: MaterialItemType, key: MaterialItemKeys, value: any) => {
    setMaterialListData((prev) => {
      prev.forEach((item: any) => {
        if (item._id === row._id) {
          item[key] = value;
        }
      });
      return [...prev];
    });
  }, []);

  const handleUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, row: MaterialItemType) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        const errMsg = checkFile(intl, imageAccept[row.type], file);
        if (errMsg) return dispatch(appendAlertItem([{ severity: AlertType.ERROR, title: '', content: errMsg }]));
        try {
          const createBlobRes = await createBlob(
            { mimeType: file.type, accessLevel: 'public', module: 'category-material' },
            dispatch,
          );
          await fileUpload(createBlobRes.url, file);
          const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
          const result = blobDetail[0];
          if (result) {
            handleChange(row, 'blob', {
              blobId: result.blobId,
              filename: file.name,
              url: result.url,
              type: result.mimeType,
            });
          }
        } catch (err) {}
      }
    },
    [dispatch, handleChange, intl],
  );

  const handleClose = useCallback(() => {
    onClose();
    deletedMaterialDataRef.current = [];
    setMaterialListData([]);
  }, [onClose]);

  const handleConfirm = useCallback(async () => {
    const filterData = materialListData
      .filter((item) => !!item.blob?.blobId)
      .map((item) => {
        if (typeof item._id === 'number') {
          item._id = undefined;
        }
        return item;
      });
    const materials = deletedMaterialDataRef.current.concat(filterData as EventCategoryMaterialsParams[]);
    if (materials.length > 0) {
      await updateEventCategoryMaterials(id!, { materials });
    }
    onClose();
  }, [id, materialListData, onClose]);

  return {
    loading,
    materialListData,
    setMaterialListData,
    filteredLanguage,
    handleChange,
    addNewRow,
    deleteRow,
    handleUpload,
    handleClose,
    handleConfirm,
  };
};
