import React, { useCallback, useMemo, useState } from 'react';
import { CreateScheduleFields } from 'src/app/modules/Recruitment/types/license-exam-types';
import { useForm } from 'src/app/common/components/Form';
import { createScheduleConfigs } from '../../../util/form-configs.util';

export interface CreateScheduleProps {
  initialValues?: Record<CreateScheduleFields, any>;
  disabled?: Record<CreateScheduleFields, boolean>;
  onSubmit: (value: Record<CreateScheduleFields, any>) => Promise<void>;
  onCancel: () => void;
  showSubmit: boolean;
}

export const useCreateSchedule = ({ onSubmit }: CreateScheduleProps) => {
  const [form] = useForm();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const onFinish = useCallback(
    async (values: any) => {
      setConfirmLoading(true);
      await onSubmit(values);
      setConfirmLoading(false);
    },
    [onSubmit],
  );

  const [
    examCodeConfig,
    examNameConfig,
    localExamNameConfig,
    examTypeConfig,
    examDateConfig,
    startTimeConfig,
    endTimeConfig,
    venueConfig,
    addressConfig,
    lastRegistrationDateConfig,
    quotaConfig,
    remainingQuotaConfig,
    remarkConfig,
  ] = useMemo(() => createScheduleConfigs(), []);

  return {
    form,
    examCodeConfig,
    examNameConfig,
    localExamNameConfig,
    examTypeConfig,
    examDateConfig,
    startTimeConfig,
    endTimeConfig,
    venueConfig,
    addressConfig,
    lastRegistrationDateConfig,
    quotaConfig,
    remainingQuotaConfig,
    remarkConfig,
    onFinish,
    confirmLoading,
  };
};
