import { I18nGenericData } from 'src/app/i18n';

export enum DialogueStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface DialogueListParam {
  isActive?: boolean;
  sortBy?: string;
}

export enum DialogueKeyEnum {
  'careshield' = 'careshield',
  'savings-call' = 'savings-call',
  'retirement-call' = 'retirement-call',
  'general-policy-review-call' = 'general-policy-review-call',
  'annual-check-in-servicing-virtual-agent-mentioned' = 'annual-check-in-servicing-virtual-agent-mentioned',
  'annual-check-in-servicing-virtual-agent-not-mentioned' = 'annual-check-in-servicing-virtual-agent-not-mentioned',
  'birthday-call' = 'birthday-call',
}

export interface TalkbotFlowItem {
  dialogueKey: DialogueKeyEnum;
  flowId: number;
}

export interface DialogueItem extends DialogueDetails {
  _id: string;
  updatedBy: string;
  updatedAt: Date;
}

export interface DialogueDetails {
  flowId: number;
  name: I18nGenericData<string>;
  description?: I18nGenericData<string>;
  isActive: boolean;
  image: { blobId: string };
  weight: number;
}
