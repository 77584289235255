import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { map } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { Dialog, Button } from '@mui/material';
import { regionLocale } from 'src/app/i18n';
import { PaginateList } from 'src/app/common/types';
import { useDataProvider, getDefaultDisplayDate } from 'src/app/common/utils';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { IncentiveTypeEnum, PublishStatusEnum, IncentiveListItem } from '../../../../types/incentive-types';
import { fetchIncentiveList, IncentiveListParam } from '../../../../network/incentiveCrud';

type SelectIncentiveDialogProps = {
  open: boolean;
  selectedIncentives?: IncentiveListItem[];
  onConfirm: (selected: IncentiveListItem[]) => void;
  onClose: () => void;
};

const initialState: IncentiveListParam = {
  type: IncentiveTypeEnum.INCENTIVE,
  code: '',
  name: '',
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  createdDateFrom: null,
  createdDateTo: null,
  status: '',
  isArchived: 'false',
  page: 1,
  limit: 5,
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: '80vw',
    padding: 50,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 28,
  },
}));

const SelectIncentiveDialog: FC<SelectIncentiveDialogProps> = ({ open, selectedIncentives, onConfirm, onClose }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [filterState, setFilterState] = useState<IncentiveListParam>(initialState);
  const [incentiveList, setIncentiveList] = useState<PaginateList<IncentiveListItem>>();
  const [selectedRows, setSelectedRows] = useState<IncentiveListItem[]>([]);

  const { isLoading, refreshData } = useDataProvider<PaginateList<IncentiveListItem>>(
    async () => {
      try {
        return await fetchIncentiveList(filterState, dispatch);
      } catch (err) {}
    },
    setIncentiveList,
    false,
  );

  return (
    <Dialog maxWidth={false} open={open} classes={{ paper: classes.paper }}>
      <PruFilter
        style={{ padding: 0 }}
        title={Translation('incentive.set.common.select_incentive.header')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('incentive.common.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('incentive.common.status'),
            choices: [
              { displayName: '', value: '' },
              ...map(PublishStatusEnum, (option: string) => ({ displayName: option.toUpperCase(), value: option })),
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
            status: data.status || '',
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        style={{ maxHeight: 'calc(100% - 146px)', overflowY: 'auto' }}
        headerBtnDef={[]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'code',
            displayName: Translation('incentive.common.code'),
            renderData: (row) => row.code,
          },
          ...(() =>
            regionLocale.map((locale) => ({
              keyIndex: `name-${locale}`,
              displayName: Translation(`incentive.common.name.${locale}`),
              renderData: (row: IncentiveListItem) => row.name[locale] || '-',
            })))(),
          {
            keyIndex: 'startDate',
            align: 'center',
            displayName: Translation('incentive.common.startDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.startDate)),
          },
          {
            keyIndex: 'endDate',
            align: 'center',
            displayName: Translation('incentive.common.endDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.endDate)),
          },
          {
            keyIndex: 'incentiveStatus',
            align: 'center',
            displayName: Translation('incentive.common.status'),
            renderData: (row) => (row.incentiveStatus || '-').toUpperCase(),
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('incentive.common.createdDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.createdAt)),
          },
        ]}
        isLoading={isLoading}
        onRefresh={refreshData}
        dataSource={incentiveList?.docs}
        totalPages={incentiveList?.totalPages}
        totalRecords={incentiveList?.totalDocs}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        currentSelectedRow={(rowData) => setSelectedRows(rowData)}
        bulkSelectCheckboxDisable={(rowData) => !!selectedIncentives?.find((item) => item._id === rowData._id)}
      />
      <div className={classes.footer}>
        <Button onClick={onClose} variant="contained">
          {Translation('app.button.cancel')}
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          variant="contained"
          color="secondary"
          onClick={() => {
            onConfirm(selectedRows);
            onClose();
          }}
        >
          {Translation('app.button.submit')}
        </Button>
      </div>
    </Dialog>
  );
};

export default SelectIncentiveDialog;
