import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ResourceLibraryList from '../../../components/Library/pages/List';
import { SALESKIT_BASE_PATH, SALES_MATERIAL_BASE_PATH, SALES_LIBRARY_BASE_PATH,  } from '../../../constants';
import { ModulePermissionProps } from '../../../../../common/module/types';

export const SystemConfig =  {
  filterName: 'component.filterTitle.sales-material',
  blockName: 'component.formTitle.sales-material',
  blockType:  'salesMaterial',
  moduleType: 'Sales',
  // baseUrl: SALES_LIBRARY_BASE_PATH
  createPagePath: `${SALESKIT_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}/create`,
  editPageBasePath: `${SALESKIT_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}/edit`,
  viewPageBasePath: `${SALESKIT_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}/view`,
  performancePagePath: `${SALESKIT_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}/performance`,
  // categoryType: 'resourceCategory',
  // columnType: 'resourceColumn',
  // addSuccessText: 'global.submit.success',
  // columnModuleType: 'recruitment'
}

const SalesLibraryListPage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return (
    <ResourceLibraryList { ...props } { ...SystemConfig } />
  )
};

export default SalesLibraryListPage;
