import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useDataProvider, getDayStart, getDayEnd } from 'src/app/common/utils';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PaginateList } from 'src/app/common/types';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { UploadStatusItem } from '../../../types/upload-status-types';
import { UploadStatusListParam, fetchUploadStatusList } from '../../../network/uploadStatusCrud';
import UploadStatusList from './components/UploadStatusList';

type UploadStatusListingPageProps = ParamsProps;

const initialState: UploadStatusListParam = {
  filename: '',
  incentiveName: '',
  updatedDateFrom: null,
  updatedDateTo: null,
  page: 1,
  limit: 5,
};

export const uploadStatusFilterKeys = ['filename', 'incentiveName', 'updatedDateFrom', 'updatedDateTo'];

const paramsInitiator = (initialParams?: Record<string, string>): UploadStatusListParam => {
  return initialParams
    ? {
        filename: initialParams.name || '',
        incentiveName: initialParams.incentiveName || '',
        updatedDateFrom: initialParams.updatedDateFrom ? getDayStart(new Date(initialParams.updatedDateFrom)) : null,
        updatedDateTo: initialParams.updatedDateTo ? getDayEnd(new Date(initialParams.updatedDateTo)) : null,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const UploadStatusListingPage: FC<UploadStatusListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<UploadStatusListParam>(paramsInitiator(initialParams));
  const [uploadStatusList, setUploadStatusList] = useState<PaginateList<UploadStatusItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<UploadStatusItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchUploadStatusList(filterState, dispatch);
      } catch (err) {}
    },
    setUploadStatusList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation(`incentive.upload_status.filter`)}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'filename',
            initialValue: filterState.filename,
            displayName: Translation('incentive.upload_status.common.filename'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'incentiveName',
            initialValue: filterState.incentiveName,
            displayName: Translation('incentive.common.name'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'updatedDateFrom',
            fieldTo: 'updatedDateTo',
            initialDateFrom: filterState.updatedDateFrom,
            initialDateTo: filterState.updatedDateTo,
            displayName: Translation('incentive.upload_status.common.updated_at'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            filename: data.filename,
            incentiveName: data.incentiveName,
            updatedDateFrom: data.updatedDateFrom,
            updatedDateTo: data.updatedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <UploadStatusList
        isLoading={isLoading}
        onRefresh={refreshData}
        uploadStatusList={uploadStatusList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default UploadStatusListingPage;
