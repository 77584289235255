import { FC } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Dialog, Button } from '@mui/material';

type UpdateSetDialogProps = {
  open: boolean;
  setCode: string;
  onClose: () => void;
  onUpdate: (updateSet: boolean) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footer: {
    marginTop: 20,
  },
}));

const UpdateSetDialog: FC<UpdateSetDialogProps> = ({ open, setCode, onClose, onUpdate }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.container}>
        <div>{Translation('incentive.common.update_set_reminder', { setCode })}</div>
        <div className={classes.footer}>
          <Button style={{ marginRight: 20 }} variant="contained" color="secondary" onClick={() => onUpdate(true)}>
            {Translation('app.checkbox.yes')}
          </Button>
          <Button variant="contained" color="inherit" onClick={() => onUpdate(false)}>
            {Translation('app.checkbox.no')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateSetDialog;
