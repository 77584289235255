import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    marginBottom: 24,
  },
  error: {
    backgroundColor: 'red',
  },
  container: {
    padding: 24,
    marginTop: 10,
    marginBottom: 32,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 8,
  },
  header: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  dialogContainer: {
    padding: 48,
    minWidth: 500,
  },
  dialogTitle: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: 8,
    textAlign: 'center',
  },
  dialogSubtitle: {
    textAlign: 'center',
  },
  dialogButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
  },
  buttonMargin: {
    marginLeft: 24,
  },
  content: {
    padding: 24,
  },
  list: {
    paddingLeft: 16,
    listStyleType: 'disc',
  },
  formHeader: {
    width: '160px',
  },
  formContent: {
    fontSize: '1.2rem',
  },
  mandatory: {
    color: '#E8192C',
  },
  radioGroup: { display: 'flex', flexDirection: 'row' },
  resultContainer: {
    height: 40,
  },

  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 42,
  },
}));
