import { FC } from 'react';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useTrainingFeeVerificationListingPage } from './training-fee-verification-listing-page.hook';
import { TrainingFeeVerificationList } from './components/training-fee-verification-list.component';
import { PaymentTypeEnum, TrainingFeeVerificationStatusEnum } from 'src/app/modules/Recruitment/enum/license-exam.enum';
import { RouteComponentProps } from 'react-router-dom';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { Box } from '@mui/material';

type TrainingFeeVerificationListingPageProps = ParamsProps & RouteComponentProps & ModulePermissionProps;

export const TrainingFeeVerificationListingPage: FC<TrainingFeeVerificationListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { filterState, trainingFeeVerificationList, isLoading, setFilterState, refreshData, onSort } =
    useTrainingFeeVerificationListingPage({
      initialParams,
      onChangeQueryParams,
    });

  return (
    <Box className="auto-table-height">
      <PruFilter
        title={Translation('onboarding.training.fee.verification.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'submissionIds',
            initialValue: filterState.submissionIds,
            displayName: Translation('onboarding.training.fee.verification.submissionId'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'applicantIds',
            initialValue: filterState.applicantIds,
            displayName: Translation('onboarding.training.fee.verification.applicantId'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'firstnames',
            initialValue: filterState.firstnames,
            displayName: Translation('onboarding.training.fee.verification.firstname'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'lastnames',
            initialValue: filterState.lastnames,
            displayName: Translation('onboarding.training.fee.verification.lastname'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'orNumber',
            initialValue: filterState.trainingFeeOfficialReceiptNumbers,
            displayName: Translation('onboarding.training.fee.verification.orNumber'),
          },
          {
            type: PruFilterItemType.MULTIPLE_DROPDOWN,
            style: { width: 180 },
            field: 'paymentTypeList',
            initialValue: filterState.paymentTypeList,
            displayName: Translation('onboarding.training.fee.verification.paymentType'),
            choices: Object.entries(PaymentTypeEnum).map(([key, value]) => {
              return {
                displayName: Translation(`onboarding.training.fee.verification.${value}`),
                value: key,
              };
            }),
          },
          {
            type: PruFilterItemType.MULTIPLE_DROPDOWN,
            style: { width: 180 },
            field: 'paymentStatusList',
            initialValue: filterState.paymentStatusList,
            displayName: Translation('onboarding.training.fee.verification.paymentStatus'),
            choices: Object.entries(TrainingFeeVerificationStatusEnum).map(([key, value]) => {
              return {
                displayName: Translation(`onboarding.training.fee.verification.${value}`),
                value: key,
              };
            }),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'submissionDateFrom',
            fieldTo: 'submissionDateTo',
            initialDateFrom: filterState.submissionDateFrom,
            initialDateTo: filterState.submissionDateTo,
            displayName: Translation('onboarding.training.fee.verification.submissionDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            submissionIds: data.submissionIds,
            applicantIds: data.applicantIds,
            firstnames: data.firstnames,
            lastnames: data.lastnames,
            trainingFeeOfficialReceiptNumbers: data.orNumber,
            paymentTypeList: data.paymentTypeList,
            paymentStatusList: data.paymentStatusList,
            submissionDateFrom: data.submissionDateFrom,
            submissionDateTo: data.submissionDateTo,
          })
        }
        fetchData={refreshData}
      />
      <TrainingFeeVerificationList
        isLoading={isLoading}
        trainingFeeVerificationList={trainingFeeVerificationList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
        formState={filterState}
      />
    </Box>
  );
};
