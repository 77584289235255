import React, { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import { LayoutSplashScreen } from 'src/app/layout';
import { useDataProvider } from 'src/app/common/utils';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PaginateList } from 'src/app/common/types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { fetchUploadStatusItem } from '../../../network/uploadStatusCrud';
import { UploadStatusItem } from '../../../types/upload-status-types';

const UploadFailedList: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadStatusItem, setUploadStatusItem] = useState<UploadStatusItem>();

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const res = await fetchUploadStatusItem(id, dispatch);
        setUploadStatusItem(res);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    reloadData();
  }, [id]);

  return (
    <PruTable
      title={Translation('pulseleads.bulkUpload.common.failedList')}
      disableBulkSelect
      disablePagination
      disableRefresh
      operationDef={[]}
      columnDef={[
        {
          isId: true,
          hidden: true,
          keyIndex: '_id',
          displayName: '',
          renderData: (row) => '',
        },
        {
          keyIndex: 'name',
          displayName: Translation('incentive.kpi.common.name'),
          renderData: (row) => row.name[locale] || '-',
        },
        {
          keyIndex: 'key',
          displayName: Translation('incentive.upload_status.common.kpi_key_missing'),
          renderData: (row) => row.key || '-',
        },
      ]}
      isLoading={isLoading}
      dataSource={uploadStatusItem?.kpiMiss}
    />
  );
};

export default UploadFailedList;
