import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';

import { updateVerificationStatus, fetchLicenseVerificationDetail } from '../../../network/license-verification-crud';
import { useHistory } from 'react-router-dom';
import { licenseVerificationPath } from '../license-verification-routes';
import { LicenseApprovalEventsEnum, LicenseVerificationStatusEnum } from '../../../enum/license-exam.enum';
import { getBlob } from 'src/app/common/network';
import { Translation, TranslationWithParams } from 'src/app/i18n';

interface HookProps {
  id: string;
  enableUpdate: boolean;
}

export const useLicenseVerificationDetail = ({ id, enableUpdate }: HookProps) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState<{ open: boolean; header: string }>({ open: false, header: '' });
  const [action, setAction] = useState<LicenseApprovalEventsEnum>(LicenseApprovalEventsEnum.approve);
  const [remark, setRemark] = useState<string>('');

  const { data, run } = useRequest(() => fetchLicenseVerificationDetail(id, dispatch), {
    manual: true,
  });

  const { run: runUpdateVerificationStatus, loading: loadingUpdate } = useRequest(
    () =>
      updateVerificationStatus(
        {
          licenseApprovalEvents: action,
          remark: remark,
        },
        id,
        dispatch,
      )
        .then(() => {
          handleCloseDialog();
          history.push(`${licenseVerificationPath}/list`);
        })
        .catch((error) => {
          console.log({ error });
          handleCloseDialog();
        }),
    {
      manual: true,
    },
  );

  useEffect(() => {
    run();
  }, [id]);

  const showApprovalButtons = useMemo(
    () => data?.licenseApprovalStates === LicenseVerificationStatusEnum.PENDING_APPROVAL.toUpperCase() && enableUpdate,
    [data],
  );

  const handleCloseDialog = useCallback(() => {
    setDialogState({ open: false, header: '' });
    setRemark('');
  }, []);

  const handlePress = useCallback((action: LicenseApprovalEventsEnum) => {
    setDialogState({
      open: true,
      header: TranslationWithParams('recruitment.license.verification.dialogTitle', {
        action: Translation(`recruitment.exam.registration.${action.toLowerCase()}`).toLowerCase(),
      }),
    });
    setAction(action);
  }, []);

  const handleConfirm = useCallback(async () => {
    runUpdateVerificationStatus();
  }, []);

  const fetchFile = async (blobId: string) => {
    try {
      const blobDetail = await getBlob({ resourceIds: blobId }, dispatch);
      const result = blobDetail[0];
      if (result?.url) {
        window.open(result.url, '_blank');
      }
    } catch (err) {}
  };

  return {
    data,
    run,
    handlePress,
    dialogState,
    handleCloseDialog,
    action,
    handleConfirm,
    showApprovalButtons,
    remark,
    setRemark,
    loadingUpdate,
    fetchFile,
  };
};
