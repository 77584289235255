import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { regionLocale } from 'src/app/i18n';
import { PaginateList } from 'src/app/common/types';
import { getConfigurations, getDefaultDisplayDate } from 'src/app/common/utils';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { incentivePath } from '../../IncentiveRoutes';
import { PublishStatusEnum, IncentiveListItem } from '../../../../types/incentive-types';
import { publishIncentive, unpublishIncentive } from '../../../../network/incentiveCrud';
import UploadResultDialog from './UploadResultDialog';
import UpdateSetDialog from '../../components/UpdateSetDialog';

type IncentiveListProps = {
  isLoading: boolean;
  incentiveList?: PaginateList<IncentiveListItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  code: string;
  resultFileName: string;
};

type UpdateSetDialogState = {
  open: boolean;
  code: string;
  setCode: string;
};

const IncentiveList: FC<IncentiveListProps> = ({ isLoading, incentiveList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const enableUploadCsv = getConfigurations()?.Incentive?.dataProvider === 'MONGODB';

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    code: '',
    resultFileName: '',
  });
  const [updateSetDialogState, setUpdateSetDialogState] = useState<UpdateSetDialogState>({
    open: false,
    code: '',
    setCode: '',
  });

  const onPublish = async (code: string, updateSet?: boolean) => {
    const fromUpdateSetDialog = typeof updateSet === 'boolean';
    try {
      await publishIncentive(code, updateSet, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Published successfully - ${code}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {
      if (fromUpdateSetDialog) {
        throw new Error('Update Failed');
      }
    }
  };

  const onUnpublish = async (code: string) => {
    try {
      await unpublishIncentive(code, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unpublished successfully - ${code}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onUpdate = async (updateSet: boolean) => {
    try {
      await onPublish(updateSetDialogState.code, updateSet);
      if (updateSet) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Set's duration, publish date and archive date are updated.`,
            },
          ]),
        );
      }
    } catch (err) {
    } finally {
      setUpdateSetDialogState({ open: false, code: '', setCode: '' });
    }
  };

  return (
    <>
      <UploadResultDialog
        key={`upload-csv-dialog-${dialogState.open}`}
        code={dialogState.code}
        resultFileName={dialogState.resultFileName}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, code: '', resultFileName: '' })}
        onRefresh={onRefresh}
      />
      {updateSetDialogState.open && (
        <UpdateSetDialog
          open={updateSetDialogState.open}
          setCode={updateSetDialogState.setCode}
          onClose={() => setUpdateSetDialogState({ open: false, code: '', setCode: '' })}
          onUpdate={onUpdate}
        />
      )}
      <PruTable
        title={Translation('incentive.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('incentive.newIncentive'),
            onClick: () => history.push(`${incentivePath}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Incentive',
            onClick: (row) => history.push(`${incentivePath}/edit/${row.code}`),
            condition: (row) => row.incentiveStatus === PublishStatusEnum.DRAFT,
          },
          {
            title: Translation('section.common.operation.view'),
            tooltipText: 'View Incentive',
            onClick: (row) => history.push(`${incentivePath}/edit/${row.code}`),
            condition: (row) => row.incentiveStatus !== PublishStatusEnum.DRAFT,
          },
          {
            title: Translation('section.common.operation.publish'),
            tooltipText: 'Publish Incentive',
            onClick: (row) =>
              row.setCode
                ? setUpdateSetDialogState({ open: true, code: row.code, setCode: row.setCode })
                : onPublish(row.code),
            condition: (row) => row.incentiveStatus !== PublishStatusEnum.PUBLISHED,
          },
          {
            title: Translation('section.common.operation.unpublish'),
            tooltipText: 'Unpublish Incentive',
            onClick: (row) => onUnpublish(row.code),
            condition: (row) => row.incentiveStatus === PublishStatusEnum.PUBLISHED,
          },
          {
            title: Translation('section.common.operation.upload'),
            tooltipText: 'Upload CSV Result',
            onClick: (row) => setDialogState({ open: true, code: row.code, resultFileName: '' }),
            condition: () => enableUploadCsv,
          },
        ]}
        columnDef={[
          {
            isId: true,
            keyIndex: 'code',
            displayName: Translation('incentive.common.code'),
            renderData: (row) => row.code,
          },
          ...(() =>
            regionLocale.map((locale) => ({
              keyIndex: `name-${locale}`,
              displayName: Translation(`incentive.common.name.${locale}`),
              renderData: (row: IncentiveListItem) => row.name[locale] || '-',
            })))(),
          {
            keyIndex: 'startDate',
            align: 'center',
            displayName: Translation('incentive.common.startDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.startDate)),
          },
          {
            keyIndex: 'endDate',
            align: 'center',
            displayName: Translation('incentive.common.endDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.endDate)),
          },
          {
            keyIndex: 'incentiveStatus',
            align: 'center',
            displayName: Translation('incentive.common.status'),
            renderData: (row) => (row.incentiveStatus || '-').toUpperCase(),
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('incentive.common.createdDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.createdAt)),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={incentiveList?.docs}
        totalPages={incentiveList?.totalPages}
        totalRecords={incentiveList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default IncentiveList;
