import React, { FC } from 'react';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { editHistoryBasePath } from './edit-history-routes';
import { utcToLocalDate } from '../../utils';
type HistoryListProps = {
  isLoading: boolean;
  historyList: any;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};


const HistoryList: FC<HistoryListProps> = ({ isLoading, historyList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });



  return (
    <>
      <PruTable
        disableBulkSelect
        isLoading={isLoading}
        onRefresh={onRefresh}
        onChangePage={onChangePage}
        title={Translation('recruitment.applicationForm.editHistory.list.title')}
        operationDef={[
          {
            title: Translation('section.common.operation.view'),
            tooltipText: `${Translation('recruitment.applicationForm.editHistory.list.viewEditHistory')}`,
            onClick: (row) => history.push(`${editHistoryBasePath}/detail/${row.id}`),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: 'id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'lastUpdatedBy',
            displayName: `${Translation('recruitment.common.lastUpdatedBy')}`,
            renderData: (row) => `${row.lastUpdatedBy}` || '-',
          },
          {
            keyIndex: 'lastUpdatedAt',
            displayName: `${Translation('recruitment.common.lastUpdatedAt')}`,
            renderData: (row) => `${utcToLocalDate(row.lastUpdatedAt)}` || '-',
          },
        ]}
        dataSource={historyList?.data}
        totalPages={historyList?.totalPages}
        totalRecords={historyList?.totalNumbers}
      />
    </>
  );
};

export default HistoryList;
