import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { applicationListFilterKeys } from './list/application-list-listing-page.hook';
import { ApplicationListListingPage } from './list/application-list-listing-page.screen';
import {
  RECRUITMENT_ONBOARDING_APPLICATION_PATH,
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_APPROVAL_BASE_PATH,
  RECRUITMENT_APPROVAL_LIST_PATH,
  RECRUITMENT_APPROVAL_detail_PATH,
} from '../../../constants';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { DetailPage } from './detail/detail.screen';

export const approvalBasePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_APPLICATION_PATH}${RECRUITMENT_APPROVAL_BASE_PATH}`;

export const applicationListPath = `${approvalBasePath}${RECRUITMENT_APPROVAL_LIST_PATH}`;
export const applicationDetailPath = `${approvalBasePath}${RECRUITMENT_APPROVAL_detail_PATH}`;

const ApprovalRoutes: FC<RouteComponentProps & ModulePermissionProps> = (routeProps) => {
  return (
    <Switch>
      <Redirect exact from={approvalBasePath} to={applicationListPath} />
      <PruRoute
        exact
        path={applicationListPath}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={applicationListFilterKeys}>
            <ApplicationListListingPage {...routeProps} />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${applicationDetailPath}/:id`} render={() => <DetailPage {...routeProps} />} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default ApprovalRoutes;
