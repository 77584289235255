import dayjs from 'dayjs';
import { regionLocale } from 'src/app/i18n';
import { CreateScheduleFields } from 'src/app/modules/Recruitment/types/license-exam-types';

export const combineDateTime = (date: string, timeStr: string) => {
  const time = dayjs(timeStr, 'HH:mm');
  const combinedDateTime = dayjs(date).set('hour', time.hour()).set('minute', time.minute());
  return combinedDateTime;
};

export const formatParams = (params: Record<CreateScheduleFields, any>) => {
  const { examDate, startTime, endTime, lastRegistrationDate, examNameLocal, examNameEnglish, ...rest } = params;
  const examName = { en: examNameEnglish } as any;
  regionLocale.map((locale) => {
    if (locale !== 'en') {
      examName[locale] = examNameLocal;
    }
  });

  return {
    ...rest,
    examName,
    examDate: dayjs(examDate).format('YYYY-MM-DD'),
    startTime: dayjs(startTime).format('HH:mm'),
    endTime: dayjs(endTime).format('HH:mm'),
    lastRegistrationDate: dayjs(lastRegistrationDate).toISOString(),
  };
};
