import { IntlShape } from 'react-intl';
import { ModuleHierachy } from 'src/app/common/module/types';
import { isPermitted, getConfigurations } from 'src/app/common/utils';
import {
  INCENTIVE_TITLE,
  INCENTIVE_SET_TITLE,
  INCENTIVE_KPI_TITLE,
  INCENTIVE_UPLOAD_STATUS_TITLE,
  INCENTIVE_BASE_PATH,
  INCENTIVE_INCENTIVE_PATH,
  INCENTIVE_INCENTIVE_SET_PATH,
  INCENTIVE_KPI_PATH,
  INCENTIVE_UPLOAD_STATUS_PATH,
  INCENTIVE_NAMESPACE,
  INCENTIVE_KPI_NAMESPACE,
  AdminIncentivePermissionType,
} from './constants';
import IncentiveRoutes from './pages/Incentive/IncentiveRoutes';
import IncentiveSetRoutes from './pages/IncentiveSet/IncentiveSetRoutes';
import KpiRoutes from './pages/Kpi/KpiRoutes';
import UploadStatusRoutes from './pages/UploadStatus/UploadStatusRoutes';

export const incentiveModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  const enableUploadPage = getConfigurations()?.Incentive?.dataProvider === 'MONGODB';
  return {
    title: Translation(INCENTIVE_TITLE),
    path: INCENTIVE_BASE_PATH,
    icon: '/media/svg/icons/Media/Equalizer.svg',
    enableRead: isPermitted(
      [
        AdminIncentivePermissionType.INCENTIVE_READ,
        AdminIncentivePermissionType.INCENTIVE_CREATE,
        AdminIncentivePermissionType.INCENTIVE_UPDATE,
        AdminIncentivePermissionType.INCENTIVE_KPI_READ,
        AdminIncentivePermissionType.INCENTIVE_KPI_CREATE,
        AdminIncentivePermissionType.INCENTIVE_KPI_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(INCENTIVE_TITLE),
        path: `${INCENTIVE_BASE_PATH}${INCENTIVE_INCENTIVE_PATH}`,
        namespace: INCENTIVE_NAMESPACE,
        component: IncentiveRoutes,
        enableRead: isPermitted(
          [
            AdminIncentivePermissionType.INCENTIVE_READ,
            AdminIncentivePermissionType.INCENTIVE_CREATE,
            AdminIncentivePermissionType.INCENTIVE_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminIncentivePermissionType.INCENTIVE_CREATE], permissions),
        enableUpdate: isPermitted([AdminIncentivePermissionType.INCENTIVE_UPDATE], permissions),
      },
      {
        disableRoleControl: true,
        title: Translation(INCENTIVE_SET_TITLE),
        path: `${INCENTIVE_BASE_PATH}${INCENTIVE_INCENTIVE_SET_PATH}`,
        namespace: INCENTIVE_NAMESPACE,
        component: IncentiveSetRoutes,
        enableRead: isPermitted(
          [
            AdminIncentivePermissionType.INCENTIVE_READ,
            AdminIncentivePermissionType.INCENTIVE_CREATE,
            AdminIncentivePermissionType.INCENTIVE_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminIncentivePermissionType.INCENTIVE_CREATE], permissions),
        enableUpdate: isPermitted([AdminIncentivePermissionType.INCENTIVE_UPDATE], permissions),
      },
      {
        title: Translation(INCENTIVE_KPI_TITLE),
        path: `${INCENTIVE_BASE_PATH}${INCENTIVE_KPI_PATH}`,
        namespace: INCENTIVE_KPI_NAMESPACE,
        component: KpiRoutes,
        enableRead: isPermitted(
          [
            AdminIncentivePermissionType.INCENTIVE_KPI_READ,
            AdminIncentivePermissionType.INCENTIVE_KPI_CREATE,
            AdminIncentivePermissionType.INCENTIVE_KPI_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminIncentivePermissionType.INCENTIVE_KPI_CREATE], permissions),
        enableUpdate: isPermitted([AdminIncentivePermissionType.INCENTIVE_KPI_UPDATE], permissions),
      },
      {
        disableRoleControl: true,
        title: Translation(INCENTIVE_UPLOAD_STATUS_TITLE),
        path: `${INCENTIVE_BASE_PATH}${INCENTIVE_UPLOAD_STATUS_PATH}`,
        namespace: INCENTIVE_NAMESPACE,
        component: UploadStatusRoutes,
        enableRead: enableUploadPage
          ? isPermitted(
              [
                AdminIncentivePermissionType.INCENTIVE_READ,
                AdminIncentivePermissionType.INCENTIVE_CREATE,
                AdminIncentivePermissionType.INCENTIVE_UPDATE,
              ],
              permissions,
            )
          : false,
        enableCreate: enableUploadPage
          ? isPermitted([AdminIncentivePermissionType.INCENTIVE_CREATE], permissions)
          : false,
        enableUpdate: enableUploadPage
          ? isPermitted([AdminIncentivePermissionType.INCENTIVE_UPDATE], permissions)
          : false,
      },
    ],
  };
};
