import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ResourceLibraryList from '../../../components/Library/pages/List';
import { SALESKIT_BASE_PATH, PROMOTION_MATERIAL_BASE_PATH, PROMOTION_LIBRARY_BASE_PATH } from '../../../constants';
import { ModulePermissionProps } from '../../../../../common/module/types';

export const SystemConfig =  {
  filterName: 'component.filterTitle.promotion-material',
  blockName: 'component.formTitle.promotion-material',
  blockType:  'promotionMaterial',
  moduleType: 'Promotion',
  createPagePath: `${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_LIBRARY_BASE_PATH}/create`,
  editPageBasePath: `${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_LIBRARY_BASE_PATH}/edit`,
  viewPageBasePath: `${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_LIBRARY_BASE_PATH}/view`,
  performancePagePath: `${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_LIBRARY_BASE_PATH}/performance`,
}

const PromotionLibraryListPage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return (
    <ResourceLibraryList { ...props } { ...SystemConfig } />
  )
};

export default PromotionLibraryListPage;
