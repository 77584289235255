import React, { FC, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import PruFilter, {
  PruFilterDateRangeDef,
  PruFilterDropdownDef,
  PruFilterItemType,
} from '../../../../../common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import DashboardDetailPage from './components/DashboardDetailPage';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { ChartListParam, fetchChartList } from '../../../network/dashboardCrud';
import { DashboardItem, BDMdashboardName, DashboardType, BDMdashboardFilter } from '../../../types/dashboard-types';
import { useDataProvider, useJWT } from 'src/app/common/utils';
const ExportJsonExcel = require('js-export-excel');
type DashboardListingPageProps = {} & ParamsProps;
type PruFilterItemDef = PruFilterDateRangeDef | PruFilterDropdownDef;
const initialState: ChartListParam = {
  chartName: 'Appointment to Lost Report',
  chartType: 'Table',
  campaignId: '',
  consultantCode: '',
  campaignStatus: '',
  campaignType: '',
  campaignRecordType: '',
  leadSubSource: '',
  startDate: [],
  campaignStartDt: [],
  assignDate: [],
  assignedDate: [],
  type: '',
  leadSource: '',
  region: '',
  channel: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): ChartListParam => {
  return initialParams
    ? {
        chartName: 'Appointment to Lost Report',
        //chartID: "62625264-8055-4590-8c78-8bf995f6bcd1",
        chartType: 'Table',
        campaignId: '',
        consultantCode: '',
        campaignStatus: '',
        campaignType: '',
        campaignRecordType: '',
        leadSubSource: '',
        startDate: [],
        campaignStartDt: [],
        assignDate: [],
        assignedDate: [],
        type: '',
        leadSource: '',
        region: '',
        channel: '',
      }
    : initialState;
};

export const dashboardFilterKeys = ['bundleIdentifier', 'name', 'role', 'isPublic'];

const DashboardListingPage: FC<DashboardListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<ChartListParam>(paramsInitiator(initialParams));
  const [filterList, setFilterList] = useState<PruFilterItemDef[]>([]);
  const [myDataList, setMyDataList] = useState<Record<string, string[]>>({
    leadSource: [],
    channel: [],
    region: [],
  });
  const [, setDashboardList] = useState<DashboardItem[]>();
  const jwt = useJWT() || {};
  const { region, channel } = jwt;

  const { isLoading, refreshData } = useDataProvider<DashboardItem[]>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchChartList(filterState, dispatch);
      } catch (err) {}
    },
    setDashboardList,
    false,
  );

  const [excelData, setExcelData] = useState<{ fields: object; documents: object[] }>();

  const exportExcel = async () => {
    let sheetHeader: any[] = [];
    let sheetData: any[] = [];

    if (excelData) {
      type fieldsType = keyof typeof excelData.fields;

      let fields = excelData.fields;
      let fieldsKey: string[] = [];

      for (let key in fields) {
        fieldsKey.push(key as fieldsType);
        sheetHeader.push(fields[key as fieldsType]);
      }

      //adjust the order of data : group -> group_series_(index) -> value
      excelData.documents.forEach((doc: any) => {
        let tmpDoc: Record<string, string> = {};
        fieldsKey.forEach((key) => {
          tmpDoc[key] = doc[key] ?? '';
        });
        sheetData.push(tmpDoc);
      });
      // over

      var option = {
        fileName: filterState.chartName,
        datas: [
          {
            sheetData: sheetData,
            sheetName: 'sheet',
            sheetHeader: sheetHeader,
          },
        ],
      };
      var toExcel = new ExportJsonExcel(option);

      toExcel.saveExcel();
    }
  };

  useEffect(() => {
    const filterMap = BDMdashboardFilter.get(filterState.chartName);
    let tmpList: PruFilterItemDef[] = [];
    for (const key in filterMap) {
      console.log('filterMap', key);
      if (myDataList[key]) {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: filterMap[key],
          choices: [
            { displayName: 'All', value: 'all' },
            ...map(myDataList[key], (option: string) => ({ displayName: option, value: option })),
          ],
        });
      } else if (filterMap[key] == 'Region') {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: filterMap[key],
          choices: [{ displayName: region, value: region }],
        });
      } else if (filterMap[key] == 'Channel') {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: filterMap[key],
          choices: [{ displayName: channel, value: channel }],
        });
      }
    }
    setFilterList(tmpList);
  }, [filterState.chartName]);

  return (
    <>
      <PruFilter
        title={Translation('dashboard.common.controlPanel')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'chartName',
            initialValue: filterState.chartName,
            defaultValue: initialState.chartName,
            displayName: Translation('dashboard.common.name'),
            choices: [...map(BDMdashboardName, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'chartType',
            initialValue: filterState.chartType,
            defaultValue: initialState.chartType,
            displayName: Translation('dashboard.common.type'),
            choices: [...map(DashboardType, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'type',
            displayName: BDMdashboardFilter.get(filterState.chartName).type,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              {
                displayName: 'Yes',
                value: 'Yes',
              },
              {
                displayName: 'No',
                value: 'No',
              },
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startDate',
            fieldTo: 'endDate',
            displayName: BDMdashboardFilter.get(filterState.chartName).assignedDate,
          },
          ...map(filterList, (option: PruFilterItemDef) => option),
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'region',
            initialValue: filterState.region,
            defaultValue: initialState.region,
            displayName: 'Region',
            choices: [{ displayName: region, value: region }],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'channel',
            initialValue: filterState.channel,
            defaultValue: initialState.channel,
            displayName: 'Channel',
            choices: [{ displayName: channel, value: channel }],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            chartName: data.chartName,
            chartType: data.chartType,
            type: data.type,
            assignedDate: [!!data.startDate ? data.startDate : null, !!data.endDate ? data.endDate : null],
            leadSource: data.leadSource,
            region: data.region,
            channel: data.channel,
          })
        }
        fetchData={refreshData}
        downloadExcel={exportExcel}
      />
      <DashboardDetailPage
        filter={filterState}
        onRefresh={true}
        refreshdata={refreshData}
        setExcelData={(data) => setExcelData(data)}
        getDataList={(list) => setMyDataList(list)}
      />
    </>
  );
};

export default DashboardListingPage;
