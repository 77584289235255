import React, { FC} from "react";
import { RouteComponentProps, Switch, Redirect } from "react-router-dom";
import { CONFIG_BASE_PATH, CONFIG_AGENT_SEGMENT_PATH } from "../../constants";
import PruRoute from "../../../../common/components/PruRoute";
import ConfigAgentSegmentDetailPage from "./View/ConfigAgentSegmentDetailPage";
import ConfigAgentSegmentListPage from "./List/ConfigAgentSegmentListingPage";
import { ModulePermissionProps } from "../../../../common/module/types";

const configAgentSegmentPath = `${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}`;

const ConfigAgentSegmentPage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return (
    <Switch>
      <Redirect exact from={configAgentSegmentPath}
        to={`${configAgentSegmentPath}/list`} />
      <PruRoute
        exact
        path={`${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}/list`}
        component={ConfigAgentSegmentListPage}
      />
      <PruRoute exact path={`${configAgentSegmentPath}/edit/:id`}
        component={ConfigAgentSegmentDetailPage} />
      <PruRoute exact path={`${configAgentSegmentPath}/create`}
        component={ConfigAgentSegmentDetailPage} />
    </Switch>
  )
};

export default ConfigAgentSegmentPage;