export const PULSELEADS_POINT_TITLE = 'pulseleads.activityPoint.title';
export const PULSELEADS_POINT_RULE_SETTINGS_TITLE = 'pulseleads.activityPoint.ruleSettings.title';
export const PULSELEADS_POINT_RULE_NAME_TITLE = 'pulseleads.activityPoint.ruleName.title';
export const PULSELEADS_POINT_RULE_LEAD_GOAL_TITLE = 'pulseleads.activityPoint.leadGoal.title';

export const PULSELEADS_POINT_NAMESPACE = 'PULSELEADS-ACTIVITY-POINT';
export const PULSELEADS_POINT_RULE_SETTINGS_NAMESPACE = 'PULSELEADS-POINT-RULE-SETTINGS';
export const PULSELEADS_POINT_RULE_NAME_NAMESPACE = 'PULSELEADS-POINT-RULE-NAME';

export const PULSELEADS_POINT_RULE_PATH = '/activity-point';
export const PULSELEADS_POINT_RULE_SETTINGS_PATH = '/rule-settings';
export const PULSELEADS_POINT_RULE_NAME_PATH = '/rule-name';
export const PULSELEADS_POINT_RULE_LEAD_GOAL_PATH = '/rule-lead-goal';

export enum MTmode {
  CREATE = 'add',
  EDIT = 'edit',
  COPY = 'copy',
  VIEW = 'view',
}

export const activeType: any = {
  Active: {
    value: 'true',
    text: 'Active',
  },
  Inactive: {
    value: 'false',
    text: 'Inactive',
  },
};

export const commonSelectType: any = {
  Yes: {
    value: 'true',
    text: 'Yes',
  },
  No: {
    value: 'false',
    text: 'No',
  },
};

export const dailyTargetType = {
  Day: {
    value: 'day',
    text: 'All days',
  },
  Weekday: {
    value: 'weekday',
    text: 'Weekday/Weekend',
  },
};

export interface AgentRuleMapCheckDto {
  agents: string[];
  ruleSetId?: string;
}

export interface I18nData<T> {
  en: T;
  [locale: string]: T;
}

export interface DailyTargetConfig {
  type: 'day' | 'weekday';
  weekday?: number;
  weekend?: number;
  day?: number;
}

export enum ActivityRuleTemplateType {
  CREATE_NEW_AGENT_SOURCED_LEAD = 'create_new_agent_sourced_lead',
  CREATE_NEW_AGENT_SOURCED_PROSPECT = 'create_new_agent_sourced_prospect',
  CREATE_NEW_AGENT_SOURCED_REFERRAL_PROSPECT = 'create_new_agent_sourced_referral_prospect',
  INITIAL_CONTACT_OF_LEAD_BEFORE_SLA = 'initial_contact_of_lead_before_sla',
  SETUP_APPOINTMENT_WITH_LEAD = 'setup_appointment_with_lead',
  PERFORM_ENGAGEMENT_AGAINST_PROSPECT = 'perform_engagement_against_prospect',
  SET_OUTCOME_OF_APPOINTMENT_WITH_LEAD = 'set_outcome_of_appointment_with_lead',
  CONFIRM_CONTACTED_LEAD = 'confirm_contacted_lead',
  CONFIRM_HAD_MEETING_WITH_LEAD = 'confirm_had_meeting_with_lead',
  CONFIRM_PROPOSAL_WAS_GIVEN_TO_LEAD = 'confirm_proposal_was_given_to_lead',
  CONFIRM_APPLICATION_SUBMITTED_ON_LEAD = 'confirm_application_submitted_on_lead',
  CONFIRM_POLICY_WAS_ISSUED_BY_FOR_LEAD = 'confirm_policy_was_issued_by_for_lead',
}

export interface ActivityRuleDataDto {
  type: ActivityRuleTemplateType;
  name?: I18nData<string>;
  marketSourcePoint: number;
  agentSourcePoint: number;
  marketDisabled: boolean;
  agentDisabled: boolean;
}

export interface ActivityRuleGroupDataDto {
  name: I18nData<string>;
  description: I18nData<string>;
  maxPoint: number;
  suggestedPoint: string;
  rules: ActivityRuleDataDto[];
  hasMaxPoint: string;
}

export interface ActivityRuleSetDetailDto {
  name: string;
  isActive: boolean;
  isDefault: boolean;
  dailyTarget: DailyTargetConfig;
  ruleGroupData: ActivityRuleGroupDataDto[];
  targetAgent: string[];
  overrideAgent?: string[];
  updatedBy?: string;
  remarks?: I18nData<string>;
}
