import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import RecruitLibraryRoutes from './pages/recruit/RecruitLibrary/RecruitLibraryRoutes';
import RecruitCategoryPage from './pages/recruit/RecruitCategory/RecruitCategoryPage';
import RecruitPruforceColumnPage from './pages/recruit/PruforceColumn/RecruitPruforceColumnPage';
import SalesLibraryRoutes from './pages/sales-resource/SalesLibrary/SalesLibraryRoutes';
import SalesCategoryPage from './pages/sales-resource/SalesCategory/SalesCategoryPage';
import SalesPruforceColumnPage from './pages/sales-resource/PruforceColumn/SalesPruforceColumnPage';
import PromotionLibraryRoutes from './pages/latest-promotion/PromotionLibrary/PromotionLibraryRoutes';
import PromotionCategoryPage from './pages/latest-promotion/PromotionCategory/PromotionCategoryPage';
import PromotionPruforceColumnPage from './pages/latest-promotion/PruforceColumn/PromotionPruforceColumnPage';
import CustomerSurveyManagementPage from './pages/customer-survey/CustomerSurveyManagementPage';
import {
  AGENTLEADFORM_NAMESPACE,
  AGENTLEADFORM_PATH,
  AGENTLEADFORM_TITLE,
  AI_TALKBOT_NAMESPACE,
  AI_TALKBOT_PATH,
  AI_TALKBOT_TITLE,
  AdminSaleskitPermissionType,
  COMPANYPICK_NAMESPACE,
  COMPANYPICK_PATH,
  COMPANYPICK_TITLE,
  CUSTOMER_SURVEY_NAMESPACE,
  CUSTOMER_SURVEY_TITLE,
  INSURANCE_SUMMARY_CONFIG,
  INSURANCE_SUMMARY_CONFIG_NAMESPACE,
  INSURANCE_SUMMARY_CONFIG_PATH,
  INSURANCE_SUMMARY_FEEDBACK,
  INSURANCE_SUMMARY_FEEDBACK_NAMESPACE,
  INSURANCE_SUMMARY_FEEDBACK_PATH,
  INSURANCE_SUMMARY_PATH,
  INSURANCE_SUMMARY_TITLE,
  PROMOTION_CATEGORY_BASE_PATH,
  PROMOTION_COLUMN_BASE_PATH,
  PROMOTION_LIBRARY_BASE_PATH,
  PROMOTION_MATERIAL_BASE_PATH,
  RECRUIT_BASE_PATH,
  RECRUIT_CATEGORY_BASE_PATH,
  RECRUIT_COLUMN_BASE_PATH,
  RECRUIT_LIBRARY_BASE_PATH,
  SALESKIT_BASE_PATH,
  SALESKIT_PROMOTION_CATEGORY_NAMESPACE,
  SALESKIT_PROMOTION_CATEGORY_TITLE,
  SALESKIT_PROMOTION_COLUMN_NAMESPACE,
  SALESKIT_PROMOTION_COLUMN_TITLE,
  SALESKIT_PROMOTION_LIBRARY_NAMESPACE,
  SALESKIT_PROMOTION_LIBRARY_TITLE,
  SALESKIT_PROMOTION_TITLE,
  SALESKIT_RECRUIT_CATEGORY_NAMESPACE,
  SALESKIT_RECRUIT_CATEGORY_TITLE,
  SALESKIT_RECRUIT_COLUMN_NAMESPACE,
  SALESKIT_RECRUIT_COLUMN_TITLE,
  SALESKIT_RECRUIT_LIBRARY_NAMESPACE,
  SALESKIT_RECRUIT_LIBRARY_TITLE,
  SALESKIT_RECRUIT_TITLE,
  SALESKIT_SALES_CATEGORY_NAMESPACE,
  SALESKIT_SALES_CATEGORY_TITLE,
  SALESKIT_SALES_COLUMN_NAMESPACE,
  SALESKIT_SALES_COLUMN_TITLE,
  SALESKIT_SALES_LIBRARY_NAMESPACE,
  SALESKIT_SALES_LIBRARY_TITLE,
  SALESKIT_SALES_TITLE,
  SALESKIT_TITLE,
  SALES_CATEGORY_BASE_PATH,
  SALES_COLUMN_BASE_PATH,
  SALES_LIBRARY_BASE_PATH,
  SALES_MATERIAL_BASE_PATH,
  SURVEY_CUSTOMER_PATH,
} from './constants';
import { isPermitted, getConfigurations } from 'src/app/common/utils';
import InsuranceSummaryConfigRoutes from './pages/insurance-summary/route/insurance-summary-config.route';
import InsuranceSummaryFeedbackRoutes from './pages/insurance-summary/route/insurance-summary-feedback.route';
import AgentLeadFormRoutes from './pages/agent-lead-form/agent-lead-form-routes';
import CompanyPickRoute from './pages/company-pick/company-pick.route';
import TalkbotDialogueRoutes from './pages/ai-talkbot/pages/dialogue/talkbot-dialogue-routes';

export const resourceModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(SALESKIT_TITLE),
    path: SALESKIT_BASE_PATH,
    icon: '/media/svg/icons/Text/Bullet-list.svg',
    enableRead: isPermitted(
      [
        AdminSaleskitPermissionType.SALESKIT_RECRUIT_LIBRARY_READ,
        AdminSaleskitPermissionType.SALESKIT_RECRUIT_LIBRARY_CREATE,
        AdminSaleskitPermissionType.SALESKIT_RECRUIT_LIBRARY_UPDATE,
        AdminSaleskitPermissionType.SALESKIT_RECRUIT_CATEGORY_READ,
        AdminSaleskitPermissionType.SALESKIT_RECRUIT_CATEGORY_CREATE,
        AdminSaleskitPermissionType.SALESKIT_RECRUIT_CATEGORY_UPDATE,
        AdminSaleskitPermissionType.SALESKIT_RECRUIT_COLUMN_READ,
        AdminSaleskitPermissionType.SALESKIT_RECRUIT_COLUMN_CREATE,
        AdminSaleskitPermissionType.SALESKIT_RECRUIT_COLUMN_UPDATE,
        AdminSaleskitPermissionType.SALESKIT_SALES_LIBRARY_READ,
        AdminSaleskitPermissionType.SALESKIT_SALES_LIBRARY_CREATE,
        AdminSaleskitPermissionType.SALESKIT_SALES_LIBRARY_UPDATE,
        AdminSaleskitPermissionType.SALESKIT_SALES_CATEGORY_READ,
        AdminSaleskitPermissionType.SALESKIT_SALES_CATEGORY_CREATE,
        AdminSaleskitPermissionType.SALESKIT_SALES_CATEGORY_UPDATE,
        AdminSaleskitPermissionType.SALESKIT_SALES_COLUMN_READ,
        AdminSaleskitPermissionType.SALESKIT_SALES_COLUMN_CREATE,
        AdminSaleskitPermissionType.SALESKIT_SALES_COLUMN_UPDATE,
        AdminSaleskitPermissionType.SALESKIT_PROMOTION_LIBRARY_READ,
        AdminSaleskitPermissionType.SALESKIT_PROMOTION_LIBRARY_CREATE,
        AdminSaleskitPermissionType.SALESKIT_PROMOTION_LIBRARY_UPDATE,
        AdminSaleskitPermissionType.SALESKIT_PROMOTION_CATEGORY_READ,
        AdminSaleskitPermissionType.SALESKIT_PROMOTION_CATEGORY_CREATE,
        AdminSaleskitPermissionType.SALESKIT_PROMOTION_CATEGORY_UPDATE,
        AdminSaleskitPermissionType.SALESKIT_PROMOTION_COLUMN_READ,
        AdminSaleskitPermissionType.SALESKIT_PROMOTION_COLUMN_CREATE,
        AdminSaleskitPermissionType.SALESKIT_PROMOTION_COLUMN_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(COMPANYPICK_TITLE),
        path: `${SALESKIT_BASE_PATH}${COMPANYPICK_PATH}`,
        namespace: COMPANYPICK_NAMESPACE,
        component: CompanyPickRoute,
        enableRead: isPermitted(
          [
            AdminSaleskitPermissionType.SALESKIT_AGENT_LEAD_FORM_READ,
            AdminSaleskitPermissionType.SALESKIT_AGENT_LEAD_FORM_CREATE,
            AdminSaleskitPermissionType.SALESKIT_AGENT_LEAD_FORM_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_AGENT_LEAD_FORM_CREATE], permissions),
        enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_AGENT_LEAD_FORM_UPDATE], permissions),
      },
      {
        title: Translation(SALESKIT_SALES_TITLE),
        path: `${SALESKIT_BASE_PATH}${SALES_MATERIAL_BASE_PATH}`,
        enableRead: isPermitted(
          [
            AdminSaleskitPermissionType.SALESKIT_SALES_LIBRARY_READ,
            AdminSaleskitPermissionType.SALESKIT_SALES_LIBRARY_CREATE,
            AdminSaleskitPermissionType.SALESKIT_SALES_LIBRARY_UPDATE,
            AdminSaleskitPermissionType.SALESKIT_SALES_CATEGORY_READ,
            AdminSaleskitPermissionType.SALESKIT_SALES_CATEGORY_CREATE,
            AdminSaleskitPermissionType.SALESKIT_SALES_CATEGORY_UPDATE,
            AdminSaleskitPermissionType.SALESKIT_SALES_COLUMN_READ,
            AdminSaleskitPermissionType.SALESKIT_SALES_COLUMN_CREATE,
            AdminSaleskitPermissionType.SALESKIT_SALES_COLUMN_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(SALESKIT_SALES_LIBRARY_TITLE),
            path: `${SALESKIT_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}`,
            namespace: SALESKIT_SALES_LIBRARY_NAMESPACE,
            component: SalesLibraryRoutes,
            enableRead: isPermitted(
              [
                AdminSaleskitPermissionType.SALESKIT_SALES_LIBRARY_READ,
                AdminSaleskitPermissionType.SALESKIT_SALES_LIBRARY_CREATE,
                AdminSaleskitPermissionType.SALESKIT_SALES_LIBRARY_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_SALES_LIBRARY_CREATE], permissions),
            enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_SALES_LIBRARY_UPDATE], permissions),
          },
          {
            title: Translation(SALESKIT_SALES_CATEGORY_TITLE),
            path: `${SALESKIT_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_CATEGORY_BASE_PATH}`,
            namespace: SALESKIT_SALES_CATEGORY_NAMESPACE,
            component: SalesCategoryPage,
            enableRead: isPermitted(
              [
                AdminSaleskitPermissionType.SALESKIT_SALES_CATEGORY_READ,
                AdminSaleskitPermissionType.SALESKIT_SALES_CATEGORY_CREATE,
                AdminSaleskitPermissionType.SALESKIT_SALES_CATEGORY_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_SALES_CATEGORY_CREATE], permissions),
            enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_SALES_CATEGORY_UPDATE], permissions),
          },
          {
            title: Translation(SALESKIT_SALES_COLUMN_TITLE),
            path: `${SALESKIT_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_COLUMN_BASE_PATH}`,
            namespace: SALESKIT_SALES_COLUMN_NAMESPACE,
            component: SalesPruforceColumnPage,
            enableRead: isPermitted(
              [
                AdminSaleskitPermissionType.SALESKIT_SALES_COLUMN_READ,
                AdminSaleskitPermissionType.SALESKIT_SALES_COLUMN_CREATE,
                AdminSaleskitPermissionType.SALESKIT_SALES_COLUMN_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_SALES_COLUMN_CREATE], permissions),
            enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_SALES_COLUMN_UPDATE], permissions),
          },
        ],
      },
      {
        title: Translation(SALESKIT_PROMOTION_TITLE),
        path: `${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}`,
        enableRead: isPermitted(
          [
            AdminSaleskitPermissionType.SALESKIT_PROMOTION_LIBRARY_READ,
            AdminSaleskitPermissionType.SALESKIT_PROMOTION_LIBRARY_CREATE,
            AdminSaleskitPermissionType.SALESKIT_PROMOTION_LIBRARY_UPDATE,
            AdminSaleskitPermissionType.SALESKIT_PROMOTION_CATEGORY_READ,
            AdminSaleskitPermissionType.SALESKIT_PROMOTION_CATEGORY_CREATE,
            AdminSaleskitPermissionType.SALESKIT_PROMOTION_CATEGORY_UPDATE,
            AdminSaleskitPermissionType.SALESKIT_PROMOTION_COLUMN_READ,
            AdminSaleskitPermissionType.SALESKIT_PROMOTION_COLUMN_CREATE,
            AdminSaleskitPermissionType.SALESKIT_PROMOTION_COLUMN_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(SALESKIT_PROMOTION_LIBRARY_TITLE),
            path: `${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_LIBRARY_BASE_PATH}`,
            namespace: SALESKIT_PROMOTION_LIBRARY_NAMESPACE,
            component: PromotionLibraryRoutes,
            enableRead: isPermitted(
              [
                AdminSaleskitPermissionType.SALESKIT_PROMOTION_LIBRARY_READ,
                AdminSaleskitPermissionType.SALESKIT_PROMOTION_LIBRARY_CREATE,
                AdminSaleskitPermissionType.SALESKIT_PROMOTION_LIBRARY_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_PROMOTION_LIBRARY_CREATE], permissions),
            enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_PROMOTION_LIBRARY_UPDATE], permissions),
          },
          {
            title: Translation(SALESKIT_PROMOTION_CATEGORY_TITLE),
            path: `${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_CATEGORY_BASE_PATH}`,
            namespace: SALESKIT_PROMOTION_CATEGORY_NAMESPACE,
            component: PromotionCategoryPage,
            enableRead: isPermitted(
              [
                AdminSaleskitPermissionType.SALESKIT_PROMOTION_CATEGORY_READ,
                AdminSaleskitPermissionType.SALESKIT_PROMOTION_CATEGORY_CREATE,
                AdminSaleskitPermissionType.SALESKIT_PROMOTION_CATEGORY_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_PROMOTION_CATEGORY_CREATE], permissions),
            enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_PROMOTION_CATEGORY_UPDATE], permissions),
          },
          {
            title: Translation(SALESKIT_PROMOTION_COLUMN_TITLE),
            path: `${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_COLUMN_BASE_PATH}`,
            namespace: SALESKIT_PROMOTION_COLUMN_NAMESPACE,
            component: PromotionPruforceColumnPage,
            enableRead: isPermitted(
              [
                AdminSaleskitPermissionType.SALESKIT_PROMOTION_COLUMN_READ,
                AdminSaleskitPermissionType.SALESKIT_PROMOTION_COLUMN_CREATE,
                AdminSaleskitPermissionType.SALESKIT_PROMOTION_COLUMN_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_PROMOTION_COLUMN_CREATE], permissions),
            enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_PROMOTION_COLUMN_UPDATE], permissions),
          },
        ],
      },
      {
        title: Translation(AI_TALKBOT_TITLE),
        path: `${SALESKIT_BASE_PATH}${AI_TALKBOT_PATH}`,
        namespace: AI_TALKBOT_NAMESPACE,
        component: TalkbotDialogueRoutes,
        enableRead: isPermitted(
          [
            AdminSaleskitPermissionType.SALESKIT_TALKBOT_READ,
            AdminSaleskitPermissionType.SALESKIT_TALKBOT_CREATE,
            AdminSaleskitPermissionType.SALESKIT_TALKBOT_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_TALKBOT_CREATE], permissions),
        enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_TALKBOT_UPDATE], permissions),
      },
      {
        title: Translation(CUSTOMER_SURVEY_TITLE),
        path: `${SALESKIT_BASE_PATH}${SURVEY_CUSTOMER_PATH}`,
        namespace: CUSTOMER_SURVEY_NAMESPACE,
        component: CustomerSurveyManagementPage,
        enableRead: isPermitted(
          [
            AdminSaleskitPermissionType.SALESKIT_CUSTOMER_SURVEY_READ,
            AdminSaleskitPermissionType.SALESKIT_CUSTOMER_SURVEY_CREATE,
            AdminSaleskitPermissionType.SALESKIT_CUSTOMER_SURVEY_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_CUSTOMER_SURVEY_CREATE], permissions),
        enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_CUSTOMER_SURVEY_UPDATE], permissions),
      },
      {
        title: Translation(INSURANCE_SUMMARY_TITLE),
        path: `${SALESKIT_BASE_PATH}${INSURANCE_SUMMARY_PATH}`,
        enableRead: isPermitted(
          [
            AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_CONFIG_CREATE,
            AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_CONFIG_READ,
            AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_CONFIG_UPDATE,
            AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_FEEDBACK_CREATE,
            AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_FEEDBACK_READ,
            AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_FEEDBACK_UPDATE,
          ],
          permissions,
        ),
        enableModule: getConfigurations()?.InsuranceSummary?.enableAdminportal,
        child: [
          {
            title: Translation(INSURANCE_SUMMARY_CONFIG),
            path: `${SALESKIT_BASE_PATH}${INSURANCE_SUMMARY_PATH}${INSURANCE_SUMMARY_CONFIG_PATH}`,
            namespace: INSURANCE_SUMMARY_CONFIG_NAMESPACE,
            component: InsuranceSummaryConfigRoutes,
            enableRead: isPermitted(
              [
                AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_CONFIG_CREATE,
                AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_CONFIG_READ,
                AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_CONFIG_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_CONFIG_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_CONFIG_UPDATE],
              permissions,
            ),
          },
          {
            title: Translation(INSURANCE_SUMMARY_FEEDBACK),
            path: `${SALESKIT_BASE_PATH}${INSURANCE_SUMMARY_PATH}${INSURANCE_SUMMARY_FEEDBACK_PATH}`,
            namespace: INSURANCE_SUMMARY_FEEDBACK_NAMESPACE,
            component: InsuranceSummaryFeedbackRoutes,
            enableRead: isPermitted(
              [
                AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_FEEDBACK_CREATE,
                AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_FEEDBACK_READ,
                AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_FEEDBACK_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_FEEDBACK_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminSaleskitPermissionType.SALESKIT_INSURANCE_SUMMARY_FEEDBACK_UPDATE],
              permissions,
            ),
          },
        ],
      },
      {
        title: Translation(AGENTLEADFORM_TITLE),
        path: `${SALESKIT_BASE_PATH}${AGENTLEADFORM_PATH}`,
        namespace: AGENTLEADFORM_NAMESPACE,
        component: AgentLeadFormRoutes,
        enableRead: isPermitted(
          [
            AdminSaleskitPermissionType.SALESKIT_AGENT_LEAD_FORM_READ,
            AdminSaleskitPermissionType.SALESKIT_AGENT_LEAD_FORM_CREATE,
            AdminSaleskitPermissionType.SALESKIT_AGENT_LEAD_FORM_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_AGENT_LEAD_FORM_CREATE], permissions),
        enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_AGENT_LEAD_FORM_UPDATE], permissions),
      },

      {
        title: Translation(SALESKIT_RECRUIT_TITLE),
        path: `${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}`,
        enableRead: isPermitted(
          [
            AdminSaleskitPermissionType.SALESKIT_RECRUIT_LIBRARY_READ,
            AdminSaleskitPermissionType.SALESKIT_RECRUIT_LIBRARY_CREATE,
            AdminSaleskitPermissionType.SALESKIT_RECRUIT_LIBRARY_UPDATE,
            AdminSaleskitPermissionType.SALESKIT_RECRUIT_CATEGORY_READ,
            AdminSaleskitPermissionType.SALESKIT_RECRUIT_CATEGORY_CREATE,
            AdminSaleskitPermissionType.SALESKIT_RECRUIT_CATEGORY_UPDATE,
            AdminSaleskitPermissionType.SALESKIT_RECRUIT_COLUMN_READ,
            AdminSaleskitPermissionType.SALESKIT_RECRUIT_COLUMN_CREATE,
            AdminSaleskitPermissionType.SALESKIT_RECRUIT_COLUMN_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(SALESKIT_RECRUIT_LIBRARY_TITLE),
            path: `${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}`,
            namespace: SALESKIT_RECRUIT_LIBRARY_NAMESPACE,
            component: RecruitLibraryRoutes,
            enableRead: isPermitted(
              [
                AdminSaleskitPermissionType.SALESKIT_RECRUIT_LIBRARY_READ,
                AdminSaleskitPermissionType.SALESKIT_RECRUIT_LIBRARY_CREATE,
                AdminSaleskitPermissionType.SALESKIT_RECRUIT_LIBRARY_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_RECRUIT_LIBRARY_CREATE], permissions),
            enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_RECRUIT_LIBRARY_UPDATE], permissions),
          },
          {
            title: Translation(SALESKIT_RECRUIT_CATEGORY_TITLE),
            path: `${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_CATEGORY_BASE_PATH}`,
            namespace: SALESKIT_RECRUIT_CATEGORY_NAMESPACE,
            component: RecruitCategoryPage,
            enableRead: isPermitted(
              [
                AdminSaleskitPermissionType.SALESKIT_RECRUIT_CATEGORY_READ,
                AdminSaleskitPermissionType.SALESKIT_RECRUIT_CATEGORY_CREATE,
                AdminSaleskitPermissionType.SALESKIT_RECRUIT_CATEGORY_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_RECRUIT_CATEGORY_CREATE], permissions),
            enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_RECRUIT_CATEGORY_UPDATE], permissions),
          },
          {
            title: Translation(SALESKIT_RECRUIT_COLUMN_TITLE),
            path: `${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_COLUMN_BASE_PATH}`,
            namespace: SALESKIT_RECRUIT_COLUMN_NAMESPACE,
            component: RecruitPruforceColumnPage,
            enableRead: isPermitted(
              [
                AdminSaleskitPermissionType.SALESKIT_RECRUIT_COLUMN_READ,
                AdminSaleskitPermissionType.SALESKIT_RECRUIT_COLUMN_CREATE,
                AdminSaleskitPermissionType.SALESKIT_RECRUIT_COLUMN_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminSaleskitPermissionType.SALESKIT_RECRUIT_COLUMN_CREATE], permissions),
            enableUpdate: isPermitted([AdminSaleskitPermissionType.SALESKIT_RECRUIT_COLUMN_UPDATE], permissions),
          },
        ],
      },
    ],
  };
};
