import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PaginateList } from 'src/app/common/types';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { KpiItem } from '../../../types/kpi-types';
import { KpiListParam, fetchKpiList } from '../../../network/kpiCrud';
import KpiList from './components/KpiList';

type KpiListingPageProps = ParamsProps;

const initialState: KpiListParam = {
  name: '',
  page: 1,
  limit: 5,
};

export const kpiFilterKeys = ['name'];

const paramsInitiator = (initialParams?: Record<string, string>): KpiListParam => {
  return initialParams
    ? {
        name: initialParams.name || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const KpiListingPage: FC<KpiListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<KpiListParam>(paramsInitiator(initialParams));
  const [kpiList, setKpiList] = useState<PaginateList<KpiItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<KpiItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchKpiList(filterState, dispatch);
      } catch (err) {}
    },
    setKpiList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('incentive.kpi.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('incentive.kpi.common.name'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
          })
        }
        fetchData={refreshData}
      />
      <KpiList
        isLoading={isLoading}
        onRefresh={refreshData}
        kpiList={kpiList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default KpiListingPage;
