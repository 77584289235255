import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { I18nGenericData } from 'src/app/i18n';
import { PaginateList } from 'src/app/common/types';
import { getQueryPath } from 'src/app/common/utils';
import { KpiTypeEnum, KpiItem } from '../types/kpi-types';

const kpiEndpoint = 'incentive-criteria-categories';

export type KpiListParam = {
  name?: string;
  page?: number;
  limit?: number;
  pagination?: boolean;
};

export type CreateUpdateKpiBody = {
  name: I18nGenericData<string>;
  key: string;
  type: KpiTypeEnum;
  values: string;
};

export const fetchKpiList = async (params: KpiListParam, dispatch?: Dispatch<any>): Promise<PaginateList<KpiItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${kpiEndpoint}?`, params);
  return apiClient
    .get<PaginateList<KpiItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchKpiItem = async (id: string, dispatch?: Dispatch<any>): Promise<KpiItem> => {
  return apiClient
    .get<KpiItem>(`${ADMIN_URL}/${kpiEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createKpiItem = async (body: CreateUpdateKpiBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${kpiEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyKpiItem = async (id: string, body: CreateUpdateKpiBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${kpiEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteKpiItem = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${kpiEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
