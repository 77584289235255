import React, { memo } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel, Grid, Radio } from '@mui/material';
import { InputValue, RemarkInput, RemarkInputProps } from '../remark-input/remark-input.component';
import { REMARK_OTHERS } from '../../../util/constant';

export type AssigneeSelectorValue = {
  checked?: boolean;
  remark?: InputValue;
};

export type AssigneeSelectorProps = {
  label?: string;
  value?: AssigneeSelectorValue;
  onChange?: (val?: AssigneeSelectorValue) => void;
  options?: RemarkInputProps['options'];
  isMultipleSelect?: boolean;
};

type CheckItemProps = CheckboxProps & { isMultipleSelect?: boolean };

const CheckItem = ({ isMultipleSelect, ...restProps }: CheckItemProps) =>
  isMultipleSelect ? <Checkbox {...restProps} /> : <Radio {...restProps} />;

export const AssigneeSelector: React.FC<AssigneeSelectorProps> = memo(
  ({ label, value, onChange, options, isMultipleSelect = true }: AssigneeSelectorProps) => {
    return (
      <Grid container item spacing={1}>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <CheckItem
                isMultipleSelect={isMultipleSelect}
                checked={value?.checked ?? false}
                onChange={(e, checked) =>
                  onChange?.({ checked, remark: checked ? { key: options?.[0]?.value ?? REMARK_OTHERS } : undefined })
                }
              />
            }
            label={label}
          />
        </Grid>
        <Grid item xs={9}>
          <RemarkInput
            value={value?.remark}
            onChange={(r) => onChange?.({ checked: true, remark: r })}
            options={options}
          />
        </Grid>
      </Grid>
    );
  },
);
