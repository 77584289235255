import { FC } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
  Tabs,
  Tab,
} from '@mui/material';
import { useLang } from 'src/app/i18n';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form';
import {
  EventHostEnum,
  ParticipantRoleEnum,
  ParticipantContactEnum,
  AttendanceStatusEnum,
  RegistrationTypeEnum,
  EventFormCommonProps,
  ParticipantItem,
} from 'src/app/modules/event-v2/types';
import PruFilter, { PruFilterItemType, PruFilterItemDef } from 'src/app/common/components/PruTable/PruFilter';
import PruTable, { PruTableColumnDef } from 'src/app/common/components/PruTable/PruTable';
import { useCommonFormStyles, sessionKeyToDisplayName } from '../../common';
import { useParticipantListStyles } from './participant-list.style';
import { useParticipantList } from './participant-list.hook';
import { ParticipantDetail } from './components';

type ParticipantListProps = ComponentProps<EventFormCommonProps>;

export const ParticipantList: FC<ParticipantListProps> = ({ formCommonProps, ...rest }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();
  const { classes } = useParticipantListStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { eventItem, walkInAllowed } = formCommonProps;
  const {
    eventId,
    title,
    participantOverview,
    selectedSession,
    filterState,
    participantList,
    isExporting,
    isExportingFormResult,
    attendancePageState,
    removeDialogState,
    isLoading,
    setSelectedSession,
    setFilterState,
    setAttendancePageState,
    setRemoveDialogState,
    refreshParticipantList,
    onExport,
    onExportFormResult,
    onRemove,
    toParticipantDetail,
  } = useParticipantList({ formCommonProps, ...rest });
  const disableAction =
    !eventId ||
    (eventItem?.eventDate ? moment().isAfter(eventItem.eventDate, 'day') : false) ||
    eventItem?.host === EventHostEnum.AGENCY;
  const walkInTab = filterState.registrationType === RegistrationTypeEnum.WALKIN;
  const showWalkInListReminder = walkInTab && eventItem?.multiSession === true && !selectedSession;

  return (
    <>
      <Dialog open={removeDialogState.open} classes={{ paper: classes.reminderDialogPaper }}>
        <DialogTitle>{Translation('reminder_title')}</DialogTitle>
        <DialogContent>{Translation('event.form.remove_participant_reminder')}</DialogContent>
        <DialogActions>
          <Button className={classes.blueText} onClick={() => setRemoveDialogState({ open: false })}>
            {Translation('app.button.cancel')}
          </Button>
          <Button className={classes.blueText} onClick={() => onRemove()}>
            {Translation('app.button.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.container}>
        <div className={classes.title}>{title}</div>
        <div className={classes.content}>
          {attendancePageState.open && attendancePageState.participantDetail ? (
            <ParticipantDetail
              multiSession={!!eventItem?.multiSession}
              participantDetail={attendancePageState.participantDetail}
              onBack={() => setAttendancePageState({ open: false })}
            />
          ) : (
            <>
              {eventItem?.multiSession === true &&
                participantOverview?.sessions &&
                participantOverview.sessions.length > 0 && (
                  <div className={`${classes.sessionSelectContainer} ${classes.sectionSpacing}`}>
                    <div>{Translation('event.form.session')}</div>
                    <FormControl className={classes.sessionSelect}>
                      <Select
                        displayEmpty
                        value={selectedSession?.key || ''}
                        renderValue={(selected) =>
                          selected ? (
                            sessionKeyToDisplayName(selected)
                          ) : (
                            <span className={commonFormClasses.placeholder}>
                              {Translation('app.input.placeholder.none_selection')}
                            </span>
                          )
                        }
                        onChange={(e) => {
                          if (e.target.value) {
                            const newSelectedSession = participantOverview.sessions.find(
                              (session) => session.key === e.target.value,
                            );
                            setSelectedSession(newSelectedSession);
                            setFilterState({
                              ...filterState,
                              sessionId: newSelectedSession?._id,
                            });
                          } else {
                            setSelectedSession(undefined);
                            setFilterState({
                              ...filterState,
                              sessionId: undefined,
                            });
                          }
                          refreshParticipantList();
                        }}
                      >
                        <MenuItem key={'none-selection'} value={undefined}>
                          {Translation('app.input.placeholder.none_selection')}
                        </MenuItem>
                        {participantOverview?.sessions.map((session) => (
                          <MenuItem key={session.key} value={session.key}>
                            {sessionKeyToDisplayName(session.key)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div>{Translation('event.form.select_session_reminder')}</div>
                  </div>
                )}
              <div className={`${classes.sectionContainer} ${classes.sectionSpacing}`}>
                <div className={classes.headerContainer}>
                  <div className={classes.header}>{Translation('event.form.overview')}</div>
                </div>
                {eventItem?.multiSession === true && selectedSession && (
                  <div className={classes.overviewSessionTitle}>
                    {`${sessionKeyToDisplayName(selectedSession.key)} - ${selectedSession.name[locale]}`}
                  </div>
                )}
                <div className={classes.overviewContainer}>
                  <div className={classes.overviewDataContainer}>
                    <div className={classes.overviewData}>
                      {selectedSession?.quota || participantOverview?.quota || '-'}
                    </div>
                    <div className={classes.overviewLabel}>{Translation('event.form.quota')}</div>
                  </div>
                  <div className={classes.overviewDataContainer}>
                    <div className={classes.overviewData}>
                      {selectedSession?.registered || participantOverview?.registered || '-'}
                    </div>
                    <div className={classes.overviewLabel}>{Translation('event.form.total_registered')}</div>
                  </div>
                  <div className={classes.overviewDataContainer}>
                    <div className={classes.overviewData}>
                      {selectedSession?.attended || participantOverview?.attended || '-'}
                    </div>
                    <div className={classes.overviewLabel}>{Translation('event.form.attended')}</div>
                  </div>
                  <div className={classes.overviewDataContainer}>
                    <div className={classes.overviewData}>
                      {selectedSession?.attendanceRate || participantOverview?.attendanceRate || '-'}
                    </div>
                    <div className={classes.overviewLabel}>{Translation('event.form.attendance_rate')}</div>
                  </div>
                  {walkInAllowed && (
                    <div className={classes.overviewDataContainer}>
                      <div className={classes.overviewData}>
                        {selectedSession?.walkin || participantOverview?.walkin || '-'}
                      </div>
                      <div className={classes.overviewLabel}>{Translation('event.form.walk_ins')}</div>
                    </div>
                  )}
                </div>
              </div>
              {walkInAllowed && (
                <Tabs
                  TabIndicatorProps={{ style: { backgroundColor: '#E8192C' } }}
                  textColor="inherit"
                  value={filterState.registrationType}
                  onChange={(_, value: RegistrationTypeEnum) => {
                    setFilterState({ ...filterState, registrationType: value });
                    refreshParticipantList();
                  }}
                >
                  <Tab
                    sx={{ fontSize: 16, fontFamily: 'system-ui', textTransform: 'none', margin: '0 60px' }}
                    label={Translation('event.form.with_rsvp')}
                    value={RegistrationTypeEnum.RSVP}
                  />
                  <Tab
                    sx={{ fontSize: 16, fontFamily: 'system-ui', textTransform: 'none', margin: '0 60px' }}
                    label={Translation('event.form.walk_ins')}
                    value={RegistrationTypeEnum.WALKIN}
                  />
                </Tabs>
              )}
              <div className={`${classes.sectionContainer} ${classes.sectionSpacing}`}>
                <PruFilter
                  updateFilterOnChange
                  style={{ padding: 0 }}
                  title={
                    walkInTab
                      ? Translation('event.form.walk_ins_filter')
                      : Translation('event.form.participant_rsvp_filter')
                  }
                  titleClassName={classes.header}
                  filterClassName={classes.filter}
                  itemDef={[
                    {
                      type: PruFilterItemType.FREE_TEXT,
                      field: 'firstName',
                      initialValue: filterState.firstName,
                      displayName: Translation('event.form.first_name'),
                    },
                    {
                      type: PruFilterItemType.FREE_TEXT,
                      field: 'lastName',
                      initialValue: filterState.lastName,
                      displayName: Translation('event.form.last_name'),
                    },
                    {
                      type: PruFilterItemType.DROPDOWN,
                      field: 'role',
                      initialValue: filterState.role,
                      displayName: Translation('event.form.role'),
                      choices: [
                        { displayName: Translation('component.status.all'), value: '' },
                        ...map(ParticipantRoleEnum, (option) => ({
                          displayName: Translation(`event.form.role.${option.toLowerCase()}`),
                          value: option,
                        })),
                      ],
                    },
                    {
                      type: PruFilterItemType.FREE_TEXT,
                      field: 'referer',
                      initialValue: filterState.referer,
                      displayName: Translation('event.form.referred_agent_code'),
                    },
                    {
                      type: PruFilterItemType.MULTIPLE_DROPDOWN,
                      style: { minWidth: 200 },
                      field: 'contact',
                      initialValue: filterState.contact,
                      displayName: Translation('event.form.contact'),
                      choices: [
                        ...map(ParticipantContactEnum, (option) => ({
                          displayName: Translation(`event.form.contact.${option.toLowerCase()}`),
                          value: option,
                        })),
                      ],
                    },
                    ...(() => {
                      const filterItemDef: PruFilterItemDef[] = [];
                      if (!walkInTab) {
                        filterItemDef.push(
                          {
                            type: PruFilterItemType.DATE_TIME_RANGE,
                            fieldFrom: 'regStartDate',
                            fieldTo: 'regEndDate',
                            initialDateFrom: filterState.regStartDate,
                            initialDateTo: filterState.regEndDate,
                            displayName: Translation('event.form.registration_time'),
                          },
                          {
                            type: PruFilterItemType.FREE_TEXT,
                            field: 'qrCode',
                            initialValue: filterState.qrCode,
                            displayName: Translation('event.form.participation_qr_code'),
                          },
                        );
                        if (
                          eventItem?.multiSession === false ||
                          (eventItem?.multiSession === true && selectedSession)
                        ) {
                          filterItemDef.push({
                            type: PruFilterItemType.DROPDOWN,
                            field: 'requestAttendanceStatus',
                            initialValue: filterState.requestAttendanceStatus,
                            displayName: Translation('event.form.attendance_status'),
                            choices: [
                              { displayName: Translation('component.status.all'), value: '' },
                              ...map(AttendanceStatusEnum, (option) => ({
                                displayName: Translation(`event.form.attendance_status.${option.toLowerCase()}`),
                                value: option,
                              })),
                            ],
                          });
                        }
                      }
                      return filterItemDef;
                    })(),
                  ]}
                  onChangeFilter={(data) =>
                    setFilterState({
                      ...filterState,
                      firstName: data.firstName,
                      lastName: data.lastName,
                      role: data.role,
                      referer: data.referer,
                      contact: data.contact,
                      regStartDate: data.regStartDate,
                      regEndDate: data.regEndDate,
                      qrCode: data.qrCode,
                      requestAttendanceStatus: data.requestAttendanceStatus,
                    })
                  }
                  fetchData={refreshParticipantList}
                />
              </div>
              <div className={classes.sectionContainer}>
                <div className={classes.headerContainer}>
                  <div className={classes.header}>
                    {walkInTab
                      ? Translation('event.form.walk_ins_list')
                      : Translation('event.form.participant_rsvp_list')}
                  </div>
                  <div className={classes.exportButtonContainer}>
                    {!walkInTab && (
                      <div>
                        <Button
                          disabled={isExportingFormResult || !participantList || participantList.totalDocs === 0}
                          variant="contained"
                          color="secondary"
                          onClick={() => onExportFormResult()}
                        >
                          {Translation('event.form.registration_form_result')}
                        </Button>
                      </div>
                    )}
                    <div>
                      <Button
                        disabled={isExporting || !participantList || participantList.totalDocs === 0}
                        variant="contained"
                        color="secondary"
                        onClick={() => onExport()}
                      >
                        {Translation('export.list.text')}
                      </Button>
                    </div>
                  </div>
                </div>
                <PruTable
                  disableToolbar
                  disableBulkSelect
                  headerBtnDef={[]}
                  operationDef={[
                    {
                      title: Translation('event.form.attendance'),
                      tooltipText: 'Attendance',
                      onClick: (row) => toParticipantDetail(row.registrationId),
                    },
                    {
                      title: Translation('app.button.remove'),
                      tooltipText: 'Remove',
                      onClick: (row) => setRemoveDialogState({ open: true, id: row.registrationId }),
                      condition: () => !walkInTab && !disableAction,
                    },
                  ]}
                  columnDef={[
                    {
                      isId: true,
                      hidden: true,
                      keyIndex: 'registrationId',
                      displayName: '',
                      renderData: () => '',
                    },
                    {
                      keyIndex: 'firstName',
                      displayName: Translation('event.form.first_name'),
                      renderData: (row) => row.firstName || '-',
                    },
                    {
                      keyIndex: 'lastName',
                      displayName: Translation('event.form.last_name'),
                      renderData: (row) => row.lastName || '-',
                    },
                    {
                      keyIndex: 'role',
                      displayName: Translation('event.form.role'),
                      renderData: (row) =>
                        row.role
                          ? row.role === ParticipantRoleEnum.AGENT
                            ? `${Translation(`event.form.role.${row.role.toLowerCase()}`)} (${row.agentCode || '-'})`
                            : Translation(`event.form.role.${row.role.toLowerCase()}`)
                          : '-',
                    },
                    {
                      keyIndex: 'referer',
                      displayName: Translation('event.form.referred_agent'),
                      renderData: (row) => row.referer || '-',
                    },
                    {
                      keyIndex: 'contact',
                      displayName: Translation('event.form.contact'),
                      renderData: (row) =>
                        row.contact ? Translation(`event.form.contact.${row.contact.toLowerCase()}`) : '-',
                    },
                    ...(() => {
                      const columnDef: PruTableColumnDef<ParticipantItem>[] = [];
                      if (walkInTab) {
                        columnDef.push(
                          {
                            keyIndex: 'registrationTime',
                            displayName: Translation('event.form.form_submission_time'),
                            renderData: (row) => getDefaultDisplayDate(row.registrationTime, 'datetime'),
                          },
                          {
                            keyIndex: 'qrCode',
                            displayName: Translation('event.form.participation_qr_code'),
                            renderData: (row) => row.qrCode || '-',
                          },
                        );
                      } else {
                        columnDef.push(
                          {
                            keyIndex: 'registrationTime',
                            displayName: Translation('event.form.registration_time'),
                            renderData: (row) => getDefaultDisplayDate(row.registrationTime, 'datetime'),
                          },
                          {
                            keyIndex: 'qrCode',
                            displayName: Translation('event.form.participation_qr_code'),
                            renderData: (row) => row.qrCode || '-',
                          },
                          {
                            keyIndex: 'createdFrom',
                            displayName: Translation('event.form.created_via'),
                            renderData: (row) =>
                              row.createdFrom
                                ? Translation(`event.form.created_via.${row.createdFrom.toLowerCase()}`)
                                : '-',
                          },
                          {
                            keyIndex: 'createdBy',
                            displayName: Translation('event.common.created_by'),
                            renderData: (row) => row.createdBy || '-',
                          },
                          {
                            keyIndex: 'lastUpdatedBy',
                            displayName: Translation('event.common.last_updated_by'),
                            renderData: (row) => row.lastUpdatedBy || '-',
                          },
                          {
                            keyIndex: 'updatedAt',
                            displayName: Translation('component.formLabel.last-updated-time'),
                            renderData: (row) => getDefaultDisplayDate(row.updatedAt, 'datetime'),
                          },
                          {
                            hidden: eventItem?.multiSession === true && !selectedSession,
                            keyIndex: 'attendanceStatus',
                            displayName: Translation('event.form.attendance_status'),
                            renderData: (row) =>
                              row.attendanceStatus
                                ? Translation(`event.form.attendance_status.${row.attendanceStatus.toLowerCase()}`)
                                : '-',
                          },
                        );
                      }
                      return columnDef;
                    })(),
                  ]}
                  isLoading={isLoading}
                  onRefresh={refreshParticipantList}
                  dataSource={participantList?.docs}
                  defaultRowsPerPage={20}
                  totalPages={participantList?.totalPages}
                  totalRecords={participantList?.totalDocs}
                  noRecordMsg={
                    showWalkInListReminder ? Translation('event.form.walk_in_select_session_reminder') : undefined
                  }
                  onChangePage={(page, rowsPerPage) => {
                    setFilterState({
                      ...filterState,
                      page,
                      limit: rowsPerPage,
                    });
                    refreshParticipantList();
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
