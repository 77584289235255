import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ColumnPage from '../../../components/Column/pages/List';
import { ModulePermissionProps } from '../../../../../common/module/types';

const SystemConfig =  {
  filterName: 'component.filterTitle.sales-material-column',
  blockName: 'component.formTitle.sales-material-column',
  blockType: 'salesMaterialColumn',
  moduleType: 'Sales',
  addSuccessText: 'global.submit.success'
}

const SalesPruforceColumnPage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return (
    <ColumnPage { ...props } { ...SystemConfig }  />
  )
};

export default SalesPruforceColumnPage;
