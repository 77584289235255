export const SYSTEM_ADMIN_BASE_PATH = '/system-admin';
export const SCHEDULE_JOB_BASE_PATH = '/schedule-job';
export const SCHEDULE_JOB_HISTORY_PATH = '/history';

//  These constants map to aside menu, header menu to display title
export const SYSTEM_ADMIN_TITLE = 'title.system-admin';
export const SCHEDULE_JOB_TITLE = 'title.schedule-job';
export const SCHEDULE_JOB_HISTORY_TITLE = 'title.schedule-job.history';

//  These constants map to permission control, identical to permission enum defined below
export const SCHEDULE_JOB_NAMESPACE = 'SCHEDULE-JOB';
export const SCHEDULE_JOB_HISTORY_NAMESPACE = 'SCHEDULE-JOB-HISTORY';

export enum AdminScheduleJobPermissionType {
  SCHEDULE_JOB_READ = 'READ_ANY_SCHEDULE-JOB',
  SCHEDULE_JOB_CREATE = 'CREATE_ANY_SCHEDULE-JOB',
  SCHEDULE_JOB_UPDATE = 'UPDATE_ANY_SCHEDULE-JOB',
  SCHEDULE_JOB_HISTORY_READ = 'READ_ANY_SCHEDULE-JOB-HISTORY',
  SCHEDULE_JOB_HISTORY_CREATE = 'CREATE_ANY_SCHEDULE-JOB-HISTORY',
  SCHEDULE_JOB_HISTORY_UPDATE = 'UPDATE_ANY_SCHEDULE-JOB-HISTORY',
}
