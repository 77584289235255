import { IncentiveHighlight } from '../../../../types/incentive-types';
import { KpiItem } from '../../../../types/kpi-types';

export type HighlightFormState = {
  highlights: IncentiveHighlight[];
  selectedRankKpiItem?: KpiItem;
};

type AddHighlightRowAction = {
  type: 'ADD_HIGHLIGHT_ROW';
};

type MoveHighlightRowAction = {
  type: 'MOVE_HIGHLIGHT_ROW';
  payload: {
    from: number;
    to: number;
  };
};

type ModifyHighlightRowAction = {
  type: 'MODIFY_HIGHLIGHT_ROW';
  payload: {
    index: number;
    value: IncentiveHighlight;
  };
};

type DeleteHighlightRowAction = {
  type: 'DELETE_HIGHLIGHT_ROW';
  payload: {
    index: number;
  };
};

type SetSelectedRankKpiItemAction = {
  type: 'SET_SELECTED_RANK_KPI_ITEM';
  payload: {
    value: KpiItem | undefined;
  };
};

type HighlightFormAction =
  | AddHighlightRowAction
  | MoveHighlightRowAction
  | ModifyHighlightRowAction
  | DeleteHighlightRowAction
  | SetSelectedRankKpiItemAction;

export const highlightFormReducer = (state: HighlightFormState, action: HighlightFormAction): HighlightFormState => {
  const newHighlights = [...state.highlights];
  switch (action.type) {
    case 'ADD_HIGHLIGHT_ROW':
      newHighlights.push({ keyId: '' });
      return {
        ...state,
        highlights: newHighlights,
      };
    case 'MODIFY_HIGHLIGHT_ROW':
      newHighlights[action.payload.index] = action.payload.value;
      return {
        ...state,
        highlights: newHighlights,
      };
    case 'MOVE_HIGHLIGHT_ROW':
      newHighlights.splice(action.payload.to, 0, newHighlights.splice(action.payload.from, 1)[0]);
      return {
        ...state,
        highlights: newHighlights,
      };
    case 'DELETE_HIGHLIGHT_ROW':
      newHighlights.splice(action.payload.index, 1);
      return {
        ...state,
        highlights: newHighlights,
      };
    case 'SET_SELECTED_RANK_KPI_ITEM':
      return {
        ...state,
        selectedRankKpiItem: action.payload.value,
      };
    default:
      return state;
  }
};
