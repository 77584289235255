import { FC, useMemo } from 'react';
import { get, map } from 'lodash';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PruFilter, { PruFilterItemDef, PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { ApplicationListListingPageProps, useApplicationListListingPage } from './application-list-listing-page.hook';
import { ApplicationListList } from './components/application-list-list.component';
import {
  ApplicationStatus,
  ApplicationType,
  ListItemKeys,
  TaskStatus,
} from 'src/app/modules/Recruitment/types/approval-types';
import { fetchAssigneeList } from 'src/app/modules/Recruitment/network/approval-crud';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

export const ApplicationListListingPage: FC<ApplicationListListingPageProps> = (props) => {
  const { enableUpdate } = props;

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { filterState, applicationListList, isLoading, setFilterState, refreshData, onSort, isWorkflowMgr } =
    useApplicationListListingPage(props);

  const dispatch = useDispatch();

  const onboardingTemplate = useSelector((state: any) => get(state.config.data, 'Recruitment.onboardingTemplate', []));
  const formTypeOptions = onboardingTemplate.map((item: any) => item.type);

  const itemDef: PruFilterItemDef[] = useMemo(
    () => [
      ...(isWorkflowMgr
        ? [
            {
              type: PruFilterItemType.MULTIPLE_DROPDOWN,
              style: { width: 180 },
              field: ListItemKeys.taskStatus,
              initialValue: filterState?.taskStatus,
              displayName: Translation('onboarding.application.approval.list.column.task_status'),
              choices: [
                {
                  displayName: Translation('onboarding.application.approval.list.task_status.unassigned'),
                  value: TaskStatus.unassigned,
                },
                {
                  displayName: Translation('onboarding.application.approval.list.task_status.assigned'),
                  value: TaskStatus.assigned,
                },
              ],
            } as PruFilterItemDef,
          ]
        : []),
      {
        type: PruFilterItemType.MULTIPLE_DROPDOWN,
        field: ListItemKeys.applicationStatus,
        style: { width: 180 },
        initialValue: filterState?.applicationStatus,
        displayName: Translation('onboarding.application.approval.list.column.application_status'),
        choices: [
          {
            displayName: Translation('onboarding_form_status_draft'),
            value: ApplicationStatus.draft,
          },
          {
            displayName: Translation('onboarding_form_status_editing'),
            value: ApplicationStatus.editing,
          },
          {
            displayName: Translation('onboarding_form_status_under_review'),
            value: ApplicationStatus.underReview,
          },
          {
            displayName: Translation('onboarding_form_status_pending_signature'),
            value: ApplicationStatus.pendingSignature,
          },
          {
            displayName: Translation('onboarding_form_status_submitted'),
            value: ApplicationStatus.submitted,
          },
          {
            displayName: Translation('onboarding_form_status_rejected'),
            value: ApplicationStatus.rejected,
          },
          {
            displayName: Translation('onboarding_form_status_follow_up_required'),
            value: ApplicationStatus.followUpRequired,
          },
          {
            displayName: Translation('onboarding_form_status_l1_pending_approval'),
            value: ApplicationStatus.screeningPendingApproval,
          },
          {
            displayName: Translation('onboarding_form_status_l1_approved'),
            value: ApplicationStatus.screeningApproved,
          },
          {
            displayName: Translation('onboarding_form_status_l2_pending_approval'),
            value: ApplicationStatus.icPendingApproval,
          },
          {
            displayName: Translation('onboarding_form_status_l2_approved'),
            value: ApplicationStatus.icApproved,
          },
          {
            displayName: Translation('onboarding_form_status_l3_pending_approval'),
            value: ApplicationStatus.agentCodePendingApproval,
          },
          {
            displayName: Translation('onboarding_form_status_l3_approved'),
            value: ApplicationStatus.agentCodeApproved,
          },
          {
            displayName: Translation('onboarding_form_status_l4_pending_approval'),
            value: ApplicationStatus.l4PendingApproval,
          },
          {
            displayName: Translation('onboarding_form_status_accepted'),
            value: ApplicationStatus.accepted,
          },
          // {
          //   displayName: Translation('onboarding_form_status_canceled'),
          //   value: ApplicationStatus.canceled,
          // },
          // {
          //   displayName: Translation('onboarding_form_status_deleted'),
          //   value: ApplicationStatus.deleted,
          // },
          // {
          //   displayName: Translation('onboarding_form_status_expired'),
          //   value: ApplicationStatus.expired,
          // },
        ],
      },
      {
        type: PruFilterItemType.FREE_TEXT,
        field: ListItemKeys.applicationId,
        initialValue: filterState.applicationId,
        displayName: Translation('onboarding.application.approval.list.column.application_id'),
      },
      {
        type: PruFilterItemType.FREE_TEXT,
        field: ListItemKeys.applicantId,
        initialValue: filterState.applicantId,
        displayName: Translation('onboarding.application.approval.list.column.applicant_id'),
      },
      {
        type: PruFilterItemType.FREE_TEXT,
        field: 'firstnames',
        initialValue: filterState.firstnames,
        displayName: Translation('onboarding.application.approval.list.column.first_name'),
      },
      {
        type: PruFilterItemType.FREE_TEXT,
        field: 'lastnames',
        initialValue: filterState.lastnames,
        displayName: Translation('onboarding.application.approval.list.column.last_name'),
      },
      ...(isWorkflowMgr
        ? [
            {
              type: PruFilterItemType.ASYNC_MULTIPLE_DROPDOWN,
              style: { width: 180 },
              field: ListItemKeys.assignee,
              initialValue: filterState.taskAssignee,
              displayName: Translation('onboarding.application.approval.list.column.assignee'),
              fetchList: async () => {
                const res = await fetchAssigneeList(dispatch);

                return res.map((item) => ({
                  displayName: item.approverId,
                  value: item.approverId,
                }));
              },
            } as PruFilterItemDef,
          ]
        : []),
      {
        type: PruFilterItemType.DATE_RANGE,
        fieldFrom: 'submittedDateFrom',
        fieldTo: 'submittedDateTo',
        initialDateFrom: filterState.submittedDateFrom,
        initialDateTo: filterState.submittedDateTo,
        displayName: Translation('onboarding.application.approval.list.search.submission_date'),
      },
      {
        type: PruFilterItemType.MULTIPLE_DROPDOWN,
        field: ListItemKeys.applicationType,
        style: { width: 180 },
        initialValue: filterState?.applicationType,
        displayName: Translation('onboarding.application.approval.list.column.application_type'),
        choices: [
          {
            displayName: Translation('onboarding.application.approval.list.application_type.new_recruit'),
            value: ApplicationType.newRecruit,
          },
          {
            displayName: Translation('onboarding.application.approval.list.application_type.reinstatement'),
            value: ApplicationType.reinstatement,
          },
        ],
      },
      {
        type: PruFilterItemType.MULTIPLE_DROPDOWN,
        field: ListItemKeys.formType,
        initialValue: filterState.formType,
        displayName: Translation('onboarding.application.approval.list.column.form_type'),
        style: { width: 180 },
        choices: [
          ...map(formTypeOptions, (option: string) => ({
            displayName: Translation(`Recruitment.onboarding_type_${option}`),
            value: option,
          })),
        ],
      },
    ],
    [isWorkflowMgr],
  );

  return (
    <Box className="auto-table-height">
      <PruFilter
        title={Translation('golbal.filters.search')}
        itemDef={itemDef}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            ...data,
          })
        }
        fetchData={refreshData}
      />
      <ApplicationListList
        isLoading={isLoading}
        applicationListList={applicationListList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
        formState={filterState}
        enableUpdate={enableUpdate}
      />
    </Box>
  );
};
