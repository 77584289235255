import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_APPLICATION_FORM_PATH,
  FORM_TEMPLATE_PATH,
  PDF_TEMPLATE_PATH,
  RECRUITMENT_ONBOARDING_APPLICATION_PATH,
} from '../../constants';
// import OnboardingApplicationDetailPage from './onboarding-application-page';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import PruRoute from '../../../../common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { formTemplateFilterKeys } from './form-template/list/form-template-listing-page.hook';
import { FormTemplateListingPage } from './form-template/list/form-template-listing-page.screen';
import { PDFTemplateFilterKeys } from './pdf-template/list/pdf-template-listing-page.hook';
import { PDFTemplateListingPage } from './pdf-template/list/pdf-template-listing-page.screen';

export const onboardingApplicationBasePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_APPLICATION_PATH}${RECRUITMENT_APPLICATION_FORM_PATH}`;
const OnboardingApplicationRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${onboardingApplicationBasePath}`} to={`${onboardingApplicationBasePath}/setting`} />

      <PruRoute
        exact
        path={`${onboardingApplicationBasePath}${FORM_TEMPLATE_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={formTemplateFilterKeys}>
            <FormTemplateListingPage />
          </ParamsProvider>
        )}
      />

      <PruRoute
        exact
        path={`${onboardingApplicationBasePath}/${PDF_TEMPLATE_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={PDFTemplateFilterKeys}>
            <PDFTemplateListingPage />
          </ParamsProvider>
        )}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default OnboardingApplicationRoutes;
