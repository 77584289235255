import { ReturnCategories } from 'src/app/modules/Recruitment/types/approval-types';
import { REMARK_OTHERS } from '../../../../util/constant';

export const getOptions = (valueArr: string[]) => {
  return valueArr.map((i: string) => ({
    label: i,
    value: i,
  }));
};

export const getDefaultValue = (options: { label: string; value: string }[], key: ReturnCategories) => {
  return {
    [key]: {
      checked: true,
      remark: { key: options?.length <= 0 ? REMARK_OTHERS : options[0].value },
    },
  };
};
