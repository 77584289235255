import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { DialogueDetails, DialogueItem, DialogueListParam } from '../types/talkbot-dialogue-type';
import { getQueryPath } from 'src/app/common/utils';

const talkbotEndpoint = 'talkbot-flow';

export const fetchDialogueList = async (
  params: DialogueListParam,
  dispatch?: Dispatch<any>,
): Promise<DialogueItem[]> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${talkbotEndpoint}?`, params);
  return apiClient
    .get<any>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createDialogue = async (body: DialogueDetails, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${talkbotEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDialogueDetail = async (id: string, dispatch?: Dispatch<any>): Promise<DialogueDetails> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${talkbotEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateDialogue = async (
  id: string,
  body: Partial<DialogueDetails>,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${talkbotEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
