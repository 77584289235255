import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { PaginateList } from 'src/app/modules/Recruitment/types/recruit-generic-types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import {
  TrainingFeeVerificationItem,
  TrainingFeeVerificationListParam,
} from '../../../../../types/training-fee-verification-types';
import { trainingFeeVerificationPath } from '../../training-fee-verification-routes';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleDowloadFileError, utcToLocalDate } from 'src/app/modules/Recruitment/utils';
import { downloadTrainingFeeVerificationList } from 'src/app/modules/Recruitment/network/training-fee-verification-crud';
import moment from 'moment';

type TrainingFeeVerificationListProps = {
  isLoading: boolean;
  trainingFeeVerificationList?: PaginateList<TrainingFeeVerificationItem>;
  formState: TrainingFeeVerificationListParam;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
};

export const TrainingFeeVerificationList: FC<TrainingFeeVerificationListProps> = ({
  isLoading,
  trainingFeeVerificationList,
  formState,
  onRefresh,
  onChangePage,
  onSort,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();

  const downloadTrainingFeeVerification = useCallback(async () => {
    try {
      await downloadTrainingFeeVerificationList({
        ...formState,
        submissionDateFrom: formState.submissionDateFrom
          ? moment(formState.submissionDateFrom).format('YYYY-MM-DD')
          : undefined,
        submissionDateTo: formState.submissionDateTo
          ? moment(formState.submissionDateTo).format('YYYY-MM-DD')
          : undefined,
      });
    } catch (err) {
      handleDowloadFileError(err, dispatch);
    }
  }, [formState, dispatch]);

  return (
    <PruTable
      title={Translation('onboarding.training.fee.verification.list')}
      disableBulkSelect
      operationSticky
      headerBtnDef={[
        {
          color: 'secondary',
          title: Translation('app.button.download'),
          onClick: downloadTrainingFeeVerification,
        },
      ]}
      operationDef={[
        {
          title: Translation('section.common.operation.view'),
          tooltipText: 'View',
          onClick: (row) => {
            history.push(`${trainingFeeVerificationPath}/view/${row.submissionId}`);
          },
        },
      ]}
      columnDef={[
        {
          keyIndex: 'submissionId',
          displayName: Translation('onboarding.training.fee.verification.submissionId'),
          renderData: (row) => row.submissionId || '-',
        },
        {
          keyIndex: 'applicantId',
          displayName: Translation('onboarding.training.fee.verification.applicantId'),
          renderData: (row) => row.applicantId || '-',
        },
        {
          keyIndex: 'firstname',
          displayName: Translation('onboarding.training.fee.verification.firstname'),
          renderData: (row) => row.firstname || '-',
        },
        {
          keyIndex: 'lastname',
          displayName: Translation('onboarding.training.fee.verification.lastname'),
          renderData: (row) => row.lastname || '-',
        },
        {
          keyIndex: 'paymentType',
          displayName: Translation('onboarding.training.fee.verification.paymentType'),
          renderData: (row) =>
            row.paymentType
              ? Translation(`onboarding.training.fee.verification.${row.paymentType.toLowerCase()}`)
              : '-',
        },
        {
          keyIndex: 'trainingFeeOfficialReceiptNumber',
          displayName: Translation('onboarding.training.fee.verification.orNumber'),
          renderData: (row) => row.trainingFeeOfficialReceiptNumber || '-',
        },
        {
          keyIndex: 'trainingFeeOfficialReceiptDate',
          displayName: Translation('onboarding.training.fee.verification.orDate'),
          renderData: (row) => row.trainingFeeOfficialReceiptDate || '-',
        },
        {
          keyIndex: 'status',
          displayName: Translation('onboarding.training.fee.verification.paymentStatus'),
          renderData: (row) =>
            row.paymentStatus
              ? Translation(`onboarding.training.fee.verification.${row.paymentStatus.toLowerCase()}`)
              : '-',
        },
        {
          keyIndex: 'submittedTime',
          displayName: Translation('onboarding.training.fee.verification.submittedTime'),
          renderData: (row) => utcToLocalDate(row.lastSubmittedAt) || '-',
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'SUBMITTED_DATE', value: sort['submittedTime']?.toLocaleUpperCase() });
          },
        },
        {
          keyIndex: 'lastUpdatedBy',
          displayName: Translation('onboarding.training.fee.verification.lastUpdatedBy'),
          renderData: (row) => row.lastUpdatedBy || '-',
        },
        {
          keyIndex: 'lastUpdatedAt',
          displayName: Translation('onboarding.training.fee.verification.lastUpdatedAt'),
          renderData: (row) => utcToLocalDate(row.lastUpdatedAt) || '-',
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'LAST_UPDATED_AT', value: sort['lastUpdatedAt']?.toLocaleUpperCase() });
          },
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={trainingFeeVerificationList?.data}
      totalPages={trainingFeeVerificationList?.totalPages}
      totalRecords={trainingFeeVerificationList?.totalNumbers}
      onChangePage={onChangePage}
      defaultRowsPerPage={20}
    />
  );
};
