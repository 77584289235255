import React, { FC } from 'react';
import ResourceLibraryCreate from '../../../components/Library/pages/Create';
import { SALESKIT_BASE_PATH, PROMOTION_MATERIAL_BASE_PATH, PROMOTION_LIBRARY_BASE_PATH } from '../../../constants';

export const SystemConfig =  {
  blockName: 'component.formTitle.promotion-material',
  blockType:  'promotionMaterial',
  libraryBasePath: `${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_LIBRARY_BASE_PATH}`,
  categoryType: 'promotionMaterialCategory',
  columnType: 'promotionMaterialColumn',
  addSuccessText: 'global.submit.success',
  columnModuleType: 'promotionMaterial',
  moduleType: 'Promotion'
}

const PromotionLibraryCreatePage: FC<any> = (props) => {
  return (
    <ResourceLibraryCreate { ...props } { ...SystemConfig } />
  )
};

export default PromotionLibraryCreatePage;
