import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PaginateList } from 'src/app/modules/Recruitment/types/recruit-generic-types';
import {
  TrainingFeeVerificationListParam,
  TrainingFeeVerificationItem,
} from '../../../../types/training-fee-verification-types';
import { fetchTrainingFeeVerificationList } from '../../../../network/training-fee-verification-crud';
import moment from 'moment';

const initialState: TrainingFeeVerificationListParam = {
  page: 1,
  limit: 20,
  submissionIds: '',
  applicantIds: '',
  firstnames: '',
  lastnames: '',
  paymentTypeList: [],
  trainingFeeOfficialReceiptNumbers: '',
  paymentStatusList: [],
  submissionDateFrom: undefined,
  submissionDateTo: undefined,
  sortBy: '',
  orderBy: '',
};

export const trainingFeeVerificationFilterKeys = [
  'submissionIds',
  'applicantIds',
  'firstnames',
  'lastnames',
  'paymentTypeList',
  'trainingFeeOfficialReceiptNumbers',
  'paymentStatusList',
  'submissionDateFrom',
  'submissionDateTo',
  'sortBy',
  'orderBy',
];

const paramsInitiator = (initialParams?: Record<string, any>): TrainingFeeVerificationListParam => {
  return initialParams
    ? {
        submissionIds: initialParams.submissionIds,
        applicantIds: initialParams.applicantIds,
        firstnames: initialParams.firstnames,
        lastnames: initialParams.lastnames,
        paymentTypeList: initialParams.paymentTypeList?.split(','),
        trainingFeeOfficialReceiptNumbers: initialParams.trainingFeeOfficialReceiptNumbers,
        paymentStatusList: initialParams.paymentStatusList?.split(','),
        submissionDateFrom: initialParams.submissionDateFrom,
        submissionDateTo: initialParams.submissionDateTo,
        page: 1,
        limit: 20,
      }
    : initialState;
};

export const useTrainingFeeVerificationListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<TrainingFeeVerificationListParam>(paramsInitiator(initialParams));
  const [trainingFeeVerificationList, setTrainingFeeVerificationList] =
    useState<PaginateList<TrainingFeeVerificationItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<TrainingFeeVerificationItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchTrainingFeeVerificationList(
          {
            ...filterState,
            submissionDateFrom: filterState.submissionDateFrom
              ? moment(filterState.submissionDateFrom).format('YYYY-MM-DD')
              : null,
            submissionDateTo: filterState.submissionDateTo
              ? moment(filterState.submissionDateTo).format('YYYY-MM-DD')
              : null,
          },
          dispatch,
        );
      } catch (err) {}
    },
    setTrainingFeeVerificationList,
    false,
  );

  const onSort = (newSortState: { key: string; value?: string }) => {
    setFilterState({
      ...filterState,
      sortBy: newSortState.value ? newSortState.key : undefined,
      orderBy: newSortState.value,
    });
    refreshData();
  };

  return {
    filterState,
    trainingFeeVerificationList,
    isLoading,
    setFilterState,
    refreshData,
    onSort,
  };
};
