import React, { useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Dialog, Grid, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './license-verification-detail.style';
import { useLicenseVerificationDetail } from './license-verification-detail.hook';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { proofInfo, statusType } from '../../../types/license-verification-types';
import {
  ExamEventsEnum,
  LicenseApprovalEventsEnum,
  LicenseVerificationStatusEnum,
} from '../../../enum/license-exam.enum';
import { LoadingButton } from '@mui/lab';
import { TranslationWithParams } from 'src/app/i18n';
import { eventType } from '../../../types/license-exam-types';
import { dateFormatToLocal, utcToLocalDate } from '../../../utils';

type PageProps = ParamsProps & ModulePermissionProps & RouteComponentProps;

export const LicenseVerificationDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { history, enableUpdate } = props;
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const {
    loadingUpdate,
    data,
    handlePress,
    dialogState,
    handleCloseDialog,
    handleConfirm,
    showApprovalButtons,
    remark,
    setRemark,
    fetchFile,
  } = useLicenseVerificationDetail({ id: id, enableUpdate: enableUpdate });

  return (
    <>
      <Dialog open={dialogState.open}>
        <div className={styles.dialogContainer}>
          <div className={styles.subHeader}>{dialogState.header}</div>
          <div className={styles.content}>
            <TextField
              variant="outlined"
              label={Translation('recruitment.exam.registration.remark')}
              value={remark}
              fullWidth
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            />
          </div>
          <div className={styles.dialogButton}>
            <Button variant="outlined" color="inherit" disabled={loadingUpdate} onClick={handleCloseDialog}>
              {Translation('app.button.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              loading={loadingUpdate}
              className={styles.buttonMargin}
              onClick={handleConfirm}
            >
              {Translation('app.button.confirm')}
            </LoadingButton>
          </div>
        </div>
      </Dialog>
      <div className={commonStyles.headerContainer}>
        <div className={commonStyles.rowContainer}>
          <div className={commonStyles.header}>{Translation('recruitment.license.detail')}</div>
        </div>
        <Button variant="outlined" color="inherit" onClick={history.goBack}>
          {Translation('app.button.back')}
        </Button>
      </div>
      {data && (
        <>
          <div className={styles.container}>
            <Grid container spacing={3} className={styles.wrap}>
              <Grid item xs={5}>
                <div className={styles.header}>{Translation('recruitment.license.verification.id')}</div>
                <div className={styles.header}>{data.verificationId || '-'}</div>
              </Grid>
              <Grid item xs={3}>
                <div className={styles.header}>{Translation('recruitment.exam.registration.status')}</div>
                <div className={styles.header}>
                  {data.licenseApprovalStates
                    ? Translation(
                        `recruitment.license.verification.status.${
                          LicenseVerificationStatusEnum[data.licenseApprovalStates as statusType]
                        }`,
                      )
                    : '-'}
                </div>
              </Grid>
            </Grid>

            <div className={styles.header}>{Translation('recruitment.license.applicant.detail')}</div>
            <Grid container spacing={3} className={styles.wrap}>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.registration.applicantId')}
                  value={data.applicantId || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.registration.firstName')}
                  value={data.firstName || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.registration.lastName')}
                  value={data.lastName || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>

            <div className={styles.header}>{Translation('recruitment.license.info')}</div>
            <Grid container spacing={3} className={styles.wrap}>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.examType')}
                  value={data.examType || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.license.proofOfPassedExam')}
                  value={
                    data.hasLicenseProof
                      ? Translation('recruitment.license.hasProof.true')
                      : Translation('recruitment.license.hasProof.false')
                  }
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.examDate')}
                  value={data.examDate ? dateFormatToLocal(data.examDate) : '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.score')}
                  value={data.examScore || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.license.or.number')}
                  value={data.officialReceiptNumber || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  FormHelperTextProps={{ sx: { color: '#E8192C' } }}
                  helperText={
                    data.orNumberUsedBefore &&
                    data.licenseApprovalStates !== LicenseVerificationStatusEnum.APPROVED.toUpperCase()
                      ? Translation('recruitment.common.or.error.alert')
                      : null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.license.or.date')}
                  value={data.paymentReceiptDate ? dateFormatToLocal(data.paymentReceiptDate) : '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>

            <div className={styles.attachmentList}>
              <span className={styles.attachmentHeader}>{Translation('recruitment.license.attachment')}</span>
              {data.hasLicenseProof && data.proofInfos
                ? data.proofInfos.map((info: proofInfo, index: number) => {
                    return (
                      <div
                        key={index}
                        className={styles.attachmentLink}
                        onClick={() => fetchFile(info.resourceId as string)}
                      >
                        {info.fileName}
                      </div>
                    );
                  })
                : '-'}
            </div>
          </div>

          <div className={commonStyles.header}>{Translation('recruitment.license.approvalRecord')}</div>
          <div className={styles.container}>
            <ul className={styles.list}>
              {data.operationHistoryList?.map((history, index) => (
                <li key={index}>
                  <span>
                    {history.event
                      ? history.event === LicenseApprovalEventsEnum.submit
                        ? TranslationWithParams(`recruitment.exam.registration.remark.submit`, {
                            firstName: data.firstName,
                            lastName: data.lastName,
                            time: utcToLocalDate(history.updatedAt),
                          })
                        : TranslationWithParams(
                            `recruitment.exam.registration.remark.${ExamEventsEnum[history.event as eventType]}`,
                            {
                              operationUser: history.event !== 'EXPIRE' ? history.operator.operatorName : undefined,
                              time: utcToLocalDate(history.updatedAt),
                            },
                          )
                      : '-'}
                  </span>
                  {history.remark && (
                    <span>{` ${Translation('recruitment.exam.registration.remark')}: ${history.remark}`}</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      {enableUpdate && showApprovalButtons && (
        <div className={styles.buttonGroup}>
          <Button
            variant="contained"
            color="secondary"
            className={styles.buttonMargin}
            onClick={() => handlePress(LicenseApprovalEventsEnum.reject)}
          >
            {Translation('recruitment.exam.registration.reject')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={styles.buttonMargin}
            onClick={() => handlePress(LicenseApprovalEventsEnum.return)}
          >
            {Translation('recruitment.exam.registration.return')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={styles.buttonMargin}
            onClick={() => handlePress(LicenseApprovalEventsEnum.approve)}
          >
            {Translation('recruitment.exam.registration.approve')}
          </Button>
        </div>
      )}
    </>
  );
};
