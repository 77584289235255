import { Dispatch } from 'react';
import qs from 'qs';
import moment from 'moment';
import { downloadFile } from 'src/app/common/utils';
import { ADMIN_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { PaginateList } from '../types/recruit-generic-types';
import { LicenseVerificationDetails, LicenseVerificationListParam } from '../types/license-verification-types';
import { LicenseApprovalEventsEnum } from '../enum/license-exam.enum';

const licenseVerification = 'license-verification';

// const ADMIN_URL = 'http://localhost:3000';

export const fetchLicenseVerificationList = async (
  params: LicenseVerificationListParam,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  return apiClient
    .get<PaginateList<LicenseVerificationListParam>>(`${ADMIN_URL}/${licenseVerification}`, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLicenseVerificationDetail = async (
  verificationId: string,
  dispatch?: Dispatch<any>,
): Promise<LicenseVerificationDetails> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${licenseVerification}/${verificationId}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateVerificationStatus = async (
  body: { licenseApprovalEvents: LicenseApprovalEventsEnum; remark: string },
  verificationId: string,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${licenseVerification}/${verificationId}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadVerification = async (params: LicenseVerificationListParam): Promise<any> => {
  const query = qs.stringify(params);
  const queryPath = `${ADMIN_URL}/${licenseVerification}/download/verifications?${query}`;
  const fileName = `Licensing Info Verification ${moment().format('DDMMYYYY')}`;
  return downloadFile(fileName, queryPath);
};
