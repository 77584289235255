import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 250,
    boxSizing: 'border-box',
  },
  checkboxFieldContainer: {
    width: 300,
    boxSizing: 'border-box',
  },
  imageContainer: {
    width: 120,
    height: 120,
    overflow: 'hidden',
    borderRadius: 8,
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  optionDefault: {
    color: '#AAAAAA',
  },
  innerFieldContainer: {
    width: 250,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 25,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  subSectionHeader: {
    fontSize: '14px',
    fontWeight: 500,
    fontColor: 'black',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    width: 250,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  checkboxPadding: {
    marginLeft: -9,
  },
}));
