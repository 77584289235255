import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import PruTable, {
  PruTableButtonDef,
  PruTableColumnDef,
  PruTableOperationDef,
} from 'src/app/common/components/PruTable/PruTable';
import {
  ApplicationListItem,
  ApplicationStatus,
  FilterState,
  ListItemKeys,
} from 'src/app/modules/Recruitment/types/approval-types';
import { APPLICATION_STATUS_MAPS, APPLICATION_TYPE_MAPS, TASK_STATUS_MAPS } from '../../util/constant';
import { formatDate } from '../../util/date.util';
import { useHistory } from 'react-router-dom';
import { applicationDetailPath } from '../../approval-routes';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationListResponse, downloadApproveList } from 'src/app/modules/Recruitment/network/approval-crud';
import { handleDowloadFileError } from 'src/app/modules/Recruitment/utils';
import { AssignDialog } from './assign-dialog/assign-dialog.component';
import { formatListParams } from '../../util/api-helper.util';
import { get } from 'lodash';

type ApplicationListListProps = {
  isLoading: boolean;
  applicationListList?: ApplicationListResponse;
  formState: FilterState;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
  enableUpdate?: boolean;
  // enableApprove?: boolean;
};

export const ApplicationListList: FC<ApplicationListListProps> = ({
  isLoading,
  applicationListList,
  formState,
  onRefresh,
  onChangePage,
  onSort,
  enableUpdate,
  // enableApprove
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();
  const dispatch = useDispatch();
  const manualApproveConfig = useSelector((state: any) =>
    get(state.config.data, 'Recruitment.onboardingApplicationApprovalConfig', []),
  );

  const [isL1ManualApprove, isL3ManualApprove] = useMemo(() => {
    return [
      manualApproveConfig.find((config: any) => config.approvalLevel === 1)?.['approvalRule'] === 'manual',
      manualApproveConfig.find((config: any) => config.approvalLevel === 3)?.['approvalRule'] === 'manual',
    ];
  }, [manualApproveConfig]);

  const isWorkflowMgr = enableUpdate;

  const columnRef: PruTableColumnDef<ApplicationListItem>[] = useMemo(
    () => [
      {
        isId: true,
        hidden: true,
        keyIndex: 'id',
        displayName: '',
        renderData: () => '',
      },
      {
        keyIndex: ListItemKeys.applicationId,
        displayName: Translation('onboarding.application.approval.list.column.application_id'),
        renderData: (row) => row[ListItemKeys.applicationId] || '-',
      },
      {
        keyIndex: ListItemKeys.applicantId,
        displayName: Translation('onboarding.application.approval.list.column.applicant_id'),
        renderData: (row) => row[ListItemKeys.applicantId] || '-',
      },
      {
        keyIndex: ListItemKeys.firstname,
        displayName: Translation('onboarding.application.approval.list.column.first_name'),
        renderData: (row) => row[ListItemKeys.firstname] || '-',
      },
      {
        keyIndex: ListItemKeys.lastname,
        displayName: Translation('onboarding.application.approval.list.column.last_name'),
        renderData: (row) => row[ListItemKeys.lastname] || '-',
      },
      {
        keyIndex: ListItemKeys.taskStatus,
        hidden: !isWorkflowMgr,
        displayName: Translation('onboarding.application.approval.list.column.task_status'),
        renderData: (row) =>
          TASK_STATUS_MAPS[row[ListItemKeys.taskStatus]]
            ? Translation(TASK_STATUS_MAPS[row[ListItemKeys.taskStatus]])
            : '-',
      },
      {
        keyIndex: ListItemKeys.assignee,
        hidden: !isWorkflowMgr,
        displayName: Translation('onboarding.application.approval.list.column.assignee'),
        renderData: (row) => row[ListItemKeys.assignee] || '-',
      },
      {
        keyIndex: ListItemKeys.applicationStatus,
        displayName: Translation('onboarding.application.approval.list.column.application_status'),
        renderData: (row) =>
          APPLICATION_STATUS_MAPS[row[ListItemKeys.applicationStatus]]
            ? Translation(APPLICATION_STATUS_MAPS[row[ListItemKeys.applicationStatus]])
            : '-',
      },
      {
        keyIndex: ListItemKeys.remarks,
        displayName: Translation('onboarding.application.approval.list.column.remarks'),
        renderData: (row) => (row[ListItemKeys.remarks] ? row[ListItemKeys.remarks] : '-'),
      },
      {
        keyIndex: ListItemKeys.applicationType,
        displayName: Translation('onboarding.application.approval.list.column.application_type'),
        renderData: (row) =>
          APPLICATION_TYPE_MAPS[row[ListItemKeys.applicationType]]
            ? Translation(APPLICATION_TYPE_MAPS[row[ListItemKeys.applicationType]])
            : '-',
      },
      {
        keyIndex: ListItemKeys.formType,
        displayName: Translation('onboarding.application.approval.list.column.form_type'),
        renderData: (row) =>
          row[ListItemKeys.formType] ? Translation(`Recruitment.onboarding_type_${row[ListItemKeys.formType]}`) : '-',
      },
      {
        keyIndex: ListItemKeys.submittedTime,
        displayName: Translation('onboarding.application.approval.list.column.submitted_time'),
        renderData: (row) => formatDate(row[ListItemKeys.submittedTime]) || '-',
        sortable: true,
        onSort: (sort) => {
          onSort({ key: 'SUBMITTED_DATE', value: sort[ListItemKeys.submittedTime]?.toUpperCase() });
        },
      },
      {
        keyIndex: ListItemKeys.lastUpdatedBy,
        displayName: Translation('onboarding.application.approval.list.column.last_updated_by'),
        renderData: (row) => row[ListItemKeys.lastUpdatedBy] || '-',
      },
      {
        keyIndex: ListItemKeys.lastUpdatedAt,
        displayName: Translation('onboarding.application.approval.list.column.last_updated_at'),
        renderData: (row) => formatDate(row[ListItemKeys.lastUpdatedAt]) || '-',
        sortable: true,
        onSort: (sort) => {
          onSort({ key: 'LAST_UPDATED_AT', value: sort[ListItemKeys.lastUpdatedAt]?.toUpperCase() });
        },
      },
    ],
    [isWorkflowMgr, onSort],
  );

  const downloadLicenseVerification = useCallback(async () => {
    try {
      // new add sort
      const reqParams = formatListParams(formState, isWorkflowMgr);
      await downloadApproveList(reqParams);
    } catch (err) {
      handleDowloadFileError(err, dispatch);
    }
  }, [formState, dispatch, isWorkflowMgr]);

  const headerBtnDef: PruTableButtonDef[] = useMemo(
    () => [
      {
        color: 'secondary',
        title: Translation('app.button.download'),
        onClick: downloadLicenseVerification,
      },
    ],
    [downloadLicenseVerification, Translation],
  );

  const operationDef: PruTableOperationDef<ApplicationListItem>[] = useMemo(
    () => [
      {
        title: Translation('section.common.operation.view'),
        tooltipText: Translation('section.common.operation.view'),
        onClick: (row) => {
          history.push(`${applicationDetailPath}/${row.applicationId}`);
        },
      },
      {
        title: Translation('onboarding.application.approval.list.action.assign'),
        tooltipText: Translation('onboarding.application.approval.list.action.assign'),
        condition: (row) =>
          !!isWorkflowMgr &&
          (row[ListItemKeys.applicationStatus] === ApplicationStatus.submitted ||
            (isL1ManualApprove && row[ListItemKeys.applicationStatus] === ApplicationStatus.screeningPendingApproval) ||
            row[ListItemKeys.applicationStatus] === ApplicationStatus.screeningApproved ||
            (isL3ManualApprove && row[ListItemKeys.applicationStatus] === ApplicationStatus.agentCodePendingApproval) ||
            row[ListItemKeys.applicationStatus] === ApplicationStatus.icApproved ||
            row[ListItemKeys.applicationStatus] === ApplicationStatus.agentCodeApproved),
        onClick: (row) => {
          setAssignedRow(row);
        },
      },
    ],
    [isWorkflowMgr, Translation, history],
  );

  const [assignedRow, setAssignedRow] = useState<ApplicationListItem>();

  return (
    <>
      <PruTable
        title={Translation('onboarding.application.approval.list.title')}
        disableBulkSelect
        operationSticky
        headerBtnDef={headerBtnDef}
        operationDef={operationDef}
        columnDef={columnRef}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={applicationListList?.data}
        totalPages={applicationListList?.totalPages}
        totalRecords={applicationListList?.totalNumbers}
        defaultRowsPerPage={formState?.limit}
        onChangePage={onChangePage}
      />
      {assignedRow ? (
        <AssignDialog
          onRefresh={onRefresh}
          id={assignedRow?.[ListItemKeys.applicationId]}
          applicationFormStatus={assignedRow?.[ListItemKeys.applicationStatus]}
          onClose={() => setAssignedRow(undefined)}
        />
      ) : null}
    </>
  );
};
