import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import {
  LicenseVerificationItem,
  LicenseVerificationListParam,
  PaginateList,
} from '../../../types/license-verification-types';
import { fetchLicenseVerificationList } from '../../../network/license-verification-crud';
import moment from 'moment';

const initialState: LicenseVerificationListParam = {
  examType: '',
  examDateFrom: undefined,
  examDateTo: undefined,
  examCentre: '',
  licenseApprovalStates: [],
  verificationIds: '',
  applicantIds: '',
  firstnames: '',
  lastnames: '',
  submissionDateFrom: undefined,
  submissionDateTo: undefined,
  page: 1,
  limit: 20,
  sortBy: '',
  orderBy: '',
};

export const licenseVerificationFilterKeys = [
  'examType',
  'examDateFrom',
  'examDateTo',
  'examCentre',
  'licenseApprovalStates',
  'verificationIds',
  'applicantIds',
  'firstnames',
  'lastnames',
  'submissionDateFrom',
  'submissionDateTo',
  'sortBy',
  'orderBy',
];

const paramsInitiator = (initialParams?: Record<string, string>): LicenseVerificationListParam => {
  return initialParams
    ? {
        examType: initialParams.examType,
        examDateFrom: initialParams.examDateFrom,
        examDateTo: initialParams.examDateTo,
        examCentre: initialParams.examCentre,
        licenseApprovalStates: initialParams.licenseApprovalStates?.split(','),
        verificationIds: initialParams.verificationIds,
        applicantIds: initialParams.applicantIds,
        firstnames: initialParams.firstnames,
        lastnames: initialParams.lastnames,
        submissionDateFrom: initialParams.submissionDateFrom,
        submissionDateTo: initialParams.submissionDateTo,
        page: 1,
        limit: 20,
      }
    : initialState;
};

export const useLicenseVerificationListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<LicenseVerificationListParam>(paramsInitiator(initialParams));
  const [licenseVerificationList, setLicenseVerificationList] = useState<PaginateList<LicenseVerificationItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<LicenseVerificationItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchLicenseVerificationList(
          {
            ...filterState,
            examDateFrom: filterState.examDateFrom ? moment(filterState.examDateFrom).format('YYYY-MM-DD') : undefined,
            examDateTo: filterState.examDateTo ? moment(filterState.examDateTo).format('YYYY-MM-DD') : undefined,
            submissionDateFrom: filterState.submissionDateFrom
              ? moment(filterState.submissionDateFrom).format('YYYY-MM-DD')
              : null,
            submissionDateTo: filterState.submissionDateTo
              ? moment(filterState.submissionDateTo).format('YYYY-MM-DD')
              : null,
          },
          dispatch,
        );
      } catch (err) {}
    },
    setLicenseVerificationList,
    false,
  );

  const onSort = (newSortState: { key: string; value?: string }) => {
    setFilterState({
      ...filterState,
      sortBy: newSortState.value ? newSortState.key : undefined,
      orderBy: newSortState.value,
    });
    refreshData();
  };

  return {
    filterState,
    licenseVerificationList,
    isLoading,
    setFilterState,
    refreshData,
    onSort,
  };
};
