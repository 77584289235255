export const CONFIG_BASE_PATH = '/config';
export const CONFIG_AGENT_PATH = '/agent-config';
export const CONFIG_AGENT_SEGMENT_PATH = "/agent-segment";

export const DATE_ERROR_TEXT = 'Archive date must be after Publish date';
export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const EMAIL_FORMAT_ERROR_TEXT = "Please fill in a valid email address";

//  These constants map to aside menu, header menu to display title
export const CONFIG_TITLE = "title.config";
export const CONFIG_AGENT_TITLE = "title.config.agent";

//  These constants map to permission control, identical to permission enum defined below
export const CONFIG_AGENT_NAMESPACE = "CONFIGZ";
export const CONFIG_AGENT_SEGMENT_NAMESPACE = "AGENT_SEGMENT_CONFIGZ";


export enum AdminNewsPermissionType {
  CONFIG_AGENT_READ = "READ_ANY_CONFIGZ",
  CONFIG_AGENT_CREATE = "CREATE_ANY_CONFIGZ",
  CONFIG_AGENT_UPDATE = "UPDATE_ANY_CONFIGZ",
};

export enum AdminAgentSegmentPermissionType {
  CONFIG_AGENT_SEGMENT_READ = "READ_ANY_CONFIGZ",
  CONFIG_AGENT_SEGMENT_CREATE = "CREATE_ANY_CONFIGZ",
  CONFIG_AGENT_SEGMENT_UPDATE = "UPDATE_ANY_CONFIGZ",
  CONFIG_AGENT_SEGMENT_APPROVE = "APPROVE_ANY_CONFIG-AGENT-SEGMENT",
  CONFIG_AGENT_SEGMENT_ARCHIVE = "ARCHIVE_ANY_CONFIG-AGENT-SEGMENT",
};
