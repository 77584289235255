import { useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { regionLocale, initI18nGenericData, RegionLocale } from 'src/app/i18n';
import { FormMode } from 'src/app/common/types';
import { useErrorHandler, ErrorFieldType, ErrorFieldDef } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import {
  EventAccessibilityEnum,
  EventAudienceEnum,
  EventCategoryStatusEnum,
  EventCategoryItem,
  ReminderDialogState,
} from 'src/app/modules/event-v2/types';
import {
  CreateUpdateEventCategoryBody,
  createEventCategoryItem,
  modifyEventCategoryItem,
} from 'src/app/modules/event-v2/network';
import { EventCategoryFormState, formReducer } from '../../reducers';

interface HookProps {
  formMode: FormMode;
  eventCategoryItem?: EventCategoryItem;
  onClose: () => void;
  onRefresh: () => void;
}

const detailToStateConvertor = (
  regionLocale: RegionLocale[],
  eventCategoryItem?: EventCategoryItem,
): EventCategoryFormState => {
  const initI18nStringData = initI18nGenericData<string>(regionLocale);
  return eventCategoryItem
    ? {
        name: eventCategoryItem.name,
        description: eventCategoryItem.description,
        host: eventCategoryItem.host,
        accessibility: eventCategoryItem.accessibility,
        audience: eventCategoryItem.audience,
        status: eventCategoryItem.status,
      }
    : {
        name: initI18nStringData,
        description: undefined,
        host: undefined,
        accessibility: undefined,
        audience: undefined,
        status: EventCategoryStatusEnum.ACTIVE,
      };
};

export const useEventCategoryFormDialog = ({ formMode, eventCategoryItem, onClose, onRefresh }: HookProps) => {
  const dispatch = useDispatch();
  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(regionLocale, eventCategoryItem));
  const [reminderDialog, setReminderDialog] = useState<ReminderDialogState>({ open: false });

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'host',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'accessibility',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'audience',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => formState.accessibility === EventAccessibilityEnum.PUBLIC && !!!formState.audience,
    },
    {
      name: 'status',
      fieldType: ErrorFieldType.MANDATORY,
    },
    ...(() => {
      let errorFieldDef: ErrorFieldDef[] = [];
      regionLocale.forEach((locale) => {
        errorFieldDef = [
          ...errorFieldDef,
          {
            name: `name-${locale}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () => !!!formState.name[locale],
          },
        ];
      });
      return errorFieldDef;
    })(),
  ]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: CreateUpdateEventCategoryBody = {
        name: formState.name,
        description: formState.description,
        accessibility: formState.accessibility as EventAccessibilityEnum,
        audience: formState.audience as EventAudienceEnum,
        status: formState.status,
        host: formState.host,
      };
      try {
        if (formMode === FormMode.CREATE) {
          await createEventCategoryItem({ ...body, approvalRequired: false }, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Event Category saved successfully',
              },
            ]),
          );
        } else if (eventCategoryItem) {
          await modifyEventCategoryItem(eventCategoryItem._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Event Category updated successfully`,
              },
            ]),
          );
        }
        onClose();
        onRefresh();
      } catch (err) {}
    }
  };

  return {
    formState,
    reminderDialog,
    errorState,
    formDispatch,
    setReminderDialog,
    onDismissErrorHandler,
    onSubmit,
  };
};
