import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { campaignPath } from '../../../CampaignRoutes';
import { PaginateList } from 'src/app/common/types/common-types';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { CampaignTypeEnum, CampaignLeadItem } from 'src/app/modules/PulseLeads/types/campaign-types';
import { convertStatus } from '../../convertStatus-utils';
import { CircularProgress, Button, DialogContent, DialogActions, DialogTitle, Dialog } from '@mui/material';

type CampaignLeadListProps = {
  isLoading: boolean;
  campaignLeadList?: PaginateList<CampaignLeadItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
  onExport: () => Promise<any>;
};

const MAX_DIRECT_EXPORT = 2000;

const CampaignLeadList: FC<CampaignLeadListProps> = ({
  isLoading,
  campaignLeadList,
  onRefresh,
  onChangePage,
  onSort,
  onExport,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [loading, setLoadingData] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const exportLeadList = useCallback(() => {
    const reachMaxDirectExport = campaignLeadList && campaignLeadList?.totalDocs > MAX_DIRECT_EXPORT;
    setLoadingData(true);
    onExport()
      .then(() => {
        if (reachMaxDirectExport) {
          setOpenDialog(true);
        }
      })
      .finally(() => {
        setLoadingData(false);
      });
  }, [campaignLeadList]);

  return (
    <>
      <PruTable
        title={Translation('pulseleads.campaign.lead.list')}
        disableBulkSelect
        operationDef={[]}
        headerBtnDef={[
          {
            color: 'primary',
            title: loading ? <CircularProgress style={{ color: 'gray' }} size={18} /> : Translation('export.list.text'),
            disabled: loading,
            onClick: exportLeadList,
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'leadName',
            displayName: Translation('pulseleads.campaign.lead.leadName'),
            renderData: (row) =>
              (
                <a onClick={() => history.push(`${campaignPath}/lead/${row._id}`)}>
                  {row.firstName ? row.firstName + (row.lastName ? ' ' + row.lastName : '') : '-'}
                </a>
              ) || '-',
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'leadName', value: sort['leadName'] });
            },
          },

          {
            keyIndex: 'agentCode',
            displayName: Translation('pulseleads.campaign.lead.assignedAgentCode'),
            renderData: (row) => row.agentCode || '-',
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'agentCode', value: sort['agentCode'] });
            },
          },
          {
            keyIndex: 'agentName',
            displayName: Translation('pulseleads.campaign.lead.assignedAgentName'),
            renderData: (row) => row.agentName?.enUs?.displayName || row.agentName || '-',
          },
          {
            keyIndex: 'leadDate',
            displayName: Translation('pulseleads.campaign.lead.leadDate'),
            renderData: (row) => getDefaultDisplayDate(row.leadDate) || '-',
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'leadDate', value: sort['leadDate'] });
            },
          },
          {
            keyIndex: 'status',
            displayName: Translation('pulseleads.campaign.common.status'),
            renderData: (row) =>
              row.campaign?.type === CampaignTypeEnum.SERVICING && row.feedback?.name
                ? row.feedback.name
                : Translation(
                    'pulseleads.campaign.lead.' +
                      convertStatus(
                        row.agentStatus,
                        row.lastFollowUpStatus,
                        row?.isActive,
                        row?.expiredAt,
                        row?.contactExpiredAt,
                        row?.followUp,
                      ),
                  ),
          },
          {
            keyIndex: 'isActive',
            displayName: Translation('pulseleads.campaign.lead.isActive'),
            renderData: (row) => (row.isActive ? 'True' : 'False'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={campaignLeadList?.docs}
        totalPages={campaignLeadList?.totalPages}
        totalRecords={campaignLeadList?.totalDocs}
        onChangePage={onChangePage}
      />
      <Dialog open={openDialog} maxWidth="xs">
        <DialogTitle>{Translation('pulseleads.campaign.common.lead.export.dialog.title')}</DialogTitle>
        <DialogContent>{Translation('pulseleads.campaign.common.lead.export.dialog.description')}</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            {Translation('pulseleads.campaign.common.lead.export.dialog.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampaignLeadList;
