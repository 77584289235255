import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Backdrop, Button, CircularProgress } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LayoutSplashScreen } from 'src/app/layout';
import commonStyles from 'src/app/common/styles/page.module.css';
import { CreateSchedule } from '../components/create-schedule/create-schedule.component';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { useEditSchedule } from './edit-schedule.hook';
import { useStyles } from './edit-schedule.style';
import { ModulePermissionProps } from 'src/app/common/module/types';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  } & ModulePermissionProps;

export const EditSchedulePage: React.FC<PageProps> = (props: PageProps) => {
  const Translation = useTranslation();

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { disabled, data, error, loading, onSubmit } = useEditSchedule();

  return (
    <div className={`${commonStyles.container} ${styles.container}`}>
      <div className={commonStyles.headerContainer}>
        <div className={commonStyles.rowContainer}>
          <div className={commonStyles.header}>{Translation('recruitment.exam.schedule.edit')}</div>
        </div>
        <Button variant="contained" color="inherit" onClick={props.history.goBack}>
          {Translation('app.button.back')}
        </Button>
      </div>
      {!loading && !error ? (
        <CreateSchedule
          showSubmit={props.enableUpdate}
          initialValues={data}
          disabled={disabled}
          onSubmit={onSubmit}
          onCancel={() => {
            props.history.goBack();
          }}
        />
      ) : null}
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
      {/* {loading ? <LayoutSplashScreen /> : null} */}
    </div>
  );
};
