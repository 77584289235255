import React, { memo, useCallback, useState } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Dialog } from 'src/app/modules/Recruitment/components/dialog/dialog.component';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { useRequest } from 'ahooks';
import { assignApproval, fetchAssigneeList } from 'src/app/modules/Recruitment/network/approval-crud';
import { useDispatch } from 'react-redux';
import { ApplicationStatus } from 'src/app/modules/Recruitment/types/approval-types';

type ComponentProps = {
  onRefresh?: () => void;
  onClose?: () => void;
  id?: string;
  applicationFormStatus?: ApplicationStatus;
};

export const AssignDialog: React.FC<ComponentProps> = memo(
  ({ id, onRefresh, onClose, applicationFormStatus }: ComponentProps) => {
    const Translation = useTranslation();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState<string>();
    const [confirmLoading, setConfirmLoading] = useState(false);

    const { data } = useRequest(
      async () => {
        return await fetchAssigneeList(dispatch, { applicationFormStatus });
      },
      { manual: false },
    );

    const onConfirm = useCallback(async () => {
      try {
        setConfirmLoading(true);
        await assignApproval({ approverId: selected!, applicationId: id! }, dispatch);
        await onRefresh?.();
        onClose?.();
      } catch (err) {
        console.warn('assignApproval err: ', err);
      } finally {
        setConfirmLoading(false);
      }
    }, [id, selected]);

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setSelected(value);
    }, []);

    return (
      <Dialog
        open
        title={Translation('onboarding.application.approval.list.dialog.title')}
        disabledConfirmBtn={!selected}
        onClose={onClose}
        onConfirm={onConfirm}
        loading={confirmLoading}
      >
        <Box width={'358px'}>
          <FormControl>
            <RadioGroup
              aria-labelledby="approve-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={onChange}
            >
              {data?.map(({ approverId }) => (
                <FormControlLabel key={approverId} value={approverId} control={<Radio />} label={approverId} />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Dialog>
    );
  },
);
