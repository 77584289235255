import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { downloadFile, getConfigurations, useJWT } from 'src/app/common/utils';
import { RecruitmentStepProps } from '../template-detail-page.type';
import { OnboardingTemplate } from '../../../types/form-template-types';
import { ADMIN_URL } from 'src/app/common/network';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import moment from 'moment';
import { DateFormat } from 'src/app/common/constants';

export const useStep3 = ({
  type,
  pdfTemplates,
  templateId,
  version,
  setTemplate,
  setValidate,
}: RecruitmentStepProps) => {
  // i18n
  const [update, setUpdate] = useState(false);
  const t = useTranslation();
  const refLocalLanguage = useRef<string | undefined>('');
  const jwt = useJWT() || {};

  // redux
  const dispatch = useDispatch();
  // const onboardingTemplate: OnboardingTemplate[] =
  //   useSelector((state: any) => state.config.data.onboardingTemplate) || [];
  const onboardingTemplate: OnboardingTemplate[] = useSelector((state: any) =>
    get(state.config.data, 'Recruitment.onboardingTemplate', []),
  );
  // pdf list, includes en + local.
  const pdfList = useMemo(() => {
    const filteredData = onboardingTemplate.filter((item: OnboardingTemplate) => item.type === type);
    // PDF Types sample data:
    // ['APPLICATION_DECLARATION', 'APPLICANT_SELF_DECLARATION', 'APPLICATION_FORM', 'ETHICS_CONDUCT_CODE', 'COMPULSORY_GROUP_INSURANCE_SCHEME', 'DATA_PRIVACY_DECLARATION', 'OBSERVANCE_DECLARATION', 'NEW_AGENTS_PROPER_PRACTICES_GUIDELINE']
    const arrPdfTypes = get(filteredData, '[0].pdfTemplate.types', []);

    // pdfTemplate.languages array: ['en', 'zh-Hant']
    const localLanguage = get(filteredData, '[0].pdfTemplate.languages', ['en']).find(function (element) {
      return element !== 'en' || !element.includes('en');
    });
    // const localLanguage = ['en', 'zh-Hant'].find(function (element) {
    //   return element !== 'en' || !element.includes('en');
    // });
    // const localLanguage = '';
    refLocalLanguage.current = localLanguage;

    if (!isEmpty(pdfTemplates)) {
      let arrPdf: any[] = [];
      arrPdfTypes.map((typeName) => {
        const target = pdfTemplates || [];
        // english
        const indexEn = target.findIndex((pdf) => pdf.type === typeName && pdf.language === 'en');
        const itemEn = target[indexEn];
        if (itemEn) {
          arrPdf.push({ ...itemEn, status: itemEn.isValid ? 'checked' : itemEn.errorMsg ? 'error' : 'empty' });
        } else {
          arrPdf.push({ type: typeName, language: 'en' });
        }
        if (localLanguage) {
          // local language
          const indexLocal = target.findIndex((pdf) => pdf.type === typeName && pdf.language !== 'en');
          const itemLocal = target[indexLocal];
          if (itemLocal) {
            arrPdf.push({
              ...itemLocal,
              status: itemLocal.isValid ? 'checked' : itemLocal.errorMsg ? 'error' : 'empty',
            });
          } else {
            arrPdf.push({ type: typeName, language: localLanguage });
          }
        }
      });
      return arrPdf;
    } else {
      const defaultList = arrPdfTypes.flatMap((typeName) => {
        if (localLanguage) {
          // array map to en + local
          return [
            { type: typeName, language: 'en' },
            { type: typeName, language: localLanguage },
          ];
        }
        return [{ type: typeName, language: 'en' }];
      });

      return defaultList;
    }
    // return arrPdfTypes;
  }, [onboardingTemplate, pdfTemplates, type]);

  useEffect(() => {
    if (isEmpty(pdfList)) {
      return;
    }
    // some pdf is not-been-uploaded
    const pendingUpload = pdfList.some((item) => !item.blobId);
    if (pendingUpload) {
      setValidate(false);
    } else {
      setValidate(true);
    }
  }, [pdfList, update]);

  const downloadKeys = useCallback(() => {
    const url = `${ADMIN_URL}/form-template/download-survey-keys?templateId=${templateId}`;
    // e.g. takefulApplicationForm_2024042014000001.csv
    const fileName = `${type.toLowerCase()}ApplicationForm_${version}.csv`;
    downloadFile(fileName, url).then(() => {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: t('global.submit.success'),
            content: t('global.download.success'),
          },
        ]),
      );
    });
  }, [templateId]);

  const onUploadChange = useCallback(
    (file: any, index: number) => {
      // when delete file: file=null, only keep type prop
      const targetFile = file ? pdfList[index] : { ...pdfList[index], id: '', blobId: '' };
      pdfList[index] = {
        ...targetFile,
        ...file,
        errorMsg: '',
        isValid: null,
        status: 'empty',
        // current time
        createdAt: moment.utc(new Date()).local().utc().format(DateFormat.utcTime),
        createdBy: jwt.username,
      };

      setTemplate({ pdfTemplates: pdfList });
      setUpdate((value) => !value);

      // const index = pdfList.findIndex((pdf) => {
      //   pdf.type === pdfType && pdf.language === language;
      // });
      // if (index !== -1) {
      //   pdfList[index] = { ...pdfList[index], ...file };
      //   // const newFile = { ...pdfList[index], ...file };
      //   setTemplate({ pdfTemplates: pdfList });
      // }
    },
    [pdfList],
  );

  const onDownloadInFormChange = useCallback(
    (downloadInForm: boolean, index: number) => {
      const targetFile = { ...pdfList[index], downloadInForm: downloadInForm };
      pdfList[index] = {
        ...targetFile,
      };
      setTemplate({ pdfTemplates: pdfList });
    },
    [pdfList],
  );

  return {
    pdfList,
    localLanguage: refLocalLanguage.current,
    downloadKeys,
    onUploadChange,
    onDownloadInFormChange,
  };
};
