import { fetchApprovalDetail } from 'src/app/modules/Recruitment/network/approval-crud';
import { expendAllElements, findAnswers, findSpecificFields, formatData, hasFile } from './surveyjs.util';
import { ApprovalDetail } from 'src/app/modules/Recruitment/types/approval-types';

export const getApprovalDetail = async (id: string, dispatch?: any): Promise<[ApprovalDetail, any, boolean]> => {
  const res = await fetchApprovalDetail(id, dispatch);

  const { modules, ...rest } = res;
  const content = res?.content ?? { pages: [] };
  const answers = findAnswers(modules);
  const specificFields = findSpecificFields(content.pages);
  const needQueryFile = hasFile(specificFields, answers);
  const formattedData = await formatData(specificFields, answers, {
    applicationId: res.applicationId,
    content,
    firstname: res.firstname,
    lastname: res.lastname,
  });

  expendAllElements(content.pages);

  return [
    {
      ...rest,
      modules: formattedData,
      // questionsOnPageMode: show all question in one page
      content: { ...content, questionsOnPageMode: 'singlePage', showQuestionNumbers: 'off' },
    } as ApprovalDetail,
    specificFields,
    needQueryFile,
  ];
};
