import React, { useRef, useState, useEffect } from 'react';
import { TextField, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface TagListProps {
  onChange?: (value: string[]) => void;
  value?: string[];
  disabled: boolean;
  btnTxt: string;
  sameTag?: string;
}

const EditableTagGroup: React.FC<TagListProps> = (props) => {
  const { disabled, value, btnTxt } = props
  const input = useRef<any>();
  const [ inputVisible, setInputVisible ] = useState<boolean>(false);
  const [ inputValue, setInputValue ] = useState<string>('');

  const triggerChange = (changedValue: string[]) => {
    const { onChange } = props
    if (onChange) {
      onChange([...changedValue]);
    }
  };

  const handleClose = (removedTag: string) => {
    const { value = [] } = props
    const tags = value.filter((tag) => tag !== removedTag);
    triggerChange(tags);
  };

  const showInput = () => {
    setInputVisible(true)
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  };

  const handleInputConfirm = () => {
    let tags = props.value || []
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    setInputVisible(false)
    setInputValue('')
    triggerChange(tags);
  };

  const forMap = (tag: string) => {
    const { disabled } = props
    const tagElem = (
      <Chip
        label={tag}
        disabled={disabled}
        onDelete={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.preventDefault();
          handleClose(tag);
        }}
        // deleteIcon={<DoneIcon />}
        variant="outlined"
      />
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  useEffect(() => {
    inputVisible && input.current.focus()
  },[inputVisible])

  return (
    <div>
        {(value || []).map(forMap)}
        {inputVisible && (
          <TextField
            inputRef={input}
            disabled={disabled}
            margin="dense"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
          />
        )}
        {!inputVisible && !disabled && (
          <Chip
            icon={<AddIcon />}
            label={btnTxt}
            onClick={showInput}
            variant="outlined"
          />
        )}
      {disabled && !(value || []).length && '--'}
    </div>
  );
}

export default EditableTagGroup;
