import React, { memo } from 'react';
import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material';
import { useExamType } from './exam-type.hook';
import { withStyles } from 'tss-react/mui';

interface ComponentProps extends RadioGroupProps {
  disabled?: boolean;
}

const StyledRadioGroup = withStyles(RadioGroup, {
  root: {
    flexDirection: 'row',
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: '15px',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
});

export const ExamType: React.FC<ComponentProps> = memo(({ disabled, ...rest }: ComponentProps) => {
  // custom-hook
  const { data } = useExamType();
  return (
    <>
      <StyledRadioGroup {...rest}>
        {data
          ? data.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio disabled={disabled} sx={{ marginRight: '12px' }} />}
                label={label}
              />
            ))
          : null}
      </StyledRadioGroup>
    </>
  );
});
