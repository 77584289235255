import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PaginateList } from 'src/app/common/types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { LeadGoalItem } from '../lead-goal-listing-page.hook';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { TranslationWithParams } from 'src/app/i18n';
import dayjs, { Dayjs } from 'dayjs';

type LeadGoalListProps = {
  isLoading: boolean;
  leadGoalList?: PaginateList<LeadGoalItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
  onAddClick: () => void;
  onEditClick: (row: LeadGoalItem) => void;
};

export const LeadGoalList: FC<LeadGoalListProps> = ({
  isLoading,
  leadGoalList,
  onRefresh,
  onChangePage,
  onSort,
  onAddClick,
  onEditClick,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <PruTable
      title={Translation('pulseleads.activityPoint.leadGoal.title')}
      disableBulkSelect
      headerBtnDef={[
        {
          color: 'secondary',
          title: Translation('app.button.add'),
          onClick: onAddClick,
        },
      ]}
      operationDef={[
        {
          title: Translation('section.common.operation.edit'),
          tooltipText: 'Edit',
          onClick: (row) => {
            onEditClick(row);
          },
        },
      ]}
      columnDef={[
        {
          keyIndex: '_id',
          displayName: Translation('pulseleads.activityPoint.leadGoal.goal'),

          renderData: (row: any) => {
            return TranslationWithParams('pulseleads.activityPoint.leadGoal.goalName', {
              points: row.targetPoint,
              days: row.timeRange.days,
            });
          },
        },
        {
          keyIndex: 'updatedAt',
          displayName: Translation('pulseleads.activityPoint.leadGoal.status'),

          renderData: (row: any) => {
            const today = dayjs();
            const targetDate = dayjs(row.updatedAt);
            const isToday = today.isSame(targetDate, 'day');
            return isToday
              ? Translation('pulseleads.activityPoint.leadGoal.status.inactive')
              : Translation('pulseleads.activityPoint.leadGoal.status.active');
          },
        },
        {
          keyIndex: 'updatedBy',
          displayName: Translation('pulseleads.activityPoint.leadGoal.lastUpdatedBy'),

          renderData: (row: any) => {
            return row.updatedBy ?? '';
          },
        },
        {
          keyIndex: 'updatedAt',
          displayName: Translation('pulseleads.activityPoint.leadGoal.lastUpdatedAt'),

          renderData: (row: any) => {
            return getDefaultDisplayDate(new Date(row.updatedAt), 'datetime');
          },
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={leadGoalList?.docs}
      totalPages={leadGoalList?.totalPages}
      totalRecords={leadGoalList?.totalDocs}
      onChangePage={onChangePage}
      disablePagination
    />
  );
};
