import { FC, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { IncentiveFormMode, IncentiveDetail } from '../../../types/incentive-types';
import { fetchIncentiveItem } from '../../../network/incentiveCrud';
import IncentiveSetForm from './components/IncentiveSetForm';

const IncentiveSetDetailPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { code } = useParams<{ code: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: code ? IncentiveFormMode.EDIT : IncentiveFormMode.CREATE,
    }),
    [code],
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [incentiveSetItem, setIncentiveSetItem] = useState<IncentiveDetail>();

  const reloadData = async () => {
    if (code) {
      setIsLoading(true);
      try {
        const res = await fetchIncentiveItem(code, dispatch);
        setIncentiveSetItem(res);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    reloadData();
  }, [code]);

  return (
    <>
      {formMode === IncentiveFormMode.CREATE && <IncentiveSetForm formMode={formMode} />}
      {formMode === IncentiveFormMode.EDIT &&
        (isLoading ? (
          <LayoutSplashScreen />
        ) : (
          <IncentiveSetForm code={code} formMode={formMode} incentiveSetItem={incentiveSetItem} />
        ))}
    </>
  );
};

export default IncentiveSetDetailPage;
