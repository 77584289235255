import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getConfigurations, useDataProvider } from 'src/app/common/utils';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { RegistrationListParam, RegistrationItem, PaginateList } from '../../../types/license-exam-types';
import { fetchRegistrationList } from '../../../network/license-exam-crud';
import moment from 'moment';
import { useLang } from 'src/app/i18n';
import { get } from 'lodash';

const initialState: RegistrationListParam = {
  examCodes: '',
  examType: '',
  examDateFrom: '',
  examDateTo: '',
  examCentre: '',
  examName: '',
  registrationApprovalStates: [],
  orNumbers: '',
  registrationIds: '',
  applicantIds: '',
  firstnames: '',
  lastnames: '',
  result: '',
  page: 1,
  limit: 20,
};

export const registrationFilterKeys = [
  'examCodes',
  'examType',
  'examName',
  'examDateFrom',
  'examDateTo',
  'examCentre',
  'registrationApprovalStates',
  'orNumbers',
  'registrationIds',
  'applicantIds',
  'firstnames',
  'lastnames',
  'result',
];

const paramsInitiator = (initialParams?: Record<string, any>): RegistrationListParam => {
  return initialParams
    ? {
        examCodes: initialParams.examCodes,
        examType: initialParams.examType,
        examName: initialParams.examName,
        examDateFrom: initialParams.examDateFrom,
        examDateTo: initialParams.examDateTo,
        examCentre: initialParams.examCentre,
        registrationApprovalStates: initialParams.registrationApprovalStates?.split(','),
        orNumbers: initialParams.orNumbers,
        registrationIds: initialParams.registrationIds,
        applicantIds: initialParams.applicantIds,
        firstnames: initialParams.firstnames,
        lastnames: initialParams.lastnames,
        result: initialParams.result,
        page: 1,
        limit: 20,
      }
    : initialState;
};

export const useRegistrationListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const dispatch = useDispatch();
  const locale = useLang();

  const [filterState, setFilterState] = useState<any>(paramsInitiator(initialParams));
  const [registrationList, setRegistrationList] = useState<PaginateList<RegistrationItem>>();

  // get runtime-config
  const enableApproval: boolean = useMemo(() => {
    const config = getConfigurations();
    const enableApproval = get(config, 'Recruitment.enableExamApproval', false);
    return enableApproval;
  }, []);

  const { isLoading, refreshData } = useDataProvider<PaginateList<RegistrationItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchRegistrationList(
          {
            ...filterState,
            examDateFrom: filterState.examDateFrom ? moment(filterState.examDateFrom).format('YYYY-MM-DD') : undefined,
            examDateTo: filterState.examDateTo ? moment(filterState.examDateTo).format('YYYY-MM-DD') : undefined,
            examName: filterState.examName as string,
            lang: locale ?? 'en',
          },
          dispatch,
        );
      } catch (err) {}
    },
    setRegistrationList,
    false,
  );

  const onSort = (newSortState: { key: string; value?: string }) => {
    setFilterState({
      ...filterState,
      sortBy: newSortState.value ? newSortState.key : undefined,
      orderBy: newSortState.value,
    });
    refreshData();
  };

  return {
    filterState,
    registrationList,
    isLoading,
    enableApproval,
    setFilterState,
    refreshData,
    onSort,
  };
};
