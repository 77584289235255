/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import {
  Button,
  TableContainer,
  TableFooter,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  LinearProgress,
  IconButton,
} from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { useIntl } from 'react-intl';
import { PruForm, PruFormItemType } from 'src/app/common/components/PruForm';
import PruTablePaginationActions from 'src/app/common/components/Table/PruTablePaginationActions';
import { TimeRangeOptsType, TimeRangeOpts as TimeRangeOptsOrigin, NoPruDNAResultType } from '../constants';
import {
  REPORT_RECRUITMENT_PRUDNA_MASS_DETAIL_PATH,
  REPORT_RECRUITMENT_PATH,
  REPORT_BASE_PATH,
} from '../../../constants';
import { useLang } from 'src/app/i18n';
import moment from 'moment-timezone';
import { getDayEnd, getDayStart } from 'src/app/common/utils';
import apis from '../apis';
import { styles } from '../styles';
import { LoadingButton } from '@mui/lab';

const MassPRUdnaSummaryReport: FC<void> = () => {
  const formRef = useRef<any>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const e = (s: string) => {
    return Translation('report.recruitment.summary.' + s.replace(/ /g, '_').toLowerCase());
  };
  const { classes } = styles();
  const history = useHistory();
  const langOrigin = useLang();
  const lang = langOrigin;
  const currentYear = moment().year();
  const ytdTime = moment(`${currentYear}-01-01`).startOf('day').valueOf();
  const filterInitData = {
    timeRange: [new Date(ytdTime), getDayEnd(new Date())],
  };

  const [designationOpts, setDesignationOpts] = useState<{ label: string; value: any }[]>([]);
  const [filterOpts, setFilterOpts] = useState<any>({});
  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  const [tmpFilterData, setTempFilterData] = useState<{ [key: string]: any }>(filterInitData);
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const pruDNAAssessmentTypeOpts = useMemo(() => {
    const opts = [{ value: '', label: 'All' }];
    return opts.concat([
      {
        value: 'Screen',
        label: 'Screen',
      },
      {
        value: 'Pop',
        label: 'Pop',
      },
      {
        value: 'Express',
        label: 'Express',
      },
    ]);
  }, []);
  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    page: 0,
    pageSize: 20,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [isDownLoading, setIsDownloading] = useState<boolean>(false);
  const [open, setOpen] = useState<Record<number, boolean>>({});

  useEffect(() => {
    const aggregateFields: string[] = [];
    if (window.envConfig['ENABLE_ZONE'] === 'true') {
      aggregateFields.push('zone');
    }
    if (window.envConfig['ENABLE_BRANCH'] === 'true') {
      aggregateFields.push('branch');
    }
    if (window.envConfig['ENABLE_UNIT'] === 'true') {
      aggregateFields.push('unit');
    }
    if (aggregateFields.length) {
      apis.getAggregateFields({ paths: aggregateFields }).then((res: any) => {
        const aggregateFieldData = res.data || [];
        const _filterOpts: any = {};
        aggregateFields.forEach((path) => {
          _filterOpts[path] = [{ value: '', label: 'All' }].concat(
            (aggregateFieldData[path] ?? [])
              .filter((val: string) => val)
              .sort()
              .map((val: string) => ({ value: val, label: val as unknown as string })),
          );
        });
        setFilterOpts(_filterOpts);
      });
    }
    if (window.envConfig['ENABLE_DESIGNATION'] === 'true') {
      apis.getDesignation().then((res: any) => {
        const des = res.data || [];
        const opts = [{ value: '', label: 'All' }];
        setDesignationOpts(
          opts.concat(
            des.map((d: any) => {
              return { label: d.shortName, value: d.code };
            }),
          ),
        );
      });
    }
    moment.tz.guess(true); // clear cache

    fetchData({ ...filterInitData, page: 0 });
  }, []);

  const TimeRangeOpts = TimeRangeOptsOrigin.map((opt) => {
    return {
      value: opt.value,
      label: e(opt.label),
    };
  });
  const changeFilterData = (d: object) => {
    setTempFilterData({ ...tmpFilterData, ...d });
  };
  const onTimeTypeChange = (v: any) => {
    const currentYear = moment().year();
    const currentMonth = moment().month() + 1; // month() is zero-indexed

    const ytdTime = moment(`${currentYear}-01-01`).startOf('day').valueOf();
    const currentMonthTime = moment().startOf('month').valueOf();
    const lastMonthTime = moment().subtract(1, 'months').startOf('month').valueOf();

    switch (parseInt(v)) {
      case TimeRangeOptsType.YTD:
        changeFilterData({
          timeRangeType: v,
          timeRange: [new Date(ytdTime), getDayEnd(new Date())],
        });
        break;
      case TimeRangeOptsType.LastMonth:
        changeFilterData({
          timeRangeType: v,
          timeRange: [new Date(lastMonthTime), new Date(currentMonthTime)],
        });
        break;
      case TimeRangeOptsType.CurrentMonth:
        changeFilterData({
          timeRangeType: v,
          timeRange: [new Date(currentMonthTime), getDayEnd(new Date())],
        });
        break;
      case TimeRangeOptsType.CustomizedTimeRange:
        changeFilterData({
          timeRangeType: v,
          timeRange: [null, null],
        });
        break;
      default:
        return;
    }
  };

  const { timeRangeType } = tmpFilterData;
  const filterConfig = () => {
    return [
      [
        { type: 'header', label: e('Report Filter'), sm: true },
        {
          type: 'custom',
          sm: false,
          render: () => (
            <Button variant="contained" color="inherit" onClick={onReset}>
              {Translation('golbal.filters.reset')}
            </Button>
          ),
        },
        {
          type: 'custom',
          sm: false,
          render: () => (
            <Button variant="contained" color="secondary" onClick={onSearch}>
              {Translation('golbal.filters.search')}
            </Button>
          ),
        },
      ],
      [
        {
          type: 'select',
          label: e('Time Range'),
          opts: TimeRangeOpts,
          prop: 'timeRangeType',
          width: 400,
          onChange: onTimeTypeChange,
        },
        // { type: 'dateRange', label: 'Time Range', styles: {visibility:'hidden'}, prop: 'timeRange', }
        {
          type: timeRangeType === TimeRangeOptsType.CustomizedTimeRange + '' ? 'dateRange' : 'hide',
          label: '',
          prop: 'timeRange',
          width: 400,
          rules: [
            {
              type: 'array',
              len: 2,
              fields: {
                0: { type: 'date', required: true, message: e('Please input the time range') },
                1: { type: 'date', required: true, message: e('Please input the time range') },
              },
            },
          ],
        },
      ],
      [
        { type: 'input', label: e('Agent Name'), prop: 'agentName', width: 400 },
        { type: 'input', label: e('Agent Code'), prop: 'agentCode', width: 400 },
        {
          type: window.envConfig['ENABLE_DIVISION'] === 'true' ? 'input' : 'none',
          label: e('Division'),
          prop: 'division',
          width: 400,
        },
      ],
      [
        {
          type: window.envConfig['ENABLE_AGENCY_CODE'] === 'true' ? 'input' : 'none',
          label: e('Agency Code'),
          prop: 'agencyCode',
          width: 400,
        },
        {
          type: window.envConfig['ENABLE_ZONE'] === 'true' ? PruFormItemType.select : 'none',
          label: e('Zone'),
          prop: 'zone',
          opts: filterOpts.zone ?? [],
          width: 400,
        },
        {
          type: window.envConfig['ENABLE_DESIGNATION'] === 'true' ? PruFormItemType.select : 'none',
          label: e('Designation'),
          prop: 'designation',
          opts: designationOpts,
          width: 400,
        },
        {
          type: window.envConfig['ENABLE_BRANCH'] === 'true' ? PruFormItemType.autoComplete : 'none',
          label: Translation('Common.agent_branch'),
          prop: 'branch',
          opts: filterOpts.branch ?? [],
          width: 400,
        },
        {
          type: window.envConfig['ENABLE_UNIT'] === 'true' ? PruFormItemType.autoComplete : 'none',
          label: Translation('Common.agent_unit'),
          prop: 'unit',
          opts: filterOpts.unit ?? [],
          width: 400,
        },
        {
          type: 'select',
          label: Translation('Recruitment.pru_dna_assessment_type'),
          prop: 'pruDNAAssessmentType',
          opts: pruDNAAssessmentTypeOpts,
          width: 400,
        },
      ],
    ];
  };
  const tableHeader = () => [
    [
      { type: 'header', label: Translation('title.report.recruitment.mass.prudnaSummary'), sm: true },
      {
        type: 'custom',
        sm: false,
        render: () => (
          <LoadingButton loading={isDownLoading} variant="contained" color="secondary" onClick={onExport}>
            {Translation('export.list.text')}
          </LoadingButton>
        ),
      },
    ],
  ];

  interface tableConfigType {
    title: string;
    prop: string;
    align?: any;
    render?: any;
    minWidth?: number;
  }
  const tableConfig = useCallback((): tableConfigType[] => {
    const config = [
      {
        title: '',
        prop: 'collapseIcon',
        minWidth: 50,
        render: (row: any, index: number) => {
          if (filterData.pruDNAAssessmentType) {
            return <div className="tw-h-8" />;
          }
          return (
            index >= 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpen({
                    ...open,
                    [index]: !open[index],
                  });
                }}
              >
                {open[index] ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxOutlinedIcon />}
              </IconButton>
            )
          );
        },
      },
      { title: e('Agent Code'), prop: 'agentCode' },
      {
        title: e('Agent Name'),
        prop: 'agentName',
      },
      {
        title: e('Designation'),
        prop: 'designation',
        render: (row: any) => {
          const disignation = designationOpts.find((d: any) => {
            return d.value === row.designation;
          });
          return <span>{disignation?.label ?? '-'} </span>;
        },
      },
      { title: e('Agency Code'), prop: 'agencyCode' },
      { title: e('Division'), prop: 'division' },
      { title: e('Zone'), prop: 'zone' },
      { title: Translation('Common.agent_branch'), prop: 'branch' },
      { title: Translation('Common.agent_unit'), prop: 'unit' },
      {
        title: Translation('Recruitment.number_of_pending_candidates_converted_from_mass'),
        prop: 'noOfConvertedPendingCandidate',
        render: (row: any) => {
          const num = row.noOfConvertedPendingCandidate;
          return num ? (
            num === '0' ? (
              num
            ) : (
              <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.Invited)}>
                {' '}
                {num}
              </a>
            )
          ) : (
            num ?? '-'
          );
        },
      },
      {
        title: Translation('Recruitment.number_of_candidates_converted_from_mass'),
        prop: 'noOfConvertedCandidate',
        render: (row: any) => {
          const num = row.noOfConvertedCandidate;
          return num ? (
            num === '0' ? (
              num
            ) : (
              <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.Accepted)}>
                {' '}
                {num}
              </a>
            )
          ) : (
            num ?? '-'
          );
        },
      },
      {
        title: Translation('Recruitment.pru_dna_assessment_type'),
        prop: 'pruDNAAssessmentType',
        render: (row: any) => {
          const pruDNAAssessmentType = row.pruDNAAssessmentType;
          return <span>{pruDNAAssessmentType} </span>;
        },
      },
      {
        title: Translation('Recruitment.number_of_prudna_invitations'),
        prop: 'noOfPruDNAInvitations',
        render: (row: any) => {
          const num = row.noOfPruDNAInvitations;
          return num ? (
            num === '0' ? (
              num
            ) : (
              <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.Invitations)}>
                {' '}
                {num}
              </a>
            )
          ) : (
            num ?? '-'
          );
        },
      },
      {
        title: Translation('Recruitment.number_of_prudna_assessment_started'),
        prop: 'noOfPruDNAStarted',
        render: (row: any) => {
          const num = row.noOfPruDNAStarted;
          return num ? (
            num === '0' ? (
              num
            ) : (
              <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.StartedInvitations)}>
                {' '}
                {num}
              </a>
            )
          ) : (
            num ?? '-'
          );
        },
      },
      {
        title: Translation('Recruitment.number_of_prudna_assessment_completed'),
        prop: 'noOfPruDNACompleted',
        render: (row: any) => {
          const num = row.noOfPruDNACompleted;
          return num ? (
            num === '0' ? (
              num
            ) : (
              <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.Completed)}>
                {' '}
                {num}
              </a>
            )
          ) : (
            num ?? '-'
          );
        },
      },
      {
        title: Translation('Recruitment.number_of_prudna_report_proceed'),
        prop: 'noOfProceed',
        render: (row: any) => {
          const num = row.noOfProceed;
          return num ? (
            num === '0' ? (
              num
            ) : (
              <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.Proceed)}>
                {' '}
                {num}
              </a>
            )
          ) : (
            num ?? '-'
          );
        },
      },
      {
        title: Translation('Recruitment.number_of_prudna_report_proceed_with_caution'),
        prop: 'noOfProceedWithCaution',
        render: (row: any) => {
          const num = row.noOfProceedWithCaution;
          return num ? (
            num === '0' ? (
              num
            ) : (
              <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.ProceedCaution)}>
                {' '}
                {num}
              </a>
            )
          ) : (
            num ?? '-'
          );
        },
      },
      {
        title: Translation('Recruitment.number_of_prudna_report_reconsider'),
        prop: 'noOfProceedWithExtremeCaution',
        render: (row: any) => {
          const num = row.noOfProceedWithExtremeCaution;
          return num ? (
            num === '0' ? (
              num
            ) : (
              <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.ProceedExtremeCaution)}>
                {' '}
                {num}
              </a>
            )
          ) : (
            num ?? '-'
          );
        },
      },
    ];
    if (window.envConfig['ENABLE_DESIGNATION'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'designation'),
        1,
      );
    }
    if (window.envConfig['ENABLE_ZONE'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'zone'),
        1,
      );
    }
    if (window.envConfig['ENABLE_AGENCY_CODE'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'agencyCode'),
        1,
      );
    }
    if (window.envConfig['ENABLE_DIVISION'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'division'),
        1,
      );
    }
    if (window.envConfig['ENABLE_BRANCH'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'branch'),
        1,
      );
    }
    if (window.envConfig['ENABLE_UNIT'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'unit'),
        1,
      );
    }

    return config;
  }, [filterData, open, designationOpts]);
  const renderedTableBody = useMemo(() => {
    return (
      <>
        {isLoading && (
          <TableRow>
            <TableCell style={{ padding: 0 }} colSpan={12} align="center">
              <LinearProgress color="secondary" variant="indeterminate" />
            </TableCell>
          </TableRow>
        )}
        {tableData.length === 0 && (
          <TableRow>
            <TableCell colSpan={12} align="center">
              <div style={{ margin: '30px 0 30px 0 ' }}>No record to display</div>
            </TableCell>
          </TableRow>
        )}
        {tableData.map((data, index) => {
          let rows = [];
          rows.push(
            <TableRow>
              {tableConfig().map((config) => (
                <TableCell>{config.render ? config.render(data, index) : data[config.prop]}</TableCell>
              ))}
            </TableRow>,
          );
          if (data.pruDNAMetricsOfEachType && open[index]) {
            rows = rows.concat(
              data.pruDNAMetricsOfEachType.map((item: { [key: string]: any }) => (
                <TableRow>
                  {tableConfig().map((config) => (
                    <TableCell>
                      {config.render ? config.render({ ...item, parent: data }, -1) : item[config.prop] ?? '-'}
                    </TableCell>
                  ))}
                </TableRow>
              )),
            );
          }
          return rows;
        })}
      </>
    );
  }, [tableData, open, isLoading]);

  const mapToApi = (params: any) => {
    params.size = params.pageSize;
    params.page = params.page + 1 || undefined;
    params.timeZone = moment.tz.guess();
    params.lan = lang;
    delete params.pageSize;

    params.timeRangeStart = params.timeRange && params.timeRange[0];
    params.timeRangeEnd = params.timeRange && params.timeRange[1];
    if (parseInt(params.timeRangeType) === TimeRangeOptsType.CustomizedTimeRange) {
      if (params.timeRangeEnd) {
        params.timeRangeEnd = getDayEnd(params.timeRangeEnd);
      }
      if (params.timeRangeStart) {
        params.timeRangeStart = getDayStart(params.timeRangeStart);
      }
    }
    delete params.timeRange;
    delete params.timeRangeType;
  };

  const formValidate = useCallback(() => {
    const { validate } = formRef!.current;
    return validate && validate();
  }, []);
  const fetchData = useCallback(
    async (newState?: any) => {
      formValidate()
        .then((errs: any) => {
          if (!errs) {
            const requestParams = newState || {};
            mapToApi(requestParams);
            setIsLoading(true);
            apis
              .getMassSummaryReport(requestParams)
              .then((res: any) => {
                setTableData(
                  res.data
                    ? res.data.content?.map((item: any) => {
                        return {
                          ...item,
                          ...(newState.pruDNAAssessmentType
                            ? {
                                pruDNAAssessmentType: newState.pruDNAAssessmentType,
                                pruDNAMetricsOfEachType: undefined,
                              }
                            : {}),
                        };
                      })
                    : [],
                );
                setPageData({
                  page: res.data?.number || 0,
                  pageSize: res.data?.size || 0,
                  total: res.data?.totalElements || 0,
                });
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
              });
          }
        })
        .catch((errs: any) => {
          console.error(errs);
        });
    },
    [formValidate],
  );

  const onClickReport = (row: any, key: any) => {
    // const win = window.open("/report/prudna/detail", "_blank");
    // win?.focus();
    history.push(`${REPORT_BASE_PATH}${REPORT_RECRUITMENT_PATH}${REPORT_RECRUITMENT_PRUDNA_MASS_DETAIL_PATH}`, {
      key,
      row,
      timeRange: filterData.timeRange,
      timeRangeType: filterData.timeRangeType,
    });
  };
  const onExport = async () => {
    try {
      const conditions = { ...filterData, lan: lang };
      mapToApi(conditions);
      setIsDownloading(true);
      apis.getMassSummaryReportExcel(conditions).then((res) => {
        const blob = new Blob([res.data || res], { type: res.data.type });
        const fileName = `PRUdna_Mass_Sharing_Summary_Report_${new Date().toLocaleDateString()}.xlsx`;
        if ('msSaveOrOpenBlob' in navigator) {
          // @ts-ignore
          window.navigator.msSaveOrOpenBlob(blob, fileName);
          setIsDownloading(false);
          return;
        }
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        if ('download' in document.createElement('a')) {
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // 对不支持download进行兼容
          link.target = '_blank';
          link.click();
        }
        URL.revokeObjectURL(url);
        setIsDownloading(false);
      });
    } catch (error) {
      setIsDownloading(false);
    }
  };

  const onReset = () => {
    setTempFilterData(filterInitData);
  };

  const onSearch = useCallback(() => {
    const newPageData = {
      ...pageData,
      page: 0,
    };
    setPageData(newPageData);
    setFilterData(tmpFilterData);
    fetchData({ ...tmpFilterData, ...newPageData });
  }, [tmpFilterData, pageData, fetchData]);

  const keyPressSearch = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [tmpFilterData]);

  const onChangePage = (page: number) => {
    const newPageData = {
      ...pageData,
      page,
    };
    setPageData(newPageData);
    fetchData({ ...filterData, ...newPageData });
  };
  const onChangePageRows = (rows: any) => {
    const newPageData = {
      ...pageData,
      page: 0,
      pageSize: rows,
    };
    setPageData(newPageData);
    fetchData({ ...filterData, ...newPageData });
  };

  const PruTableHeader = withStyles(TableCell, (theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  return (
    <>
      <PruForm
        ref={formRef}
        config={filterConfig()}
        data={tmpFilterData}
        onChange={setTempFilterData}
        space={20}
        style={{ padding: 20 }}
      ></PruForm>

      <PruForm
        config={tableHeader()}
        data={[]}
        onChange={() => {}}
        space={10}
        style={{ padding: '15px 20px', marginTop: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
      ></PruForm>
      <TableContainer
        component={Paper}
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, maxHeight: 'calc(100vh - 520px)' }}
      >
        <Table stickyHeader className={classes.table} size="small">
          <TableHead>
            <TableRow>
              {tableConfig().map((item) => (
                <PruTableHeader key={item.prop} align={item.align || 'left'} style={{ minWidth: item.minWidth || 140 }}>
                  {item.title}
                </PruTableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderedTableBody}</TableBody>
        </Table>
      </TableContainer>
      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          <TablePagination
            align="right"
            rowsPerPageOptions={[5, 10, 20, 50]}
            colSpan={12}
            component="div"
            count={pageData.total || 0}
            rowsPerPage={pageData.pageSize}
            page={pageData.page || 0}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={(e, page) => onChangePage(page)}
            onRowsPerPageChange={(e) => onChangePageRows(e.target.value)}
            ActionsComponent={PruTablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};

export default MassPRUdnaSummaryReport;
