import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    // borderRadius: 5,
    // margin: theme.spacing(1),
    fontWeight: 700,
    fontSize: '12px',
    marginBottom: '12px',
  },
  // error: {
  //   backgroundColor: 'red',
  // },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 20,
  },
  mt30: {
    marginTop: '30px',
  },
}));
