import React, { FC } from 'react';
import ResourceLibraryCreate from '../../../components/Library/pages/Create';
import { SALESKIT_BASE_PATH, SALES_MATERIAL_BASE_PATH, SALES_LIBRARY_BASE_PATH } from '../../../constants';

export const SystemConfig =  {
  blockName: 'component.formTitle.sales-material',
  blockType:  'salesMaterial',
  libraryBasePath: `${SALESKIT_BASE_PATH}${SALES_MATERIAL_BASE_PATH}${SALES_LIBRARY_BASE_PATH}`,
  categoryType: 'salesMaterialCategory',
  columnType: 'salesMaterialColumn',
  addSuccessText: 'global.submit.success',
  columnModuleType: 'productionInfo',
  moduleType: 'Sales'
}

const SalesLibraryCreatePage: FC<any> = (props) => {
  return (
    <ResourceLibraryCreate { ...props } { ...SystemConfig } />
  )
};

export default SalesLibraryCreatePage;
