import React, { FC, useReducer, useMemo } from 'react';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ErrorFieldType, useErrorHandler, ErrorFieldDef, useUpdateEffect, preFillZeroList } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { downloadAgentListTemplate } from 'src/app/common/network';
import { map, forEach } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import {
  EligibilityRuleFormMode,
  EligibilityRule,
  EligibilityRuleOperator,
  EligibilityRuleNewAgent,
  EligibilityAgentType,
  EligibilityRuleType,
} from 'src/app/modules/PulseLeads/types/eligibility-rule-types';
import {
  CreateEligibilityRuleBody,
  UpdateEligibilityRuleBody,
  modifyEligibilityRule,
  createEligibilityRule,
} from 'src/app/modules/PulseLeads/network/eligibilityRuleCrud';
import { eligibilityRulePath } from '../../EligibilityRuleRoutes';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import { RuleOperator } from 'src/app/modules/PulseLeads/enum/rule-enum';
import FormTable, { FormTableItemType } from 'src/app/common/components/FormTable';
import { NO_ROW_ERROR_TEXT } from 'src/app/modules/PulseLeads/constants';
import { Info } from '@mui/icons-material';
import { LeadGoalItem } from '../../../ActivityPoint/lead-goal/list/lead-goal-listing-page.hook';
import { TranslationWithParams } from 'src/app/i18n';

type EligibilityRuleFormProps = {
  formMode: EligibilityRuleFormMode;
  eligibilityRule?: EligibilityRule;
  leadGoal?: LeadGoalItem[];
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 220,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: 220,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: 220,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type EligibilityRuleNewAgentState = {
  name: string;
  startDaySinceHired: string;
  endDaySinceHired: string;
  targetWeeklyLeads: string;
  priority: string;
};

type EligibilityRuleFormState = {
  name?: string;
  description?: string;
  agentType: string;
  PRULeadsTraining: boolean;
  newAgentRules?: EligibilityRuleNewAgentState[];
  rules: EligibilityRuleOperator[];
  listOfAgents?: string[];
  goalId?: string;
};

const initialState: EligibilityRuleFormState = {
  name: undefined,
  description: undefined,
  agentType: EligibilityAgentType.PARTIAL_AGENTS,
  PRULeadsTraining: false,
  newAgentRules: undefined,
  rules: map(EligibilityRuleType, (type) => ({
    type,
    operator: RuleOperator.EXCLUDE,
  })),
  listOfAgents: undefined,
  goalId: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof EligibilityRuleFormState;
    value: any;
  };
};

type ModifyRuleItemAction = {
  type: 'MODIFY_RULE_ITEM';
  payload: {
    field: keyof EligibilityRuleOperator;
    index: number;
    value: any;
  };
};

type AddNewAgentRule = {
  type: 'ADD_NEW_AGENT_RULE';
};

type ModifyNewAgentRule = {
  type: 'MODIFY_NEW_AGENT_RULE';
  payload: {
    index: number;
    field: keyof EligibilityRuleNewAgent;
    value: any;
  };
};

type DeleteNewAgentRule = {
  type: 'DELETE_NEW_AGENT_RULE';
  payload: {
    index: number;
  };
};

type EligibilityRuleFormAction =
  | ModifyFieldAction
  | ModifyRuleItemAction
  | AddNewAgentRule
  | ModifyNewAgentRule
  | DeleteNewAgentRule;

const formReducer = (state: EligibilityRuleFormState, action: EligibilityRuleFormAction): EligibilityRuleFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      if (action.payload.field === 'agentType') {
        state.listOfAgents = undefined;
        if (action.payload.value === EligibilityAgentType.ROOKIE_AGENTS) {
          state.newAgentRules = [
            {
              name: '',
              startDaySinceHired: '',
              endDaySinceHired: '',
              targetWeeklyLeads: '',
              priority: '',
            },
          ];
        } else {
          state.newAgentRules = undefined;
        }
      }
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_RULE_ITEM':
      let item = state.rules[action.payload.index];
      //@ts-ignore
      item[action.payload.field] = action.payload.value;
      if (action.payload.field === 'priority' && !!action.payload.value) {
        item[action.payload.field] = Number(item[action.payload.field]);
      }
      if (action.payload.field === 'operator' && action.payload.value === RuleOperator.EXCLUDE) {
        item.priority = undefined;
      }
      return { ...state };
    case 'ADD_NEW_AGENT_RULE':
      const newItem: EligibilityRuleNewAgentState = {
        name: '',
        startDaySinceHired: '',
        endDaySinceHired: '',
        targetWeeklyLeads: '',
        priority: '',
      };
      return {
        ...state,
        newAgentRules: state.newAgentRules ? [...state.newAgentRules, newItem] : [newItem],
      };
    case 'MODIFY_NEW_AGENT_RULE':
      if (state.newAgentRules && state.newAgentRules[action.payload.index]) {
        let item = state.newAgentRules[action.payload.index];
        item[action.payload.field] = action.payload.value;
      }
      return { ...state };
    case 'DELETE_NEW_AGENT_RULE':
      if (state.newAgentRules && state.newAgentRules[action.payload.index]) {
        let agentRuleArr = state.newAgentRules;
        agentRuleArr.splice(action.payload.index, 1);
      }
      return { ...state };
    default:
      return state;
  }
};

const detailToStateConvertor = (eligibilityRule: EligibilityRule): EligibilityRuleFormState => {
  const rules = eligibilityRule.rules || [];
  return {
    name: eligibilityRule.name,
    description: eligibilityRule.description,
    agentType: eligibilityRule.agentType,
    PRULeadsTraining: eligibilityRule.PRULeadsTraining ? true : false,
    newAgentRules: eligibilityRule.newAgentRules
      ? eligibilityRule.newAgentRules.map((rule) => ({
          name: rule.name,
          startDaySinceHired: String(rule.startDaySinceHired),
          endDaySinceHired: String(rule.endDaySinceHired),
          targetWeeklyLeads: String(rule.targetWeeklyLeads),
          priority: String(rule.priority),
        }))
      : undefined,
    rules: map(EligibilityRuleType, (type) => {
      const correspondingRule = rules.find((rule) => rule.type === type);
      return (
        correspondingRule ?? {
          type,
          operator: RuleOperator.EXCLUDE,
        }
      );
    }),
    listOfAgents: eligibilityRule.listOfAgents,
    goalId: eligibilityRule.goalId,
  };
};

const EligibilityRuleForm: FC<EligibilityRuleFormProps> = ({ formMode, eligibilityRule, leadGoal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [formState, formDispatch] = useReducer(
    formReducer,
    eligibilityRule ? detailToStateConvertor(eligibilityRule) : initialState,
  );

  const newAgentErrorDef = useMemo(() => {
    let errorItems: ErrorFieldDef[] = [];
    (formState.newAgentRules || []).forEach((rule, ruleIndex) => {
      forEach(rule, (value, key) => {
        errorItems.push({
          name: `${key}-${ruleIndex}`,
          fieldType: ErrorFieldType.MANDATORY,
          condition: () => {
            if (formState.newAgentRules && formState.newAgentRules[ruleIndex]) {
              return !!!formState.newAgentRules[ruleIndex][key as keyof EligibilityRuleNewAgentState];
            } else {
              return false;
            }
          },
        });
      });
    });
    return errorItems;
  }, [formState]);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'name',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'listOfAgents',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        if (
          formState.agentType === EligibilityAgentType.PARTIAL_AGENTS &&
          !(formState.listOfAgents && formState.listOfAgents.length > 0)
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
    {
      name: 'newAgentGroupEmptyRow',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        if (formState.agentType === EligibilityAgentType.ROOKIE_AGENTS) {
          return !(formState.newAgentRules && formState.newAgentRules.length > 0);
        } else {
          return false;
        }
      },
    },
    ...newAgentErrorDef,
  ]);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let reader = new FileReader();
      reader.onload = () => {
        let csvData = String(reader.result);
        let row = csvData.split('\n');
        let agentList: string[] = [];
        row.shift();
        row.forEach((item) => {
          let rowItems = item.split(',');
          if (rowItems[0] && rowItems[0].trim()) {
            agentList.push(rowItems[0].trim());
          }
        });
        onDismissErrorHandler('listOfAgents', agentList.length > 0);
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'listOfAgents', value: agentList } });
      };
      if (e.target.files) {
        reader.readAsBinaryString(e.target.files[0]);
      }
    }
  };

  useUpdateEffect(() => {
    onDismissErrorHandler('listOfAgents', true);
  }, [formState.agentType]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formMode === EligibilityRuleFormMode.CREATE) {
        const body: CreateEligibilityRuleBody = {
          name: formState.name || '',
          description: formState.description,
          agentType: formState.agentType,
          PRULeadsTraining: formState.PRULeadsTraining,
          newAgentRules: formState.newAgentRules
            ? formState.newAgentRules.map((rule) => ({
                name: rule.name,
                startDaySinceHired: Number(rule.startDaySinceHired),
                endDaySinceHired: Number(rule.endDaySinceHired),
                targetWeeklyLeads: Number(rule.targetWeeklyLeads),
                priority: Number(rule.priority),
              }))
            : undefined,
          rules: formState.rules,
          listOfAgents: formState.listOfAgents ? preFillZeroList(formState.listOfAgents) : [],
          createdBy: user?.username || 'Default',
          updatedBy: user?.username || 'Default',
          goalId: formState.goalId,
        };
        try {
          await createEligibilityRule(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Eligibility Rule saved successfully',
              },
            ]),
          );
          history.push(eligibilityRulePath);
        } catch (err) {}
      } else if (eligibilityRule) {
        const body: UpdateEligibilityRuleBody = {
          name: formState.name || '',
          description: formState.description,
          agentType: formState.agentType,
          PRULeadsTraining: formState.PRULeadsTraining,
          newAgentRules: formState.newAgentRules
            ? formState.newAgentRules.map((rule) => ({
                name: rule.name,
                startDaySinceHired: Number(rule.startDaySinceHired),
                endDaySinceHired: Number(rule.endDaySinceHired),
                targetWeeklyLeads: Number(rule.targetWeeklyLeads),
                priority: Number(rule.priority),
              }))
            : undefined,
          rules: formState.rules,
          listOfAgents: formState.listOfAgents ? preFillZeroList(formState.listOfAgents) : [],
          updatedBy: user?.username || 'Default',
          goalId: formState.goalId,
        };
        try {
          await modifyEligibilityRule(eligibilityRule._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Eligibility Rule updated successfully - ${eligibilityRule._id}`,
              },
            ]),
          );
          history.push(eligibilityRulePath);
        } catch (err) {}
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(
              `pulseleadsEligibilityRule.form.title.${formMode === EligibilityRuleFormMode.CREATE ? 'create' : 'edit'}`,
            )}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(eligibilityRulePath)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.eligibilityRule.common.name')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              error={errorState.mandatory.name}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.name}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
              }}
            />
          </div>
        </div>

        <div className={classes.textAreaRowContainer}>
          <div className={classes.textAreaFieldContainer}>
            <span className={classes.field}>{Translation('pulseleads.eligibilityRule.common.description')} :</span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              fullWidth
              multiline
              margin="dense"
              variant="outlined"
              value={formState.description}
              InputProps={{
                classes: {
                  input: classes.textArea,
                },
              }}
              onChange={(e) => {
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'description', value: e.target.value } });
              }}
            />
          </div>
        </div>

        <div className={classes.rowContainer} style={{ marginTop: 4 }}>
          <div className={classes.innerFieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.eligibilityRule.form.isAgencyCampaignRule')} :
            </span>
          </div>
          <FormControlLabel
            style={{ margin: '0 30px 0 0' }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.agentType === EligibilityAgentType.AGENCY}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'agentType', value: EligibilityAgentType.AGENCY },
                    });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.true')}
            labelPlacement="end"
          />
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.agentType !== EligibilityAgentType.AGENCY}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'agentType', value: EligibilityAgentType.PARTIAL_AGENTS },
                    });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.false')}
            labelPlacement="end"
          />
        </div>

        <div className={classes.rowContainer} style={{ marginTop: 4 }}>
          <div className={classes.innerFieldContainer}>
            <span className={classes.field}>{Translation('pulseleads.eligibilityRule.form.allAgents')} :</span>
          </div>
          <FormControlLabel
            style={{ margin: '0 30px 0 0' }}
            control={
              <Checkbox
                disabled={formState.agentType === EligibilityAgentType.AGENCY}
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.agentType === EligibilityAgentType.ALL_AGENTS}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'agentType', value: EligibilityAgentType.ALL_AGENTS },
                    });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.true')}
            labelPlacement="end"
          />
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <Checkbox
                disabled={formState.agentType === EligibilityAgentType.AGENCY}
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.agentType !== EligibilityAgentType.ALL_AGENTS}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'agentType', value: EligibilityAgentType.PARTIAL_AGENTS },
                    });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.false')}
            labelPlacement="end"
          />
        </div>

        <div className={classes.rowContainer} style={{ marginTop: 4 }}>
          <div className={classes.innerFieldContainer}>
            <span className={classes.field}>{Translation('pulseleads.eligibilityRule.form.pruLeadsTraining')} :</span>
          </div>
          <FormControlLabel
            style={{ margin: '0 30px 0 0' }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.PRULeadsTraining === true}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'PRULeadsTraining', value: true } });
                  }
                }}
              />
            }
            label={
              <>
                <span style={{ marginRight: 10 }}>{Translation('app.checkbox.true')}</span>
                <Tooltip
                  arrow
                  title={
                    <span style={{ fontSize: 10 }}>
                      {intl.formatMessage(
                        { id: 'pulseleads.eligibilityRule.form.pruLeadsTrainingTrueToolTip' },
                        { br: <br /> },
                      )}
                    </span>
                  }
                >
                  <Info style={{ color: 'grey' }} />
                </Tooltip>
              </>
            }
            labelPlacement="end"
          />
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.PRULeadsTraining === false}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'PRULeadsTraining', value: false } });
                  }
                }}
              />
            }
            label={
              <>
                <span style={{ marginRight: 10 }}>{Translation('app.checkbox.false')}</span>
                <Tooltip
                  arrow
                  title={
                    <span style={{ fontSize: 10 }}>
                      {intl.formatMessage(
                        { id: 'pulseleads.eligibilityRule.form.pruLeadsTrainingFalseToolTip' },
                        { br: <br /> },
                      )}
                    </span>
                  }
                >
                  <Info style={{ color: 'grey' }} />
                </Tooltip>
              </>
            }
            labelPlacement="end"
          />
        </div>

        {/* 20210804 - R1 Descope */}

        {/* <div className={classes.rowContainer} style={{ marginTop: 4 }}>
          <div className={classes.innerFieldContainer}>
            <span className={classes.field}>{Translation("pulseleads.eligibilityRule.form.rookieAgents")} :</span>
          </div>
          <FormControlLabel
            style={{ margin: "0 30px 0 0" }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.agentType === EligibilityAgentType.ROOKIE_AGENTS}
                onChange={e => {
                  if (e.target.checked) {
                    formDispatch({ type: "MODIFY_FIELD", payload: { field: "agentType", value: EligibilityAgentType.ROOKIE_AGENTS }});
                  }
                }}
              />
            }
            label={Translation("app.checkbox.true")}
            labelPlacement="end"
          />
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={formState.agentType !== EligibilityAgentType.ROOKIE_AGENTS}
                onChange={e => {
                  if (e.target.checked) {
                    formDispatch({ type: "MODIFY_FIELD", payload: { field: "agentType", value: EligibilityAgentType.PARTIAL_AGENTS }});
                  }
                }}
              />
            }
            label={Translation("app.checkbox.false")}
            labelPlacement="end"
          />
        </div> */}

        {formState.agentType === EligibilityAgentType.ROOKIE_AGENTS && (
          <>
            <FormTable
              style={{ margin: '10px 0' }}
              errorState={errorState}
              onDismissErrorHandler={onDismissErrorHandler}
              dataSource={formState.newAgentRules || []}
              columnDef={[
                {
                  keyIndex: 'name',
                  displayName: Translation('pulseleads.eligibilityRule.rookieAgents.name'),
                  item: {
                    type: FormTableItemType.FREE_TEXT,
                  },
                },
                {
                  keyIndex: 'startDaySinceHired',
                  displayName: Translation('pulseleads.eligibilityRule.rookieAgents.startDaySinceHired'),
                  item: {
                    type: FormTableItemType.FREE_TEXT,
                    regExp: /[^0-9]/g,
                  },
                },
                {
                  keyIndex: 'endDaySinceHired',
                  displayName: Translation('pulseleads.eligibilityRule.rookieAgents.endDaySinceHired'),
                  item: {
                    type: FormTableItemType.FREE_TEXT,
                    regExp: /[^0-9]/g,
                  },
                },
                {
                  keyIndex: 'targetWeeklyLeads',
                  displayName: Translation('pulseleads.eligibilityRule.rookieAgents.targetWeeklyLeads'),
                  item: {
                    type: FormTableItemType.FREE_TEXT,
                    regExp: /[^0-9]/g,
                  },
                },
                {
                  keyIndex: 'priority',
                  displayName: Translation('pulseleads.eligibilityRule.rookieAgents.priority'),
                  item: {
                    type: FormTableItemType.FREE_TEXT,
                    regExp: /[^0-9]/g,
                  },
                },
              ]}
              onAddRow={() => {
                onDismissErrorHandler('newAgentGroupEmptyRow', true);
                formDispatch({ type: 'ADD_NEW_AGENT_RULE' });
              }}
              onModifyField={(index, field, value) =>
                formDispatch({ type: 'MODIFY_NEW_AGENT_RULE', payload: { index, field, value } })
              }
              onDeleteRow={(index) => formDispatch({ type: 'DELETE_NEW_AGENT_RULE', payload: { index } })}
            />
            {errorState.mandatory.newAgentGroupEmptyRow && (
              <FormHelperText style={{ color: '#f018a6' }}>{NO_ROW_ERROR_TEXT}</FormHelperText>
            )}
          </>
        )}

        {formState.agentType === EligibilityAgentType.PARTIAL_AGENTS && (
          <>
            <div className={classes.rowContainer} style={{ marginTop: 8 }}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('pulseleads.eligibilityRule.form.specificAgents')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div>
                <input
                  id="upload-list-agent-csv"
                  hidden
                  type="file"
                  accept=".csv"
                  onClick={(e) => {
                    const element = e.target as HTMLInputElement;
                    element.value = '';
                  }}
                  onChange={handleFile}
                />
                <div className={classes.rowContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => document.getElementById('upload-list-agent-csv')!.click()}
                  >
                    {Translation('app.button.chooseFile')}
                  </Button>
                </div>
              </div>
              <div style={{ marginLeft: 20 }}>
                <AsyncCsvLink
                  isDisabled={false}
                  filename={'agent-list-template.csv'}
                  dataParser={(str) => str}
                  asyncCall={() => downloadAgentListTemplate(dispatch)}
                >
                  <Button variant="contained" color="secondary">
                    {Translation('pulseleads.eligibilityRule.form.downloadTemplate')}
                  </Button>
                </AsyncCsvLink>
              </div>
            </div>
            <div style={{ flexGrow: 1, marginTop: 4 }}>
              <TextField
                fullWidth
                error={errorState.mandatory.listOfAgents}
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory.listOfAgents && MANDATORY_FIELD_ERROR_TEXT}
                placeholder={'Input agent code separate by comma. e.g. 10001, 10002'}
                value={(formState.listOfAgents || []).join(',')}
                onChange={(e) => {
                  const agentCode = e.target.value.replace(/[^0-9,]/g, '');
                  onDismissErrorHandler('listOfAgents', agentCode);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'listOfAgents', value: agentCode ? agentCode.split(',') : [] },
                  });
                }}
              />
            </div>
          </>
        )}

        <div style={{ marginTop: 12 }}>
          {map(EligibilityRuleType, (type) => {
            const foundIndex = formState.rules.findIndex((item) => item.type === type);
            return (
              foundIndex !== -1 && (
                <div key={`eligibility-rule-item-${type}`} className={classes.divideMargin}>
                  <span className={classes.subHeader}>
                    {Translation(`pulseleads.eligibilityRule.type.${formState.rules[foundIndex].type}`)}
                  </span>
                  <div style={{ padding: 10 }}>
                    <div className={classes.rowContainer}>
                      <div className={classes.innerFieldContainer}>
                        <span className={classes.field}>{Translation('common.operator.include')} :</span>
                      </div>
                      <FormControlLabel
                        style={{ margin: '0 30px 0 0' }}
                        control={
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={formState.rules[foundIndex].operator !== RuleOperator.EXCLUDE}
                            onChange={(e) => {
                              if (e.target.checked) {
                                formDispatch({
                                  type: 'MODIFY_RULE_ITEM',
                                  payload: { field: 'operator', index: foundIndex, value: RuleOperator.INCLUDE },
                                });
                              }
                            }}
                          />
                        }
                        label={Translation('app.checkbox.true')}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        style={{ margin: 0 }}
                        control={
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={formState.rules[foundIndex].operator === RuleOperator.EXCLUDE}
                            onChange={(e) => {
                              if (e.target.checked) {
                                formDispatch({
                                  type: 'MODIFY_RULE_ITEM',
                                  payload: { field: 'operator', index: foundIndex, value: RuleOperator.EXCLUDE },
                                });
                              }
                            }}
                          />
                        }
                        label={Translation('app.checkbox.false')}
                        labelPlacement="end"
                      />
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 4 }}>
          <div className={classes.innerFieldContainer}>
            <span className={classes.field}>{Translation('pulseleads.activityPoint.leadGoal.title')}:</span>
          </div>

          <FormControl margin="dense" variant="outlined">
            <Select
              // disabled={!enableDraftUpdate}
              style={{ minWidth: 320 }}
              value={formState.goalId}
              onChange={(e) => {
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: {
                    field: 'goalId',
                    value: e.target.value,
                  },
                });
              }}
              displayEmpty
            >
              <MenuItem key={'defaultNone'} value={undefined}>
                None
              </MenuItem>
              {leadGoal &&
                leadGoal.map((value) => (
                  <MenuItem key={value._id} value={value._id}>
                    {TranslationWithParams('pulseleads.activityPoint.leadGoal.goalName', {
                      points: value.targetPoint,
                      days: value.timeRange.days,
                    })}
                  </MenuItem>
                ))}
            </Select>
            {errorState.mandatory.distributionChannel && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          </FormControl>
        </div>
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default EligibilityRuleForm;
