import { I18nGenericData } from 'src/app/i18n';

export enum KpiFormMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum KpiTypeEnum {
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
  VALUE = 'VALUE',
  RANK = 'RANK',
  PERCENTAGE = 'PERCENTAGE',
  CATEGORY = 'CATEGORY',
}

export type KpiItem = {
  _id: string;
  key: string;
  name: I18nGenericData<string>;
  type: KpiTypeEnum;
  values?: string[];
  createdAt: Date;
  updatedAt: Date;
};
