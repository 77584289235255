import React from 'react';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import { fetchLicenseExamExamOptions } from 'src/app/modules/Recruitment/network/license-exam-crud';

export const useExamType = () => {
  // i18n
  const locale = useLang();
  const dispatch = useDispatch();

  const { data } = useRequest(
    async () => {
      const res = await fetchLicenseExamExamOptions('EXAM_TYPE', { lang: locale }, dispatch);
      return res
        ? res.map((item) => ({
            label: item,
            value: item,
          }))
        : [];
    },
    { manual: false },
  );

  return {
    data,
  };
};
