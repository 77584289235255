import React, { memo } from 'react';
import { BaseProps } from '../base-dialog/base-dialog.hook';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { ApprovalEvents } from 'src/app/modules/Recruitment/types/approval-types';
import { BaseDialog } from '../base-dialog/base-dialog.component';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

export const RejectDialog: React.FC<BaseProps> = memo(({ onRefresh, id, onClose, open }: BaseProps) => {
  const Translation = useTranslation();
  const remarks = useSelector((state: any) =>
    get(state.config.data, 'Recruitment.onboardingApplicationRemarks.reject', []),
  );

  return (
    <BaseDialog
      open={open}
      id={id}
      title={Translation('onboarding.application.approval.detail.dialog.reject.title')}
      onClose={onClose}
      approvalEvents={ApprovalEvents.reject}
      onRefresh={onRefresh}
      options={remarks}
    />
  );
});
