import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PaginateList } from 'src/app/common/types/common-types';
import { LayoutSplashScreen } from 'src/app/layout';
import {
  ApprovalCampaignItem,
  ResourceItem,
  ParticipantIndividualItem,
  LouTemplateItem,
} from 'src/app/modules/AgencyCampaign/types/approval-types';
import {
  ParticipantListParam,
  fetchApprovalItem,
  fetchApprovalResources,
  fetchUserRole,
  fetchParticipantList,
  fetchLouTemplateList,
  cancelCampaignSubmission,
} from 'src/app/modules/AgencyCampaign/network/approvalCrud';
import ApprovalForm from './components/ApprovalForm';
import { approvalPath } from '../ApprovalRoutes';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';

const ApprovalDetailPage: FC<RouteComponentProps & ParamsProps> = ({ history, initialParams }) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [approvalItem, setApprovalItem] = useState<ApprovalCampaignItem>();
  const [approvalResources, setApprovalResources] = useState<ResourceItem[]>([]);
  const [userRoleList, setUserRoleList] = useState<string[]>([]);
  const [louTemplateList, setLouTemplateList] = useState<LouTemplateItem[]>([]);

  const [filterState, setFilterState] = useState<ParticipantListParam>({ id, page: 1, limit: 5 });
  const [participantList, setParticipantList] = useState<PaginateList<ParticipantIndividualItem>>();

  const refreshParticipantList = (newFilterState?: ParticipantListParam) => {
    const filter = newFilterState || filterState;
    fetchParticipantList(filter, dispatch)
      .then((result) => {
        setParticipantList(result);
      })
      .catch((err: ApiErrorResponse) => {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      });
  };

  const refreshLouTemplateList = () => {
    fetchLouTemplateList(dispatch)
      .then((result) => {
        setLouTemplateList(result);
      })
      .catch((err: ApiErrorResponse) => {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      });
  };

  const reloadData = () => {
    if (id) {
      setIsLoading(true);
      refreshParticipantList();
      refreshLouTemplateList();
      fetchApprovalItem(id, dispatch)
        .then((result) => {
          setApprovalItem(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const reloadResources = () => {
    if (id) {
      fetchApprovalResources(id, dispatch)
        .then((result) => {
          setApprovalResources(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        });
    }
  };

  const loadUserRoleList = async () => {
    const result = await fetchUserRole({ type: 'STAFF', user: user?.sub || '' });
    setUserRoleList(result);
  };

  useEffect(() => {
    loadUserRoleList();
  }, []);

  useEffect(() => {
    reloadResources();
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  const onCancelCampaign = ({ reason }: any) => {
    const data = {
      id,
      reason,
    };
    cancelCampaignSubmission(data, dispatch).then(() => {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Cancel submission successfully`,
          },
        ]),
      );
    });
    history.push(`${approvalPath}/list?status=${initialParams?.status ?? 'pendingApproval'}`);
  };
  return (
    <>
      {isLoading || !approvalItem ? (
        <LayoutSplashScreen />
      ) : (
        <ApprovalForm
          approvalItem={approvalItem}
          approvalResources={approvalResources}
          user={user}
          userRoleList={userRoleList}
          participantList={participantList}
          louTemplateList={louTemplateList}
          reloadResources={reloadResources}
          onChangePage={(page, rowsPerPage) => {
            const newFilterState = {
              ...filterState,
              page,
              limit: rowsPerPage,
            };
            setFilterState(newFilterState);
            refreshParticipantList(newFilterState);
          }}
          onCancel={onCancelCampaign}
        />
      )}
    </>
  );
};

export default ApprovalDetailPage;
