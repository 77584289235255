import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputValue } from '../remark-input/remark-input.component';
import { updateApprovalStatus, updateRemarkApprovalStatus } from 'src/app/modules/Recruitment/network/approval-crud';
import { ApplicationStatus, ApprovalEvents } from 'src/app/modules/Recruitment/types/approval-types';
import { REMARK_OTHERS } from '../../../util/constant';
import { useTranslation } from 'src/app/common/hook/translation.hook';
export interface BaseProps {
  onRefresh?: () => void;
  onClose?: () => void;
  id: string;
  open: boolean;
  applicationFormStatus?: ApplicationStatus;
}

export interface BaseDialogProps extends BaseProps {
  approvalEvents: ApprovalEvents;
  options: string[];
  title: string;
}

export const useBaseDialog = ({
  id,
  approvalEvents,
  onRefresh,
  onClose,
  options,
  applicationFormStatus,
}: BaseDialogProps) => {
  const Translation = useTranslation();
  const [selected, setSelected] = useState<InputValue | undefined>(() => {
    if (options?.length <= 0) return { key: REMARK_OTHERS };
    return { key: options[0] };
  });
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useDispatch();

  const onConfirm = useCallback(async () => {
    try {
      setConfirmLoading(true);
      if (approvalEvents === ApprovalEvents.updateRemark) {
        await updateRemarkApprovalStatus(
          {
            remark:
              (selected?.key === REMARK_OTHERS ? selected?.remark : selected?.key ? Translation(selected?.key) : '') ??
              '',
            applicationFormStatus,
          },
          id!,
          dispatch,
        );
      } else {
        await updateApprovalStatus(
          {
            approvalEvents: approvalEvents,
            remark:
              selected?.key === REMARK_OTHERS ? selected?.remark : selected?.key ? Translation(selected?.key) : '',
          },
          id!,
          dispatch,
        );
      }
      onClose?.();
      setSelected(undefined);
      await onRefresh?.();
    } catch (err) {
      console.warn('onConfirm: ', err);
    } finally {
      setConfirmLoading(false);
    }
  }, [id, selected, dispatch, applicationFormStatus]);

  const onChange = useCallback((value?: InputValue) => {
    setSelected(value);
  }, []);

  return {
    selected,
    onClose,
    confirmLoading,
    onConfirm,
    onChange,
    options: useMemo(() => {
      return options.map((i: string) => ({
        label: i,
        value: i,
      }));
    }, [options]),
  };
};
