import React, { FC } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { useIntl } from 'react-intl';

type PruTableEmptyRowProps = {
  isEmpty: boolean;
  type?: string;
  noRecordMsg?: string;
  colSpan?: number;
};

const PruTableEmptyRow: FC<PruTableEmptyRowProps> = ({ isEmpty, type, noRecordMsg, colSpan = 12 }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return isEmpty ? (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        <div style={{ margin: '30px 0 30px 0 ' }}>
          {noRecordMsg ??
            (type === 'participants'
              ? Translation('agencyCampaign.create.noParticipants')
              : Translation('prutable.noRecord'))}
        </div>
      </TableCell>
    </TableRow>
  ) : (
    <></>
  );
};

export default PruTableEmptyRow;
