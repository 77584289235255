export interface ImageAccept {
  type: string;
  maxSize: number;
  errMsgMap: Record<string, string>;
}

export const checkFile = (intl: any, accepts: ImageAccept, file: any) => {
  let errMsg = '';

  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const acceptFileTypes = accepts.type.split(',');
  // check file type
  if (!acceptFileTypes.includes(file.type)) {
    errMsg = Translation('global.max.file.type.allow', {
      num: acceptFileTypes.map((fileType) => accepts.errMsgMap[fileType] || fileType.replace('.', '')).join('/'),
    });
  }
  // check file size
  if (file.size > accepts.maxSize) {
    errMsg = Translation('global.max.file.size.allow');
  }
  return errMsg;
};
