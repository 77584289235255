import { FC, useReducer, useEffect, CSSProperties } from 'react';
import { range } from 'lodash';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { Add, ArrowDropDown, ArrowDropUp, HighlightOff } from '@mui/icons-material';
import { useLang, regionLocale, initI18nGenericData } from 'src/app/i18n';
import { IncentiveHighlight } from '../../../../types/incentive-types';
import { KpiTypeEnum, KpiItem } from '../../../../types/kpi-types';
import { HighlightFormState, highlightFormReducer } from '../reducers';
import { IncentiveTableCell } from './IncentiveTableCell';

type HighlightSectionProps = {
  style?: CSSProperties;
  isDisabled: boolean;
  kpiList: KpiItem[];
  nonRankKpiList: KpiItem[];
  initialFormState: HighlightFormState;
  hasError: boolean;
  onChange: (newHighlights: HighlightFormState['highlights']) => void;
  onDismissErrorHandler: (field: string, value: any) => void;
};

const FIELD_CONTAINER_WIDTH = 160;

const useStyles = makeStyles()((theme) => ({
  operationCell: {
    width: 140,
  },
  iconButton: {
    padding: 5,
  },
  noMargin: {
    margin: 0,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpacing: {
    marginBottom: 16,
  },
  fieldContainer: {
    minWidth: FIELD_CONTAINER_WIDTH,
  },
  field: {
    fontSize: '1rem',
  },
  rankField: {
    width: 240,
  },
  errorText: {
    fontSize: 10,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#F018A6',
    marginTop: 4,
  },
}));

const topCountOptions = range(3, 16);

const HighlightSection: FC<HighlightSectionProps> = ({
  style,
  isDisabled,
  kpiList,
  nonRankKpiList,
  hasError,
  initialFormState,
  onChange,
  onDismissErrorHandler,
}) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();

  const [formState, formDispatch] = useReducer(highlightFormReducer, initialFormState);
  const { highlights, selectedRankKpiItem } = formState;

  useEffect(() => {
    onChange(highlights);
  }, [highlights]);

  const renderRankSection = () => {
    const rankHighlightIndex = highlights.findIndex((highlight) => highlight.keyId === selectedRankKpiItem?._id);
    const rankHighlight = highlights[rankHighlightIndex];
    return (
      <>
        <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('incentive.common.top_count')} : </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <FormControl
              disabled={isDisabled}
              className={`${classes.rankField} ${classes.noMargin}`}
              margin="dense"
              variant="outlined"
            >
              <Select
                value={rankHighlight.rankTopCount}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_HIGHLIGHT_ROW',
                    payload: {
                      index: rankHighlightIndex,
                      value: { ...rankHighlight, rankTopCount: e.target.value as number },
                    },
                  });
                }}
              >
                {topCountOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {Translation('incentive.common.top_count_option', { num: option.toString() })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('incentive.common.rank_value')} : </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <FormControl
              disabled={isDisabled}
              className={`${classes.rankField} ${classes.noMargin}`}
              margin="dense"
              variant="outlined"
            >
              <Select
                value={nonRankKpiList.find((kpiItem) => kpiItem._id === rankHighlight.rankValueKeyId) || ''}
                onChange={(e) => {
                  const newKpiItem = e.target.value as KpiItem;
                  formDispatch({
                    type: 'MODIFY_HIGHLIGHT_ROW',
                    payload: {
                      index: rankHighlightIndex,
                      value: { ...rankHighlight, rankValueKeyId: newKpiItem._id },
                    },
                  });
                }}
              >
                {nonRankKpiList.map((kpiItem) => (
                  <MenuItem key={kpiItem._id} value={kpiItem as any}>
                    {kpiItem.name[locale]} | {kpiItem.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {regionLocale.map((locale, index) => (
          <div
            key={`highlight-rank-desc-${locale}`}
            className={`${classes.rowContainer} ${index !== regionLocale.length - 1 ? classes.rowSpacing : undefined}`}
          >
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation(`incentive.common.rank_description.${locale}`)} : </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={isDisabled}
                className={`${classes.rankField} ${classes.noMargin}`}
                margin="dense"
                variant="outlined"
                value={rankHighlight.rankDescription?.[locale] || ''}
                onChange={(e) =>
                  formDispatch({
                    type: 'MODIFY_HIGHLIGHT_ROW',
                    payload: {
                      index: rankHighlightIndex,
                      value: {
                        ...rankHighlight,
                        rankDescription: { ...rankHighlight.rankDescription, [locale]: e.target.value },
                      },
                    },
                  })
                }
              />
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div style={style}>
      <TableContainer className={selectedRankKpiItem ? classes.rowSpacing : undefined}>
        <Table>
          <TableHead>
            <TableRow>
              <IncentiveTableCell>{Translation('incentive.common.kpi')}</IncentiveTableCell>
              <IncentiveTableCell className={classes.operationCell} align="right">
                <IconButton
                  disabled={isDisabled || highlights.length === 5}
                  className={classes.iconButton}
                  style={{ color: 'white' }}
                  onClick={() => formDispatch({ type: 'ADD_HIGHLIGHT_ROW' })}
                >
                  <Add />
                </IconButton>
              </IncentiveTableCell>
            </TableRow>
            {hasError && (
              <TableRow>
                <TableCell colSpan={2} style={{ padding: 0, borderBottom: 'none' }}>
                  <div className={classes.errorText}>All fields in KPI are mandatory</div>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {highlights.map((highlight, index) => (
              <TableRow key={`highlight-${index}-${highlight.keyId}`}>
                <IncentiveTableCell>
                  <FormControl
                    disabled={isDisabled}
                    className={classes.noMargin}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  >
                    <Select
                      value={kpiList.find((kpiItem) => kpiItem._id === highlight.keyId) || ''}
                      onChange={(e) => {
                        const newKpiItem = e.target.value as KpiItem;
                        let newHighlight: IncentiveHighlight = { keyId: newKpiItem._id };
                        if (!selectedRankKpiItem) {
                          // if select rank type, init rank related fields
                          if (newKpiItem.type === KpiTypeEnum.RANK) {
                            formDispatch({
                              type: 'SET_SELECTED_RANK_KPI_ITEM',
                              payload: { value: newKpiItem },
                            });
                            newHighlight = {
                              ...newHighlight,
                              rankTopCount: 3,
                              rankValueKeyId: nonRankKpiList[0]._id,
                              rankDescription: initI18nGenericData<string>(regionLocale, ''),
                            };
                          }
                        } else if (
                          selectedRankKpiItem._id === highlight.keyId &&
                          selectedRankKpiItem._id !== newKpiItem._id
                        ) {
                          // if from rank type to non-rank type
                          formDispatch({
                            type: 'SET_SELECTED_RANK_KPI_ITEM',
                            payload: { value: undefined },
                          });
                        }
                        formDispatch({
                          type: 'MODIFY_HIGHLIGHT_ROW',
                          payload: { index, value: newHighlight },
                        });
                        if (hasError) {
                          onDismissErrorHandler('highlights', true);
                        }
                      }}
                    >
                      {selectedRankKpiItem && selectedRankKpiItem._id === highlight.keyId && (
                        <MenuItem key={selectedRankKpiItem._id} value={selectedRankKpiItem as any}>
                          {selectedRankKpiItem.name[locale]} | {selectedRankKpiItem.type}
                        </MenuItem>
                      )}
                      {(selectedRankKpiItem ? nonRankKpiList : kpiList).map((kpiItem) => (
                        <MenuItem key={kpiItem._id} value={kpiItem as any}>
                          {kpiItem.name[locale]} | {kpiItem.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </IncentiveTableCell>
                <IncentiveTableCell align="right">
                  <IconButton
                    disabled={isDisabled || !!!highlights[index + 1]}
                    className={classes.iconButton}
                    onClick={() =>
                      highlights[index + 1] &&
                      formDispatch({ type: 'MOVE_HIGHLIGHT_ROW', payload: { from: index, to: index + 1 } })
                    }
                  >
                    <ArrowDropDown />
                  </IconButton>
                  <IconButton
                    disabled={isDisabled || !!!highlights[index - 1]}
                    className={classes.iconButton}
                    onClick={() =>
                      highlights[index - 1] &&
                      formDispatch({ type: 'MOVE_HIGHLIGHT_ROW', payload: { from: index, to: index - 1 } })
                    }
                  >
                    <ArrowDropUp />
                  </IconButton>
                  <IconButton
                    disabled={isDisabled}
                    className={classes.iconButton}
                    onClick={() => {
                      formDispatch({ type: 'DELETE_HIGHLIGHT_ROW', payload: { index } });
                      if (selectedRankKpiItem?._id === highlight.keyId) {
                        formDispatch({
                          type: 'SET_SELECTED_RANK_KPI_ITEM',
                          payload: { value: undefined },
                        });
                      }
                      if (hasError) {
                        onDismissErrorHandler('highlights', true);
                      }
                    }}
                  >
                    <HighlightOff />
                  </IconButton>
                </IncentiveTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedRankKpiItem && renderRankSection()}
    </div>
  );
};

export default HighlightSection;
