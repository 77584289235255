import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { AgentSegmentItem } from 'src/app/common/types';
import {
  NewsCategoryPaginateList,
  NewsCategoryItem,
  NewsCategoryEditItem,
  AgentSegmentEditItem,
  NewsHeadlineItem,
  NewsHeadlinePaginateList,
} from '../types/agent-segment-type';

export type NewsCategoryListParam = {
  limit: number;
  page: number;
};

export type NewsCategoryTypeParam = {
  category?: string;
};

export type NewsHeadlineListParam = {
  limit: number;
  page: number;
};

export const fetchNewsCategoryList = async (
  params: NewsCategoryListParam,
  dispatch?: Dispatch<any>,
): Promise<NewsCategoryPaginateList> => {
  let queryPath = `${ADMIN_URL}/news/categories?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  return apiClient
    .get<NewsCategoryPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCheckNewsAttached = async (segmentId: string, dispatch?: Dispatch<any>): Promise<boolean> => {
  return apiClient
    .get<boolean>(`${ADMIN_URL}/news/attached/${segmentId}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyNewsCategory = async (
  id: string,
  body: NewsCategoryEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/news/categories/${id}`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveNewsCategoryUpward = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(
      `${ADMIN_URL}/news/categories/${id}/up`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveNewsCategoryDownward = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(
      `${ADMIN_URL}/news/categories/${id}/down`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsCategoryItem = async (id: string, dispatch?: Dispatch<any>): Promise<NewsCategoryItem> => {
  return apiClient
    .get<NewsCategoryItem>(`${ADMIN_URL}/news/categories/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAgentSegmentItem = async (id: string, dispatch?: Dispatch<any>): Promise<AgentSegmentItem> => {
  return apiClient
    .get<AgentSegmentItem>(`${ADMIN_URL}/agent-segment/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewAgentSegment = async (
  data: AgentSegmentEditItem,
  dispatch?: Dispatch<any>,
): Promise<AgentSegmentItem> => {
  return apiClient
    .post<AgentSegmentItem>(`${ADMIN_URL}/agent-segment`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyAgentSegment = async (
  code: string,
  data: AgentSegmentEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/agent-segment/${code}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyAndApproveNewsArticle = async (
  code: string,
  data: AgentSegmentEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/news/approve/${code}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsHeadlineList = async (
  params: NewsHeadlineListParam,
  dispatch?: Dispatch<any>,
): Promise<NewsHeadlinePaginateList> => {
  let queryPath = `${ADMIN_URL}/news/headline?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  return apiClient
    .get<NewsHeadlinePaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const setNewsAsHeadline = async (data: NewsHeadlineItem, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/news/headline`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unsetNewsHeadline = async (newsId: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/news/headline/${newsId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveNewsHeadlineUpward = async (data: NewsHeadlineItem, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/news/headline/up`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveNewsHeadlineDownward = async (data: NewsHeadlineItem, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/news/headline/down`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
