import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  backdrop: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: '0.5 !important',
  },
  container: {
    height: 'auto',
  },
}));
