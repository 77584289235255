import React, { FC, useState, useEffect } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import { Button, Dialog, IconButton, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { NewsArticlePaginateList, NewsArticleItem, NewsHeadlineItem } from 'src/app/modules/News/types/news-type';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate, useJWT, isPermitted } from 'src/app/common/utils';
import {
  SCHEDULE_JOB_BASE_PATH,
  SCHEDULE_JOB_HISTORY_PATH,
  AdminScheduleJobPermissionType,
} from 'src/app/modules/SystemAdmin/constants';
import { useServerLocale, getAvailableServerLocale } from 'src/app/i18n';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { setNewsAsHeadline, unsetNewsHeadline } from 'src/app/modules/News/network/newsCrud';
import { useDispatch } from 'react-redux';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { map } from 'lodash';
import { useStyles, styles } from './ScheduleJobStyle';
import ScheduleJobHistoryListingPage from '../../ScheduleJobHistoryListingPage';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

type NewsArticleListProps = {
  isLoading: boolean;
  newsArticleList?: NewsArticlePaginateList;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

// export type EventCategoryItem = {
//   _id: string;
//   name: I18nGenericData<string>;
//   description?: string;
//   host: EventHostEnum[];
//   accessibility: EventAccessibilityEnum;
//   audience: EventAudienceEnum;
//   status: EventCategoryStatusEnum;
//   lastUpdatedBy: string;
//   createdAt?: Date;
//   updatedAt?: Date;
// };

export type FormDialogState = {
  open: boolean;
  eventCategoryItem?: any; //hims mark
};

const LargerFontTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    fontSize: 12,
  },
}));

const NewsArticleList: FC<NewsArticleListProps> = ({ isLoading, newsArticleList, onRefresh, onChangePage }) => {
  const dispatch = useDispatch();
  const locale = useServerLocale();
  const availableLocales = getAvailableServerLocale();
  const history = useHistory();
  const intl = useIntl();
  const { classes } = useStyles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [headlineDialogId, setHeadlineDialogId] = useState<string>('');
  const [headlineDialogData, setHeadlineDialogData] = useState<NewsArticleItem>();
  const [headlineIsNotPublshedDialog, setHeadlineIsNotPublshedDialog] = useState<boolean>(false);
  const [headlineIsNotSetForAllAgentDialog, setHeadlineIsNotSetForAllAgentDialog] = useState<boolean>(false);
  const [formDialogState, setFormDialogState] = useState<FormDialogState>({ open: false });
  let updateHeadlinePermission = false;
  let updateNewsPermission = false;
  let createNewsPermission = false;
  const jwt = useJWT() || {};
  console.log('newNewsArticleList', newsArticleList);
  //mock data
  const onClickSetHeadline = () => {
    setFormDialogState({ open: true });
  };
  const mockData = {
    newsList: [
      {
        _id: '669f581aab531ebe83baa400',
        group: 'training',
        jobName: 'User Update',
        description: 'create / update users account in LMS',
        lastExecutionTime: '25/07/2024 13:55',
        lastExecutionStatus: 'Completed',
        operation: 'View Job History',
        triggers: 'Trigger1 Trigger2 Trigger3 Trigger4',
        tags: [],
        attachments: [],
        eligibleAgents: ['00010009'],
        excludedAgents: [],
        thumbnail: {
          type: 'image',
        },
        banner: {
          blobId: '669f581897130455633161df',
          type: 'image',
        },
        categoryId: '606540afc4ded47ae1acc9e7',
        title: {
          zhHk: 'testlist1',
          enUs: 'testlist1',
        },
        content: {
          zhHk: '',
          enUs: '',
          plainTextZhHk: '',
          plainTextEnUs: '',
        },
        publishDate: '2024-06-30T16:00:00.000Z',
        archiveDate: '2024-09-28T16:00:00.000Z',
        isSuggested: false,
        isHeadline: false,
        readingTime: {
          zhHk: 0,
          enUs: 0,
        },
        enquiryEmail: 'man.him.fu2@prudential.com.hk',
        likes: 0,
        bookmarked: 0,
        comments: 0,
        readCount: 1,
        createdBy: 'Man.Him.Fu2@prudential.com.hk',
        updatedBy: 'Man.Him.Fu2@prudential.com.hk',
        status: 'Published',
        enrolmentType: 'AGENTS',
        eligibleSegments: {
          designation: [],
        },
        agentListFileName: 'testlist1.csv',
        region: 'HK',
        channel: 'AGENCY',
        newsType: 'GENERAL',
        createdAt: '2024-07-23T07:13:30.238Z',
        updatedAt: '2024-07-23T07:20:18.333Z',
        noArchiveDate: false,
        category: {
          _id: '606540afc4ded47ae1acc9e7',
          name: {
            zhHk: '法規',
            enUs: 'Regulation',
          },
          key: 'regulation',
          color: '#998789',
          createdAt: '2021-03-31T14:31:09.191Z',
          updatedAt: '2023-11-15T10:46:33.914Z',
          order: 10,
          channel: 'AGENCY',
          region: 'HK',
          maxNoOfHeadlines: 1000,
          maxArchiveDays: 90,
          categoryId: '606540afc4ded47ae1acc9e7',
        },
        newsId: '669f581aab531ebe83baa400',
        isPublished: true,
      },
      {
        _id: '668f786519e601283a3584de',
        group: 'training',
        jobName: 'candidate_convert',
        description: 'candidate to agent conversion in LMS',
        lastExecutionTime: '25/07/2024 13:55',
        lastExecutionStatus: 'Completed',
        operation: 'View Job History',
        triggers: 'Trigger1 Trigger2 Trigger3 Trigger4',
        tags: [],
        attachments: [],
        excludedAgents: [],
        thumbnail: {
          type: 'image',
        },
        banner: {
          blobId: '668f785d911aaade55bf3c05',
          type: 'image',
        },
        categoryId: '60648510fd8c1b62d87734b4',
        title: {
          zhHk: 'videodownload',
          enUs: 'videodownload',
        },
        content: {
          zhHk: '<p><video preload="metadata" controls="controls" width="100%" height="100%" data-blob-id="668f7833911aaade55bf3c01">\n  <source src="https://storage.googleapis.com/prusgrass-nprd-dev-ad73dl-asia-east2-pruforce/prunews/Huangzhi.Li%40prudential.com.sg/668f7833911aaade55bf3c01.mp4?X-Goog-Algorithm=GOOG4-RSA-SHA256&amp;X-Goog-Credential=sa-8424-prusgrass-nprd%40prusgrass-nprd-dev-ad73dl-3eeb.iam.gserviceaccount.com%2F20240711%2Fauto%2Fstorage%2Fgoog4_request&amp;X-Goog-Date=20240711T061414Z&amp;X-Goog-Expires=604800&amp;X-Goog-SignedHeaders=host&amp;X-Goog-Signature=7d105c04c9156510cfb876f2280e0d482b3f9bc5d98a3f8754ddd6a11d085d473553bfbaae5887e9e8d0c44228260fd7e8ae0bea2df4065ab248b8b6744e40746f69ff2ab8858bf4c1464bdf1b4d1e8ca034b20c31211125fb3260f7bbaac1f77c033693c0e596bb0d36ee0b2f442259393d25a65b6789b161ffe6ef6ecd551d7a5dcf0f00f29c4579a5bc2186abda61b8778f14e503a26b4e4cd78154aaa583b7ce8ce552cd7d9ca90d1df9ae8a08a24374f8a045eea4f8e9122675a534b8ca8d8ee045d3b6c45409988eb8c84e54b11b28bd80d02328e39e59304b93a7c7d9552df5f389e473c0c6a23d69ec9638012714f45eb45fdd7a2c63ec085cf0c075#t=0.1&amp;blobId=668f7833911aaade55bf3c01" />\n  </video></p>',
          enUs: '<p><video preload="metadata" controls="controls" width="100%" height="100%" data-blob-id="668f7833911aaade55bf3c01">\n  <source src="https://storage.googleapis.com/prusgrass-nprd-dev-ad73dl-asia-east2-pruforce/prunews/Huangzhi.Li%40prudential.com.sg/668f7833911aaade55bf3c01.mp4?X-Goog-Algorithm=GOOG4-RSA-SHA256&amp;X-Goog-Credential=sa-8424-prusgrass-nprd%40prusgrass-nprd-dev-ad73dl-3eeb.iam.gserviceaccount.com%2F20240711%2Fauto%2Fstorage%2Fgoog4_request&amp;X-Goog-Date=20240711T061414Z&amp;X-Goog-Expires=604800&amp;X-Goog-SignedHeaders=host&amp;X-Goog-Signature=7d105c04c9156510cfb876f2280e0d482b3f9bc5d98a3f8754ddd6a11d085d473553bfbaae5887e9e8d0c44228260fd7e8ae0bea2df4065ab248b8b6744e40746f69ff2ab8858bf4c1464bdf1b4d1e8ca034b20c31211125fb3260f7bbaac1f77c033693c0e596bb0d36ee0b2f442259393d25a65b6789b161ffe6ef6ecd551d7a5dcf0f00f29c4579a5bc2186abda61b8778f14e503a26b4e4cd78154aaa583b7ce8ce552cd7d9ca90d1df9ae8a08a24374f8a045eea4f8e9122675a534b8ca8d8ee045d3b6c45409988eb8c84e54b11b28bd80d02328e39e59304b93a7c7d9552df5f389e473c0c6a23d69ec9638012714f45eb45fdd7a2c63ec085cf0c075#t=0.1&amp;blobId=668f7833911aaade55bf3c01" />\n  </video></p>',
          plainTextZhHk: '',
          plainTextEnUs: '',
        },
        publishDate: '2024-07-11T06:14:00.000Z',
        archiveDate: '2024-10-09T06:14:00.000Z',
        isSuggested: false,
        isHeadline: true,
        readingTime: {
          zhHk: 0,
          enUs: 0,
        },
        enquiryEmail: 'huangzhi.li@prudential.com.sg',
        likes: 0,
        bookmarked: 0,
        comments: 0,
        readCount: 1,
        createdBy: 'Huangzhi.Li@prudential.com.sg',
        updatedBy: 'hoi.kei.jasmine.ng@prudential.com.hk',
        status: 'Published',
        enrolmentType: 'AGENTS',
        eligibleSegments: {
          designation: [],
        },
        agentListFileName: 'Book3.xlsx',
        region: 'HK',
        channel: 'AGENCY',
        newsType: 'GENERAL',
        createdAt: '2024-07-11T06:15:01.740Z',
        updatedAt: '2024-07-23T07:00:55.288Z',
        noArchiveDate: false,
        rank: 70,
        category: {
          _id: '60648510fd8c1b62d87734b4',
          name: {
            enUs: 'Promotion',
            zhHk: '產品及推廣',
          },
          key: 'promotion',
          color: '#caabbb',
          createdAt: '2021-03-31T14:20:00.639Z',
          updatedAt: '2024-01-16T10:27:35.863Z',
          order: 2,
          emails: [
            {
              name: 'Promotion Team',
              address: 'heman.hm.tai@prudential.com.hkk',
            },
          ],
          channel: 'AGENCY',
          region: 'HK',
          maxNoOfHeadlines: 5,
          maxArchiveDays: 90,
          categoryId: '60648510fd8c1b62d87734b4',
        },
        newsId: '668f786519e601283a3584de',
        isPublished: true,
      },
      {
        _id: '668f71e019e601283a358233',
        group: 'training',
        jobName: 'CPD',
        description: 'CPD result sync from LMS',
        lastExecutionTime: '25/07/2024 13:55',
        lastExecutionStatus: 'Completed',
        operation: 'View Job History',
        triggers: 'Trigger1 Trigger2 Trigger3 Trigger4',
        tags: [],
        attachments: [
          {
            blobId: '668f7180911aaade55bf39a9',
            filename: 'Simulator Screenshot - iPhone 15 Pro - 2024-06-24 at 14.10.49.png.pdf',
          },
        ],
        eligibleAgents: [],
        excludedAgents: [],
        segments: [],
        thumbnail: {
          type: 'image',
        },
        banner: {
          blobId: '668f71da911aaade55bf39ae',
          type: 'image',
        },
        categoryId: '60648510fd8c1b62d87734b4',
        title: {
          zhHk: 'pdfdownload',
          enUs: 'pdfdownload',
        },
        content: {
          zhHk: '<p>test pdf download</p>',
          enUs: '<p>test pdf download</p>',
          plainTextZhHk: 'test pdf download',
          plainTextEnUs: 'test pdf download',
        },
        publishDate: '2024-07-11T05:45:00.000Z',
        archiveDate: '2024-10-09T05:45:00.000Z',
        isSuggested: false,
        isHeadline: false,
        readingTime: {
          zhHk: 1,
          enUs: 1,
        },
        enquiryEmail: 'huangzhi.li@prudential.com.sg',
        likes: 0,
        bookmarked: 0,
        comments: 0,
        readCount: 1,
        createdBy: 'Huangzhi.Li@prudential.com.sg',
        updatedBy: 'Huangzhi.Li@prudential.com.sg',
        status: 'Published',
        enrolmentType: 'SEGMENTS',
        eligibleSegments: {
          designation: ['G5', 'G4', 'G3', 'G2', 'G1L3', 'G1L2', 'G1L1', 'SIC2', 'SIC1', 'SLU', 'LU', 'LA'],
        },
        agentListFileName: '',
        region: 'HK',
        channel: 'AGENCY',
        newsType: 'GENERAL',
        createdAt: '2024-07-11T05:47:12.859Z',
        updatedAt: '2024-07-11T05:47:45.632Z',
        category: {
          _id: '60648510fd8c1b62d87734b4',
          name: {
            enUs: 'Promotion',
            zhHk: '產品及推廣',
          },
          key: 'promotion',
          color: '#caabbb',
          createdAt: '2021-03-31T14:20:00.639Z',
          updatedAt: '2024-01-16T10:27:35.863Z',
          order: 2,
          emails: [
            {
              name: 'Promotion Team',
              address: 'heman.hm.tai@prudential.com.hkk',
            },
          ],
          channel: 'AGENCY',
          region: 'HK',
          maxNoOfHeadlines: 5,
          maxArchiveDays: 90,
          categoryId: '60648510fd8c1b62d87734b4',
        },
        newsId: '668f71e019e601283a358233',
        isPublished: true,
      },
      {
        _id: '668f575819e601283a357fa1',
        group: 'training',
        jobName: 'productCert',
        description: 'product cert result sync from LMS',
        lastExecutionTime: '25/07/2024 13:55',
        lastExecutionStatus: 'Completed',
        operation: 'View Job History',
        triggers: 'Trigger1 Trigger2 Trigger3 Trigger4',
        tags: [],
        attachments: [],
        eligibleAgents: [],
        excludedAgents: [],
        segments: [],
        thumbnail: {
          type: 'image',
        },
        banner: {
          blobId: '668f558e911aaade55bf38c1',
          type: 'image',
        },
        categoryId: '60648510fd8c1b62d87734b4',
        title: {
          zhHk: 'pdftest',
          enUs: 'pdftest',
        },
        content: {
          zhHk: '<p><img src="https://storage.googleapis.com/prusgrass-nprd-dev-ad73dl-asia-east2-pruforce/prunews/Huangzhi.Li%40prudential.com.sg/668f5741911aaade55bf38c6.png?X-Goog-Algorithm=GOOG4-RSA-SHA256&amp;X-Goog-Credential=sa-8424-prusgrass-nprd%40prusgrass-nprd-dev-ad73dl-3eeb.iam.gserviceaccount.com%2F20240711%2Fauto%2Fstorage%2Fgoog4_request&amp;X-Goog-Date=20240711T035338Z&amp;X-Goog-Expires=604800&amp;X-Goog-SignedHeaders=host&amp;X-Goog-Signature=441810c0e3f6186091b45a95d38964036cd82e8fc9bcaa8dfc0b0cefbb2428ef081dc003ab36ae63723d7c3e556eb89a8ed83d101e097054b2e5dd3ab7e78b644bb0eb7fec7fc8620a6671c6040349c273fd2fc4cbc8fc0c3dee255b8ac5c9f962a8f33f4cfc87e5905b677685762948b11481d1f3e43ff3c935b749d78e410007e79596d46a2e1909df194adc6399e703e9ab72511557146a97c8395c8b5a0ea073f5f2668320e127e3db61e51568a199fde67221dabbc83e196c9c349f12b6d406d88c798666bb9d61344bf3a6287b82fc6f40710ab70960b27db3089aeab6a9c879c8d661eb28681f561bd0dec7074494c0929a5f79dda7053e2fad52a389" alt=""  data-blob-id=""> </img></p>',
          enUs: '<p><img src="https://storage.googleapis.com/prusgrass-nprd-dev-ad73dl-asia-east2-pruforce/prunews/Huangzhi.Li%40prudential.com.sg/668f5741911aaade55bf38c6.png?X-Goog-Algorithm=GOOG4-RSA-SHA256&amp;X-Goog-Credential=sa-8424-prusgrass-nprd%40prusgrass-nprd-dev-ad73dl-3eeb.iam.gserviceaccount.com%2F20240711%2Fauto%2Fstorage%2Fgoog4_request&amp;X-Goog-Date=20240711T035338Z&amp;X-Goog-Expires=604800&amp;X-Goog-SignedHeaders=host&amp;X-Goog-Signature=441810c0e3f6186091b45a95d38964036cd82e8fc9bcaa8dfc0b0cefbb2428ef081dc003ab36ae63723d7c3e556eb89a8ed83d101e097054b2e5dd3ab7e78b644bb0eb7fec7fc8620a6671c6040349c273fd2fc4cbc8fc0c3dee255b8ac5c9f962a8f33f4cfc87e5905b677685762948b11481d1f3e43ff3c935b749d78e410007e79596d46a2e1909df194adc6399e703e9ab72511557146a97c8395c8b5a0ea073f5f2668320e127e3db61e51568a199fde67221dabbc83e196c9c349f12b6d406d88c798666bb9d61344bf3a6287b82fc6f40710ab70960b27db3089aeab6a9c879c8d661eb28681f561bd0dec7074494c0929a5f79dda7053e2fad52a389" alt=""  data-blob-id=""> </img></p>',
          plainTextZhHk: '',
          plainTextEnUs: '',
        },
        publishDate: '2024-07-11T03:46:00.000Z',
        archiveDate: '2024-10-09T03:46:00.000Z',
        isSuggested: false,
        isHeadline: false,
        readingTime: {
          zhHk: 0,
          enUs: 0,
        },
        enquiryEmail: 'huangzhi.li@prudential.com.sg',
        likes: 0,
        bookmarked: 0,
        comments: 0,
        readCount: 1,
        createdBy: 'Huangzhi.Li@prudential.com.sg',
        updatedBy: 'Huangzhi.Li@prudential.com.sg',
        status: 'Published',
        enrolmentType: 'SEGMENTS',
        eligibleSegments: {
          designation: ['G5', 'G4', 'G3', 'G2', 'G1L3', 'G1L2', 'G1L1', 'SIC2', 'SIC1', 'SLU', 'LU', 'LA'],
        },
        agentListFileName: '',
        region: 'HK',
        channel: 'AGENCY',
        newsType: 'GENERAL',
        createdAt: '2024-07-11T03:54:00.376Z',
        updatedAt: '2024-07-11T03:54:31.230Z',
        category: {
          _id: '60648510fd8c1b62d87734b4',
          name: {
            enUs: 'Promotion',
            zhHk: '產品及推廣',
          },
          key: 'promotion',
          color: '#caabbb',
          createdAt: '2021-03-31T14:20:00.639Z',
          updatedAt: '2024-01-16T10:27:35.863Z',
          order: 2,
          emails: [
            {
              name: 'Promotion Team',
              address: 'heman.hm.tai@prudential.com.hkk',
            },
          ],
          channel: 'AGENCY',
          region: 'HK',
          maxNoOfHeadlines: 5,
          maxArchiveDays: 90,
          categoryId: '60648510fd8c1b62d87734b4',
        },
        newsId: '668f575819e601283a357fa1',
        isPublished: true,
      },
      {
        _id: '661e245dab27f0a370a25a2f',
        group: 'training',
        jobName: 'training_history',
        description: 'training history sync from LMS',
        lastExecutionTime: '25/07/2024 13:55',
        lastExecutionStatus: 'Completed',
        operation: 'View Job History',
        triggers: 'Trigger1 Trigger2 Trigger3 Trigger4',
        tags: [],
        attachments: [],
        eligibleAgents: [],
        excludedAgents: ['00010009'],
        thumbnail: {
          type: 'image',
        },
        banner: {
          blobId: '661e244c7a60495cbe5653bf',
          type: 'image',
        },
        categoryId: '60648510fd8c1b62d87734b4',
        title: {
          zhHk: 'New ArticleJUAN',
          enUs: 'New ArticleJUAN',
        },
        content: {
          zhHk: '<p><a href="https://www.google.com.hk/?hl=zh-HK" target="_blank" rel="noopener">Link</a></p>',
          enUs: '',
          plainTextZhHk: 'Link',
          plainTextEnUs: '',
        },
        publishDate: '2024-06-03T16:00:00.000Z',
        archiveDate: '2024-09-01T16:00:00.000Z',
        isSuggested: false,
        isHeadline: true,
        readingTime: {
          zhHk: 1,
          enUs: 0,
        },
        enquiryEmail: 'lijuan.liu@prudential.com.sg',
        likes: 0,
        bookmarked: 1,
        comments: 1,
        readCount: 2,
        createdBy: 'Lijuan.Liu@prudential.com.sg',
        updatedBy: 'ming.fai.yau@prudential.com.hk',
        status: 'Published',
        enrolmentType: 'SEGMENTS',
        eligibleSegments: {
          designation: ['G5', 'G4', 'G3', 'G2', 'G1L3', 'G1L2', 'G1L1', 'SIC2', 'SIC1', 'SLU', 'LU', 'LA'],
        },
        agentListFileName: '',
        region: 'HK',
        channel: 'AGENCY',
        newsType: 'GENERAL',
        createdAt: '2024-04-16T07:10:21.983Z',
        updatedAt: '2024-07-23T01:32:39.274Z',
        noArchiveDate: false,
        rank: 55,
        category: {
          _id: '60648510fd8c1b62d87734b4',
          name: {
            enUs: 'Promotion',
            zhHk: '產品及推廣',
          },
          key: 'promotion',
          color: '#caabbb',
          createdAt: '2021-03-31T14:20:00.639Z',
          updatedAt: '2024-01-16T10:27:35.863Z',
          order: 2,
          emails: [
            {
              name: 'Promotion Team',
              address: 'heman.hm.tai@prudential.com.hkk',
            },
          ],
          channel: 'AGENCY',
          region: 'HK',
          maxNoOfHeadlines: 5,
          maxArchiveDays: 90,
          categoryId: '60648510fd8c1b62d87734b4',
        },
        newsId: '661e245dab27f0a370a25a2f',
        isPublished: true,
      },
    ],
    totalRecords: 129,
    totalPages: 26,
    hasNextPage: true,
    hasPrevPage: false,
    page: 1,
  };
  //mock data end

  if (jwt.permissions) {
    const jwtPermission = map(jwt.permissions, (_, key) => key);
    updateNewsPermission = isPermitted([AdminScheduleJobPermissionType.SCHEDULE_JOB_UPDATE], jwtPermission);
    createNewsPermission = isPermitted([AdminScheduleJobPermissionType.SCHEDULE_JOB_CREATE], jwtPermission);
    updateHeadlinePermission = isPermitted(
      [AdminScheduleJobPermissionType.SCHEDULE_JOB_CREATE, AdminScheduleJobPermissionType.SCHEDULE_JOB_CREATE],
      jwtPermission,
    );
  }
  const copyIdClicked = async (rowId: string) => {
    navigator.clipboard && navigator.clipboard.writeText(rowId);
  };

  const onSetHeadlineClicked = (selectedData: NewsArticleItem) => {
    setHeadlineDialogId(selectedData.newsId);
    setHeadlineDialogData(selectedData);
  };

  const checkIfAllAgentAreSelectedForTarget = (data: NewsArticleItem) => {
    if (data.agentListFileName != '') {
      return false;
    } else if (data.eligibleAgents.length > 0 || data.excludedAgents.length > 0) {
      return false;
    } else if (data.eligibleSegments.designation.length < 12) {
      return false;
    } else {
      return true;
    }
  };

  const confirmSetHeadline = async (isTriggerPush: boolean) => {
    //Prompt user cannot send push as article is unpublished
    if (isTriggerPush && !headlineDialogData?.isPublished) {
      setHeadlineIsNotPublshedDialog(true);
    } else if (isTriggerPush && headlineDialogData && !checkIfAllAgentAreSelectedForTarget(headlineDialogData)) {
      setHeadlineIsNotSetForAllAgentDialog(true);
    } else {
      setHeadlineIsNotPublshedDialog(false);
      setHeadlineIsNotSetForAllAgentDialog(false);
      try {
        const data: NewsHeadlineItem = {
          newsId: headlineDialogId,
          triggerPush: isTriggerPush,
        };
        await setNewsAsHeadline(data);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: Translation('newsArticle.common.setAsHeadlineSuccess'),
            },
          ]),
        );
        setHeadlineDialogId('');
        onRefresh();
      } catch (err: any) {
        setErrorDialog((prev) => ({
          ...prev,
          show: true,
          message: err.errors.message,
        }));
        setHeadlineDialogId('');
        onRefresh();
      }
    }
  };

  const [errorDialog, setErrorDialog] = useState({
    show: false,
    message: '',
  });

  const onUnsetHeadlineClicked = async (newsId: string) => {
    try {
      await unsetNewsHeadline(newsId, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: Translation('newsArticle.common.unsetHeadlineSuccess'),
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      {formDialogState.open && (
        <>
          <Dialog open={formDialogState.open} classes={{ paper: classes.dialogContainer }}>
            <div className={classes.dialogContainer}>
              <div style={styles.dialogTitle}>Trigger 1</div>
              <div>
                <div style={styles.dialogSubTitle}>Trigger Name:</div>
                <div style={styles.dialogContent}>trigger name abcdefg</div>
              </div>
              <div>
                <div style={styles.dialogSubTitle}>Trigger Description:</div>
                <div style={styles.dialogContent}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                  release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                  software like Aldus PageMaker including versions of Lorem Ipsum.
                </div>
              </div>
              <div>
                <div style={styles.dialogSubTitle}>CronExpression:</div>
                <div style={styles.dialogContent}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a
                </div>
              </div>
              <div>
                <div style={styles.dialogSubTitle}>Trigger Data</div>
                <div style={styles.dialogContent}>JSON Object ...</div>
              </div>
              <div>
                <div style={styles.dialogSubTitle}>Priority:</div>
                <div style={styles.dialogContent}>1</div>
              </div>
              <div>
                <div style={styles.dialogSubTitle}>Trigger Time:</div>
                <div>Previous Fire Time: 25/07/2024 15:45</div>
                <div>Next Fire Time: 25/07/2024 16:45</div>
              </div>
              {/* <div className={classes.dialogTitle}>{Translation('newsArticle.common.reminder')}</div>
              <div className={classes.dialogContent}>{Translation('newsArticle.common.reminderContent')}</div> */}
              <div className={classes.btnContainer}>
                <Button
                  style={{ marginRight: 20 }}
                  variant="contained"
                  color="inherit"
                  onClick={() => {
                    setFormDialogState({ open: false });
                  }}
                >
                  {'DISABLE'}
                </Button>
                <Button
                  style={{ marginRight: 20 }}
                  variant="contained"
                  color="inherit"
                  disabled
                  onClick={() => {
                    setFormDialogState({ open: false });
                  }}
                >
                  {'ENABLE'}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setFormDialogState({ open: false });
                  }}
                >
                  {'RUN'}
                </Button>
              </div>
            </div>
          </Dialog>
        </>
      )}
      <PruTable
        // title={Translation('newsArticle.list')} hims mark
        title={'Schedule Job List'}
        disableBulkSelect
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('newsArticle.common.newNewsArticle'),
            onClick: () => {
              history.push(`${SCHEDULE_JOB_BASE_PATH}${SCHEDULE_JOB_BASE_PATH}/create`);
            },
            condition: () => createNewsPermission,
          },
        ]}
        operationDef={[
          {
            title: 'View Job History',
            tooltipText: 'View Job History',
            onClick: (row) =>
              // console.log(`${SCHEDULE_JOB_BASE_PATH}/history`),
              history.push(`list/history`),
            condition: () => true,
          },
        ]}
        columnDef={[
          {
            isId: true,
            keyIndex: 'newsId', //hims mark
            align: 'center',
            displayName: 'Group', //hims mark
            renderData: (row) => row.group || '-',
            // renderData: (row) => (
            //   <LargerFontTooltip title={row.newsId} style={{ fontSize: 20 }} placement="left">
            //     <IconButton
            //       onClick={() => {
            //         copyIdClicked(row.newsId);
            //       }}
            //     >
            //       <FileCopyIcon />
            //     </IconButton>
            //   </LargerFontTooltip>
            // ),
          },
          {
            keyIndex: 'category',
            align: 'center',
            displayName: 'Job Name',
            renderData: (row) => row.jobName || '-',
          },
          {
            keyIndex: 'category',
            align: 'center',
            displayName: 'Description',
            renderData: (row) => row.description || '-',
          },
          {
            keyIndex: 'publishDate',
            align: 'center',
            // displayName: Translation('newsArticle.common.publishDate'), //hims mark
            displayName: 'Last Execution Time',
            // renderData: (row) => (row.publishDate ? getDefaultDisplayDate(row.publishDate, 'datetime') : '-'),
            renderData: (row) => (row.lastExecutionTime ? row.lastExecutionTime : '-'),
          },
          {
            keyIndex: 'lastExecutionStatus',
            align: 'center',
            // displayName: Translation('newsArticle.common.publishDate'), //hims mark
            displayName: 'Last Execution Status',
            // renderData: (row) => (row.publishDate ? getDefaultDisplayDate(row.publishDate, 'datetime') : '-'),
            renderData: (row) => (row.lastExecutionStatus ? row.lastExecutionStatus : '-'),
          },
          {
            keyIndex: 'triggers',
            align: 'justify',
            // displayName: Translation('newsArticle.common.publishDate'), //hims mark
            displayName: 'Triggers',
            // renderData: (row) => (row.publishDate ? getDefaultDisplayDate(row.publishDate, 'datetime') : '-'),
            renderData: () => (
              <>
                <span
                  onClick={onClickSetHeadline}
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  Trigger 1
                </span>
                <span
                  onClick={onClickSetHeadline}
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  Trigger 2
                </span>
                <span
                  onClick={onClickSetHeadline}
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  Trigger 3
                </span>
                <span
                  onClick={onClickSetHeadline}
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  Trigger 4
                </span>
              </>
            ),
          },
          // {
          //   keyIndex: 'readCount',
          //   align: 'center',
          //   displayName: Translation('newsArticle.common.readCount'),
          //   renderData: (row) => String(row.readCount ? row.readCount : 0),
          // },
          // {
          //   keyIndex: 'likes',
          //   align: 'center',
          //   displayName: Translation('newsArticle.common.likes'),
          //   renderData: (row) => String(row.likes ? row.likes : 0),
          // },
          // {
          //   keyIndex: 'bookmarked',
          //   align: 'center',
          //   displayName: Translation('newsArticle.common.bookmarked'),
          //   renderData: (row) => String(row.bookmarked ? row.bookmarked : 0),
          // },
          // {
          //   keyIndex: 'status',
          //   align: 'center',
          //   displayName: Translation('newsArticle.common.status'),
          //   renderData: (row) => (row.status ? row.status : ''),
          // },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        // dataSource={newsArticleList?.newsList}
        dataSource={mockData.newsList}
        totalPages={newsArticleList?.totalPages}
        totalRecords={newsArticleList?.totalRecords}
        onChangePage={onChangePage}
      />
      <Dialog open={!!headlineDialogId} onClose={() => setHeadlineDialogId('')}>
        <div className={classes.dialogContainer}>{Translation('newsArticle.common.confirmSetHeadline')}</div>
        <div className={classes.btnContainer}>
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="secondary"
            onClick={() => confirmSetHeadline(true)}
          >
            {Translation('global.text.yes')}
          </Button>
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="secondary"
            onClick={() => confirmSetHeadline(false)}
          >
            {Translation('global.text.no')}
          </Button>
          <Button variant="contained" color="inherit" onClick={() => setHeadlineDialogId('')}>
            {Translation('global.text.cancel')}
          </Button>
        </div>
      </Dialog>
      <Dialog open={!!headlineIsNotPublshedDialog} onClose={() => setHeadlineIsNotPublshedDialog(false)}>
        <div className={classes.dialogContainer}>{Translation('newsArticle.common.selectedNewsIsNotPublished')}</div>
        <div className={classes.btnContainer}>
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="inherit"
            onClick={() => confirmSetHeadline(false)}
          >
            {Translation('global.text.okay')}
          </Button>
        </div>
      </Dialog>
      <Dialog open={!!headlineIsNotSetForAllAgentDialog} onClose={() => setHeadlineIsNotSetForAllAgentDialog(false)}>
        <div className={classes.dialogContainer}>{Translation('newsArticle.common.selectedNewsIsNotForAllAgent')}</div>
        <div className={classes.btnContainer}>
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="inherit"
            onClick={() => confirmSetHeadline(false)}
          >
            {Translation('global.text.okay')}
          </Button>
        </div>
      </Dialog>

      {/** Error Dialog */}
      <Dialog
        onClose={() => {
          setErrorDialog((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        open={errorDialog.show}
      >
        <div className={classes.errorContainer}>
          <div>{errorDialog.message}</div>
          <div className={classes.errorFooter}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setErrorDialog((prev) => ({
                  ...prev,
                  show: false,
                }));
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default NewsArticleList;
