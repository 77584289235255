import { useSelector } from 'react-redux';
import { useState, useCallback, useEffect, useMemo, useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
// import { getNextDay } from '../../../utils';
import dayjs from 'dayjs';
import { DateFormat } from 'src/app/common/constants';
import { RecruitmentStepProps } from '../template-detail-page.type';
import { get } from 'lodash';
import { RecruitmentTemplatePublishStatusEnum, RecruitmentTemplateStatusEnum } from '../template-detail-page.enum';
import qs from 'qs';
import { getNextDay } from '../../../utils';

export function useTemplateDetailStep1Hook(props: RecruitmentStepProps) {
  const { queryAction } = props;
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  const onboardingTemplate = useSelector((state: any) => get(state.config.data, 'Recruitment.onboardingTemplate', []));
  const formTypeOptions = onboardingTemplate.map((item: any) => item.type);
  // const validToValue = validDateTo ? dayjs(validDateTo).toDate() : undefined;

  const [formType, setFormType] = useState(props.type); // formTypeDefault
  const [noEndDate, setNoEndDate] = useState(false);
  const isView = props.queryAction === 'view';
  const fromDateDisabled = useMemo(() => {
    if (isView) {
      return true;
    }
    if (
      props.publishStatus === RecruitmentTemplatePublishStatusEnum.PUBLISHED &&
      props.status === RecruitmentTemplateStatusEnum.ACTIVE
    ) {
      return true;
    }
  }, [isView, props.publishStatus, props.status]);
  const [validDateFrom, setValidDateFrom] = useState<Date | null>(props.validDateFrom || null);
  const [validDateTo, setValidDateTo] = useState<Date | null>(props.validDateTo || null);

  const [validateText, setValidateText] = useState({
    fromError: '',
    toError: '',
  });

  const formTypeChangeHandler = useCallback(
    async ({ target }: { target: { value: string } }) => {
      setFormType(target.value);
      props.setTemplate({ type: target.value });
      if (props.onTypeChange) {
        const typeRes = await props.onTypeChange(target.value);
        const { earliestValidFrom, earliestValidTo } = typeRes;
        if (earliestValidFrom) {
          const _date = moment(earliestValidFrom, 'YYYY-MM-DD').utc().toDate();
          setValidDateFrom(_date);
          props.setTemplate({ validDateFrom: _date });
        } else {
          const initDate = getNextDay();
          setValidDateFrom(initDate || null);
          props.setTemplate({ validDateFrom: initDate || null });
        }
        if (earliestValidTo) {
          const _date = moment(earliestValidTo, 'YYYY-MM-DD').utc().toDate();
          setValidDateTo(_date);
          props.setTemplate({ validDateTo: _date });
        } else {
          setNoEndDate(true);
          setValidDateTo(null);
          props.setTemplate({ validDateTo: null });
        }
      }
    },
    [props],
  );
  useLayoutEffect(() => {
    const ref = setTimeout(() => {
      const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const queryAction = queryParams.action?.toString().toLowerCase();
      if (queryAction === 'edit') {
        return;
      }
      formTypeChangeHandler({ target: { value: props.type } });
    }, 0);
    return () => {
      clearTimeout(ref);
    };
  }, []);
  const startDateChangeHandler = useCallback(
    (value: Date | null) => {
      // setFormType(target.value);\valu
      setValidDateFrom(value);
      props.setTemplate({ validDateFrom: value });
    },
    [props],
  );
  const endDateChangeHandler = useCallback(
    (value: Date | null) => {
      // the endDate is the end time of the day, so we need to add 23h 59min 59 sec to the date
      const _date = value ? moment(value).endOf('day').toDate() : null;
      setValidDateTo(_date);
      props.setTemplate({ validDateTo: _date });
    },
    [props],
  );

  useEffect(() => {
    if (queryAction === 'view') {
      return;
    }
    const emptyStartDateError = !validDateFrom;
    const emptyEndDateError = !validDateTo && !noEndDate;
    const notTodayError = fromDateDisabled
      ? false
      : validDateFrom && moment(validDateFrom).toDate().getTime() <= moment().endOf('day').toDate().getTime();
    const timeRangeError =
      validDateFrom && validDateTo && moment(validDateTo).toDate().getTime() < moment(validDateFrom).toDate().getTime();
    const noError = !emptyStartDateError && !emptyEndDateError && !notTodayError && !timeRangeError;
    // const noError = !emptyStartDateError && !notTodayError;

    props.setValidate(noError);

    setValidateText({
      fromError: emptyStartDateError ? t('pdf_valid_tip1') : notTodayError ? t('pdf_valid_tip2') : '',
      toError: emptyEndDateError ? 'Please select a date' : timeRangeError ? t('component.form-date-compare') : '',
    });
  }, [validDateFrom, validDateTo, queryAction, noEndDate]);

  return {
    formType,
    validateText,
    formTypeOptions,
    validDateFrom,
    validDateTo,
    startDateChangeHandler,
    endDateChangeHandler,
    formTypeChangeHandler,
    isView,
    fromDateDisabled,
    noEndDate,
    setNoEndDate,
  };
}
