import React, { memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { ApplicationStatus, ApprovalEvents } from 'src/app/modules/Recruitment/types/approval-types';
import { BaseDialog } from '../base-dialog/base-dialog.component';
import { BaseProps } from '../base-dialog/base-dialog.hook';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

type ApprovalDialogProps = BaseProps & { applicationStatus: ApplicationStatus };

export const ApprovalDialog: React.FC<ApprovalDialogProps> = memo(
  ({ onRefresh, id, onClose, open, applicationStatus }: ApprovalDialogProps) => {
    const Translation = useTranslation();
    const remarks = useSelector((state: any) =>
      get(state.config.data, 'Recruitment.onboardingApplicationRemarks.approve', []),
    );

    const approvalEvents = useMemo(() => {
      switch (applicationStatus) {
        case ApplicationStatus.screeningPendingApproval:
          return ApprovalEvents.l1Approve;
        case ApplicationStatus.icPendingApproval:
          return ApprovalEvents.l2Approve;
        case ApplicationStatus.agentCodePendingApproval:
          return ApprovalEvents.l3Approve;
        case ApplicationStatus.l4PendingApproval:
          return ApprovalEvents.l4Approve;
        default:
          return ApprovalEvents.l1Approve;
      }
    }, [applicationStatus]);

    return (
      <BaseDialog
        open={open}
        id={id}
        title={Translation('onboarding.application.approval.detail.dialog.approve.title')}
        onClose={onClose}
        approvalEvents={approvalEvents}
        onRefresh={onRefresh}
        options={remarks}
      />
    );
  },
);
