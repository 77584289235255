import { CSSProperties } from 'react';
import { withStyles } from 'tss-react/mui';
import { TableCell } from '@mui/material';

export const IncentiveTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 'bold',
    borderBottom: 'none',
  },
  body: {
    backgroundColor: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 'bold',
    borderBottom: 'none',
  },
}));

export const IncentiveLeftStickyTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 'bold',
    borderBottom: 'none',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 2,
  },
  body: {
    backgroundColor: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 'bold',
    borderBottom: 'none',
    position: 'sticky',
    left: 0,
    zIndex: 2,
  },
} as Record<string, CSSProperties>));

export const IncentiveRightStickyTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 'bold',
    borderBottom: 'none',
    position: 'sticky',
    top: 0,
    right: 0,
    zIndex: 2,
  },
  body: {
    backgroundColor: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 'bold',
    borderBottom: 'none',
    position: 'sticky',
    right: 0,
    zIndex: 2,
  },
} as Record<string, CSSProperties>));
