import { Action, ThunkAction, createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { CONFIG_STORAGE_KEY } from 'src/app/common/constants';
import { TokenInfo, RegionChannelListItem } from '../types/auth-types';

export type AuthenticationState = {
  isExpired?: boolean;
  token?: string;
  user?: TokenInfo;
  regionChannelList?: RegionChannelListItem[];
};

export const authPersistConfig = {
  key: 'auth',
  storage
}

const initialState: AuthenticationState = {
  isExpired: undefined,
  token: undefined,
  user: undefined,
  regionChannelList: undefined,
};

type LoginAction = {
  payload: string;
};

type SetAgentAction = {
  payload: TokenInfo;
};

type SetRegionChannelListAction = {
  payload: RegionChannelListItem[];
};

type SetTokenExpiredAction = {
  payload: boolean;
};

export type AuthThunkAction = ThunkAction<void, AuthenticationState, unknown, Action<string>>;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate: (state: AuthenticationState, action: LoginAction) => {
      if (typeof window !== undefined) {
        window.localStorage.setItem('jwt', action.payload)
      };
      return {
        ...state,
        token: action.payload
      }
    },
    setTokenInfo: (state: AuthenticationState, action: SetAgentAction) => {
      return {
        ...state,
        user: action.payload
      };
    },
    setRegionChannelList: (state: AuthenticationState, action: SetRegionChannelListAction) => {
      return {
        ...state,
        regionChannelList: action.payload,
      };
    },
    setExpired: (state: AuthenticationState, action: SetTokenExpiredAction) => {
      return {
        ...state,
        isExpired: action.payload
      };
    },
    logout: () => {
      window.localStorage.removeItem('jwt');
      window.localStorage.removeItem('refreshToken');
      window.localStorage.removeItem('expireDate');
      window.localStorage.removeItem('abondonSession');
      window.localStorage.removeItem(CONFIG_STORAGE_KEY);
      return initialState;
    }
  }
});

export const {
  authenticate,
  setTokenInfo,
  setRegionChannelList,
  logout,
  setExpired
} = authSlice.actions;
