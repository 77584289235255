import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../../common/constants';
import ParamsProvider from '../../../../../../common/components/ParamsProvider';
import { ListPage } from './list/list.screen';
import { DetailPage } from './detail/detail.screen';
import { AI_TALKBOT_PATH, SALESKIT_BASE_PATH } from 'src/app/modules/sales-kit/constants';

const TalkbotDialogueRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${SALESKIT_BASE_PATH}${AI_TALKBOT_PATH}`}
        to={`${SALESKIT_BASE_PATH}${AI_TALKBOT_PATH}/list`}
      />
      <PruRoute exact path={`${SALESKIT_BASE_PATH}${AI_TALKBOT_PATH}/list`} component={ListPage} />
      <PruRoute exact path={`${SALESKIT_BASE_PATH}${AI_TALKBOT_PATH}/edit/:id`} component={DetailPage} />
      <PruRoute exact path={`${SALESKIT_BASE_PATH}${AI_TALKBOT_PATH}/create`} component={DetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default TalkbotDialogueRoutes;
