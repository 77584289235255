import React, { FC, useState, useMemo } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useList } from './list.hook';
import { DialogueListComponent } from './components/dialogue-list/dialogue-list.component';

type PageProps = ParamsProps;

export const ListPage: React.FC<PageProps> = (props: PageProps) => {
  // custom-hook
  const { loading, data, run, onSort } = useList();

  return <DialogueListComponent isLoading={loading} list={data ?? []} onSort={onSort} onRefresh={run} />;
};
