import { FC, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, FormControl, Select, MenuItem } from '@mui/material';
import { regionLocale, initI18nGenericData, I18nGenericData, RegionLocale } from 'src/app/i18n';
import { getConfigurations, useErrorHandler, ErrorFieldType, ErrorFieldDef } from 'src/app/common/utils';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { kpiPath } from '../../KpiRoutes';
import { KpiFormMode, KpiTypeEnum, KpiItem } from '../../../../types/kpi-types';
import { CreateUpdateKpiBody, createKpiItem, modifyKpiItem } from '../../../../network/kpiCrud';

type KpiFormProps = {
  formMode: KpiFormMode;
  kpiItem?: KpiItem;
};

type KpiFormState = {
  name: I18nGenericData<string>;
  key?: string;
  type?: KpiTypeEnum;
  values?: string;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof KpiFormState;
    value: any;
  };
};

type KpiFormAction = ModifyFieldAction;

const formReducer = (state: KpiFormState, action: KpiFormAction): KpiFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (regionLocale: RegionLocale[], kpiItem?: KpiItem): KpiFormState => {
  if (kpiItem) {
    return {
      name: kpiItem.name,
      key: kpiItem.key,
      type: kpiItem.type,
      values: kpiItem.values?.join(', '),
    };
  } else {
    const initI18nStringData = initI18nGenericData<string>(regionLocale);
    return {
      name: initI18nStringData,
      key: undefined,
      type: undefined,
      values: undefined,
    };
  }
};

const FIELD_CONTAINER_WIDTH = 160;
const FIELD_WIDTH = 200;
const CATEGORY_VALUE_FIELD_WIDTH = 400;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpacing: {
    marginBottom: 16,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
  },
  fieldBox: {
    minWidth: FIELD_WIDTH,
  },
  categoryValueFieldBox: {
    minWidth: CATEGORY_VALUE_FIELD_WIDTH,
  },
  noMargin: {
    margin: 0,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const KpiForm: FC<KpiFormProps> = ({ formMode, kpiItem }) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const dispatch = useDispatch();
  const history = useHistory();
  const enableCategory = !!getConfigurations()?.Incentive?.criteriaCategory?.type?.enableCategory;

  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(regionLocale, kpiItem));

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'key',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'type',
      fieldType: ErrorFieldType.MANDATORY,
    },
    ...(() => {
      let errorFieldDef: ErrorFieldDef[] = [];
      regionLocale.forEach((locale) => {
        errorFieldDef = [
          ...errorFieldDef,
          {
            name: `name-${locale}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () => !!!formState.name[locale],
          },
        ];
      });
      return errorFieldDef;
    })(),
  ]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: CreateUpdateKpiBody = {
        name: formState.name,
        key: formState.key as string,
        type: formState.type as KpiTypeEnum,
        values: formState.values || '',
      };
      try {
        if (formMode === KpiFormMode.CREATE) {
          await createKpiItem(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'KPI saved successfully',
              },
            ]),
          );
        } else if (kpiItem) {
          await modifyKpiItem(kpiItem._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `KPI updated successfully`,
              },
            ]),
          );
        }
        history.push(kpiPath);
      } catch (err) {}
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(`incentive.kpi.form.title.${formMode === KpiFormMode.CREATE ? 'create' : 'edit'}`)}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(kpiPath)}>
          {Translation('app.button.back')}
        </Button>
      </div>
      <div className={classes.sectionMargin}>
        {regionLocale.map((locale) => (
          <div key={`kpi-name-${locale}`} className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation(`incentive.kpi.common.name.${locale}`)}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                error={errorState.mandatory[`name-${locale}`]}
                className={`${classes.fieldBox} ${classes.noMargin}`}
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory[`name-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.name[locale] || ''}
                onChange={(e) => {
                  onDismissErrorHandler(`name-${locale}`, e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'name', value: { ...formState.name, [locale]: e.target.value } },
                  });
                }}
              />
            </div>
          </div>
        ))}
        <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('incentive.kpi.common.key')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              disabled={formMode === KpiFormMode.EDIT}
              error={errorState.mandatory.key}
              className={`${classes.fieldBox} ${classes.noMargin}`}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.key && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.key || ''}
              onChange={(e) => {
                onDismissErrorHandler('key', e.target.value);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'key', value: e.target.value },
                });
              }}
            />
          </div>
        </div>
        <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('incentive.kpi.common.type')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <FormControl
              disabled={formMode === KpiFormMode.EDIT}
              error={errorState.mandatory.type}
              className={`${classes.fieldBox} ${classes.noMargin}`}
              margin="dense"
              variant="outlined"
            >
              <Select
                value={formState.type || ''}
                onChange={(e) => {
                  onDismissErrorHandler('type', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'type', value: e.target.value },
                  });
                }}
              >
                {Object.values(KpiTypeEnum)
                  .filter((value) => enableCategory || value !== KpiTypeEnum.CATEGORY)
                  .map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      {enableCategory && formState.type === KpiTypeEnum.CATEGORY && (
        <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('incentive.kpi.common.category_value')} :</span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              className={`${classes.categoryValueFieldBox} ${classes.noMargin}`}
              margin="dense"
              variant="outlined"
              value={formState.values || ''}
              onChange={(e) => {
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'values', value: e.target.value },
                });
              }}
            />
          </div>
        </div>
      )}
      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default KpiForm;
