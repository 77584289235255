import { Dispatch } from 'react';
import { downloadFile } from 'src/app/common/utils';
import { ADMIN_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import qs from 'qs';
import moment from 'moment';
import { PaginateList } from '../types/recruit-generic-types';
import {
  TrainingFeeVerificationDetails,
  TrainingFeeVerificationListParam,
} from '../types/training-fee-verification-types';

const TrainingFeeVerification = 'training-fee-verification';

// const ADMIN_URL = 'http://localhost:3000';

export const fetchTrainingFeeVerificationList = async (
  params: TrainingFeeVerificationListParam,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  return apiClient
    .get<PaginateList<TrainingFeeVerificationListParam>>(`${ADMIN_URL}/${TrainingFeeVerification}`, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadTrainingFeeVerificationList = async (params: TrainingFeeVerificationListParam): Promise<void> => {
  const query = qs.stringify(params);
  const queryPath = `${ADMIN_URL}/${TrainingFeeVerification}/downloads?${query}`;
  const fileName = `Onboarding Training Fee ${moment().format('DDMMYYYY')}`;
  return downloadFile(fileName, queryPath);
};

export const fetchTrainingFeeDetail = async (
  submissionId: string,
  dispatch?: Dispatch<any>,
): Promise<TrainingFeeVerificationDetails> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${TrainingFeeVerification}/${submissionId}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateTrainingFeeVerificationStatus = async (
  body: { approvalEvents: string; remark: string },
  submissionId: string,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${TrainingFeeVerification}/${submissionId}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
