import { Translation } from 'src/app/i18n';
import { statusType } from '../../../types/license-exam-types';
import { RegistrationApprovalStatesEnum } from '../../../enum/license-exam.enum';

export function getStatusLabel(status: string, enableApproval: boolean): string {
  let statusLabel = '-';
  if (status) {
    if (status.toLowerCase() === RegistrationApprovalStatesEnum.REGISTERED && enableApproval) {
      statusLabel = Translation('recruitment.exam.registration.status.approved');
    } else {
      statusLabel = Translation(
        `recruitment.exam.registration.status.${RegistrationApprovalStatesEnum[status as statusType]}`,
      );
    }
  }
  return statusLabel;
}
