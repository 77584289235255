import React from 'react';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { TypeGapPeriod } from './type';
import { Translation } from 'src/app/i18n';
import moment from 'moment';
import { DateFormat } from 'src/app/common/constants';

interface GapDescriptionProps {
  gapsData: TypeGapPeriod[];
}
export const popupGapReminder = async (gapsData: TypeGapPeriod[]) => {
  if (gapsData?.length) {
    const result = await ModalService.confirm({
      title: Translation('Common.error'),
      description: <GapDescription gapsData={gapsData} />,
      hideCancelButton: true,
      onConfirm: () => {
        // do something
      },
    });
  }
};
const GapDescription: React.FC<GapDescriptionProps> = ({ gapsData }) => {
  return (
    <div className="tw-text-sm tw-font-normal tw-leading-5 tw-text-center tw-text-black">
      <p>{Translation('Recruitment.no_template_available_below_time_period')}</p>
      {gapsData.map((gap, gapIndex) => (
        <div key={gapIndex} className="tw-flex tw-flex-col tw-flex-1 tw-justify-center tw-items-center">
          <br />
          <p>
            {Translation('onboarding.common.formType')}: {gap?.type}
          </p>
          {gap.gapPeriods.map((period, periodIndex) => (
            <p key={periodIndex} className="tw-text-left tw-w-50">
              {periodIndex + 1}. {moment(period.from, 'YYYY-MM-DD').format(DateFormat.date)} to{' '}
              {period.to ? moment(period.to, 'YYYY-MM-DD').format(DateFormat.date) : 'N/A'}
            </p>
          ))}
        </div>
      ))}
      <br />
      <p>{Translation('Recruitment.default_template_displayed_no_action')}</p>
    </div>
  );
};
