import React from 'react';
import PruTable from '../../../../../../../common/components/PruTable/PruTable';

interface ParticipantProps {
  dataSource: any[];
}

const onRefresh = () => {};
export const ImportFailedList: React.FC<ParticipantProps> = ({ dataSource }) => {
  // dataSource = [...dataSource,...dataSource,...dataSource,...dataSource]
  return (
    <PruTable
      disableToolbar={true}
      disablePagination
      disableRefresh
      disableBulkSelect={true}
      operationDef={[]}
      onRefresh={onRefresh}
      isLoading={false}
      dataSource={dataSource}
      totalPages={1}
      totalRecords={dataSource.length}
      hideBulkSelectHeader={true}
      columnDef={[
        {
          keyIndex: `agentCode`,
          align: 'center',
          // displayName: Translation(`agencyCampaign.common.designation`),
          displayName: 'Agent Code',
          renderData: (row) => row?.agentCode,
        },
        {
          keyIndex: 'businessName',
          align: 'center',
          displayName: 'Business Name',
          renderData: (row) => row?.businessName,
        },
        {
          keyIndex: 'unitCode',
          align: 'center',
          displayName: 'Unit Code',
          renderData: (row) => row?.unitCode,
        },
        {
          keyIndex: 'notEligibleReason',
          align: 'center',
          displayName: 'Ineligibility Reason',
          renderData: (row) => row?.notEligibleReason || row?.reason,
        },
      ]}
    />
  );
};
