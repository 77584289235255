import {
  NotificationDetail,
  PaginateList,
  i18nConfigItem,
  OnTop,
  NotificationTemplateListItem,
  NotificationTemplateItem,
} from '../types/notification-types';
import { apiClient, apiErrorHandler, NOTIFICATION_URL, ADMIN_URL } from 'src/app/common/network';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';

export type NotificationListParams = {
  id?: string;
  msgTitle?: string;
  modifier?: string;
  inboxStatus?: string;
  pageNum?: number;
  pageSize?: number;
  sortItem?: string;
  sortOrder?: string;
  category?: string;
  from?: string;
  sendingTimeStart?: Date | string | null;
  sendingTimeEnd?: Date | string | null;
  descSortItems?: string;
  ascSortItems?: string;
};

export type AutoNotificationTemplate = {
  id?: string;
};

export const DOWNLOAD_TEMPLATE_URL = `${NOTIFICATION_URL}/messages/variable-content-template`;

export const DOWNLOAD_LOG_URL = `${NOTIFICATION_URL}/messages/message-logs`;

export const UPLOADED_FILE_URL = `${NOTIFICATION_URL}/messages/variable-contents`;

export const fetchNotificationList = async (
  params: NotificationListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList> => {
  return apiClient
    .get(`${NOTIFICATION_URL}/messages`, {
      params,
    })
    .then((result: any) => {
      const { ret, data } = result.data;
      if (ret === 1) {
        return data;
      }
      throw new Error('response error');
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNotificationTemplateList = async (
  dispatch?: Dispatch<any>,
): Promise<NotificationTemplateListItem> => {
  return apiClient
    .get(`${ADMIN_URL}/auto-notification`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNotification = async (id: string, dispatch?: Dispatch<any>): Promise<NotificationDetail> => {
  return apiClient
    .get(`${NOTIFICATION_URL}/messages/${id}`)
    .then((result: AxiosResponse<{ ret: number; data: NotificationDetail }>) => {
      const { ret, data } = result.data;
      if (ret === 1) {
        return data;
      }
      throw new Error('response error');
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNotificationTemplate = async (
  template: string,
  dispatch?: Dispatch<any>,
): Promise<NotificationTemplateItem[]> => {
  return apiClient
    .get(`${ADMIN_URL}/auto-notification/${template}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNotification = async (data: NotificationDetail, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .post(`${NOTIFICATION_URL}/messages`, {
      ...data,
    })
    .then((result: AxiosResponse<{ ret: number }>) => {
      const { ret } = result.data;
      if (ret === 1) {
        return true;
      }
      throw new Error('response error');
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateNotification = async (
  data: NotificationDetail,
  id: string,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  return apiClient
    .patch(`${NOTIFICATION_URL}/messages/${id}`, {
      ...data,
    })
    .then(() => {
      return true;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateNotificationTemplate = async (
  template: string,
  data: { items: NotificationTemplateItem[] },
  dispatch?: Dispatch<any>,
): Promise<any> => {
  return apiClient
    .put(`${ADMIN_URL}/auto-notification/${template}`, data)
    .then((res) => {
      return res.status;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNotificationI18Config = async (dispatch?: Dispatch<any>): Promise<Array<i18nConfigItem>> => {
  return apiClient
    .get(`${NOTIFICATION_URL}/configs/langs`)
    .then((result: AxiosResponse<Array<i18nConfigItem>>) => {
      return result.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const uploadExcel = (
  params: { file: File; id: string; inclusive?: boolean; contentType?: string },
  dispatch?: Dispatch<any>,
) => {
  const { file, inclusive, contentType } = params;
  const data = new FormData();
  data.append('file', file);
  if (inclusive !== undefined) {
    data.append('inclusive', (inclusive || false).toString());
  }
  if (contentType !== undefined) {
    data.append('contentType', contentType.toString());
  }
  return apiClient
    .post(`${NOTIFICATION_URL}/messages/variable-contents`, data)
    .then((result: AxiosResponse<{ ret: number; data: { importId: string; fileName: string; url: string } }>) => {
      const { ret, data } = result.data;
      if (ret === 1) {
        return { id: data.importId, fileName: data.fileName, url: `${UPLOADED_FILE_URL}/${data.importId}` };
      }
      throw new Error('upload fail');
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export async function setOnTop(params: OnTop, id: string, dispatch?: Dispatch<any>) {
  return apiClient
    .patch(`${NOTIFICATION_URL}/messages/${id}/message-top`, {
      ...params,
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
}

export function downloadTemplate(
  fileName = '',
  url: string,
  params?: any | {},
  dispatch?: Dispatch<any>,
  Translation?: any,
) {
  function downloadURL(url: string, name = '') {
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    if ('download' in document.createElement('a')) {
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // 对不支持download进行兼容
      link.target = '_blank';
      click(link);
    }
  }
  // clone https://github.com/eligrey/FileSaver.js/blob/master/src/FileSaver.js
  function click(node: any) {
    try {
      node.dispatchEvent(new MouseEvent('click'));
    } catch (e) {
      const evt = document.createEvent('MouseEvents');
      evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80, 20, false, false, false, false, 0, null);
      node.dispatchEvent(evt);
    }
  }

  return apiClient
    .request({ url, responseType: 'blob', params })
    .then((resp) => {
      const temp: BlobPart = resp.data || resp;
      return new Blob([temp], { type: resp.data.type });
    })
    .then((blob) => {
      if ('msSaveOrOpenBlob' in navigator) {
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(blob, fileName);
        return;
      }
      return URL.createObjectURL(blob);
    })
    .then((url) => {
      if (!url) return;
      downloadURL(url, fileName);
      URL.revokeObjectURL(url);
    })
    .catch(async (err) => {
      const reader = new FileReader();

      reader.onload = function () {
        if (reader.result === '413') {
          const errObj = {
            statusCode: 413,
            // eslint-disable-next-line
            message: (Translation && Translation('err.message.export.auto.notificaton')) || err.response,
            code: '413',
            errors: [],
          };

          throw apiErrorHandler(errObj, dispatch);
        }
      };
      reader.readAsText(err);
      return Promise.reject(err);
    });
}

export const exportNotificationList = async (
  fileName = '',
  data?: any | {},
  dispatch?: Dispatch<any>,
  Translation?: any,
) => {
  const url = `${NOTIFICATION_URL}/messages/message-export`;
  return downloadTemplate(fileName, url, data, dispatch, Translation);
};
