import { Dispatch } from 'react';
import { getDayStart, getDayEnd } from 'src/app/common/utils';
import { ADMIN_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { forEach, isArray } from 'lodash';
import { PaginateList } from 'src/app/common/types/common-types';
import {
  CampaignItem,
  CampaignDropdownType,
  CampaignDropdownList,
  MaximumLeadsItem,
  CampaignLeadItem,
  LeadWarningMessageItem,
  DropLeadRuleInterface,
  TalkbotRuleInterface,
} from '../types/campaign-types';
import { getConfig } from 'src/app/i18n';

const campaignEndpoint = 'campaigns';
const leadEndpoint = 'leads';

export type CampaignListParam = {
  name: string;
  startDateFrom: Date | null;
  startDateTo: Date | null;
  endDateFrom: Date | null;
  endDateTo: Date | null;
  sort: string;
  status: string;
  page: number;
  limit: number;
  types?: string[];
};

export type CampaignDropdownListParam = {
  type: CampaignDropdownType[];
  parent?: string;
};

export type CampaignLeadListParam = {
  firstName: string;
  lastName: string;
  // phoneNumber: string;
  agentCode: string;
  leadDateFrom: Date | null;
  leadDateTo: Date | null;
  status: string;
  isActive: string;
  page: number;
  limit: number;
};

export type ConfigParam = {
  name: string;
  namespace: string;
};

export type AttachmentBody = {
  blobId: string;
  filename: string;
};

export type CampaignFaqBody = {
  question: string;
  answer: string;
};

export type CampaignLinkBody = {
  title: string;
  link: string;
};

export type CampaignPriorityBody = {
  step: string;
};

export type CampaignFlyerBody = {
  title: string;
  attachments?: AttachmentBody[];
};

export type CampaignMessageBody = {
  title: string;
  body: string;
};

export type CreateCampaignBody = {
  campaignId: string;
  name: string;
  campaignType?: string;
  description: string;
  regionalCampaignName?: string;
  type: string;
  sort?: string;
  startDate: Date | null;
  endDate: Date | null;
  launchDate?: Date;
  emailMessage?: CampaignMessageBody;
  notificationMessage?: CampaignMessageBody;
  mandatoryData: Array<string>;
  isNewLeadNotificationEnabled: boolean;
  callScripts?: string;
  saleTipsInsight?: string;
  faq: CampaignFaqBody[];
  links: CampaignLinkBody[];
  priorities: CampaignPriorityBody[];
  flyers: CampaignFlyerBody[];
  image?: AttachmentBody;
  maxLeadPerAgent?: number;
  marketingOwnerName: string;
  marketingOwnerEmail: string;
  status: string;
  source: string;
  subSource?: string[];
  acceptSLA?: string;
  contactSLA?: string;
  meetSLA?: string;
  proposalSLA?: string;
  applicationSLA?: string;
  customerIncentive?: string;
  agentIncentive?: string;
  isSelfCreateLeadEnabled: boolean;
  maximumMarketingLeads?: MaximumLeadsItem;
  maximumLeadSourceLeads?: MaximumLeadsItem;
  isAutoAssignLeadIfAgentInactive?: boolean;
  isLeadWithPartialInfo: boolean;
  leadWithPartialInfoWarning?: LeadWarningMessageItem;
  leadWithPartialCondition?: any;
  distributionRule?: string;
  eligibilityRule?: string;
  leadDedupRule?: string;
  referralAgentCheck?: string;
  customerDedupRule?: string[];
  customerDedup?: string;
  exclusionRule?: string;
  isEngagementRequired: boolean;
  isStaging: boolean;
  leadAction: string;
  prospectCategory?: string;
  distributionChannel?: string;
  targetProductType?: string[];
  feedbacks?: string[];
  feedbackGroup?: string;
  roundRobinMode: string;
  autoDropRule?: DropLeadRuleInterface;
  createdBy: string;
  updatedBy: string;
  talkbotRule?: TalkbotRuleInterface;
};

export type UpdateCampaignBody = {
  campaignId: string;
  name: string;
  campaignType?: string;
  description: string;
  regionalCampaignName?: string;
  type: string;
  sort?: string;
  startDate: Date | null;
  endDate: Date | null;
  launchDate?: Date;
  emailMessage?: CampaignMessageBody;
  notificationMessage?: CampaignMessageBody;
  mandatoryData: Array<string>;
  isNewLeadNotificationEnabled: boolean;
  callScripts?: string;
  saleTipsInsight?: string;
  faq: CampaignFaqBody[];
  links: CampaignLinkBody[];
  priorities: CampaignPriorityBody[];
  flyers: CampaignFlyerBody[];
  image?: AttachmentBody;
  maxLeadPerAgent?: number;
  marketingOwnerName: string;
  marketingOwnerEmail: string;
  status: string;
  source: string;
  subSource?: string[];
  acceptSLA?: string;
  contactSLA?: string;
  meetSLA?: string;
  proposalSLA?: string;
  applicationSLA?: string;
  customerIncentive?: string;
  agentIncentive?: string;
  isSelfCreateLeadEnabled: boolean;
  maximumMarketingLeads?: MaximumLeadsItem;
  maximumLeadSourceLeads?: MaximumLeadsItem;
  isAutoAssignLeadIfAgentInactive?: boolean;
  isLeadWithPartialInfo: boolean;
  leadWithPartialInfoWarning?: LeadWarningMessageItem;
  leadWithPartialCondition?: any;
  distributionRule?: string;
  eligibilityRule?: string;
  leadDedupRule?: string;
  referralAgentCheck?: string;
  customerDedupRule?: string[];
  customerDedup?: string;
  exclusionRule?: string;
  isEngagementRequired: boolean;
  isStaging: boolean;
  leadAction: string;
  prospectCategory?: string;
  distributionChannel?: string;
  targetProductType?: string[];
  feedbacks?: string[];
  feedbackGroup?: string;
  roundRobinMode: string;
  autoDropRule?: DropLeadRuleInterface;
  updatedBy: string;
  talkbotRule?: TalkbotRuleInterface;
};

export type UpdateCampaignStatusBody = {
  status: string;
  updatedBy: string;
};

/**
 * build query path for getting campaign list
 * @param queryPath
 * @param params
 * @returns
 */
const buildQueryPath = (queryPath: string, params: CampaignListParam) => {
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param as Date).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param as Date).toISOString())}&`;
        }
      }
      if (isArray(param) && key === 'types') {
        param.forEach((value) => {
          queryPath += `${key}=${value}&`;
        });
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });
  return queryPath;
};

export const fetchCampaignList = async (
  params: CampaignListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<CampaignItem>> => {
  const queryPath = buildQueryPath(`${ADMIN_URL}/${campaignEndpoint}?`, params);
  return apiClient
    .get<PaginateList<CampaignItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignFullList = async (
  params: CampaignListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<CampaignItem>> => {
  const queryPath = buildQueryPath(`${ADMIN_URL}/${campaignEndpoint}/list?`, params);
  return apiClient
    .get<PaginateList<CampaignItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignItem = async (id: string, dispatch?: Dispatch<any>): Promise<CampaignItem> => {
  return apiClient
    .get<CampaignItem>(`${ADMIN_URL}/${campaignEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignDropdownList = async (
  params: CampaignDropdownListParam,
  dispatch?: Dispatch<any>,
): Promise<CampaignDropdownList> => {
  return apiClient
    .get<CampaignDropdownList>(`${ADMIN_URL}/${campaignEndpoint}/dropdown`, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignLeadList = async (
  id: string,
  params: CampaignLeadListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<PaginateList<CampaignLeadItem>> => {
  let queryPath = `${ADMIN_URL}/${campaignEndpoint}/${id}/lead?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });

  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `leadDate:desc`;
  }

  return apiClient
    .get<PaginateList<CampaignLeadItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLeadItem = async (id: string, dispatch?: Dispatch<any>): Promise<CampaignLeadItem> => {
  return apiClient
    .get<CampaignLeadItem>(`${ADMIN_URL}/${leadEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchConfig = async (params: ConfigParam, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${campaignEndpoint}/config`, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const checkDuplicateCampaign = async (id: string, dispatch?: Dispatch<any>): Promise<boolean> => {
  return apiClient
    .get<boolean>(`${ADMIN_URL}/${campaignEndpoint}/duplicate-check/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createCampaign = async (body: CreateCampaignBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${campaignEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyCampaign = async (id: string, body: UpdateCampaignBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${campaignEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateCampaignStatus = async (
  id: string,
  body: UpdateCampaignStatusBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${campaignEndpoint}/${id}/status`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteCampaign = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${campaignEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const exportCampaignLeadList = async (
  id: string,
  filterParams: CampaignLeadListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
  eachMaxLimit?: boolean,
): Promise<any> => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const lang = getConfig().selectedLang;
  let queryPath = `${ADMIN_URL}/${campaignEndpoint}/${id}/lead/export-csv?timeZone=${timezone}&lang=${lang}&`;
  forEach(filterParams, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });

  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `leadDate:desc`;
  }

  const requestConfig: any = {
    responseType: 'blob',
  };

  const apiRequest = eachMaxLimit
    ? apiClient.post(queryPath, {}, requestConfig)
    : apiClient.get(queryPath, requestConfig);

  return apiRequest
    .then((response) => response)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
