import { FC, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { KpiFormMode, KpiItem } from '../../../types/kpi-types';
import { fetchKpiItem } from '../../../network/kpiCrud';
import KpiForm from './components/KpiForm';

const KpiDetailPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? KpiFormMode.EDIT : KpiFormMode.CREATE,
    }),
    [id],
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [kpiItem, setKpiItem] = useState<KpiItem>();

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const res = await fetchKpiItem(id, dispatch);
        setKpiItem(res);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    reloadData();
  }, [id]);

  return (
    <>
      {formMode === KpiFormMode.CREATE && <KpiForm formMode={formMode} />}
      {formMode === KpiFormMode.EDIT &&
        (isLoading || !kpiItem ? <LayoutSplashScreen /> : <KpiForm formMode={formMode} kpiItem={kpiItem} />)}
    </>
  );
};

export default KpiDetailPage;
