import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 24,
    marginBottom: 32,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  wrap: {
    marginBottom: 24,
    marginTop: 0,
  },
  itemTitle: {
    fontSize: '16px',
    fontWeight: '600',
  },
  itemContent: {
    fontSize: '16px',
    fontWeight: '500',
    marginTop: '12px',
  },
  list: {
    paddingLeft: 16,
    listStyleType: 'disc',
  },
  buttonMargin: {
    marginLeft: 24,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 8,
  },
}));
