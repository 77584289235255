import React, { FC } from 'react';
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, Tooltip } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { SectionItem, SectionFieldItem, RuleItem, RuleKeyEnum, RuleTypeEnum, OperatorEnum } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';

type SectionProps = {
  sections: SectionItem[];
  sectionIndex: number;
  key: string;
  title: string;
  priority: number;
  fieldPool: SectionFieldItem[];
  setSections: (sections: SectionItem[]) => void;
  setSelectFieldDialogState: (obj: any) => void;
  setEditFieldDialogState: (obj: any) => void;
}

const useSectionStyles = makeStyles()((theme) => ({
  sectionContainer: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.05)',
    padding: '20px 20px 10px',
    marginBottom: 20,
  },
  sectionRowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  headerRowFont: {
    fontWeight: 'bold',
  },
  flexCellContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  moveBtnContainer: {
    padding: 0,
  },
  moveBtn: {
    margin: '5px',
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#e0e0e0'
    }
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    whiteSpace: 'nowrap'
  },
}));

const Section: FC<SectionProps> = ({ sections, sectionIndex, key, title, priority, fieldPool, setSections, setSelectFieldDialogState, setEditFieldDialogState }) => {
  const { classes } = useStyles();
  const { classes: sectionClasses } = useSectionStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const swapArray = (index1: number, index2: number) => {
    const newSections = [...sections];
    const newSectionFields = newSections[sectionIndex].sectionFields;
    newSectionFields[index1] = newSectionFields.splice(index2, 1, newSectionFields[index1])[0];
    newSectionFields[index1].priority = index1 + 1;
    newSectionFields[index2].priority = index2 + 1;
    setSections(newSections);
  }

  const handleRemove = (index: number) => {
    const newSections = [...sections];
    const newSectionFields = newSections[sectionIndex].sectionFields;
    newSectionFields.splice(index, 1);
    newSectionFields.slice(index).forEach(field => {
      field.priority -= 1;
    });
    setSections(newSections);
  }

  const renderRuleIncluded = (rules: RuleItem[]) => {
    let ruleTitleList = rules.reduce((result: string[], rule: RuleItem) => {
      switch (rule.key) {
        case RuleKeyEnum.MANDATORY:
          if (rule.value === true) {
            result.push(Translation(`agencyCampaign.rule.${rule.key}`));
          }
          break;
        case RuleKeyEnum.MANDATORY_DEPENDS:
          if (rule.dependsOn) {
            result.push(`Depends on ${Translation(`agencyCampaign.field.${rule.dependsOn}`)}`)
          }
          break;
        case RuleKeyEnum.COMPARE:
          if (rule.type === RuleTypeEnum.DATE) {
            let validationDateStr = '';
            switch (rule.dependsOn) {
              case 'submitDate':
                validationDateStr = 'submit date(exclude weekends)';
                break;
              case 'campaignStartDate':
                validationDateStr = 'start date';
                break;
              default:
                break;
            }
            if (rule.value === 0) {
              if (rule.operator === OperatorEnum.EQ) {
                result.push(
                  intl.formatMessage(
                    { id: "agencyCampaign.campaignType.section.editField.sameDateAsValidationDate" },
                    { validationDateStr }
                  )
                );
              }
            } else {
              result.push(`
                ${rule.value}
                ${intl.formatMessage(
                  { id: "agencyCampaign.campaignType.section.editField.daysCompareToValidationDate" },
                  {
                    compareTo: rule.operator === OperatorEnum.LTE ? 'before' : 'after',
                    validationDateStr
                  }
                )}
              `);
            }
          }
          break;
        case RuleKeyEnum.LENGTH:
          result.push(
            intl.formatMessage(
              { id: "agencyCampaign.campaignType.section.editField.noMoreThanNCharacters" },
              { limit: rule.value as number }
            )
          );
          break;
        default:
          break;
      }

      return result;
    }, []);


    return ruleTitleList.join(', ');
  }

  return (
    <div className={sectionClasses.sectionContainer}>
      <div className={classes.rowContainer}>
        <div className="col">
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation("agencyCampaign.campaignType.section.name")}<span className={classes.mandatory}>*</span> :</span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              disabled={true}
              margin="dense"
              variant="outlined"
              value={title}
            />
          </div>
        </div>
      </div>

      <div className={`${sectionClasses.sectionRowContainer} ${sectionClasses.headerRowFont}`}>
        <div className="col-5">
          {Translation('agencyCampaign.campaignType.section.fields')}
        </div>
        <div className="col-5">
          {Translation('agencyCampaign.campaignType.section.ruleIncluded')}
        </div>
        <div className="col-2">
          {Translation('section.common.operation')}
        </div>
      </div>

      {(sections[sectionIndex].sectionFields || []).map((field, fieldIndex) => (
        <div className={sectionClasses.sectionRowContainer}>
          <div className="col-5">
            <div className={sectionClasses.flexCellContainer}>
              <div className={`col-1 ${sectionClasses.moveBtnContainer}`}>
                <span>
                  {fieldIndex === 0 ? null :
                    <ArrowUpward
                      className={sectionClasses.moveBtn}
                      onClick={() => { swapArray(fieldIndex, fieldIndex - 1) }}
                    />
                  }
                </span>
              </div>
              <div className="col-11">
                <span>{Translation(`agencyCampaign.field.${field.key}`)}</span>
              </div>
            </div>
          </div>

          <div className="col-5">
            {renderRuleIncluded(field.rules)}
          </div>

          <div className={`col-2 ${sectionClasses.flexCellContainer}`}>
            <Tooltip title="Edit Field" style={{ paddingRight: 20 }}>
              <div
                className={`${sectionClasses.operationBtn}`}
                onClick={() => setEditFieldDialogState({ open: true, sectionIndex, fieldIndex })}
              >
                {Translation('agencyCampaign.campaignType.section.edit')}
              </div>
            </Tooltip>
            {field.removable &&
              <Tooltip title="Remove Field">
                <div
                  className={`${sectionClasses.operationBtn}`}
                  onClick={() => handleRemove(fieldIndex)}
                >
                  {Translation('agencyCampaign.campaignType.section.remove')}
                </div>
              </Tooltip>
            }
          </div>
        </div>
      ))}

      <div className={sectionClasses.sectionRowContainer}>
        <div className="col">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setSelectFieldDialogState({ open: true, sectionIndex })}
          >
            {Translation('agencyCampaign.campaignType.section.addFromExistingFields')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Section;