import { BDMdashboardName, LMdashboardName, TopDashboardDetail } from './dashboard-types';

//please revise all dashboardLeft,dashboardRight,chartID into uat
//notice :
//'Both' -> both are numbers
//'Among' -> one number and one table
//'Unique' -> only one table

export enum LostReportTop {
  type = 'Both',
  dashboardLeft = '62e80a26-41d9-4e11-8e7f-5b0ac5e73277',
  dashboardRight = '6267bd98-d717-4757-8bff-7d34608e2384',
}

export enum SubmissionReportTop {
  type = 'Both',
  dashboardLeft = '1d9a81b7-6c42-4a5f-b2f2-ff42604f3c24',
  dashboardRight = '8f2af670-2ef7-454d-b3f6-4e927ef5e5b8',
}

export enum SubsourceTop {
  type = 'Both',
  dashboardLeft = '6273854d-2c86-442a-8275-5b28174f1bbd',
}

export enum AssignedTodayTop {
  type = 'Both',
  dashboardLeft = 'db45b716-bffb-4f64-8323-ed00315c9bb8',
}

export enum WeekCreateTop {
  type = 'Both',
  dashboardLeft = '6274f2de-c2b3-41a1-83a1-70e4924fc069',
}

export enum AverageTop {
  type = 'Both',
  dashboardLeft = '6274ea1f-f164-469e-8d95-81f43ee918de',
}

export enum StatusTop {
  type = 'Among',
  dashboardLeft = '62739ce3-94a3-44e5-84e0-071509249dba',
  dashboardRight = '62739a5d-c2b3-4483-84b0-70e492764a26',
}

export enum PerformanceTop {
  type = 'Among',
  dashboardLeft = '6273900f-2c86-4e2a-8779-5b2817527640',
  dashboardRight = '3d51dd35-dfe9-421e-91f6-b27e73ab343a',
}

export enum LostReasonTop {
  type = 'Unique',
  dashboardLeft = '62733279-8d58-42c8-893c-0a94cc74661f',
}

export const TopDashboardsUat: Map<string, any> = new Map<BDMdashboardName, TopDashboardDetail>([
  [BDMdashboardName.LOSTREPORT, LostReportTop],
  [BDMdashboardName.SUBMIISSIONREPORT, SubmissionReportTop],
  [BDMdashboardName.SUBSOURCE, SubsourceTop],
  [BDMdashboardName.ASSIGNEDTODAY, AssignedTodayTop],
  [BDMdashboardName.WEEKCREATE, WeekCreateTop],
  [BDMdashboardName.AVERAGE, AverageTop],
  [BDMdashboardName.STATUS, StatusTop],
  [BDMdashboardName.SOURCE, PerformanceTop],
  [BDMdashboardName.LOSTREASON, LostReasonTop],
]);

export const LMdashboardDataUat: Map<string, any> = new Map<LMdashboardName, any>([
  [LMdashboardName.PARTICIPANTS, '63437f51-c637-491f-8fc2-9b5a9e8afae4'],
  [LMdashboardName.CAMPAIGN, '63438953-7767-4b57-8f3b-6da997d7c5b2'],
  [LMdashboardName.LEADS, '63438b3c-85c0-4200-8138-38370c49cf34'],
]);

export const BDMdashboardDataUat = '63478306-dabe-4b51-8a42-c68ee8e485d9';

export const DashboardLibraryUat = {
  report: [
    {
      chartName: 'List of Participants',
      chartID: '628c6644-b3f9-4b69-8dbb-6191e59afe4c',
      chartType: 'Table',
    },
    {
      chartName: 'List of Campaign',
      chartID: '62625264-8055-4d27-8fc0-8bf995f6bcd3',
      chartType: 'Table',
    },
    {
      chartName: 'List of Leads',
      chartID: '62625264-8055-49fe-8579-8bf995f6bcd5',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Lost Report',
      chartID: '6267957c-d1b5-418a-808d-afce45aaa049',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Lost Report',
      chartID: '3cf0b7e8-b42c-4cb6-970d-76ad92001756',
      chartType: 'Chart',
    },
    {
      chartName: 'Appointment to Submission Report',
      chartID: 'ebcac395-c072-4ea5-b381-3db3678930e2',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Submission Report',
      chartID: '044039d7-0630-436e-aacb-ba7e2e87a0d6',
      chartType: 'Chart',
    },
    {
      chartName: 'Cumulative Leads Aging Report',
      chartID: '6267c4d0-98d7-4e8e-8a72-379d08341b5f',
      chartType: 'Table',
    },
    {
      chartName: 'Cumulative Leads Aging Report',
      chartID: '6267bf09-d717-4e15-85f2-7d34608ec1eb',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Engagement',
      chartID: '6269169e-816a-4efb-890d-52d5157e23b6',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Engagement',
      chartID: '6268fc90-616e-497b-8c32-72ea292a78c4',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Pending Call',
      chartID: '6269ff55-5899-4fce-85f1-f60be6e871f8',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Pending Call',
      chartID: '6269fa11-c207-46dc-8352-8d7e435c3545',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Submission',
      chartID: '626a054f-4604-4747-8f57-a228837daa18',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Submission',
      chartID: '626a00a8-87b8-4886-814e-be5712953107',
      chartType: 'Chart',
    },
    {
      chartName: 'Dropped-Off Report',
      chartID: '58f2b694-6814-4db2-9776-2a3245d31345',
      chartType: 'Table',
    },
    {
      chartName: 'Dropped-Off Report',
      chartID: '6268bbbb-816a-413d-8877-52d51559fe21',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Lost Reason Report',
      chartID: '627e1c20-4e6c-4f30-8944-752b4011d461',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Lost Reason Report',
      chartID: '626a5a58-5899-42d5-861a-f60be6099147',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Stage Report',
      chartID: '6273786b-62c0-4cfd-8ea7-ecf25f765f21',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Stage Report',
      chartID: '62737521-2e4a-4020-8241-d81646c163fd',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Status Report by Agent',
      chartID: '62739cb9-2e4a-4b14-868c-d81646cfd78e',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Status Report by Agent',
      chartID: '6273987b-2c86-4122-848a-5b2817550572',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Report by Sub Source',
      chartID: '627383c7-c2b3-4dd9-8db5-70e4926f086e',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Report by Sub Source',
      chartID: '6273439d-94a3-4415-882d-07150903d39f',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Assigned Today by Source',
      chartID: '00a9f905-b8ca-476f-ab50-c7bee5c797e4',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Assigned Today by Source',
      chartID: '62738a79-c2b3-4d07-89ca-70e492715b80',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Week',
      chartID: '6274f2b4-a19f-45f8-8cc5-1113c9fa4245',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Create Per Week',
      chartID: '627485b4-94a3-4b7b-8923-071509d01743',
      chartType: 'Chart',
    },
    {
      chartName: 'Average Conversion Time Report',
      chartID: '6274f450-2e4a-4b45-883b-d81646a94385',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance Report by Source',
      chartID: '7e34f60d-a8ce-4249-9d94-1404103cc7e4',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance Report by Source',
      chartID: '62738e97-2c86-4ef5-8965-5b281751dbd9',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion performance by Lead Assigned Date',
      chartID: '62c4e86c-a69a-43e2-827f-2cdf17659022',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion performance by Lead Assigned Date',
      chartID: 'b9311f23-ef4b-4553-9a53-afcdad100af3',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Create Per Week - phase 2',
      chartID: '6e4bf812-c05a-4311-8b16-b5d7e0393ba9',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Week - phase 2',
      chartID: 'a3236853-e8fe-4879-8e39-c54393886500',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance by Last Updated Date',
      chartID: '62c549e7-f6cc-4151-8466-6bde72260094',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion Performance by Last Updated Date',
      chartID: '500e8df3-a4e2-4dd3-b978-b1d179a4d9e7',
      chartType: 'Table',
    },
    {
      chartName: 'Assigned to Call Report',
      chartID: '62c5527d-6bc4-4259-8f72-15b909de1e1f',
      chartType: 'Chart',
    },
    {
      chartName: 'Assigned to Call Report',
      chartID: '03890c08-2ab9-4cc8-9e11-adf513abad9b',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days by Consultants',
      chartID: '62c695fe-d694-45e7-87b6-a13c4a2b5c1d',
      chartType: 'Chart',
    },
    {
      chartName: 'Average Conversion Days by Consultants',
      chartID: 'c2438d60-d1b5-4cf2-b10e-4131ff2e80f0',
      chartType: 'Table',
    },
    {
      chartName: 'Top Consultants by Lead Capture',
      chartID: 'dd9a2a68-6e9a-4738-a6d7-7de000014491',
      chartType: 'Chart',
    },
    {
      chartName: 'Top Consultants by Lead Capture',
      chartID: 'a0ee09ae-fbce-4384-95a7-07c32fb823f1',
      chartType: 'Table',
    },
    {
      chartName: 'Unaccepted Leads by Consultants',
      chartID: 'cd803bf2-45be-49d9-a3b8-c515bf3169b9',
      chartType: 'Chart',
    },
    {
      chartName: 'Unaccepted Leads by Consultants',
      chartID: 'bd360f0c-fe47-40df-9d2e-1ed736d46ef3',
      chartType: 'Table',
    },
  ],
};
