import React, { FC, Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Add, FileUpload, Close, Info } from '@mui/icons-material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './material-dialog.style';
import { useMaterialDialog, imageAccept } from './material-dialog.hook';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { EventMaterialTypeEnum } from 'src/app/modules/event-v2/types';
import { map } from 'lodash';

type ComponentProps = ParamsProps & {
  categoryId?: string;
  onClose: () => void;
};

const id = 'category-material-upload';

export const MaterialDialogComponent: React.FC<ComponentProps> = memo(({ categoryId, onClose }: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();

  // custom-hook
  const {
    loading,
    filteredLanguage,
    handleChange,
    materialListData,
    addNewRow,
    deleteRow,
    handleUpload,
    handleClose,
    handleConfirm,
  } = useMaterialDialog({
    id: categoryId,
    onClose,
  });

  return (
    <Dialog id="material-dialog" classes={{ paper: classes.dialogWrapper }} open={!!categoryId} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{Translation('event.category.materials.dialog.title')}</DialogTitle>
      <DialogContent>
        <PruTable
          disableToolbar
          disableBulkSelect
          disablePagination
          operationDef={[]}
          columnDef={[
            {
              keyIndex: 'material',
              displayName: (
                <div className={classes.tableHeader}>
                  <span>{Translation('event.category.material')}</span>
                  <Tooltip
                    title={Translation('event.category.material.dialog.material_tips')}
                    arrow
                    placement="top"
                    classes={{ popper: classes.popper }}
                  >
                    <Info className={classes.infoIcon} />
                  </Tooltip>
                  <IconButton onClick={addNewRow}>
                    <Add className={classes.iconColor} />
                  </IconButton>
                </div>
              ),
              renderData: (row) => (
                <div className={classes.tableHeader}>
                  <IconButton
                    onClick={() => {
                      deleteRow(row);
                    }}
                  >
                    <Delete />
                  </IconButton>
                  <Select value={row.type} disabled={!!row?.blob?.blobId}>
                    {map(EventMaterialTypeEnum, (option) => (
                      <MenuItem key={option} value={option} onClick={() => handleChange(row, 'type', option)}>
                        <Typography>
                          {Translation(`event.category.material.type.${option.toLocaleLowerCase()}`)}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ),
            },
            {
              keyIndex: 'language',
              displayName: Translation('event.common.language_version'),
              renderData: (row) => (
                <Select value={row.lang} disabled={!!row?.blob?.blobId}>
                  {filteredLanguage.map((language) => {
                    return (
                      <MenuItem
                        value={language.lang}
                        key={language.name}
                        onClick={() => handleChange(row, 'lang', language.lang)}
                      >
                        <Typography>{Translation(language.name)}</Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              ),
            },
            {
              keyIndex: 'content',
              displayName: (
                <div>
                  <span>{Translation('event.category.material.dialog.operation_content_upload')}</span>
                  <Tooltip
                    title={Translation('event.category.material.dialog.content_upload_tips')}
                    arrow
                    placement="top"
                    classes={{ popper: classes.popper }}
                  >
                    <IconButton>
                      <Info className={classes.iconColor} />
                    </IconButton>
                  </Tooltip>
                </div>
              ),
              renderData: (row) => (
                <Fragment>
                  <input
                    id={`${id}-${row._id}`}
                    hidden
                    type="file"
                    accept={imageAccept[row?.type]?.type}
                    onClick={(e) => {
                      const element = e.target as HTMLInputElement;
                      element.value = '';
                    }}
                    onChange={(e) => handleUpload(e, row)}
                  />

                  {row?.blob?.blobId ? (
                    <Fragment>{row.blob.filename}</Fragment>
                  ) : (
                    <IconButton onClick={() => document.getElementById(`${id}-${row._id}`)?.click()}>
                      <FileUpload />
                    </IconButton>
                  )}
                </Fragment>
              ),
            },
          ]}
          dataSource={materialListData}
          isLoading={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={handleClose}>
          {Translation('app.button.cancel')}
        </Button>
        <Button variant="contained" color="secondary" onClick={handleConfirm} autoFocus>
          {Translation('app.button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
