import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CategoryPage from '../../../components/Category/pages/List';
import { ModulePermissionProps } from '../../../../../common/module/types';

const SystemConfig =  {
  filterName: 'component.filterTitle.sales-material-category',
  blockName: 'component.formTitle.sales-material-category',
  blockType: 'salesMaterialCategory',
  moduleType: 'Sales',
  addSuccessText: 'global.submit.success'
}

const SalesCategoryPage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return (
    <CategoryPage { ...props } { ...SystemConfig } />
  )
};

export default SalesCategoryPage;
