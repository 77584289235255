import React, { useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Dialog, DialogTitle, Checkbox, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import {
  AgentItem,
  AgentListParam,
  AgentPaginateList,
  CampaignDetailPageStep,
  CampaignStructureItem,
  campaignStructureSection,
} from '../../../../types/staff-campaign-types';
import { convertArrayToObject, useDataProvider } from 'src/app/common/utils';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import AddParticipantMenu from './components/AddParticipantMenu';
import { CloseOutlined } from '@mui/icons-material';
import ImportParticipantPopup from './components/ImportParticipantPopup';
import { useDispatch } from 'react-redux';
import { getBlobAsAgent } from 'src/app/common/network';
import TnCItem from './components/TnCItem';
import { MANDATORY_FIELD_ERROR_TEXT } from '../../../../../../common/constants';
import { addParticipant, fetchParticipantList } from '../../../../network/staffCampaignCrud';
import moment from 'moment';

const useStyles = makeStyles<void, 'checked'>()((theme, _params, classes) => ({
  valueBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  value: {},
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    width: '20%',
    minWidth: '200px',
    paddingRight: 15,
  },
  fieldContainer: {
    width: '40%',
    minWidth: 200,
    boxSizing: 'border-box',
    marginRight: 15,
    marginBottom: 10,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    paddingLeft: 14,
  },
  checked: {},
  checkboxItem: {
    borderRadius: '12px',
    [`&.${classes.checked}`]: {
      color: '#E8192C',
    },
  },
  addBtnWrap: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 12,
    alignItems: 'center',
  },
  numberLimit: {
    paddingLeft: 30,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#FCE8BE',
    marginBottom: 10,
    color: '#666',
  },
}));

interface ParticipantProps {
  campaignTypeStructureData: CampaignStructureItem;
  participantRange: any;
  formDispatch: (data: any) => void;
  formState: any;
  sectionKey: string;
  setParticipantSelected: (data: any) => void;
  disabledEdit?: boolean;
  errorState: any;
  campaignObjId?: string;
  participantSelected: any[];
  validationHandler: any;
  startDate?: any;
}

const paramsInitiator = (initialParams?: Record<string, string>): AgentListParam => {
  return {
    page: 1,
    limit: 20,
    search: '',
    id: '',
  };
};

const Participant: React.FC<ParticipantProps> = ({
  sectionKey,
  campaignTypeStructureData,
  formDispatch,
  formState,
  setParticipantSelected,
  disabledEdit,
  errorState,
  campaignObjId,
  participantSelected,
  validationHandler,
  participantRange,
  startDate,
}) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [openSelectFromList, setOpenSelectFromList] = useState<boolean>(false);
  const [openImportFromFile, setOpenImportFromFile] = useState<boolean>(false);
  const [participantList, setParticipantList] = useState<AgentPaginateList>();

  const [filterState, setFilterState] = useState<AgentListParam>(paramsInitiator());
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [snackBar, setSnackBar] = useState<{ msg: string; visible: boolean }>({ msg: '', visible: false });

  const structureData = campaignTypeStructureData.sections.filter((item) => item.key == sectionKey)[0];
  const tNcField =
    structureData.sectionFields.filter((item) => item.key == 'tnc').length > 0
      ? structureData.sectionFields.filter((item) => item.key == 'tnc')[0]
      : null;
  const rsvpField =
    structureData.sectionFields.filter((item) => item.key == 'rsvpResponse').length > 0
      ? structureData.sectionFields.filter((item) => item.key == 'rsvpResponse')[0]
      : null;

  const arrparticipantName = structureData.sectionFields.filter((item) => item.key == 'participantName');
  const participantNameField = arrparticipantName.length > 0 ? arrparticipantName[0] : null;

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const getUniqueListBy = (arr: any[], key: string) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };
  const onRefresh = () => {};

  const setDoneImport = (data: AgentItem[]) => {
    var agentCodeArray = data.map((data) => {
      return {
        agentCode: data.agentCode,
        agency: data.agency,
        branch: data.branch,
        designation: data.designation,
        displayName: data.displayName,
        division: data.division,
        name: data.name,
        unit: data.unit,
        uplineAgent: data.uplineAgent,
        invitationTime: data.invitationTime,
        rsvpIndicator: data.rsvpIndicator,
        campaign: campaignObjId,
      };
    });
    addParticipant(agentCodeArray, dispatch)
      .then((res) => {
        setSnackBar({
          msg: res.length + Translation('agencyCampaign.create.importDone'),
          visible: true,
        });
        refreshData();
      })
      .catch((e) => {});
  };
  const { isLoading, refreshData } = useDataProvider<AgentPaginateList>(
    async () => {
      try {
        if (campaignObjId) {
          return await fetchParticipantList({ ...filterState, id: campaignObjId }, sortKey, dispatch);
        }
      } catch (err) {}
    },
    setParticipantList,
    false,
  );

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, [campaignObjId, participantSelected, filterState, sortKey]);

  const closeSnackBar = () => setSnackBar({ msg: '', visible: false });

  const renderNumberLimit = () => {
    const { gte: min, lte: max } = participantRange;
    return (
      <>
        {min > 0 ? (
          <Alert className="tw-mb-2" severity="info" color="warning">
            {`${min} ${Translation('agencyCampaign.create.participantMin')}`}
          </Alert>
        ) : null}
        {max > 0 ? (
          <Alert className="tw-mb-2" severity="info" color="warning">
            {`${max} ${Translation('agencyCampaign.create.participantMax')}`}
          </Alert>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className={classes.addBtnWrap}>
        <div style={{ fontWeight: 600 }}>{Translation('agencyCampaign.create.editParticipant')}</div>
        <div>
          {!disabledEdit && (
            <AddParticipantMenu
              campaignObjId={campaignObjId}
              onSelectFromList={() => setOpenSelectFromList(true)}
              onImportFromFile={() => setOpenImportFromFile(true)}
            />
          )}
        </div>
      </div>

      <div style={{ flex: 1, backgroundColor: 'white', padding: 20 }}>
        {renderNumberLimit()}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackBar.visible}
          autoHideDuration={5000}
          onClose={closeSnackBar}
          message={snackBar.msg}
        />
        <PruTable
          disableToolbar={true}
          title={''}
          disableBulkSelect={disabledEdit}
          operationDef={[]}
          columnDef={[
            {
              keyIndex: `designation`,
              align: 'center',
              displayName: Translation(`agencyCampaign.common.designation`),
              renderData: (row) => (row.unit ? (row.unit as string) : '-'),
              sortable: true,
              onSort: (sort) => {
                updateSortingKey({ key: 'designation', value: sort['designation'] });
              },
            },
            {
              keyIndex: 'businessName',
              align: 'center',
              displayName: Translation('agencyCampaign.common.businessName'),
              renderData: (row) => ((row as AgentItem).name?.enUs?.displayName as string) ?? '-',
              sortable: true,
              onSort: (sort) => {
                updateSortingKey({ key: 'name', value: sort['businessName'] });
              },
            },
            {
              isId: true,
              keyIndex: 'agentCode',
              align: 'center',
              displayName: Translation('agencyCampaign.common.agentCode'),
              renderData: (row) => (row.agentCode ? (row.agentCode as string) : '-'),
              sortable: true,
              onSort: (sort) => {
                updateSortingKey({ key: 'agentCode', value: sort['agentCode'] });
              },
            },
            {
              keyIndex: 'rsvp',
              align: 'center',
              displayName: Translation('agencyCampaign.common.rsvp'),
              sortable: true,
              onSort: (sort) => {
                updateSortingKey({ key: 'rsvpIndicator', value: sort['rsvp'] });
              },
              renderData: ({ invitationTime, rsvpIndicator }) =>
                invitationTime !== undefined && rsvpIndicator !== undefined && rsvpIndicator !== 'Pending'
                  ? rsvpIndicator
                  : moment().isAfter(startDate)
                    ? 'No Response'
                    : invitationTime !== undefined && rsvpIndicator !== undefined
                      ? rsvpIndicator
                      : '-',
              hidden: !rsvpField,
            },
          ]}
          isLoading={false}
          onRefresh={onRefresh}
          dataSource={participantList?.docs ? participantList.docs : []}
          totalPages={participantList?.totalPages ?? 1}
          totalRecords={participantList?.totalDocs ?? 0}
          onChangePage={(page, rowsPerPage) => {
            setFilterState({
              ...filterState,
              page: page,
              limit: rowsPerPage,
            });
          }}
          defaultRowsPerPage={20}
          currentSelectedRow={(data) => {
            setParticipantSelected(data);
          }}
          // hideBulkSelectHeader={true}
          bulkSelectCheckboxDisable={(data) => {
            return data.invitationTime;
          }}
          updateSelectedRow={participantSelected}
          type="participants"
        />
      </div>
      <Dialog
        open={openSelectFromList}
        disableEscapeKeyDown
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            setOpenSelectFromList(false);
          }
        }}
        maxWidth="md"
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <DialogTitle>{Translation('agencyCampaign.create.button.selectFromList')}</DialogTitle>
          <div
            style={{ padding: '16px 20px' }}
            onClick={() => {
              setOpenSelectFromList(false);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        <ImportParticipantPopup
          selectFromFile={false}
          campaignObjId={campaignObjId || ''}
          campaignTypeStructureData={campaignTypeStructureData}
          doneImport={(data) => {
            setDoneImport(data);
            setOpenSelectFromList(false);
          }}
          onCancel={() => {
            setOpenSelectFromList(false);
          }}
        />
      </Dialog>

      <Dialog
        disableEscapeKeyDown
        open={openImportFromFile}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            setOpenImportFromFile(false);
          }
        }}
        maxWidth="md"
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <DialogTitle>{Translation('agencyCampaign.create.button.importFromFile')}</DialogTitle>
          <div
            style={{ padding: '16px 20px' }}
            onClick={() => {
              setOpenImportFromFile(false);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        <ImportParticipantPopup
          selectFromFile={true}
          campaignObjId={campaignObjId || ''}
          campaignTypeStructureData={campaignTypeStructureData}
          doneImport={(data) => {
            setDoneImport(data);
            setOpenImportFromFile(false);
          }}
          onCancel={() => {
            setOpenImportFromFile(false);
          }}
        />
      </Dialog>
      {tNcField ? (
        <>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox
              classes={{
                root: classes.checkboxItem,
                checked: classes.checked,
              }}
              disabled={disabledEdit}
              onClick={(e: any) => {
                validationHandler.onDismissErrorHandler(`${sectionKey}_tnc`, e.target.checked);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionKey, field: 'tnc', value: e.target.checked },
                });
              }}
              checked={formState[sectionKey] && formState[sectionKey]['tnc'] ? formState[sectionKey]['tnc'] : false}
            />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {`Agree to `}
              {tNcField.value &&
                (tNcField.value as any[]).map((item, index) => {
                  return <TnCItem tNcItem={item} index={index} key={index} />;
                })}
            </div>
          </div>
          {formState.stepPopulated.includes(CampaignDetailPageStep.PARTICIPANT) &&
            errorState.mandatory[`${sectionKey}_tnc`] && (
              <span className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</span>
            )}
        </>
      ) : null}
    </>
  );
};

export default Participant;
