import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  title: {
    textAlign: 'center',
  },
  actions: {
    padding: '0 24px 16px 24px',
    justifyContent: 'center',
  },
  confirmButton: {
    marginLeft: '24px !important',
  },
}));
