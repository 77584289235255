import React, { FC, useReducer, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl, IntlShape } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT, ZERO_ERROR_TEXT } from 'src/app/common/constants';
import { map, range } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import {
  DeduplicationRuleFormMode,
  DeduplicationRule,
  DeduplicationRuleItem,
} from 'src/app/modules/PulseLeads/types/deduplication-rule-types';
import {
  UpdateDeduplicationRuleBody,
  createDeduplicationRule,
  modifyDeduplicationRule,
  CreateDeduplicationRuleBody,
} from 'src/app/modules/PulseLeads/network/deduplicationRuleCrud';
import { deduplicationRulePath } from '../../LeadDeduplicationRuleRoutes';
import RuleCriteriaDropdown from 'src/app/modules/PulseLeads/common/RuleCriteriaDropdown';
import { RuleEnum, RuleDropdown, RuleEnforcementEnum } from 'src/app/modules/PulseLeads/enum/rule-enum';

const CRITERIA_MANDATORY_LAYER = 1;

type DeduplicationRuleFormProps = {
  formMode: DeduplicationRuleFormMode;
  deduplicationRule?: DeduplicationRule;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 195,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: 195,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: 195,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export const renderOptions = (intl: IntlShape): RuleDropdown[] => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return [
    {
      optionId: 'countryNationalId',
      optionText: Translation('pulseleads.ruleCriteria.option.countryNationalId'),
      optionValue: [RuleEnum.COUNTRY_NATIONAL_ID],
    },
    {
      optionId: 'passportNumber',
      optionText: Translation('pulseleads.ruleCriteria.option.passportNumber'),
      optionValue: [RuleEnum.PASSPORT_NUMBER],
    },
    {
      optionId: 'phoneNumber',
      optionText: Translation('pulseleads.ruleCriteria.option.phoneNumber'),
      optionValue: [RuleEnum.PHONE_NUMBER],
    },
    {
      optionId: 'email',
      optionText: Translation('pulseleads.ruleCriteria.option.emailAddress'),
      optionValue: [RuleEnum.EMAIL_ADDRESS],
    },
    {
      optionId: 'firstName+lastName+phoneNumber+birthDate',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+lastName+phoneNumber+birthDate'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.LAST_NAME, RuleEnum.PHONE_NUMBER, RuleEnum.BIRTH_DATE],
    },
    {
      optionId: 'firstName+lastName+phoneNumber',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+lastName+phoneNumber'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.LAST_NAME, RuleEnum.PHONE_NUMBER],
    },
    {
      optionId: 'firstName+phoneNumber',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+phoneNumber'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.PHONE_NUMBER],
    },
    {
      optionId: 'firstName+lastName+email',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+lastName+emailAddress'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.LAST_NAME, RuleEnum.EMAIL_ADDRESS],
    },
    {
      optionId: 'firstName+email',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+emailAddress'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.EMAIL_ADDRESS],
    },
    {
      optionId: 'firstName+lastName+birthDate',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+lastName+birthDate'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.LAST_NAME, RuleEnum.BIRTH_DATE],
    },
  ];
};

type DeduplicationRuleFormState = {
  name?: string;
  description?: string;
  redistributionAfterDays?: string;
  enforcedBy?: string;
  rules: DeduplicationRuleItem[];
};

const initialState: DeduplicationRuleFormState = {
  name: undefined,
  description: undefined,
  redistributionAfterDays: undefined,
  enforcedBy: undefined,
  rules: [],
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof DeduplicationRuleFormState;
    value: any;
  };
};

type ModifyCriteriaAction = {
  type: 'MODIFY_CRITERIA_ACTION';
  payload: DeduplicationRuleItem[];
};

type DeduplicationRuleFormAction = ModifyFieldAction | ModifyCriteriaAction;

const formReducer = (
  state: DeduplicationRuleFormState,
  action: DeduplicationRuleFormAction,
): DeduplicationRuleFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_CRITERIA_ACTION':
      return {
        ...state,
        rules: action.payload,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (deduplicationRule: DeduplicationRule): DeduplicationRuleFormState => {
  return {
    name: deduplicationRule.name,
    description: deduplicationRule.description,
    redistributionAfterDays: String(deduplicationRule.redistributionAfterDays),
    enforcedBy: deduplicationRule.enforcedBy,
    rules: deduplicationRule.rules,
  };
};

const DeduplicationRuleForm: FC<DeduplicationRuleFormProps> = ({ formMode, deduplicationRule }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [formState, formDispatch] = useReducer(
    formReducer,
    deduplicationRule ? detailToStateConvertor(deduplicationRule) : initialState,
  );

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'name',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'redistributionAfterDays',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'zeroRedistributionAfterDays',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.redistributionAfterDays) {
            return parseInt(formState.redistributionAfterDays) == 0;
          } else {
            return false;
          }
        },
      },
      {
        name: 'enforcedBy',
        fieldType: ErrorFieldType.MANDATORY,
      },
      ...range(CRITERIA_MANDATORY_LAYER).map((index) => ({
        name: `criteriaPriority-${index + 1}`,
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          let flag = true;
          formState.rules.forEach((rule) => {
            if (rule.items && rule.items.length > 0 && rule.items[0].priority === index + 1) {
              flag = false;
            }
          });
          return flag;
        },
      })),
    ],
  );

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formMode === DeduplicationRuleFormMode.CREATE) {
        const body: CreateDeduplicationRuleBody = {
          name: formState.name || '',
          description: formState.description,
          enforcedBy: formState.enforcedBy || '',
          rules: formState.rules,
          redistributionAfterDays: formState.redistributionAfterDays ? Number(formState.redistributionAfterDays) : 0,
          createdBy: user?.username || 'Default',
          updatedBy: user?.username || 'Default',
        };
        try {
          await createDeduplicationRule(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Deduplication Rule saved successfully',
              },
            ]),
          );
          history.push(deduplicationRulePath);
        } catch (err) {}
      } else if (deduplicationRule) {
        const body: UpdateDeduplicationRuleBody = {
          name: formState.name || '',
          description: formState.description,
          enforcedBy: formState.enforcedBy || '',
          rules: formState.rules,
          redistributionAfterDays: formState.redistributionAfterDays ? Number(formState.redistributionAfterDays) : 0,
          updatedBy: user?.username || 'Default',
        };
        try {
          await modifyDeduplicationRule(deduplicationRule._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Deduplication Rule updated successfully - ${deduplicationRule._id}`,
              },
            ]),
          );
          history.push(deduplicationRulePath);
        } catch (err) {}
      }
    }
  };

  useEffect(() => {
    immediateErrorValidator();
  }, [formState.redistributionAfterDays]);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(
              `pulseleadsDeduplicationRule.form.title.${
                formMode === DeduplicationRuleFormMode.CREATE ? 'create' : 'edit'
              }`,
            )}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(deduplicationRulePath)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.deduplicationRule.common.name')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              error={errorState.mandatory.name}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.name}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
              }}
            />
          </div>
        </div>
        <div className={classes.textAreaRowContainer}>
          <div className={classes.textAreaFieldContainer}>
            <span className={classes.field}>{Translation('pulseleads.deduplicationRule.common.description')} :</span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              fullWidth
              multiline
              margin="dense"
              variant="outlined"
              value={formState.description}
              InputProps={{
                classes: {
                  input: classes.textArea,
                },
              }}
              onChange={(e) => {
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'description', value: e.target.value } });
              }}
            />
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.deduplicationRule.common.redistributionAfterDays')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              error={errorState.immediate.zeroRedistributionAfterDays || errorState.mandatory.redistributionAfterDays}
              margin="dense"
              variant="outlined"
              helperText={
                errorState.immediate.zeroRedistributionAfterDays
                  ? ZERO_ERROR_TEXT
                  : errorState.mandatory.redistributionAfterDays && MANDATORY_FIELD_ERROR_TEXT
              }
              value={formState.redistributionAfterDays}
              onChange={(e) => {
                const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                onDismissErrorHandler('redistributionAfterDays', onlyNums);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'redistributionAfterDays', value: onlyNums } });
              }}
            />
          </div>
        </div>

        <div className={classes.rowContainer} style={{ marginTop: 4 }}>
          <div className={classes.innerFieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.deduplicationRule.common.enforcedBy')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <FormControl error={errorState.mandatory.enforcedBy} style={{ margin: 0 }} margin="dense" variant="outlined">
            <Select
              style={{ minWidth: 200 }}
              value={formState.enforcedBy}
              onChange={(e) => {
                onDismissErrorHandler('enforcedBy', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enforcedBy', value: e.target.value } });
              }}
            >
              {map(RuleEnforcementEnum, (option: number) => (
                <MenuItem key={option} value={option}>
                  {Translation(`pulseleads.rule.enforcedBy.option.${option}`)}
                </MenuItem>
              ))}
            </Select>
            {errorState.mandatory.enforcedBy && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          </FormControl>
        </div>
      </div>

      <RuleCriteriaDropdown
        style={{ marginTop: 20 }}
        title={Translation('pulseleads.deduplicationRule.form.criteria')}
        onChangeCriteria={(ruleItems) =>
          formDispatch({
            type: 'MODIFY_CRITERIA_ACTION',
            payload: ruleItems,
          })
        }
        ruleCriteria={{
          maxLayer: 5,
          mandatoryLayer: CRITERIA_MANDATORY_LAYER,
          rules: formState.rules,
          dropdown: renderOptions(intl),
        }}
        errorState={errorState}
        onDismissErrorHandler={onDismissErrorHandler}
        customFieldContainer={classes.fieldContainer}
      />

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default DeduplicationRuleForm;
