import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { REPORT_BASE_PATH, REPORT_INCENTIVE_PATH, REPORT_INCENTIVE_RESULT_PATH } from '../../../../constants';
import { ReportType } from '../../../../types/report-types';
import { useIntl } from 'react-intl';
import { createNewIncentiveResultReport } from '../../../../network/reportCrud';
import CreateReport from '../../../../components/CreateReport';

const IncentiveResultReportPage: FC<RouteComponentProps> = ({ history, location }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  
  return (
    <CreateReport
      title={Translation("incentiveResult.title")}
      reportType={ReportType.INCENTIVE_RESULT}
      onCreate={createNewIncentiveResultReport}
      onBack={() => history.push(`${REPORT_BASE_PATH}${REPORT_INCENTIVE_PATH}${REPORT_INCENTIVE_RESULT_PATH}`)}
      fields={[{
        name: "code",
        displayText: Translation("incentiveResult.common.code"),
        type: "text",
        mandatory: true,
        rule: /[^A-Za-z0-9]/g
      }]}
    />
  );
};

export default IncentiveResultReportPage;
