import React, { FC, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PaginateList } from "src/app/common/types/common-types";
import { EventCampaignItem } from 'src/app/modules/PulseLeads/types/event-campaign-types';
import { deleteEventCampaign } from 'src/app/modules/PulseLeads/network/eventCampaignCrud';
import { eventCampaignPath } from '../../EventCampaignRoutes';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';

type EventCampaignListProps = {
  isLoading: boolean;
  eventCampaignList?: PaginateList<EventCampaignItem>;
  config?: any;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const getFieldConfig = (config: any) => {
  return {
    enableEventCampaignOptions: config?.content?.Sales?.campaign?.enableEventCampaignOptions ? true : false,
  };
};

const EventCampaignList: FC<EventCampaignListProps> = ({
  isLoading,
  eventCampaignList,
  config,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const fieldConfig = getFieldConfig(config);
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: ""
  });

  const onDelete = async (id: string) => {
    try {
      await deleteEventCampaign(id, dispatch);
      dispatch(appendAlertItem([{
        severity: AlertType.SUCCESS,
        title: 'Success',
            content: `Event Campaign deleted successfully - ${id}`,
      }]));
    } catch (err) {}
  }

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: "" })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation("pulseleads.straightThrough.eventCampaignList")}
        disableBulkSelect
        headerBtnDef={[
          {
            color: "primary",
            title: Translation("pulseleads.straightThrough.createEventCampaign"),
            onClick: () => history.push(`${eventCampaignPath}/create`)
          }
        ]}
        operationDef={[
          {
            title: Translation("section.common.operation.edit"),
            tooltipText: "Edit Event Code",
            onClick: (row) => history.push(`${eventCampaignPath}/edit/${row._id}`)
          },
          {
            title: Translation("section.common.operation.delete"),
            tooltipText: "Delete Event Code",
            onClick: (row) => setDialogState({ open: true, id: row._id })
          }
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: "_id",
            displayName: "",
            renderData: (row) => ""
          },
          {
            keyIndex: "eventCode",
            displayName: Translation("pulseleads.straightThrough.eventCode"),
            renderData: (row) => row.eventCode || '-',
          },
          {
            keyIndex: "campaignName",
            displayName: Translation("pulseleads.straightThrough.campaignName"),
            renderData: (row) => row.campaignName || "-"
          },
          ...(fieldConfig.enableEventCampaignOptions
            ? [
                {
                  keyIndex: 'product',
                  displayName: Translation('pulseleads.straightThrough.matchedProduct'),
                  renderData: (row: EventCampaignItem) => row.product || '-',
                },
                {
                  keyIndex: 'interestedProductPlan',
                  displayName: Translation('pulseleads.straightThrough.interestedProduct'),
                  renderData: (row: EventCampaignItem) => row.interestedProductPlan || '-',
                },
                {
                  keyIndex: 'leadDetail',
                  displayName: Translation('pulseleads.straightThrough.otherLeadComments'),
                  renderData: (row: EventCampaignItem) => row.leadDetail || '-',
                },
              ]
            : []),
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={eventCampaignList?.docs}
        totalPages={eventCampaignList?.totalPages}
        totalRecords={eventCampaignList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  )
}

export default EventCampaignList;