import React, { useEffect, useMemo, useState } from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { styles, useStyles } from '../../../style';
import Form from 'src/app/common/components/Form';
import { Box, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import { fetchActiveCampaignList } from 'src/app/modules/lead-microsite/network/api';
import { CampaignTypeEnum } from 'src/app/modules/PulseLeads/types/campaign-types';
import { MTmode } from 'src/app/common/constants';
import { FormSectionProps } from '../../../type/types';

enum ARL_REDIRECTION_TYPE {
  URL = 'GIVEN_URL',
  DIRECT_MANAGER = 'DIRECT_MANAGER_URL',
}

const LeadDistributionSection: FC<FormSectionProps> = (props) => {
  const { form, formMode } = props;
  const isView = formMode === MTmode.VIEW;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const [campaignIdList, setCampaignIdList] = useState<string[]>(['']);
  const [value, setValue] = React.useState(form.getFieldValue('campaignId'));

  const redirectForInactiveAgent = useMemo(() => {
    return form.getFieldValue('redirectForInactiveAgent');
  }, []);
  const [arlRedirectionType, setArlRedirectionType] = React.useState(
    redirectForInactiveAgent?.type || ARL_REDIRECTION_TYPE.DIRECT_MANAGER,
  );
  const [givenUrl, setGivenUrl] = useState(redirectForInactiveAgent?.value ?? '');

  const fetchCampaignIdList = async () => {
    const list = await fetchActiveCampaignList({ status: 'active', type: CampaignTypeEnum.NORMAL }, dispatch);
    setCampaignIdList(list.docs.map((item) => item.campaignId));
  };

  const onRedirectTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.value;
    setArlRedirectionType(type);
    const url = type === ARL_REDIRECTION_TYPE.DIRECT_MANAGER ? '' : givenUrl;
    form.setFieldsValue({
      redirectForInactiveAgent: {
        type: type,
        value: url,
      },
    });
  };

  useEffect(() => {
    fetchCampaignIdList();
  }, []);

  return (
    <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
      <div className="tw-flex">
        <div className={classes.subHeader}>{Translation('agent_referral_link_lead_dis_rule')}</div>
      </div>

      <div className={classes.campaignId}>
        <Form.Item
          name="campaignId"
          label={Translation('agent_referral_link_map_campaign')}
          rules={[DEFAULT_REQUIRED_RULES]}
        >
          <div className="tw-w-full">
            <Autocomplete
              disabled={isView}
              disablePortal
              options={campaignIdList}
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
                form.setFieldsValue({ campaignId: newValue });
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} margin="dense" variant="outlined" fullWidth style={styles.mb12} />
              )}
            />
          </div>
        </Form.Item>
      </div>
      <div className={classes.campaignId}>
        <Form.Item
          label={`${Translation('agent_referral_link_redirection')}:`}
          required={false}
          name={['redirectForInactiveAgent', 'type']}
          initialValue={arlRedirectionType}
        >
          <RadioGroup
            onChange={onRedirectTypeChange}
            // value={arlRedirectionType}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel
              value={ARL_REDIRECTION_TYPE.URL}
              control={<Radio />}
              label={Translation('component.formLabel.url')}
              disabled={isView}
            />
            <FormControlLabel
              value={ARL_REDIRECTION_TYPE.DIRECT_MANAGER}
              control={<Radio />}
              label={Translation('agent_referral_link_redirect_to_manager')}
              disabled={isView}
            />
          </RadioGroup>
        </Form.Item>
        {arlRedirectionType === ARL_REDIRECTION_TYPE.URL && (
          <Box marginLeft={'160px'} width={'580px'}>
            <Form.Item
              name={['redirectForInactiveAgent', 'value']}
              rules={[DEFAULT_REQUIRED_RULES]}
              initialValue={givenUrl}
            >
              <TextField margin="dense" variant="outlined" fullWidth disabled={isView} />
            </Form.Item>
          </Box>
        )}
      </div>
    </div>
  );
};
export default LeadDistributionSection;
