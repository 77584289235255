export enum RecruitmentTemplatePublishStatusEnum {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum RecruitmentTemplateStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
}

export enum RecruitmentPdfTemplateStatusEnum {
  CHECKED = 'CHECKED',
  UPLOADED = 'UPLOADED',
  ERROR = 'ERROR',
  EMPTY = 'EMPTY',
}
