import React, { memo } from 'react';
import { Box } from '@mui/material';
import { BaseDialogProps, useBaseDialog } from './base-dialog.hook';
import { RemarkInput } from '../remark-input/remark-input.component';
import { Dialog } from 'src/app/modules/Recruitment/components/dialog/dialog.component';

export const BaseDialog: React.FC<BaseDialogProps> = memo((props: BaseDialogProps) => {
  const { open, title } = props;

  const { selected, onClose, confirmLoading, options, onConfirm, onChange } = useBaseDialog(props);

  return (
    <Dialog
      open={open}
      title={title}
      disabledConfirmBtn={!selected}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={confirmLoading}
    >
      <Box width={'528px'}>
        <RemarkInput value={selected} onChange={onChange} options={options} />
      </Box>
    </Dialog>
  );
});
