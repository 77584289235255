import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import Tinymce from 'src/app/common/components/Tinymce-storage-blob';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './detail.style';
import { useDetail } from './detail.hook';
import { useCommonFormStyles } from 'src/app/modules/event-v2/pages/event-list/form/common/common-form.style';
import { map } from 'lodash';
import { DialogueKeyEnum, TalkbotFlowItem } from '../../../types/talkbot-dialogue-type';
import { regionLocale } from 'src/app/i18n';
import { Close } from '@mui/icons-material';
import { MANDATORY_FIELD_ERROR_TEXT, numberRegex, trueFalseOptions } from 'src/app/common/constants';
import { FormMode } from 'src/app/common/types';
import TextFieldWithLimit from 'src/app/common/components/TextFieldWithLimit';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const DetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes: commonFormClasses } = useCommonFormStyles();

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const formMode = useMemo(() => {
    return id ? FormMode.EDIT : FormMode.CREATE;
  }, []);

  // custom-hook
  const {
    flowIdList,
    existFlowIds,
    loading,
    dialogueLoading,
    formState,
    setFormState,
    errorState,
    handleUpload,
    onSubmit,
    onDismissErrorHandler,
  } = useDetail({
    id,
    formMode,
    history: props.history,
  });

  return loading || dialogueLoading ? (
    <div className={styles.loadingContainer}>
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={commonStyles.headerContainer}>
        <div className={commonStyles.rowContainer}>
          <div className={commonStyles.header}>
            {formMode === FormMode.CREATE ? Translation('talkbot.add_dialogue') : Translation('talkbot.edit_dialogue')}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={props.history.goBack}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={styles.contentContainer}>
        <FormControl fullWidth className={styles.formContainer}>
          <DialogueFormLabel label={`${Translation('talkbot.dialogue.key')}`} />
          <div className={styles.flexCol}>
            <Select
              displayEmpty
              disabled={formMode === FormMode.EDIT}
              value={formState.flowId || ''}
              onChange={(e) => {
                onDismissErrorHandler('flowId', true);
                setFormState((prev) => ({ ...prev, flowId: e.target.value }));
              }}
            >
              {flowIdList.map(({ flowId, dialogueKey }: TalkbotFlowItem) => (
                <MenuItem key={flowId} value={flowId} disabled={existFlowIds?.includes(flowId)}>
                  {Translation(`talkbot.dialogue.key.${DialogueKeyEnum[dialogueKey]}`)}
                </MenuItem>
              ))}
            </Select>
            {errorState.mandatory.flowId && (
              <FormHelperText error={errorState.mandatory.flowId}>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
            )}
          </div>
        </FormControl>

        {regionLocale.map((locale, index) => {
          return (
            <FormControl fullWidth className={styles.formContainer} key={locale}>
              <DialogueFormLabel
                label={`${Translation('talkbot.dialogue.displayed_name')} (${locale.toUpperCase()})`}
              />
              <TextFieldWithLimit
                style={{ width: 400 }}
                limitHelpText={Translation('agentProfile.ad_character_count_label')}
                error={errorState.mandatory[`name-${locale}`]}
                helperText={errorState.mandatory[`name-${locale}`] ? MANDATORY_FIELD_ERROR_TEXT : ''}
                value={formState.name?.[locale] || ''}
                limit={locale.includes('en') ? 30 : 20}
                onChange={(e) => {
                  onDismissErrorHandler(`name-${locale}`, e.target.value);
                  setFormState((prev) => ({ ...prev, name: { ...formState.name, [locale]: e.target.value } }));
                }}
              />
            </FormControl>
          );
        })}

        <FormControl fullWidth className={styles.formContainer}>
          <DialogueFormLabel label={`${Translation('talkbot.dialogue.weight')}`} />
          <TextField
            margin="dense"
            variant="outlined"
            type="number"
            className={styles.textWidth}
            required
            value={typeof formState.weight === 'number' ? formState.weight : ''}
            onChange={(e) => {
              onDismissErrorHandler('weight', e.target.value);
              setFormState((prev) => ({ ...prev, weight: e.target.value ? Number(e.target.value) : undefined }));
            }}
            error={errorState.mandatory['weight'] || errorState.immediate.weightFormat}
            helperText={
              errorState.immediate.weightFormat
                ? !numberRegex.test(formState.weight)
                  ? Translation('value.need.greater.zero.msg')
                  : Translation('saleskit.companyPick.weight.isUsed')
                : errorState.mandatory['weight'] && MANDATORY_FIELD_ERROR_TEXT
            }
          />
        </FormControl>

        {regionLocale.map((locale, index) => {
          return (
            <div className={styles.formContainer} key={locale}>
              <FormControl fullWidth className={commonFormClasses.radioGroup}>
                <DialogueFormLabel
                  label={`${Translation('component.formLabel.description')} (${locale.toUpperCase()})`}
                  required={false}
                />
                <div className="tw-flex-1">
                  <Tinymce
                    disabled={false}
                    module="talkbot"
                    value={formState.description?.[locale]}
                    onChange={(value) => {
                      setFormState((prev) => ({ ...prev, description: { ...prev.description, [locale]: value } }));
                    }}
                  />
                </div>
              </FormControl>
            </div>
          );
        })}

        {regionLocale.map((locale, index) => {
          return (
            <FormControl fullWidth className={styles.formContainer} key={locale}>
              <DialogueFormLabel label={`${Translation('talkbot.dialogue.image')} (${locale.toUpperCase()})`} />
              {formState.image?.[locale]?.url ? (
                <div className={styles.flexRow}>
                  <div className={classes.imageContainer}>
                    <img className={classes.image} src={formState.image?.[locale]?.url} alt="image" />
                  </div>
                  <span>
                    <Close
                      className="tw-ml-8"
                      onClick={() => {
                        setFormState((prev) => ({ ...prev, image: { ...formState.image, [locale]: undefined } }));
                      }}
                    />
                  </span>
                </div>
              ) : (
                <div className={styles.flexCol}>
                  <div className={styles.flexRow}>
                    <input
                      id={`upload-talkbot-dialogue-image-${locale}`}
                      hidden
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      onClick={(e) => {
                        const element = e.target as HTMLInputElement;
                        element.value = '';
                      }}
                      onChange={(e) => handleUpload(e, locale)}
                    />
                    <Button
                      key={locale}
                      variant="contained"
                      color="secondary"
                      onClick={() => document.getElementById(`upload-talkbot-dialogue-image-${locale}`)!.click()}
                    >
                      {Translation('section.common.operation.upload')}
                    </Button>
                    <div className={styles.tips}>{Translation('talkbot.coverpage.tips')}</div>
                  </div>
                  {errorState.mandatory[`image-${locale}`] && (
                    <FormHelperText error={errorState.mandatory[`image-${locale}`]}>
                      {MANDATORY_FIELD_ERROR_TEXT}
                    </FormHelperText>
                  )}
                </div>
              )}
            </FormControl>
          );
        })}

        <div className={styles.formContainer}>
          <DialogueFormLabel label={Translation('talkbot.dialogue.status')} />
          <FormControl>
            <RadioGroup
              className={commonFormClasses.radioGroup}
              onChange={(e) => {
                onDismissErrorHandler('isActive', true);
                setFormState((prev) => ({ ...prev, isActive: e.target.value === 'true' ? true : false }));
              }}
              value={typeof formState.isActive === 'boolean' ? formState.isActive.toString() : ''}
            >
              {trueFalseOptions.map((option) => (
                <FormControlLabel
                  className={commonFormClasses.formControlLabel}
                  key={`status-${option}`}
                  control={<Radio />}
                  label={
                    option === 'true'
                      ? Translation('talkbot.dialogue.status.active')
                      : Translation('talkbot.dialogue.status.inactive')
                  }
                  value={option}
                />
              ))}
            </RadioGroup>
            {errorState.mandatory.isActive && (
              <FormHelperText error={errorState.mandatory.isActive}>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
            )}
          </FormControl>
        </div>
      </div>

      <div className={styles.action}>
        <Button variant="contained" color="inherit" onClick={props.history.goBack}>
          {Translation('app.button.cancel')}
        </Button>
        <Button className={styles.button} variant="contained" color="secondary" onClick={() => onSubmit(formState)}>
          {Translation('app.button.confirm')}
        </Button>
      </div>
    </div>
  );
};

const DialogueFormLabel = ({ label, required = true }: { label: string; required?: boolean }) => {
  const { classes: commonFormClasses } = useCommonFormStyles();
  return (
    <FormLabel className={commonFormClasses.radioGroupLabel} style={{ flexShrink: 0 }}>
      {label}
      {required && <span className={commonFormClasses.mandatory}>*</span>}
    </FormLabel>
  );
};
