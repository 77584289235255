import React, { FC, useReducer } from 'react';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl, IntlShape } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { map, range } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import RuleCriteriaDropdown from 'src/app/modules/PulseLeads/common/RuleCriteriaDropdown';
import { RuleEnum, RuleDropdown, RuleEnforcementEnum } from 'src/app/modules/PulseLeads/enum/rule-enum';
import {
  ExclusionRuleFormMode,
  ExclusionRule,
  ExclusionRuleItem,
} from 'src/app/modules/PulseLeads/types/exclusion-rule-types';
import {
  CreateExclusionRuleBody,
  createExclusionRule,
  modifyExclusionRule,
  UpdateExclusionRuleBody,
} from 'src/app/modules/PulseLeads/network/exclusionRuleCrud';
import { exclusionRulePath } from '../../ExclusionRuleRoutes';

const CRITERIA_MANDATORY_LAYER = 1;

type ExclusionRuleFormProps = {
  formMode: ExclusionRuleFormMode;
  exclusionRule?: ExclusionRule;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: 160,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export const renderOptions = (intl: IntlShape): RuleDropdown[] => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return [
    {
      optionId: 'countryNationalId',
      optionText: Translation('pulseleads.ruleCriteria.option.countryNationalId'),
      optionValue: [RuleEnum.COUNTRY_NATIONAL_ID],
    },
    {
      optionId: 'passportNumber',
      optionText: Translation('pulseleads.ruleCriteria.option.passportNumber'),
      optionValue: [RuleEnum.PASSPORT_NUMBER],
    },
    {
      optionId: 'firstName+lastName+phoneNumber',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+lastName+phoneNumber'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.LAST_NAME, RuleEnum.PHONE_NUMBER],
    },
    {
      optionId: 'firstName+lastName+email',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+lastName+emailAddress'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.LAST_NAME, RuleEnum.EMAIL_ADDRESS],
    },
    {
      optionId: 'firstName+lastName+birthDate',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+lastName+birthDate'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.LAST_NAME, RuleEnum.BIRTH_DATE],
    },
    {
      optionId: 'phoneNumber',
      optionText: Translation('pulseleads.ruleCriteria.option.phoneNumber'),
      optionValue: [RuleEnum.PHONE_NUMBER],
    },
    {
      optionId: 'email',
      optionText: Translation('pulseleads.ruleCriteria.option.emailAddress'),
      optionValue: [RuleEnum.EMAIL_ADDRESS],
    },
  ];
};

type ExclusionRuleFormState = {
  name?: string;
  description?: string;
  enforcedBy?: string;
  rules: ExclusionRuleItem[];
  isAllLead: boolean;
  daysOfAgingLead?: string;
};

const initialState: ExclusionRuleFormState = {
  name: undefined,
  description: undefined,
  enforcedBy: undefined,
  rules: [],
  isAllLead: false,
  daysOfAgingLead: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ExclusionRuleFormState;
    value: any;
  };
};

type ModifyCriteriaAction = {
  type: 'MODIFY_CRITERIA_ACTION';
  payload: ExclusionRuleItem[];
};

type ExclusionRuleFormAction = ModifyFieldAction | ModifyCriteriaAction;

const formReducer = (state: ExclusionRuleFormState, action: ExclusionRuleFormAction): ExclusionRuleFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      const newState = { ...state };
      if (action.payload.field === 'isAllLead') {
        newState.daysOfAgingLead = undefined;
      }
      return {
        ...newState,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_CRITERIA_ACTION':
      return {
        ...state,
        rules: action.payload,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (exclusionRule: ExclusionRule): ExclusionRuleFormState => {
  return {
    name: exclusionRule.name,
    description: exclusionRule.description,
    enforcedBy: exclusionRule.enforcedBy,
    rules: exclusionRule.rules,
    isAllLead: exclusionRule.isAllLead,
    daysOfAgingLead: exclusionRule.daysOfAgingLead ? String(exclusionRule.daysOfAgingLead) : undefined,
  };
};

const ExclusionRuleForm: FC<ExclusionRuleFormProps> = ({ formMode, exclusionRule }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [formState, formDispatch] = useReducer(
    formReducer,
    exclusionRule ? detailToStateConvertor(exclusionRule) : initialState,
  );

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'name',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'enforcedBy',
      fieldType: ErrorFieldType.MANDATORY,
    },
    ...range(CRITERIA_MANDATORY_LAYER).map((index) => ({
      name: `criteriaPriority-${index + 1}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        let flag = true;
        formState.rules.forEach((rule) => {
          if (rule.items && rule.items.length > 0 && rule.items[0].priority === index + 1) {
            flag = false;
          }
        });
        return flag;
      },
    })),
  ]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formMode === ExclusionRuleFormMode.CREATE) {
        const body: CreateExclusionRuleBody = {
          name: formState.name || '',
          description: formState.description,
          enforcedBy: formState.enforcedBy || '',
          rules: formState.rules,
          isAllLead: formState.isAllLead,
          daysOfAgingLead: formState.daysOfAgingLead ? Number(formState.daysOfAgingLead) : undefined,
          createdBy: user?.username || 'Default',
          updatedBy: user?.username || 'Default',
        };
        try {
          await createExclusionRule(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Exclusion Rule saved successfully',
              },
            ]),
          );
          history.push(exclusionRulePath);
        } catch (err) {}
      } else if (exclusionRule) {
        const body: UpdateExclusionRuleBody = {
          name: formState.name || '',
          description: formState.description,
          enforcedBy: formState.enforcedBy || '',
          rules: formState.rules,
          isAllLead: formState.isAllLead,
          daysOfAgingLead: formState.daysOfAgingLead ? Number(formState.daysOfAgingLead) : undefined,
          updatedBy: user?.username || 'Default',
        };
        try {
          await modifyExclusionRule(exclusionRule._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Exclusion Rule updated successfully - ${exclusionRule._id}`,
              },
            ]),
          );
          history.push(exclusionRulePath);
        } catch (err) {}
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(
              `pulseleadsExclusionRule.form.title.${formMode === ExclusionRuleFormMode.CREATE ? 'create' : 'edit'}`,
            )}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(exclusionRulePath)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.exclusionRule.common.name')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              error={errorState.mandatory.name}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.name}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
              }}
            />
          </div>
        </div>

        <div className={classes.textAreaRowContainer}>
          <div className={classes.textAreaFieldContainer}>
            <span className={classes.field}>{Translation('pulseleads.exclusionRule.common.description')} :</span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              fullWidth
              multiline
              margin="dense"
              variant="outlined"
              value={formState.description}
              InputProps={{
                classes: {
                  input: classes.textArea,
                },
              }}
              onChange={(e) => {
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'description', value: e.target.value } });
              }}
            />
          </div>
        </div>

        <div className={classes.rowContainer} style={{ marginTop: 4 }}>
          <div className={classes.innerFieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.exclusionRule.common.enforcedBy')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <FormControl error={errorState.mandatory.enforcedBy} style={{ margin: 0 }} margin="dense" variant="outlined">
            <Select
              style={{ minWidth: 200 }}
              value={formState.enforcedBy}
              onChange={(e) => {
                onDismissErrorHandler('enforcedBy', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enforcedBy', value: e.target.value } });
              }}
            >
              {map(RuleEnforcementEnum, (option: number) => (
                <MenuItem key={option} value={option}>
                  {Translation(`pulseleads.rule.enforcedBy.option.${option}`)}
                </MenuItem>
              ))}
            </Select>
            {errorState.mandatory.enforcedBy && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          </FormControl>
        </div>

        <div className={classes.rowContainer} style={{ marginTop: 4 }}>
          <div className={classes.innerFieldContainer}>
            <span className={classes.field}>{Translation('pulseleads.exclusionRule.common.isAllLead')} :</span>
          </div>
          <FormControlLabel
            style={{ margin: '0 30px 0 0' }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={!!formState.isAllLead}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isAllLead', value: true } });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.yes')}
            labelPlacement="end"
          />
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={!!!formState.isAllLead}
                onChange={(e) => {
                  if (e.target.checked) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isAllLead', value: false } });
                  }
                }}
              />
            }
            label={Translation('app.checkbox.no')}
            labelPlacement="end"
          />
        </div>

        {!formState.isAllLead && (
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.exclusionRule.common.daysOfAgingLead')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: 250 }}
                margin="dense"
                variant="outlined"
                value={formState.daysOfAgingLead}
                onChange={(e) => {
                  const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'daysOfAgingLead', value: onlyNums } });
                }}
              />
            </div>
          </div>
        )}
      </div>

      <RuleCriteriaDropdown
        style={{ marginTop: 20 }}
        title={Translation('pulseleads.exclusionRule.form.criteria')}
        onChangeCriteria={(ruleItems) =>
          formDispatch({
            type: 'MODIFY_CRITERIA_ACTION',
            payload: ruleItems,
          })
        }
        ruleCriteria={{
          maxLayer: 5,
          mandatoryLayer: CRITERIA_MANDATORY_LAYER,
          rules: formState.rules,
          dropdown: renderOptions(intl),
        }}
        errorState={errorState}
        onDismissErrorHandler={onDismissErrorHandler}
      />

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default ExclusionRuleForm;
