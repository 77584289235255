export enum RuleEnforcementEnum {
  ACTIVE_CAMPAIGN_SOURCE = 'activeLeadCampaign',
  ACTIVE_LEAD_SOURCE = 'activeLeadSource',
  ALL_LEAD = 'allLead'
}

export enum RuleEnum {
  COUNTRY_NATIONAL_ID = "countryNationalId",
  PASSPORT_NUMBER = "passportNumber",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  PHONE_NUMBER = "phoneNumber",
  BIRTH_DATE = "birthDate",
  EMAIL_ADDRESS = "email"
}

export enum RuleOperator {
  INCLUDE = "include",
  EQUAL = "equal",
  EXCLUDE = "exclude",
  MANDATORY = "mandatory"
}

export type RuleOperatorItem = {
  type: string;
  operator: string;
  priority: number;
}

export type RuleItem = {
  name?: string;
  items: RuleOperatorItem[];
}

export type RuleCriteriaLevel = {
  title: string;
  selected?: string;
  mandatory?: boolean;
}

export type RuleDropdown = {
  optionId: string;
  optionText: string;
  optionValue: RuleEnum[];
}

export type RuleCriteria = {
  maxLayer: number;
  mandatoryLayer: number;
  rules: RuleItem[];
  dropdown: RuleDropdown[];
};

