import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ColumnPage from '../../../components/Column/pages/List';
import { ModulePermissionProps } from '../../../../../common/module/types';

const SystemConfig =  {
  filterName: 'component.filterTitle.resource-column',
  blockName: 'component.formTitle.resource-column-table',
  blockType: 'resourceColumn',
  moduleType: 'Recruit',
  addSuccessText: 'global.submit.success'
}

const RecruitPruforceColumnPage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return (
    <ColumnPage { ...props } { ...SystemConfig }  />
  )
};

export default RecruitPruforceColumnPage;
