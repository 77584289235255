import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { FilterState, ListItemKeys, TaskStatus } from 'src/app/modules/Recruitment/types/approval-types';
import { ApplicationListResponse, fetchApprovalList } from 'src/app/modules/Recruitment/network/approval-crud';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { formatListParams } from '../util/api-helper.util';

const initialState: FilterState = {
  [ListItemKeys.assignee]: [],
  [ListItemKeys.applicationStatus]: [],
  [ListItemKeys.formType]: [],
  [ListItemKeys.applicationType]: [],
  submittedDateFrom: null,
  submittedDateTo: null,
  [ListItemKeys.taskStatus]: [TaskStatus.unassigned],
  page: 1,
  limit: 20,
};

const NONE = 'NONE'; // to keep alive

export const applicationListFilterKeys = [
  ListItemKeys.taskStatus,
  ListItemKeys.assignee,
  ListItemKeys.applicationStatus,
  ListItemKeys.applicationId,
  ListItemKeys.applicantId,
  'firstnames',
  'lastnames',
  'submittedDateFrom',
  'submittedDateTo',
  ListItemKeys.applicationType,
  ListItemKeys.formType,
  'sortBy',
  'orderBy',
];

export type ApplicationListListingPageProps = ParamsProps & ModulePermissionProps;

export const paramsInitiator = (isWorkflowMgr: boolean, initialParams?: Record<string, any>): FilterState => {
  return {
    ...initialState,
    ...initialParams,
    [ListItemKeys.assignee]: initialParams?.[ListItemKeys.assignee]
      ? initialParams?.[ListItemKeys.assignee]?.split(',')
      : initialState?.[ListItemKeys.assignee],
    [ListItemKeys.taskStatus]: initialParams?.[ListItemKeys.taskStatus]
      ? initialParams?.[ListItemKeys.taskStatus]
          ?.split(',')
          .filter((i: string) => i !== NONE)
          .filter((i: string) => isWorkflowMgr) // only workflowMgr need this param
      : isWorkflowMgr
      ? initialState?.[ListItemKeys.taskStatus]
      : undefined,
    [ListItemKeys.formType]: initialParams?.[ListItemKeys.formType]
      ? initialParams?.[ListItemKeys.formType]?.split(',')
      : initialState?.[ListItemKeys.formType],
    [ListItemKeys.applicationType]: initialParams?.[ListItemKeys.applicationType]
      ? initialParams?.[ListItemKeys.applicationType]?.split(',')
      : initialState?.[ListItemKeys.applicationType],
    [ListItemKeys.applicationStatus]: initialParams?.[ListItemKeys.applicationStatus]
      ? initialParams?.[ListItemKeys.applicationStatus]?.split(',')
      : initialState?.[ListItemKeys.applicationStatus],
  };
};

export const useApplicationListListingPage = ({
  initialParams,
  onChangeQueryParams,
  enableUpdate,
}: ApplicationListListingPageProps) => {
  const dispatch = useDispatch();
  const isWorkflowMgr = enableUpdate;
  const [filterState, setFilterState] = useState<FilterState>(paramsInitiator(isWorkflowMgr, initialParams));
  const [applicationListList, setApplicationListList] = useState<ApplicationListResponse>();

  const { isLoading, refreshData } = useDataProvider<ApplicationListResponse>(
    async () => {
      try {
        if (onChangeQueryParams) {
          const newParams = { ...filterState };
          if (newParams.taskStatus?.length === 0 && isWorkflowMgr) {
            newParams.taskStatus = [NONE];
          }
          onChangeQueryParams(newParams as any);
        }

        const reqParams = formatListParams(filterState, isWorkflowMgr);

        return await fetchApprovalList(reqParams, dispatch);
      } catch (err) {}
    },
    setApplicationListList,
    false,
  );

  const onSort = (newSortState: { key: string; value?: string }) => {
    const { value } = newSortState;

    if (value) {
      setFilterState({ ...filterState, sort: newSortState });
    } else {
      setFilterState({ ...filterState, sort: undefined });
    }
    refreshData();
  };

  return {
    isWorkflowMgr,
    filterState,
    applicationListList,
    isLoading,
    setFilterState,
    refreshData,
    onSort,
  };
};
