import { FC } from 'react';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useEventCategoryListingPage } from './event-category-listing-page.hook';
import { EventAccessibilityEnum, EventAudienceEnum, EventCategoryStatusEnum, EventHostEnum } from '../../../types';
import { EventCategoryList } from './components/event-category-list.component';
import { EventCategoryFormDialog } from './components/form-dialog';

type EventCategoryListingPageProps = ParamsProps;

export const EventCategoryListingPage: FC<EventCategoryListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const {
    filterState,
    formDialogState,
    eventCategoryList,
    isLoading,
    setFilterState,
    setFormDialogState,
    refreshData,
    onSort,
  } = useEventCategoryListingPage({
    initialParams,
    onChangeQueryParams,
  });

  return (
    <>
      {formDialogState.open && formDialogState.formMode && (
        <EventCategoryFormDialog
          open={formDialogState.open}
          formMode={formDialogState.formMode}
          eventCategoryItem={formDialogState.eventCategoryItem}
          onClose={() => setFormDialogState({ open: false })}
          onRefresh={refreshData}
        />
      )}
      <PruFilter
        disableKeydownEvent={formDialogState.open}
        title={Translation('title.event.category.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'containHosts',
            initialValue: filterState.containHosts,
            displayName: Translation('event.common.host'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              ...map(EventHostEnum, (option) => ({
                displayName: Translation(`event.common.host.${option.toLowerCase()}`),
                value: option,
              })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'accessibility',
            initialValue: filterState.accessibility,
            displayName: Translation('event.common.accessibility'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              ...map(EventAccessibilityEnum, (option) => ({
                displayName: Translation(`event.common.accessibility.${option.toLowerCase()}`),
                value: option,
              })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'audience',
            initialValue: filterState.audience,
            displayName: Translation('event.common.audience'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              ...map(EventAudienceEnum, (option) => ({
                displayName: Translation(`event.common.audience.${option.toLowerCase()}`),
                value: option,
              })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('event.common.status'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              ...map(EventCategoryStatusEnum, (option) => ({
                displayName: Translation(`event.common.category_status.${option.toLowerCase()}`),
                value: option,
              })),
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            containHosts: data.containHosts,
            accessibility: data.accessibility,
            audience: data.audience,
            status: data.status,
          })
        }
        fetchData={refreshData}
      />
      <EventCategoryList
        isLoading={isLoading}
        eventCategoryList={eventCategoryList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
        setFormDialogState={setFormDialogState}
      />
    </>
  );
};
