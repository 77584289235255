import React, { useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Dialog, Grid, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './training-fee-detail.style';
import { useTrainingFeeDetail } from './training-fee-detail.hook';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { TranslationWithParams } from 'src/app/i18n';
import {
  TrainingFeeVerificationEventEnum,
  TrainingFeeVerificationStatusEnum,
} from 'src/app/modules/Recruitment/enum/license-exam.enum';
import { utcToLocalDate } from 'src/app/modules/Recruitment/utils';

type PageProps = ParamsProps & ModulePermissionProps & RouteComponentProps;

export const TrainingFeeDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { history, enableUpdate } = props;
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    data,
    dialogState,
    remark,
    loadingUpdate,
    showApprovalButtons,
    handlePress,
    handleCloseDialog,
    setRemark,
    handleConfirm,
  } = useTrainingFeeDetail({ id: id, enableUpdate: enableUpdate });

  return (
    <>
      <Dialog open={dialogState.open}>
        <div className={styles.dialogContainer}>
          <div className={styles.subHeader}>{dialogState.header}</div>
          <div className={styles.content}>
            <TextField
              variant="outlined"
              label={Translation('onboarding.training.fee.verification.remark')}
              value={remark}
              fullWidth
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            />
          </div>
          <div className={styles.dialogButton}>
            <Button variant="outlined" color="inherit" disabled={loadingUpdate} onClick={handleCloseDialog}>
              {Translation('app.button.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              loading={loadingUpdate}
              className={styles.buttonMargin}
              onClick={handleConfirm}
            >
              {Translation('app.button.confirm')}
            </LoadingButton>
          </div>
        </div>
      </Dialog>

      <div className={commonStyles.headerContainer}>
        <div className={commonStyles.rowContainer}>
          <div className={commonStyles.header}>{Translation('onboarding.training.fee.verification.detail.title')}</div>
        </div>
        <Button variant="contained" color="inherit" onClick={history.goBack}>
          {Translation('app.button.back')}
        </Button>
      </div>
      {data && (
        <>
          <div className={styles.container}>
            <Grid container spacing={3} className={styles.wrap}>
              <Grid item xs={5}>
                <div className={styles.header}>{Translation('onboarding.training.fee.verification.submissionId')}</div>
                <div className={styles.header}>{data.submissionId || '-'}</div>
              </Grid>
              <Grid item xs={3}>
                <div className={styles.header}>
                  {Translation('onboarding.training.fee.verification.submission.status')}
                </div>
                <div className={styles.header}>
                  {data.paymentStatus
                    ? Translation(`onboarding.training.fee.verification.${data.paymentStatus.toLowerCase()}`)
                    : '-'}
                </div>
              </Grid>
            </Grid>

            <div className={styles.header}>{Translation('onboarding.training.fee.verification.applicant.details')}</div>
            <Grid container spacing={3} className={styles.wrap}>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('onboarding.training.fee.verification.applicantId')}
                  value={data.applicantId || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('onboarding.training.fee.verification.firstname')}
                  value={data.firstname || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('onboarding.training.fee.verification.lastname')}
                  value={data.lastname || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>

            <div className={styles.header}>{Translation('onboarding.training.fee.verification.info')}</div>
            <Grid container spacing={3} className={styles.wrap}>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('onboarding.training.fee.verification.paymentType')}
                  value={
                    data.paymentType
                      ? Translation(`onboarding.training.fee.verification.${data.paymentType.toLowerCase()}`)
                      : '-'
                  }
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('onboarding.training.fee.verification.receipt.number')}
                  value={data.trainingFeeOfficialReceiptNumber || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  FormHelperTextProps={{ sx: { color: '#E8192C' } }}
                  helperText={
                    data.orNumberUsedBefore &&
                    data.paymentStatus !== TrainingFeeVerificationStatusEnum.APPROVED.toUpperCase()
                      ? Translation('recruitment.common.or.error.alert')
                      : null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('onboarding.training.fee.verification.receipt.date')}
                  value={data.trainingFeeOfficialReceiptDate || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('onboarding.training.fee.verification.receipt.amount')}
                  value={data.trainingOfficialReceiptAmount || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </div>

          <div className={commonStyles.header}>{Translation('onboarding.training.fee.verification.record')}</div>
          <div className={styles.container}>
            <ul className={styles.list}>
              {data.operationHistoryList?.map((history, index) => (
                <li key={index}>
                  <span>
                    {history.event
                      ? history.event === TrainingFeeVerificationEventEnum.submit ||
                        history.event === TrainingFeeVerificationEventEnum.resubmit
                        ? TranslationWithParams(`onboarding.training.fee.verification.remark.submit`, {
                            firstName: data.firstname,
                            lastName: data.lastname,
                            time: utcToLocalDate(history.updatedAt),
                          })
                        : TranslationWithParams(
                            `onboarding.training.fee.verification.remark.${history.event?.toLowerCase()}`,
                            {
                              operationUser: history.event !== 'EXPIRE' ? history.operator?.operatorName : undefined,
                              time: utcToLocalDate(history.updatedAt),
                            },
                          )
                      : '-'}
                  </span>
                  {history.remark && (
                    <span>{` ${Translation('onboarding.training.fee.verification.remark')}: ${history.remark}`}</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      {enableUpdate && showApprovalButtons && (
        <div className={styles.buttonGroup}>
          <Button
            variant="contained"
            color="secondary"
            className={styles.buttonMargin}
            onClick={() => handlePress(TrainingFeeVerificationEventEnum.return)}
          >
            {Translation('onboarding.training.fee.verification.return')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={styles.buttonMargin}
            onClick={() => handlePress(TrainingFeeVerificationEventEnum.reject)}
          >
            {Translation('onboarding.training.fee.verification.reject')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={styles.buttonMargin}
            onClick={() => handlePress(TrainingFeeVerificationEventEnum.approve)}
          >
            {Translation('onboarding.training.fee.verification.approve')}
          </Button>
        </div>
      )}
    </>
  );
};
