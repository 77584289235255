import { Dispatch } from 'react';
import { ADMIN_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import {
  ApplicationListItem,
  ApplicationStatus,
  ApprovalDetail,
  ApprovalEvents,
  ApprovalListQueryBase,
  ApprovalListQueryParams,
  AssigneeItem,
  AssigneeQueryParams,
} from '../types/approval-types';
import { PaginateList } from 'src/app/common/types';
import moment from 'moment';
import { downloadFile } from 'src/app/common/utils';
import qs from 'qs';

const modulePath = 'application-approval';

// const ADMIN_URL = 'http://localhost:3000';
export type ApplicationListResponse = Omit<PaginateList<ApplicationListItem>, 'totalDocs'> & { totalNumbers: number };

export const fetchApprovalList = async (
  params: ApprovalListQueryParams,
  dispatch?: Dispatch<any>,
): Promise<ApplicationListResponse> => {
  const path = `${ADMIN_URL}/${modulePath}/list`;
  return apiClient
    .get<ApplicationListResponse>(path, { params: params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadApproveList = async (params: ApprovalListQueryBase): Promise<void> => {
  const query = qs.stringify(params);
  const queryPath = `${ADMIN_URL}/${modulePath}/download?${query}`;
  const fileName = `Onboarding Application ${moment().format('DDMMYYYY')}`;
  return downloadFile(fileName, queryPath);
};

export const fetchAssigneeList = async (
  dispatch?: Dispatch<any>,
  params?: AssigneeQueryParams,
): Promise<AssigneeItem[]> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${modulePath}/assignees`, {
      params: params,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchApprovalDetail = async (id: string, dispatch?: Dispatch<any>): Promise<ApprovalDetail> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${modulePath}/detail/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

interface ReturnInfoItem {
  assigneeRole: string;
  remark: string;
}

export const updateApprovalStatus = async (
  body: { approvalEvents: ApprovalEvents; remark?: string; returnInfoList?: ReturnInfoItem[] },
  id: string,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${modulePath}/update/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const assignApproval = async (
  body: { approverId: string; applicationId: string },
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${modulePath}/assign`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateRemarkApprovalStatus = async (
  body: { remark: string; applicationFormStatus?: ApplicationStatus },
  id: string,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${modulePath}/remark/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
