import { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PULSELEADS_POINT_RULE_LEAD_GOAL_PATH, PULSELEADS_POINT_RULE_PATH } from '../constants';
import { PULSELEADS_BASE_PATH, PULSELEADS_RULE_PATH } from 'src/app/modules/PulseLeads/constants';
import { leadGoalFilterKeys } from './list/lead-goal-listing-page.hook';
import { LeadGoalListingPage } from './list/lead-goal-listing-page.screen';

export const leadGoalPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_POINT_RULE_PATH}${PULSELEADS_POINT_RULE_LEAD_GOAL_PATH}`;

const LeadGoalRoutes: FC = () => {
  return (
    <Switch>
      <Redirect exact from={leadGoalPath} to={`${leadGoalPath}/list`} />
      <PruRoute
        exact
        path={`${leadGoalPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={leadGoalFilterKeys}>
            <LeadGoalListingPage />
          </ParamsProvider>
        )}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default LeadGoalRoutes;
