export enum TaskStatus {
  // none = '',
  unassigned = 'UNASSIGNED',
  assigned = 'ASSIGNED',
}

export enum ApplicationType {
  newRecruit = 'NewRecruit',
  reinstatement = 'ForReinstatement',
}

export enum ApplicationStatus {
  draft = 'DRAFT',
  editing = 'EDITING',
  underReview = 'UNDER_REVIEW',
  pendingSignature = 'PENDING_SIGNATURE',
  submitted = 'SUBMITTED',
  rejected = 'REJECTED',
  screeningPendingApproval = 'L1_PENDING_APPROVAL',
  followUpRequired = 'FOLLOWUP_REQUIRED',
  screeningApproved = 'L1_APPROVED',
  icPendingApproval = 'L2_PENDING_APPROVAL',
  icApproved = 'L2_APPROVED',
  agentCodePendingApproval = 'L3_PENDING_APPROVAL',
  agentCodeApproved = 'L3_APPROVED',
  l4PendingApproval = 'L4_PENDING_APPROVAL',
  // l4Approved = 'L4_APPROVED',
  accepted = 'ACCEPTED',
  canceled = 'CANCELLED',
  deleted = 'DELETED',
  expired = 'EXPIRED',
}

export interface ApprovalListQueryBase {
  taskStatus?: string[];
  applicationStatus?: string[];
  applicationId?: string;
  applicantId?: string;
  firstnames?: string;
  lastnames?: string;
  taskAssignee?: string[];
  submittedDateFrom?: string | null;
  submittedDateTo?: string | null;
  applicationType?: string[];
  formType?: string[];
}

export interface ApprovalListQueryParams extends ApprovalListQueryBase {
  page: number;
  limit: number;
  sort?: unknown;
}

export interface AssigneeQueryParams {
  applicationFormStatus?: ApplicationStatus;
}

export type FilterState = Omit<ApprovalListQueryParams, 'submittedDateFrom' | 'submittedDateTo'> & {
  submittedDateFrom?: Date | null;
  submittedDateTo?: Date | null;
  sort?: { key: string; value?: string };
};

export enum ListItemKeys {
  applicationId = 'applicationId',
  applicantId = 'applicantId',
  firstname = 'firstname',
  lastname = 'lastname',
  taskStatus = 'taskStatus',
  assignee = 'taskAssignee',
  applicationStatus = 'applicationStatus',
  remarks = 'remarks',
  applicationType = 'applicationType',
  formType = 'formType',
  submittedTime = 'submittedTime',
  lastUpdatedBy = 'lastUpdatedBy',
  lastUpdatedAt = 'lastUpdatedAt',
}

export type ApplicationListItem = {
  [ListItemKeys.applicationId]: string;
  [ListItemKeys.applicantId]: string;
  [ListItemKeys.firstname]: string;
  [ListItemKeys.lastname]: string;
  [ListItemKeys.taskStatus]: TaskStatus;
  [ListItemKeys.assignee]: string;
  [ListItemKeys.applicationStatus]: ApplicationStatus;
  [ListItemKeys.remarks]: string;
  [ListItemKeys.applicationType]: ApplicationType;
  [ListItemKeys.formType]: string;
  [ListItemKeys.submittedTime]: string;
  [ListItemKeys.lastUpdatedBy]: string;
  [ListItemKeys.lastUpdatedAt]: string;
};

export type AssigneeItem = {
  approverId: string;
};

// detail response
interface Operator {
  operatorId: string;
  // operatorRole: "USER";
  operatorRole: string;
  operatorName: string;
}

interface OperationHistory {
  event: string;
  remark: string;
  operator: Operator;
  updatedAt: string;
  metadata?: {
    approvalTaskAssignee: string;
  };
}

export interface ApprovalDetail {
  id: string;
  applicationId: string;
  applicantId: string;
  firstname: string;
  lastname: string;
  remarks: string;
  submittedTime: string;
  lastUpdatedBy: string;
  lastUpdatedAt: string;
  taskStatus: string;
  assignee: string;
  applicationStatus: ApplicationStatus;
  applicationType: string;
  formType: string;
  recruiterAgentCode: string;
  recruiterAgentName: Record<string, string>;
  content: Record<string, any>;
  modules: any;
  operationHistoryList: OperationHistory[];
  formerAgentCode: string;
  licenseExpirationDate: string;
  appointmentDate: string;
  terminationDate: string;
  agentLeaderCode: string;
  agentLeaderName: Record<string, string>;
  formCreatorUserId: string;
  allowApprove: boolean;
  recruiterIsAgentLeader: boolean;
}

export enum RecordStatus {
  submit = 'SUBMIT',
  l1Approve = 'L1_APPROVE',
  l2Approve = 'L2_APPROVE',
  l3Approve = 'L3_APPROVE',
  l4Approve = 'L4_APPROVE',
  assign = 'APPROVAL_TASK_ASSIGN',
  reject = 'REJECT',
  return = 'RETURN',
  updateRemark = 'ADD_REMARK',
}

export enum ApprovalEvents {
  l1Approve = 'L1_APPROVE',
  l2Approve = 'L2_APPROVE',
  l3Approve = 'L3_APPROVE',
  l4Approve = 'L4_APPROVE',
  assign = 'APPROVAL_TASK_ASSIGN',
  reject = 'REJECT',
  return = 'RETURN',
  updateRemark = 'UPDATE_REMARK',
}

export enum ReturnCategories {
  applicant = 'applicant',
  recruiter = 'recruiter',
  agentLeader = 'agentLeader',
}
