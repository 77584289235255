import React, { memo, ReactNode, useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useStyles } from './dialog.style';
import { useTranslation } from 'src/app/common/hook/translation.hook';

export interface RecruitmentDialogProps {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  title: string;
  children?: ReactNode;
  cancelBtnText?: string;
  confirmBtnText?: string;
  disabledConfirmBtn?: boolean;
}

export const Dialog: React.FC<RecruitmentDialogProps> = memo(
  ({
    open,
    onClose,
    onConfirm,
    loading = false,
    title,
    children,
    cancelBtnText,
    confirmBtnText,
    disabledConfirmBtn,
  }: RecruitmentDialogProps) => {
    const Translation = useTranslation();

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    return (
      <MuiDialog open={open} onClose={onClose} aria-modal>
        <DialogTitle className={styles.title}>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions className={styles.actions}>
          <Button variant="contained" onClick={onClose}>
            {cancelBtnText ?? Translation('app.button.cancel')}
          </Button>
          <Button
            disabled={disabledConfirmBtn}
            variant="contained"
            color="secondary"
            onClick={onConfirm}
            className={styles.confirmButton}
          >
            {confirmBtnText ?? Translation('app.button.confirm')}
            {loading ? <CircularProgress style={{ marginLeft: 8 }} size={15} /> : null}
          </Button>
        </DialogActions>
      </MuiDialog>
    );
  },
);
