import React, { FC, useState } from 'react';
import { useIntl } from "react-intl";
import { FormControlLabel, TextField } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { MANDATORY_FIELD_ERROR_TEXT } from "src/app/common/constants";
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { SectionFieldItem, OperatorEnum } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';

type ValidationNumberRuleProps = {
  field: SectionFieldItem;
  ruleIndex: number;
  setField: (field: SectionFieldItem) => void;
  setErrorState: (index: number, hasError: boolean) => void;
}

const ValidationNumberRule: FC<ValidationNumberRuleProps> = ({ field, ruleIndex, setField, setErrorState }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const rule = field.rules[ruleIndex];

  const [enableInputNumber, setEnableInputNumber] = useState<boolean>(rule.value as number > 0 ? true : false);
  const [inputNumber, setInputNumber] = useState(rule.value);

  return (
    <div className={classes.sectionMargin}>
      <div>
        {`${ruleIndex + 1}. `}
        {intl.formatMessage(
          { id: "agencyCampaign.campaignType.section.editField.isThereMinMaxNumber" },
          { minMax: rule.operator === OperatorEnum.LTE ? 'Maximum' : 'Minimum' }
        )}
      </div>
      <div>
        <FormControlLabel
          style={{ margin: "2px 12px 2px 0" }}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={enableInputNumber}
              onChange={e => {
                if (e.target.checked) {
                  setErrorState(ruleIndex, inputNumber === '');
                  const newField = {...field};
                  newField.rules[ruleIndex].value = Number(inputNumber);
                  setField(newField);
                  setEnableInputNumber(true);
                }
              }}
            />
          }
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 12 }}>
                {rule.operator === OperatorEnum.LTE ? Translation("agencyCampaign.campaignType.section.editField.yesAtMost") : Translation("agencyCampaign.campaignType.section.editField.yesAtLeast")}
              </span>
              <TextField
                style={{ width: 80, margin: '4px 0', marginRight: 12 }}
                type="number"
                disabled={!enableInputNumber}
                margin="dense"
                variant="outlined"
                error={enableInputNumber && inputNumber === ''}
                value={inputNumber}
                onChange={e => {
                  setErrorState(ruleIndex, e.target.value === '');
                  const newField = {...field};
                  newField.rules[ruleIndex].value = Number(e.target.value);
                  setField(newField);
                  setInputNumber(e.target.value);
                }}
              />
            </div>
          }
          labelPlacement="end"
        />
        <FormControlLabel
          style={{ margin: "2px 0" }}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={!enableInputNumber}
              onChange={e => {
                if (e.target.checked) {
                  setErrorState(ruleIndex, false);
                  const newField = {...field};
                  newField.rules[ruleIndex].value = 0;
                  setField(newField);
                  setEnableInputNumber(false);
                }
              }}
            />
          }
          label={Translation("app.checkbox.no")}
          labelPlacement="end"
        />
      </div>
      {(enableInputNumber && inputNumber === '') &&
        <div className={classes.errorText} style={{ paddingLeft: 37.47 }}>
          {MANDATORY_FIELD_ERROR_TEXT}
        </div>
      }
    </div>
  )
}

export default ValidationNumberRule;