import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Grid, TextField } from '@mui/material';
import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './detail.style';
import { useDetail } from './detail.hook';
import { ApprovalDialog } from './components/approval-dialog/approval-dialog.component';
import { SelectAssigneeDialog } from './components/select-assignee-dialog/select-assignee-dialog.component';
import { TranslationWithParams, useLang } from 'src/app/i18n';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { DateFormat, formatDate } from '../util/date.util';
import { ApplicationStatus, ApprovalEvents, RecordStatus } from 'src/app/modules/Recruitment/types/approval-types';
import { UpdateRemarkDialog } from './components/update-remark-dialog/update-remark-dialog.component';
import { RejectDialog } from './components/reject-dialog/reject-dialog.component';
import { ApplicationFormSection } from './components/application-form-section/application-form-section.component';
import { getI18nData } from '../util/i18n.util';
import { APPLICATION_STATUS_MAPS, RECORD } from '../util/constant';

type PageProps = ModulePermissionProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const DetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { enableApprove } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const locale = useLang();
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { dialogType, setDialogType, fileLoaded, data, onRefresh, onClose, formType, applicationId, agentLeaderName } =
    useDetail(props);

  return (
    <>
      <div className={commonStyles.headerContainer}>
        <div className={commonStyles.rowContainer}>
          <div className={commonStyles.header}>
            {Translation('onboarding.application.approval.detail.application_details.application_details')}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={props.history.goBack}>
          {Translation('app.button.back')}
        </Button>
      </div>
      <div className={styles.container}>
        <Grid container spacing={3} className={styles.wrap}>
          <Grid item xs={2}>
            <div className={styles.itemTitle}>
              {Translation('onboarding.application.approval.list.column.application_id')}
            </div>
            <div className={styles.itemContent}>{data?.applicationId ?? '-'}</div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.itemTitle}>
              {Translation('onboarding.application.approval.list.column.application_status')}
            </div>
            <div className={styles.itemContent}>
              {data?.applicationStatus && APPLICATION_STATUS_MAPS[data?.applicationStatus as ApplicationStatus]
                ? Translation(APPLICATION_STATUS_MAPS[data?.applicationStatus as ApplicationStatus])
                : '-'}
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.itemTitle}>{Translation('onboarding.application.approval.list.column.remarks')}</div>
            <div className={styles.itemContent}>{data?.remarks ?? '-'}</div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.itemTitle}>
              {Translation('onboarding.application.approval.list.column.application_type')}
            </div>
            <div className={styles.itemContent}>{data?.applicationType ?? '-'}</div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.itemTitle}>
              {Translation('onboarding.application.approval.list.column.form_type')}
            </div>
            <div className={styles.itemContent}>{formType ?? '-'}</div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.itemTitle}>
              {Translation('onboarding.application.approval.list.column.submitted_time')}
            </div>
            <div className={styles.itemContent}>{formatDate(data?.submittedTime) ?? '-'}</div>
          </Grid>
        </Grid>
        <div className={styles.itemTitle}>
          {Translation('onboarding.application.approval.detail.application_details.applicant_information')}
        </div>
        <Grid container spacing={3} className={styles.wrap}>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label={Translation('onboarding.application.approval.list.column.applicant_id')}
              value={data?.applicantId ?? '-'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label={Translation('onboarding.application.approval.list.column.first_name')}
              value={data?.firstname ?? '-'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label={Translation('onboarding.application.approval.list.column.last_name')}
              value={data?.lastname ?? '-'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <div className={styles.itemTitle}>
          {Translation('onboarding.application.approval.detail.application_details.recruiter_information')}
        </div>
        <Grid container spacing={3} className={styles.wrap}>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label={Translation('onboarding.application.approval.detail.application_details.recruiter_agent_code')}
              value={data?.recruiterAgentCode ?? '-'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label={Translation('onboarding.application.approval.detail.application_details.recruiter_agent_name')}
              value={data?.recruiterAgentName ? getI18nData(data?.recruiterAgentName, locale) ?? '-' : '-'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label={Translation('onboarding.application.approval.detail.application_details.al_agent_code')}
              value={data?.agentLeaderCode ?? '-'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label={Translation('onboarding.application.approval.detail.application_details.al_agent_name')}
              value={agentLeaderName || '-'}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className={commonStyles.header}>
        {Translation('onboarding.application.approval.detail.application_form.application_form')}
      </div>
      <div className={styles.container}>
        {data?.content ? (
          <ApplicationFormSection content={data.content} data={data.modules} fileLoaded={fileLoaded} />
        ) : null}
      </div>
      <div className={commonStyles.header}>{Translation('onboarding.application.approval.detail.record.record')}</div>
      <div className={styles.container}>
        <ul className={styles.list}>
          {data?.operationHistoryList?.map((history, index) => (
            <li key={index}>
              {
                <span>
                  {history.event && RECORD[history.event as RecordStatus]
                    ? TranslationWithParams(RECORD[history.event as RecordStatus], {
                        operationUser: history.operator.operatorName,
                        remark:
                          history.event === RecordStatus.assign
                            ? history.metadata?.approvalTaskAssignee ?? '-'
                            : history.remark ?? '-',
                        time: formatDate(history.updatedAt, DateFormat.datetime),
                      })
                    : null}
                </span>
              }
            </li>
          ))}
        </ul>
      </div>
      {enableApprove &&
      data?.allowApprove &&
      (data?.applicationStatus === ApplicationStatus.agentCodePendingApproval ||
        data?.applicationStatus === ApplicationStatus.icPendingApproval ||
        data?.applicationStatus === ApplicationStatus.l4PendingApproval ||
        data?.applicationStatus === ApplicationStatus.screeningPendingApproval) ? (
        <div className={styles.buttonGroup}>
          <Button
            variant="contained"
            color="inherit"
            className={styles.buttonMargin}
            onClick={() => setDialogType(ApprovalEvents.updateRemark)}
          >
            {Translation('onboarding.application.approval.detail.button.update_remark')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={styles.buttonMargin}
            onClick={() => setDialogType(ApprovalEvents.reject)}
          >
            {Translation('onboarding.application.approval.detail.button.reject')}
          </Button>
          {!(
            data?.applicationStatus == ApplicationStatus.icPendingApproval ||
            data?.applicationStatus == ApplicationStatus.l4PendingApproval
          ) ? (
            <Button
              variant="contained"
              color="secondary"
              className={styles.buttonMargin}
              onClick={() => setDialogType(ApprovalEvents.return)}
            >
              {Translation('onboarding.application.approval.detail.button.return')}
            </Button>
          ) : null}
          <Button
            variant="contained"
            color="secondary"
            className={styles.buttonMargin}
            onClick={() => setDialogType(ApprovalEvents.l1Approve)}
          >
            {Translation('onboarding.application.approval.detail.button.approve')}
          </Button>
          {data?.applicationStatus ? (
            <ApprovalDialog
              open={dialogType === ApprovalEvents.l1Approve}
              id={applicationId}
              onClose={onClose}
              onRefresh={onRefresh}
              applicationStatus={data.applicationStatus as ApplicationStatus}
            />
          ) : null}
          {data ? (
            <SelectAssigneeDialog
              open={dialogType === ApprovalEvents.return}
              id={applicationId}
              onClose={onClose}
              onRefresh={onRefresh}
              showApplicant={data?.formCreatorUserId ? data?.formCreatorUserId !== data?.recruiterAgentCode : false}
              showAgentLeader={data?.recruiterIsAgentLeader === false}
            />
          ) : null}
          <RejectDialog
            open={dialogType === ApprovalEvents.reject}
            id={applicationId}
            onClose={onClose}
            onRefresh={onRefresh}
          />
          {data ? (
            <UpdateRemarkDialog
              open={dialogType === ApprovalEvents.updateRemark}
              id={applicationId}
              onClose={onClose}
              onRefresh={onRefresh}
              applicationFormStatus={data.applicationStatus}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};
