import React, { FC, useState, useEffect } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";
import {
  SurveyResult,
  SurveyResultItem,
  SurveySummaryResult,
} from "src/app/modules/Survey/types/survey-types";
import { LayoutSplashScreen } from "src/app/layout";
import CustomerSurveyAnalyticSection from "./component/CustomerSurveyAnalyticSection";
import { fetchCustomerSurveySummary, fetchCustomerSurveyResults, CustomerSurveyResultsPaginateParam } from "../network/api";

const limit = 1000;

const CustomerSurveyAnalyticPage: FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingFullDashboard, setIsLoadingFullDashboard] = useState<boolean>(false);
  const [showDashboard, setShowDashboard] = useState<boolean>(false);
  const [surveyResults, setSurveyResults] = useState<SurveyResult>();
  const [surveySummaries, setSurveySummary] = useState<SurveySummaryResult>();

  const reloadData = async () => {
    try {
      if (id) {
        setIsLoading(true);
        const surveySummary = await fetchCustomerSurveySummary(id, dispatch);
        setSurveySummary(surveySummary);
        const isWithinLimit = surveySummary.totalSubmits <= limit;
        if (isWithinLimit) {
          const surveyResult = await fetchCustomerSurveyResults(id, { page: 1, limit }, dispatch);
          setSurveyResults(surveyResult);
        }
        setShowDashboard(isWithinLimit);
      }
    } catch (e) {
      history.push(NOT_FOUND_ERROR_PAGE_PATH);
    } finally {
      setIsLoading(false);
    }
  };

  const getFullDashboardData = async (page: number, data: SurveyResultItem[] = []) => {
    try {
      const params: CustomerSurveyResultsPaginateParam = {
        page,
        limit,
      };
      const res = await fetchCustomerSurveyResults(id, params, dispatch);
      const newData = [...data, ...res.surveyResults];
      if (res.hasNextPage) {
        await getFullDashboardData(page + 1, newData);
      } else {
        setSurveyResults({
          ...res,
          surveyResults: newData,
        });
        setIsLoadingFullDashboard(false);
        setShowDashboard(true);
      }
    } catch (err) {
      setIsLoadingFullDashboard(false);
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {isLoading || !surveySummaries ? (
        <LayoutSplashScreen />
      ) : (
        <CustomerSurveyAnalyticSection
          id={id}
          isLoadingFullDashboard={isLoadingFullDashboard}
          showDashboard={showDashboard}
          results={surveyResults}
          summaries={surveySummaries}
          setIsLoadingFullDashboard={setIsLoadingFullDashboard}
          getFullDashboardData={getFullDashboardData}
        />
      )}
    </>
  );
};

export default CustomerSurveyAnalyticPage;
