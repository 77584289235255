import React, { memo } from 'react';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { BaseDialog } from '../base-dialog/base-dialog.component';
import { ApprovalEvents } from 'src/app/modules/Recruitment/types/approval-types';
import { BaseProps } from '../base-dialog/base-dialog.hook';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

export const UpdateRemarkDialog: React.FC<BaseProps> = memo(
  ({ onRefresh, id, onClose, open, applicationFormStatus }: BaseProps) => {
    const Translation = useTranslation();

    const remarks = useSelector((state: any) =>
      get(state.config.data, 'Recruitment.onboardingApplicationRemarks.updateRemark', []),
    );

    return (
      <BaseDialog
        open={open}
        id={id}
        title={Translation('onboarding.application.approval.detail.dialog.update.remark.title')}
        onClose={onClose}
        approvalEvents={ApprovalEvents.updateRemark}
        onRefresh={onRefresh}
        options={remarks}
        applicationFormStatus={applicationFormStatus}
      />
    );
  },
);
