import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  dialogContainer: {
    padding: 48,
    minWidth: 500,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: 8,
    textAlign: 'center',
  },
  content: {
    padding: 24,
  },
  dialogButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
  },
  buttonMargin: {
    marginLeft: 24,
  },
  header: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  container: {
    padding: 24,
    marginTop: 10,
    marginBottom: 32,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  wrap: {
    marginBottom: 24,
    marginTop: 0,
  },
  list: {
    paddingLeft: 16,
    listStyleType: 'disc',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: 8,
  },
}));
