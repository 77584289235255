import { I18nGenericData } from 'src/app/i18n';
import { AttachmentDef } from 'src/app/common/types';
import { TargetApplicantTypeEnum } from 'src/app/common/components/target-applicant-section-v2';
import { PublishStatusEnum, IncentiveAward, IncentiveHighlight } from '../../../../types/incentive-types';

export type IncentiveFormState = {
  code?: string;
  name: I18nGenericData<string>;
  status: PublishStatusEnum;
  startDate: Date | null;
  endDate: Date | null;
  detail: I18nGenericData<string>;
  objective: I18nGenericData<string>;
  bulletin: I18nGenericData<string>;
  award: I18nGenericData<string>;
  incentiveImage: I18nGenericData<AttachmentDef>;
  bulletinFile: I18nGenericData<AttachmentDef>;
  highlights: IncentiveHighlight[];
  awards: IncentiveAward[];
  stickOnTop: boolean;
  publishDate: Date | null;
  archiveDate: Date | null;
  targetApplicantType: TargetApplicantTypeEnum;
  eligibleDesignations: string[];
  eligibleSegments: string[];
  eligibleAgents: string[];
  excludedAgents: string[];
  agentListFile?: AttachmentDef | null;
  resultFile?: AttachmentDef;
};

type SetFormStateAction = {
  type: 'SET_FORM_STATE';
  payload: {
    value: Partial<IncentiveFormState>;
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof IncentiveFormState;
    value: any;
  };
};

type FillInDetailAction = {
  type: 'FILL_IN_DETAIL';
  payload: {
    fromLocale: string;
    toLocale: string;
  };
};

type IncentiveFormAction = SetFormStateAction | ModifyFieldAction | FillInDetailAction;

export const incentiveFormReducer = (state: IncentiveFormState, action: IncentiveFormAction): IncentiveFormState => {
  switch (action.type) {
    case 'SET_FORM_STATE':
      return {
        ...state,
        ...action.payload.value,
      };
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'FILL_IN_DETAIL':
      const fromLocale = action.payload.fromLocale;
      const toLocale = action.payload.toLocale;
      return {
        ...state,
        detail: {
          ...state.detail,
          [toLocale]: state.detail[fromLocale],
        },
        objective: {
          ...state.objective,
          [toLocale]: state.objective[fromLocale],
        },
        bulletin: {
          ...state.bulletin,
          [toLocale]: state.bulletin[fromLocale],
        },
        award: {
          ...state.award,
          [toLocale]: state.award[fromLocale],
        },
        incentiveImage: {
          ...state.incentiveImage,
          [toLocale]: state.incentiveImage[fromLocale],
        },
        bulletinFile: {
          ...state.bulletinFile,
          [toLocale]: state.bulletinFile[fromLocale],
        },
      };
    default:
      return state;
  }
};
