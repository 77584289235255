import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import { useDataProvider, getDayStart } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PruTableSortTypeEnum } from 'src/app/common/components/PruTable/PruTable';
import { EventItem } from '../../../types';
import {
  EventListParam,
  fetchEventList,
  fetchEventCategoryList,
  fetchEventShortenCodeUrl,
  cloneEventItem,
} from '../../../network';
import { eventListPath } from '../event-list-routes';
import { downloadQRCodePDF } from './utils/generate-qr-code-pdf.utils';

const initialState: EventListParam = {
  search: '',
  category: '',
  accessibility: '',
  onlyHost: '',
  eventDate: null,
  createdBy: '',
  eventStatus: '',
  publishStatus: '',
  page: 1,
  limit: 20,
};

export const eventFilterKeys = [
  'search',
  'accessibility',
  'onlyHost',
  'eventDate',
  'createdBy',
  'eventStatus',
  'publishStatus',
];

const paramsInitiator = (initialParams?: Record<string, string>): EventListParam => {
  return initialParams
    ? {
        search: initialParams.search || '',
        category: '',
        accessibility: initialParams.accessibility || '',
        onlyHost: initialParams.onlyHost || '',
        eventDate: initialParams.eventDate ? getDayStart(new Date(initialParams.eventDate)) : null,
        createdBy: initialParams.createdBy || '',
        eventStatus: initialParams.eventStatus || '',
        publishStatus: initialParams.publishStatus || '',
        page: 1,
        limit: 20,
      }
    : initialState;
};

export const useEventListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const history = useHistory();
  const locale = useLang();
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<EventListParam>(paramsInitiator(initialParams));
  const [eventList, setEventList] = useState<PaginateList<EventItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<EventItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchEventList(filterState, dispatch);
      } catch (err) {}
    },
    setEventList,
    false,
  );

  const fetchCategoryDropdown = async () => {
    const res = await fetchEventCategoryList({ pagination: false }, dispatch);
    return res.docs.map((item) => ({ displayName: item.name[locale] as string, value: item._id }));
  };

  const onSort = (newSortState: { key: string; value?: string }) => {
    const { key, value } = newSortState;
    if (value) {
      let newSort = '';
      switch (value) {
        case PruTableSortTypeEnum.ASC:
          newSort = key;
          break;
        case PruTableSortTypeEnum.DESC:
          newSort = `-${key}`;
          break;
      }
      setFilterState({ ...filterState, sort: newSort });
    } else {
      setFilterState({ ...filterState, sort: undefined });
    }
    refreshData();
  };

  const onDownloadQRCode = async (eventItem: EventItem) => {
    try {
      const res = await fetchEventShortenCodeUrl(eventItem._id, dispatch);
      await downloadQRCodePDF(res.shortenCodeUrl, eventItem);
    } catch (err) {}
  };

  const onCopyAndNew = async (id: string) => {
    try {
      const res = await cloneEventItem(id, dispatch);
      history.push(`${eventListPath}/edit/${res._id}`);
    } catch (err) {}
  };

  return {
    filterState,
    eventList,
    isLoading,
    setFilterState,
    refreshData,
    fetchCategoryDropdown,
    onSort,
    onDownloadQRCode,
    onCopyAndNew,
  };
};
