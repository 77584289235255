import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { BaseProps } from '../base-dialog/base-dialog.hook';
import { updateApprovalStatus } from 'src/app/modules/Recruitment/network/approval-crud';
import { ReturnCategories, ApprovalEvents } from 'src/app/modules/Recruitment/types/approval-types';
import { formatReturnParams } from '../../../util/api-helper.util';
import { AssigneeSelectorValue } from '../assignee-selector/assignee-selector.component';
import { getOptions, getDefaultValue } from './util/select-assignee-dialog.util';

export type SelectAssigneeDialogProps = BaseProps & {
  showApplicant?: boolean;
  showAgentLeader?: boolean;
  isMultipleSelect?: boolean;
};

export const useSelectAssigneeDialog = ({
  onRefresh,
  id,
  onClose,
  showApplicant = true,
  showAgentLeader,
  isMultipleSelect = true,
}: SelectAssigneeDialogProps) => {
  const dispatch = useDispatch();

  const remarks = useSelector((state: any) =>
    get(state.config.data, 'Recruitment.onboardingApplicationRemarks.return', {
      [ReturnCategories.applicant]: [],
      [ReturnCategories.recruiter]: [],
      [ReturnCategories.agentLeader]: [],
    }),
  );

  const applicantOptions = useMemo(() => getOptions(remarks[ReturnCategories.applicant]), []);

  const recruiterOptions = useMemo(() => getOptions(remarks[ReturnCategories.recruiter]), []);

  const agentLeaderOptions = useMemo(() => getOptions(remarks[ReturnCategories.agentLeader]), []);

  const [selected, setSelected] = useState<Record<string, AssigneeSelectorValue | undefined>>(() => {
    const [options, key] = showApplicant
      ? [applicantOptions, ReturnCategories.applicant]
      : [recruiterOptions, ReturnCategories.recruiter];
    return getDefaultValue(options, key);
  });

  const [confirmLoading, setConfirmLoading] = useState(false);

  const onConfirm = useCallback(async () => {
    try {
      setConfirmLoading(true);
      const { result, remark } = formatReturnParams(selected, showAgentLeader);

      await updateApprovalStatus(
        { approvalEvents: ApprovalEvents.return, remark, returnInfoList: result },
        id!,
        dispatch,
      );
      onClose?.();
      await onRefresh?.();
    } catch (err) {
      console.warn('updateApprovalStatus err: ', err);
    } finally {
      setConfirmLoading(false);
    }
  }, [id, selected, onRefresh, onClose, dispatch, showAgentLeader]);

  const onChange = useCallback(
    (key: string, value?: AssigneeSelectorValue) => {
      if (isMultipleSelect) {
        setSelected((val) => ({ ...val, [key]: value }));
      } else {
        setSelected({ [key]: value });
      }
    },
    [isMultipleSelect],
  );

  const disabledConfirmBtn = useMemo(
    () =>
      !(
        selected?.[ReturnCategories.applicant]?.checked ||
        selected?.[ReturnCategories.recruiter]?.checked ||
        selected?.[ReturnCategories.agentLeader]?.checked
      ),
    [selected],
  );

  return {
    applicantOptions,
    recruiterOptions,
    agentLeaderOptions,
    confirmLoading,
    onConfirm,
    onChange,
    disabledConfirmBtn,
    isMultipleSelect,
    selected,
  };
};
