import { FC } from 'react';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useLicenseVerificationListingPage } from './license-verification-listing-page.hook';
import { LicenseVerificationList } from './components/license-verification-list.component';
import { fetchLicenseExamExamOptions } from '../../../network/license-exam-crud';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { RouteComponentProps } from 'react-router-dom';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { LicenseVerificationStatusEnum } from '../../../enum/license-exam.enum';
import { Box } from '@mui/material';

type LicenseVerificationListingPageProps = ParamsProps & RouteComponentProps & ModulePermissionProps;

export const LicenseVerificationListingPage: FC<LicenseVerificationListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();

  const { filterState, licenseVerificationList, isLoading, setFilterState, refreshData, onSort } =
    useLicenseVerificationListingPage({
      initialParams,
      onChangeQueryParams,
    });

  return (
    <Box className="auto-table-height">
      <PruFilter
        title={Translation('app.button.search')}
        itemDef={[
          {
            type: PruFilterItemType.ASYNC_DROPDOWN,
            style: { width: 180 },
            field: 'examType',
            initialValue: filterState.examType,
            displayName: Translation('recruitment.exam.examType'),
            fetchList: async () => {
              const res = await fetchLicenseExamExamOptions('EXAM_TYPE', { lang: locale }, dispatch);
              return res.map((item) => ({
                displayName: item || '-',
                value: item || '-',
              }));
            },
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'examDateFrom',
            fieldTo: 'examDateTo',
            initialDateFrom: filterState.examDateFrom,
            initialDateTo: filterState.examDateTo,
            displayName: Translation('recruitment.exam.examDate'),
          },
          {
            type: PruFilterItemType.MULTIPLE_DROPDOWN,
            style: { width: 180 },
            field: 'licenseApprovalStates',
            initialValue: filterState.licenseApprovalStates,
            displayName: Translation('recruitment.license.verification.status'),
            choices: Object.entries(LicenseVerificationStatusEnum).map(([key, value]) => {
              return {
                displayName: Translation(`recruitment.license.verification.status.${value}`),
                value: key,
              };
            }),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'verificationIds',
            initialValue: filterState.verificationIds,
            displayName: Translation('recruitment.license.verification.id'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'applicantIds',
            initialValue: filterState.applicantIds,
            displayName: Translation('recruitment.exam.registration.applicantId'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'firstnames',
            initialValue: filterState.firstnames,
            displayName: Translation('recruitment.exam.registration.firstName'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'lastnames',
            initialValue: filterState.lastnames,
            displayName: Translation('recruitment.exam.registration.lastName'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'submissionDateFrom',
            fieldTo: 'submissionDateTo',
            initialDateFrom: filterState.submissionDateFrom,
            initialDateTo: filterState.submissionDateTo,
            displayName: Translation('recruitment.license.verification.submissionDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            examType: data.examType,
            examDateFrom: data.examDateFrom,
            examDateTo: data.examDateTo,
            licenseApprovalStates: data.licenseApprovalStates,
            verificationIds: data.verificationIds,
            applicantIds: data.applicantIds,
            firstnames: data.firstnames,
            lastnames: data.lastnames,
            submissionDateFrom: data.submissionDateFrom,
            submissionDateTo: data.submissionDateTo,
          })
        }
        fetchData={refreshData}
      />
      <LicenseVerificationList
        isLoading={isLoading}
        licenseVerificationList={licenseVerificationList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
        formState={filterState}
      />
    </Box>
  );
};
