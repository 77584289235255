import { I18nGenericData } from 'src/app/i18n';

export enum UploadStatusEnum {
  SUCCESS = 'Success',
  FAIL = 'Fail',
  PENDING = 'Pending',
}

export type KpiLogData = {
  key: string;
  name: I18nGenericData<string>;
};

export type UploadStatusItem = {
  _id: string;
  incentiveCode: string;
  name: I18nGenericData<string>;
  blobId: string;
  filename: string;
  kpiMatch: KpiLogData[];
  kpiMiss: KpiLogData[];
  status: UploadStatusEnum;
  reason: string;
  lastUpdatedBy: string;
  resultDate: Date;
  updatedAt: Date;
};
