import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  AdminScheduleJobPermissionType,
  SYSTEM_ADMIN_BASE_PATH,
  SCHEDULE_JOB_BASE_PATH,
  SCHEDULE_JOB_TITLE,
  SCHEDULE_JOB_NAMESPACE,
  SCHEDULE_JOB_HISTORY_PATH,
  SCHEDULE_JOB_HISTORY_TITLE,
  SCHEDULE_JOB_HISTORY_NAMESPACE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
// import NewsCategoryPage from './pages/Category/NewsCategoryPage';
import ScheduleJobListPage from './pages/ScheduleJobPage';
import { tr } from 'date-fns/locale';
import EventCategoryRoutes from '../event-v2/pages/event-category/event-category-routes';
// import NewsHeadlinePage from './pages/Headline/NewsHeadlinePage';

export const systemAdminModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    //hims mark
    // title: Translation(SCHEDULE_JOB_TITLE),
    title: 'System Admin',
    path: SYSTEM_ADMIN_BASE_PATH,
    icon: '/media/svg/icons/Home/Book-open.svg',
    enableRead: true,
    // enableRead: isPermitted(
    //   [
    //     AdminScheduleJobPermissionType.SCHEDULE_JOB_READ,
    //     AdminScheduleJobPermissionType.SCHEDULE_JOB_CREATE,
    //     AdminScheduleJobPermissionType.SCHEDULE_JOB_UPDATE,
    //   ],
    //   permissions,
    // ),
    child: [
      {
        //hims mark
        title: 'Schedule Job',
        path: `${SYSTEM_ADMIN_BASE_PATH}${SCHEDULE_JOB_BASE_PATH}`,
        namespace: SCHEDULE_JOB_NAMESPACE,
        component: ScheduleJobListPage,
        enableRead: true,
        // enableRead: isPermitted(
        //   [
        //     AdminEventPermissionType.EVENT_LIST_READ,
        //     AdminEventPermissionType.EVENT_LIST_CREATE,
        //     AdminEventPermissionType.EVENT_LIST_UPDATE,
        //   ],
        //   permissions,
        // ),
        enableCreate: true,
        enableUpdate: true,
        // enableCreate: isPermitted([AdminEventPermissionType.EVENT_LIST_CREATE], permissions),
        // enableUpdate: isPermitted([AdminEventPermissionType.EVENT_LIST_UPDATE], permissions),
      },
    ],
  };
};
