import React, { useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TranslationWithParams } from 'src/app/i18n';
import {
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import Form from 'src/app/common/components/Form';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './registration-detail.style';
import { useRegistrationDetail } from './registration-detail.hook';
import { ModulePermissionProps } from 'src/app/common/module/types';
import {
  PaymentTypeEnum,
  RegistrationApprovalEventsEnum,
  ExamEventsEnum,
  ResultTypeEnum,
  RegistrationApprovalStatesEnum,
} from '../../../enum/license-exam.enum';
import { eventType, PaymentType, resultType } from '../../../types/license-exam-types';
import { LoadingButton } from '@mui/lab';
import { scoreValidator } from '../util/form.util';
import { dateFormatToLocal, utcToLocalDate } from '../../../utils';

type PageProps = ParamsProps & ModulePermissionProps & RouteComponentProps;

export const RegistrationDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { history, enableUpdate } = props;
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    action,
    data,
    handlePress,
    dialogState,
    handleCloseDialog,
    showResult,
    showCancelButton,
    showApprovalButtons,
    editing,
    setEditing,
    handleSaveEditResult,
    handleConfirm,
    remark,
    setRemark,
    form,
    initialValues,
    loadingUpdateResult,
    loadingApprove,
    statusLabel,
  } = useRegistrationDetail({
    id: id,
    enableUpdate: enableUpdate,
  });

  return (
    <>
      <Dialog open={dialogState.open} onClose={handleCloseDialog}>
        <div className={styles.dialogContainer}>
          <div className={styles.dialogTitle}>{dialogState.header}</div>
          {action === RegistrationApprovalEventsEnum.cancel && (
            <div className={styles.dialogSubtitle}>{Translation('recruitment.exam.registration.cancel.subtitle')}</div>
          )}
          <div className={styles.content}>
            <TextField
              variant="outlined"
              label={Translation('recruitment.exam.registration.remark')}
              value={remark}
              fullWidth
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            />
          </div>
          <div className={styles.dialogButton}>
            <Button variant="outlined" color="inherit" onClick={handleCloseDialog}>
              {Translation('app.button.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              loading={loadingApprove}
              className={styles.buttonMargin}
              onClick={handleConfirm}
            >
              {Translation('app.button.confirm')}
            </LoadingButton>
          </div>
        </div>
      </Dialog>
      <div className={commonStyles.headerContainer}>
        <div className={commonStyles.rowContainer}>
          <div className={commonStyles.header}>{Translation('recruitment.exam.registration.detail')}</div>
        </div>
        <Button variant="outlined" color="inherit" onClick={history.goBack}>
          {Translation('app.button.back')}
        </Button>
      </div>
      {data && (
        <>
          <div className={styles.container}>
            <Grid container spacing={3} className={styles.wrap}>
              <Grid item xs={4}>
                <div className={styles.header}>{Translation('recruitment.exam.registration.id')}</div>
                <div className={styles.header}>{data.registrationId || '-'}</div>
              </Grid>
              <Grid item xs={3}>
                <div className={styles.header}>{Translation('recruitment.exam.registration.application.status')}</div>
                <div className={styles.header}>{statusLabel}</div>
              </Grid>
            </Grid>

            <div className={styles.header}>{Translation('recruitment.exam.registration.examinee')}</div>
            <Grid container spacing={3} className={styles.wrap}>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.registration.applicantId')}
                  value={data.applicantId || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.registration.firstName')}
                  value={data.firstname || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.registration.lastName')}
                  value={data.lastname || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>

            <div className={styles.header}>{Translation('recruitment.exam.registration.schedule')}</div>
            <Grid container spacing={3} className={styles.wrap}>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.examCode')}
                  value={data.examCode || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.examType')}
                  value={data.examType || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.examDate')}
                  value={data.examDate ? dateFormatToLocal(data.examDate) : '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.examDateStartTime')}
                  value={data.startTime || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.examDateEndTime')}
                  value={data.endTime || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.examCentre')}
                  value={data.examCentre || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.registration.address')}
                  value={data.address || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>

            <div className={styles.header}>{Translation('recruitment.exam.registration.proofOfPayment')}</div>
            <Grid container spacing={3} className={styles.wrap}>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.fee.number')}
                  value={data.examFeeReceiptNo || '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  FormHelperTextProps={{ sx: { color: '#E8192C' } }}
                  helperText={
                    data.examFeeOrNumberUsedBefore &&
                    data.registrationApprovalStates !== RegistrationApprovalStatesEnum.REGISTERED.toUpperCase()
                      ? Translation('recruitment.common.or.error.alert')
                      : null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.exam.fee.date')}
                  value={data.examFeeReceiptDate ? dateFormatToLocal(data.examFeeReceiptDate) : '-'}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={styles.wrap}>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.license.fee.type')}
                  value={
                    data.paymentType
                      ? Translation(
                          `recruitment.exam.registration.payment.${PaymentTypeEnum[data.paymentType as PaymentType]}`,
                        )
                      : '-'
                  }
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.license.fee.number')}
                  value={
                    data.licenseFeeReceiptNo &&
                    data.paymentType &&
                    data.paymentType !== PaymentTypeEnum.CODE_NOW_PAY_LATER.toUpperCase()
                      ? data.licenseFeeReceiptNo
                      : '-'
                  }
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  FormHelperTextProps={{ sx: { color: '#E8192C' } }}
                  helperText={
                    data.licenseFeeOrNumberUsedBefore &&
                    data.registrationApprovalStates !== RegistrationApprovalStatesEnum.REGISTERED.toUpperCase()
                      ? Translation('recruitment.common.or.error.alert')
                      : null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label={Translation('recruitment.license.fee.date')}
                  value={
                    data.licenseFeeReceiptDate &&
                    data.paymentType &&
                    data.paymentType !== PaymentTypeEnum.CODE_NOW_PAY_LATER.toUpperCase()
                      ? dateFormatToLocal(data.licenseFeeReceiptDate)
                      : '-'
                  }
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </div>

          {showResult && (
            <Form form={form} onFinish={handleSaveEditResult} initialValues={{ ...initialValues }}>
              <div className={commonStyles.headerContainer}>
                <div className={commonStyles.header}>{Translation('recruitment.exam.registration.examResult')}</div>
                {editing ? (
                  <div>
                    <Button variant="outlined" color="inherit" onClick={() => setEditing(false)}>
                      {Translation('app.button.cancel')}
                    </Button>
                    <Button type="submit" variant="contained" color="secondary" className={styles.buttonMargin}>
                      {Translation('app.button.save')}
                      {loadingUpdateResult && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!enableUpdate}
                    onClick={() => setEditing(true)}
                  >
                    {Translation('app.button.edit')}
                  </Button>
                )}
              </div>

              <div className={styles.container}>
                {editing ? (
                  <>
                    <Form.Item
                      name="result"
                      label={Translation('recruitment.exam.registration.result')}
                      rules={[{ required: true, message: Translation('component.form-required-text') }]}
                      shouldUpdate
                    >
                      <FormControl error>
                        <RadioGroup
                          className={classes.radioGroup}
                          aria-label="exam-result"
                          name="exam-result"
                          defaultValue={initialValues.result}
                        >
                          {Object.entries(ResultTypeEnum).map(([value, label]) => (
                            <FormControlLabel
                              style={{ marginBottom: 0 }}
                              key={value}
                              value={value}
                              control={<Radio />}
                              label={Translation(`recruitment.exam.registration.result.${label}`)}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Form.Item>
                    <Form.Item
                      name="score"
                      label={Translation('recruitment.exam.registration.score')}
                      rules={[
                        {
                          required: true,
                          validator(rule, value, callback) {
                            const msg = scoreValidator(
                              {
                                rule,
                                emptyMsg: Translation('component.form-required-text'),
                                errorMsg: Translation('recruitment.exam.score_value_validator'),
                              },
                              value,
                            );
                            callback(msg);
                          },
                        },
                      ]}
                    >
                      <TextField
                        margin="dense"
                        variant="outlined"
                        type="number"
                        InputProps={{ inputProps: { min: 0, step: '0.00001' } }}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <div className={styles.rowContainer}>
                      <div className={styles.formHeader}>
                        {Translation('recruitment.exam.registration.result')}
                        <span className={classes.mandatory}>*</span>
                      </div>
                      <span>
                        {data.result
                          ? Translation(
                              `recruitment.exam.registration.result.${ResultTypeEnum[data.result as resultType]}`,
                            )
                          : '-'}
                      </span>
                    </div>

                    <div className={styles.rowContainer}>
                      <div className={styles.formHeader}>
                        {Translation('recruitment.exam.registration.score')}
                        <span className={classes.mandatory}>*</span>
                      </div>
                      <span>{data.score || '-'}</span>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )}
          {data?.submissionTime && (
            <>
              <div className={commonStyles.header}>{Translation('recruitment.exam.registration.record')}</div>
              <div className={styles.container}>
                <ul className={styles.list}>
                  {data.operationHistoryList?.map((history, index) => (
                    <li key={index}>
                      <span>
                        {history.event
                          ? history.event === RegistrationApprovalEventsEnum.submit
                            ? TranslationWithParams(`recruitment.exam.registration.remark.submit`, {
                                firstName: data.firstname,
                                lastName: data.lastname,
                                time: utcToLocalDate(history.updatedAt),
                              })
                            : TranslationWithParams(
                                `recruitment.exam.registration.remark.${ExamEventsEnum[history.event as eventType]}`,
                                {
                                  operationUser: history.event !== 'EXPIRE' ? history.operator.operatorName : undefined,
                                  time: utcToLocalDate(history.updatedAt),
                                },
                              )
                          : '-'}
                      </span>
                      {history.remark && (
                        <span>{` ${Translation('recruitment.exam.registration.remark')}: ${history.remark}`}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}

          <div className={styles.buttonGroup}>
            {showCancelButton && (
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => handlePress(RegistrationApprovalEventsEnum.cancel)}
              >
                {Translation('recruitment.exam.registration.cancel.button')}
              </Button>
            )}
            {showApprovalButtons && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  className={styles.buttonMargin}
                  onClick={() => handlePress(RegistrationApprovalEventsEnum.reject)}
                >
                  {Translation('recruitment.exam.registration.reject')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={styles.buttonMargin}
                  onClick={() => handlePress(RegistrationApprovalEventsEnum.return)}
                >
                  {Translation('recruitment.exam.registration.return')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={styles.buttonMargin}
                  onClick={() => handlePress(RegistrationApprovalEventsEnum.approve)}
                >
                  {Translation('recruitment.exam.registration.approve')}
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
