import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { LayoutSplashScreen } from 'src/app/layout';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { getMarketingCampaignLeadLimitRule } from '../../../network/ruleConfigCrud';
import MarketingLeadLimitForm from './marketing-lead-limit-form';
import { RouteComponentProps } from 'react-router-dom';

const MarketingLeadLimitPage: FC<RouteComponentProps> = () => {
  const [data, setData] = useState({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await getMarketingCampaignLeadLimitRule();
        setData(res);
      } catch (err) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('Error'),
              content: Translation('common.serverError'),
            },
          ]),
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <>
          <MarketingLeadLimitForm data={data} />
        </>
      )}
    </>
  );
};

export default MarketingLeadLimitPage;
