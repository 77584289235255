import React, { FC, memo, useMemo } from 'react';
import FileDownload from '@mui/icons-material/SaveAlt';
import { useIntl } from 'react-intl';
import { PDFTemplate, PDFTemplateProps } from './pdf-template/pdf-template';

import { useStyles } from '../template-detail-page.style';
import { useStep3 } from './step3.hook';
import { RecruitmentStepProps } from '../template-detail-page.type';

export const Step3Component: React.FC<RecruitmentStepProps> = memo((props: RecruitmentStepProps) => {
  // i18n
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { pdfList, localLanguage, downloadKeys, onUploadChange, onDownloadInFormChange } = useStep3(props);
  return (
    <div className={`tw-text-black tw-mb-20 ${classes.step3}`}>
      <div className="tw-flex tw-items-center tw-mt-10 link-pdf-template">
        <div className="tw-font-bold tw-text-lg tw-w-60">{t('link_pdf_template')}</div>
        <div onClick={downloadKeys}>
          <FileDownload className="download-key-btn tw-cursor-pointer" />
          <span className="download-keys tw-cursor-pointer">{t('download_the_keys')}</span>
        </div>
      </div>
      <div className="en-ct">
        {pdfList.some((item) => item.language === 'en') ? (
          <div className="tw-font-bold tw-text-lg ct-title">{t('english_version')}</div>
        ) : null}

        {pdfList.map((i, index) => {
          if (i.language === 'en') {
            return (
              <PDFTemplate
                key={index}
                onChange={onUploadChange}
                onDownloadInFormChange={onDownloadInFormChange}
                index={index}
                {...i}
              ></PDFTemplate>
            );
          }
          return null;
        })}
      </div>
      {localLanguage ? (
        <div className="local-ct">
          <div className="tw-font-bold tw-text-lg ct-title">{t('local_language_version')}</div>
          {pdfList.map((i, index) => {
            if (i.language !== 'en') {
              return (
                <PDFTemplate
                  key={index}
                  onChange={onUploadChange}
                  onDownloadInFormChange={onDownloadInFormChange}
                  index={index}
                  {...i}
                ></PDFTemplate>
              );
            }
            return null;
          })}
        </div>
      ) : null}
    </div>
  );
});

// const en: PDFTemplateProps[] = [
//   {
//     blobId: 'fdsa',
//     key: 'test',
//     status: 'checked',
//     filesize: '100M',
//     originalFilename: 'test.pdf',
//     del: (key: string) => console.log('del'),
//     download: (blobid: string) => console.log('del'),
//     showError: (key: string) => console.log('del'),
//     upload: (key, file) => console.log('del'),
//     label: 'test label1',
//   },
//   {
//     blobId: 'fdsa',
//     key: 'test',
//     status: 'empty',
//     filesize: '100M',
//     originalFilename: 'test.pdf',
//     del: (key: string) => console.log('del'),
//     download: (blobid: string) => console.log('del'),
//     showError: (key: string) => console.log('del'),
//     upload: (key, file) => console.log('del'),
//     label: 'test label2',
//   },
// ];

// const local: PDFTemplateProps[] = [
//   {
//     blobId: 'fdsa',
//     key: 'test',
//     status: 'error',
//     filesize: '100M',
//     originalFilename: 'test.pdf',
//     error: 'some error',
//     del: (key: string) => console.log('del'),
//     download: (blobid: string) => console.log('del'),
//     showError: (key: string) => console.log('del'),
//     upload: (key, file) => console.log('del'),
//     label: 'test label',
//   },
// ];
