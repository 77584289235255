import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  FormControl,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useLang, regionLocale } from 'src/app/i18n';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import Form, { useForm } from 'src/app/common/components/Form';
import { saveAs } from 'file-saver';
import { OnboardingQuizFormState } from 'src/app/modules/Recruitment/types/courses-types';
import { createBlob, getBlob } from 'src/app/common/network';
import { useDispatch } from 'react-redux';
import { fileUpload } from 'src/app/common/utils';
import { parse } from 'gift-pegjs';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { template } from './template';
import PruDialog from 'src/app/common/components/PruDialog';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  accordContainer: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  divideMargin: {
    // marginBottom: 10
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  accordHeading: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
  accordSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addNewMaterialSetBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBg: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'scroll',
  },
  modalButtons: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const StyledRadioGroup = withStyles(RadioGroup, {
  root: {
    flexDirection: 'row',
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: '15px',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
});

interface QuizFormListProp {
  id: string;
  disabled: boolean;
  onSave: (formData: OnboardingQuizFormState, courseId: string, type: string) => void;
  onDelete: () => void;
  initialValues: Partial<OnboardingQuizFormState>;
  isEdit: boolean;
  history: any;
  location?: any;
  match?: any;
  type: string;
  loadingFun?: () => void;
  linkedCourse?: {
    [key: string]: string;
  };
  linkedLesson?: {
    [key: string]: string;
  };
}

export type ColumnOptions = {
  label: string;
  value: string;
  id: string;
}[];

const initialDialogState = {
  status: false,
  type: 'delete',
  text: '',
};

export enum QuizNature {
  'courseQuiz' = 'Course Quiz',
  'lessonQuiz' = 'Lesson Quiz',
}

const deleteButtonStyle = {
  background: '#D32F2F',
  color: '#ffffff',
};

const QuizFormList: React.FC<QuizFormListProp> = (props) => {

  const locale = useLang();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { id, disabled, onSave, onDelete, initialValues, history, type, linkedCourse, linkedLesson, location } = props;
  const { courseId = '', lessonId = '' } = (location.state || {}) as any;
  const [form] = useForm();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('field_mandatory_helper_label') };
  const [loading, setIsLoading] = useState<boolean>(false);
  const [dialog, setDialog] = useState(initialDialogState);

  const onSaveDraft = () => {
    const formData = form.getFieldsValue(true);
    if (lessonId) { formData.lessonId = lessonId }
    if (formData?.passingScore) {
      formData.passingScore = +formData.passingScore
    }
    if (formData?.numQuestions) {
      formData.numQuestions = +formData.numQuestions
    }
    form
      .validateFields()
      .then(async () => {
        await onSave({ ...formData }, courseId, type);
      })
      .catch((err) => {
        console.log(err);
      });

    return false;
  };

  const onCancel = () => {
    history.goBack();
  };


  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
    // TODO: get course detail here
  }, [initialValues]);

  const placeEnter = Translation('app.input.placeholder.please-enter');


  const handleDelete = useCallback(async () => {
    const text = Translation('recruitment.quiz.delete');
    openDialog('delete', text);
  }, []);

  const openDialog = useCallback((dialogType: string, text: string) => {
    const dialogState = {
      status: true,
      type: dialogType,
      text,
    };
    setDialog(dialogState);
  }, []);

  const closeWaringDialog = () => {
    setDialog({ ...dialog, status: false });
  };

  const setData = (key: string, value: any, locale: string) => {
    setIsLoading(true);
    const prevFormValue = form.getFieldValue(key);
    const curFormValue = Object.assign({}, prevFormValue, { [locale]: value });
    form.setFieldsValue({ [`${key}`]: curFormValue });
    setIsLoading(false);
  };

  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });

  const TranslationWithType = (key: string, count: number | string) => intl.formatMessage({ id: key }, { type: count });

  const checkFile = (file: any, maxSize?: number, fileTypes?: string[]) => {
    if (!file) return false;
    if (fileTypes && !fileTypes.some((item) => file?.name?.toLowerCase().endsWith(item))) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: Translation('recruitment.exam.incorrect_file_type'),
          },
        ]),
      );
      return false;
    }

    if (maxSize && file.size > maxSize * 1024 * 1024) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: Translation('file_size_warn_label'),
          },
        ]),
      );
      return false;
    }
    return true;
  };


  const uploadFileMockStatus = async (file: any) => {
    const reader = new FileReader();
    const promise = new Promise<any>((resolve, reject) => {
      reader.onload = (event) => {
        if (event?.target?.result) {
          resolve(event.target.result);
        }
      };
      reader.onerror = (event) => reject(event?.target?.error);
      reader.readAsText(file);
    });
    let materialContent: any;
    try {
      materialContent = parse(await promise);
    } catch (e) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: Translation('recruitment.exam.file.uploadError'),
          },
        ]),
      );
    }
    if (!materialContent) return false;
    const createBlobRes = await createBlob(
      { mimeType: 'text/plain', accessLevel: 'anonymous', module: 'training' },
      dispatch,
    );
    await fileUpload(createBlobRes.url, file);
    const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
    const result = blobDetail[0] as any;
    result.materialContent = JSON.stringify(materialContent);
    if (result) {
      return result;
    } else {
      throw new Error('file upload error');
    }
  };

  const handleFile = useCallback(async (e: React.ChangeEvent<HTMLInputElement>, locale: string) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e?.target?.files?.[0];
      // set max size 10MB
      const maxSize = 10;
      if (!checkFile(file, maxSize, ['gift'])) {
        return;
      } else {
        const res = (await uploadFileMockStatus(file)) as any;
        if (!res) return;
        res.fileName = file.name;
        setData('material', res, locale);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: Translation('global.submit.success'),
              content: ``,
            },
          ]),
        );
      }
    }
  }, []);

  const downloadTemplateGift = () => {
    const text = template;
    const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'template.gift');
  };

  const handleOk = useCallback(async () => {
    switch (dialog.type) {
      case 'delete':
        onDelete();
        break;
      default:
        break;
    }
  }, [dialog]);

  const MemoQuizPageTitle = useMemo(() => {
    let title = ''
    const quizType = !!lessonId ? 'lesson' : 'course';
    const typeText = Translation(`recruitment.${quizType}.text`);
    switch (type) {
      case 'create':
        title = TranslationWithType(`recruitment.quiz.add`, typeText);
        break;
      case 'edit':
        title = TranslationWithType(`recruitment.quiz.edit`, typeText);
        break;
      case 'view':
        title = TranslationWithType(`recruitment.quiz.view`, typeText);
        break;
      default:
        break;
    }
    return title;
  }, [type, lessonId]);

  return (
    <>
      <PruDialog
        dialogTitle={Translation('reminder_title')}
        open={dialog.status}
        canCloseDialog={true}
        onCancel={closeWaringDialog}
        onOk={handleOk}
        confirmBtnText={Translation('app.button.confirm')}
        canncelBtnText={Translation('app.button.cancel')}
      >
        {dialog.text}
      </PruDialog>
      <div className="tw-bg-white tw-rounded-lg tw-h-full tw-relative">
        <Form form={form}>
          <div className={classes.container}>
            <div className={classes.headerContainer}>
              <div className={classes.rowContainer}>
                <div className={commonClasses.header}>
                  {MemoQuizPageTitle}
                </div>
              </div>
              <Button variant="contained" color="inherit" onClick={() => history.goBack()}>
                {Translation('global.back.btnText')}
              </Button>
            </div>
            {regionLocale
              .sort((lng1, _) => (lng1 === 'en' ? 1 : -1))
              .map((locale) => {
                const labelText = locale === 'en' ? `navBar.lang.${locale}` : 'local_language';
                return (
                  <Form.Item
                    name={['quizName', locale]}
                    label={`${Translation('recruitment.quiz.name')}(${Translation(labelText)})`}
                    rules={[DEFAULT_REQUIRED_RULES]}
                  >
                    <TextField
                      disabled={disabled}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      placeholder={placeEnter}
                    />
                  </Form.Item>
                );
              })}
            <Form.Item name="quizCode" label={Translation('recruitment.quiz.code')} rules={[DEFAULT_REQUIRED_RULES]}>
              <TextField
                disabled={type !== 'create'}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder={placeEnter}
              />
            </Form.Item>
            <Form.Item
              name="numQuestions"
              label={Translation('recruitment.quiz.numQuestions')}
              rules={[
                DEFAULT_REQUIRED_RULES,
                {
                  validator: (_, value) => {
                    if (value && value > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(Translation('recruitment.quiz.numQuestionsError'));
                  },
                },
              ]}
            >
              <TextField
                type="number"
                disabled={disabled}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder={placeEnter}
              />
            </Form.Item>
            <Form.Item
              name="passingScore"
              label={Translation('recruitment.quiz.passingScore')}
              rules={[
                DEFAULT_REQUIRED_RULES,
                {
                  validator: (_, value) => {
                    if (value && value < 100 && value > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(Translation('recruitment.quiz.passingScoreError'));
                  },
                },
              ]}
            >
              <TextField
                type="number"
                inputProps={{ max: 99 }}
                disabled={disabled}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder={placeEnter}
              />
            </Form.Item>
            <Form.Item
              name={'linkedCourse'}
              initialValue={linkedCourse?.[locale]}
              label={Translation('recruitment.lesson.linkedCourse')}
              required={false}
            >
              <FormControl
                margin="dense"
                variant="outlined"
              >
                <Select
                  readOnly
                  style={{ minWidth: 350 }}
                  value={linkedCourse?.[locale]}
                  disabled
                >
                  {<MenuItem value={linkedCourse?.[locale]}>{linkedCourse?.[locale]}</MenuItem>}
                </Select>
              </FormControl>
            </Form.Item>
            <Form.Item
              name={'quizNature'}
              label={Translation('recruitment.quiz.quizNature')}
              rules={[DEFAULT_REQUIRED_RULES]}
              shouldUpdate
              initialValue={lessonId ? QuizNature.lessonQuiz : QuizNature.courseQuiz}
            >
              <StyledRadioGroup aria-label="quizNature" name="contentType">
                {Object.entries(QuizNature).map(([label, value]) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Radio disabled />}
                    label={Translation(`recruitment.quiz.status.${label}`)}
                  />
                ))}
              </StyledRadioGroup>
            </Form.Item>
            {linkedLesson?.[locale] && (
              <Form.Item
                name={'linkedLesson'}
                initialValue={linkedLesson?.[locale]}
                label={Translation('recruitment.lesson.lessonLinked')}
              >
                <FormControl margin="dense" variant="outlined">
                  <Select
                    readOnly
                    style={{ minWidth: 350 }}
                    value={linkedLesson?.[locale]}
                    disabled
                  >
                    {<MenuItem value={linkedLesson?.[locale]}>{linkedLesson?.[locale]}</MenuItem>}
                  </Select>
                </FormControl>
              </Form.Item>
            )}
            {regionLocale
              .sort((lng1, _) => (lng1 === 'en' ? 1 : -1))
              .map((locale, index) => {
                const labelText = locale === 'en' ? `navBar.lang.${locale}` : 'local_language';
                return (
                  <Form.Item
                    name={['material', locale]}
                    label={`${Translation('recruitment.quiz.materialUpload')}(${Translation(labelText)})`}
                    rules={[DEFAULT_REQUIRED_RULES]}
                    key={`${index}-${locale}`}
                  >
                    {(_, meta, { getFieldValue }) => {
                      return (
                        <div className="tw-my-6" key={`${index}-${locale}`}>
                          <input
                            id={`${index}-${locale}`}
                            hidden
                            type="file"
                            accept=".gift"
                            onClick={(e) => {
                              const element = e.target as HTMLInputElement;
                              element.value = '';
                            }}
                            onChange={(e) => handleFile(e, locale)}
                          />
                          <div className="tw-flex tw-flex-col tw-items-start tw-ml-5">
                            {getFieldValue('material')?.[locale]?.fileName ? (
                              <div className="tw-flex tw-w-full tw-items-center">
                                <TextField
                                  value={getFieldValue('material')?.[locale]?.fileName}
                                  disabled
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder={placeEnter}
                                />
                                <div className="tw-ml-8">
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    style={!disabled ? deleteButtonStyle : {}}
                                    disabled={disabled}
                                    onClick={() => setData('material', null, locale)}
                                  >
                                    {Translation('app.button.delete')}
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <div className="tw-flex tw-items-center">
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  size="medium"
                                  disabled={disabled}
                                  onClick={() => document.getElementById(`${index}-${locale}`)!.click()}
                                >
                                  {Translation('app.button.upload')}
                                </Button>
                                <div className="tw-flex tw-flex-col">
                                  <span className="tw-text-base tw-ml-2">GIFT format, 10MB maximum</span>
                                  <Button onClick={downloadTemplateGift} color="secondary">
                                    <span className="tw-underline tw-font-bold">
                                      {Translation('download_template_button')}
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Form.Item>
                );
              })}
          </div>
        </Form>
        <div className="tw-bottom-10 tw-right-10 tw-absolute">
          <div className="tw-w-full tw-flex tw-flex-row">
            {type === 'edit' && (
              <div className="tw-ml-6">
                <Button
                  variant="contained"
                  size="large"
                  style={{ background: '#D32F2F', color: '#ffffff' }}
                  onClick={handleDelete}
                >
                  {Translation('app.button.delete')}
                </Button>
              </div>
            )}
            <div className="tw-ml-6">
              <Button variant="contained" size="large" color="secondary" onClick={onCancel}>
                {Translation('app.button.back')}
              </Button>
            </div>
            {type !== 'view' && (
              <div className="tw-ml-6">
                <Button variant="contained" size="large" color="secondary" onClick={onSaveDraft}>
                  {Translation('app.button.save')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizFormList;
