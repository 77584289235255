import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useRequest } from 'ahooks';
import { fetchDialogueList } from '../../../network/talkbot-dialogue-crud';
import { PruTableSortTypeEnum } from 'src/app/common/components/PruTable/PruTable';

interface HookProps {
  // other params
}

export const useList = () => {
  const [sortKey, setSortKey] = useState<string>();

  const dispatch = useDispatch();

  // useRequest to wrap api request.
  // DO NOT use useDataProvider except for <PruFilter />
  const { data, error, loading, run } = useRequest(() => fetchDialogueList({ sortBy: sortKey }, dispatch), {
    manual: true,
  });

  useEffect(() => {
    // manual call api request
    run();
  }, [sortKey]);

  const onSort = useCallback(({ key, value }: { key: string; value?: string }) => {
    setSortKey(value ? (value === PruTableSortTypeEnum.ASC ? `${key}:1` : `${key}:-1`) : undefined);
  }, []);

  return {
    loading,
    data,
    run,
    onSort,
    sortKey,
  };
};
