import React from 'react';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { Translation } from 'src/app/i18n';

interface VersionConflictDescriptionProps {
  conflictedVersions: string[];
}
export const popupConflictReminder = async (conflictedVersions: string[]) => {
  if (conflictedVersions?.length) {
    const result = await ModalService.confirm({
      title: Translation('Common.error'),
      description: <VersionConflictDescription conflictedVersions={conflictedVersions} />,
      hideCancelButton: true,
      onConfirm: () => {
        // do something
      },
    });
  }
};
const VersionConflictDescription: React.FC<VersionConflictDescriptionProps> = ({ conflictedVersions }) => {
  return (
    <div className="tw-text-sm tw-font-normal tw-leading-5 tw-text-center tw-text-black">
      <p>{Translation('Recruitment.selected_validity_period_conflict_template_version')}</p>
      {conflictedVersions?.map?.((version, versionIndex) => (
        <div key={versionIndex} className="tw-flex tw-flex-col tw-flex-1 tw-justify-center tw-items-center">
          <br />
          <p key={versionIndex} className="tw-text-left tw-w-50">
            {versionIndex + 1}. {version}
          </p>
        </div>
      ))}
      <br />
      <p>{Translation('Recruitment.adjust_templates_continue_operation')}</p>
    </div>
  );
};
