import React, { FC } from "react";
import { RouteComponentProps, Switch, Redirect } from "react-router-dom";
import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";
import PruRoute from "src/app/common/components/PruRoute";
import ParamsProvider from "src/app/common/components/ParamsProvider";
import { surveyFilterKeys } from "src/app/modules/Survey/pages/Management/List/SurveyListingPage";
import CustomerSurveyListingPage from "./List/CustomerSurveyListingPage";
import { SALESKIT_BASE_PATH, SURVEY_CUSTOMER_PATH } from "../../constants";
import CustomerSurveyDetailPage from "./Create/CustomerSurveyDetailPage";
import CustomerSurveyAnalyticPage from "./Analytics/CustomerSurveyAnalyticPage";

const CustomerSurveyManagementPage: FC<RouteComponentProps> = ({ history }) => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${SALESKIT_BASE_PATH}${SURVEY_CUSTOMER_PATH}`}
        to={`${SALESKIT_BASE_PATH}${SURVEY_CUSTOMER_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${SURVEY_CUSTOMER_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={surveyFilterKeys}>
            <CustomerSurveyListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${SURVEY_CUSTOMER_PATH}/edit/:id`}
        component={CustomerSurveyDetailPage}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${SURVEY_CUSTOMER_PATH}/create`}
        component={CustomerSurveyDetailPage}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${SURVEY_CUSTOMER_PATH}/:id/results`}
        component={CustomerSurveyAnalyticPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default CustomerSurveyManagementPage;
