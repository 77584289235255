import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { RECRUITMENT_BASE_PATH, RECRUITMENT_LICENSE_VERIFICATION_PATH } from '../../constants';
import { licenseVerificationFilterKeys } from './list/license-verification-listing-page.hook';
import { LicenseVerificationListingPage } from './list/license-verification-listing-page.screen';
import { LicenseVerificationDetailPage } from './license-verification-detail/license-verification-detail.screen';
import { ModulePermissionProps } from 'src/app/common/module/types';

export const licenseVerificationPath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_LICENSE_VERIFICATION_PATH}`;

const LicenseVerificationRoutes: FC<RouteComponentProps & ModulePermissionProps> = (routeProps) => {
  return (
    <Switch>
      <Redirect exact from={licenseVerificationPath} to={`${licenseVerificationPath}/list`} />
      <PruRoute
        exact
        path={`${licenseVerificationPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={licenseVerificationFilterKeys}>
            <LicenseVerificationListingPage {...routeProps} />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${licenseVerificationPath}/view/:id`}
        render={() => <LicenseVerificationDetailPage {...routeProps} />}
      />

      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default LicenseVerificationRoutes;
