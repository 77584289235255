import React, { FC, useState } from 'react';
// import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button, IconButton, Link } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { getDefaultDisplayDate, downloadAs } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { TokenInfo } from 'src/app/modules/Auth/types/auth-types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { trimRoleName } from 'src/app/modules/AgencyCampaign/utils/common-utils';
import { approvalPath } from '../../ApprovalRoutes';
import {
  ApprovalCampaignItem,
  ResourceItem,
  ParticipantIndividualItem,
  AttachmentDetailItem,
  RemarkItem,
  LouTemplateItem,
  AgencyCampaignStatusEnum,
  WorkflowApprovalStatusEnum,
  ContentEnum,
} from 'src/app/modules/AgencyCampaign/types/approval-types';
import {
  generateLouTemplate,
  modifyApproval,
  modifyRemark,
  GenerateLouTemplateBody,
  UpdateApprovalBody,
  UpdateRemarkBody,
} from 'src/app/modules/AgencyCampaign/network/approvalCrud';
import ApprovalDialog from '../../components/ApprovalDialog';
import AttachmentDialog from './AttachmentDialog/AttachmentDialog';
import ProgressBarItem from './ProgressBar/ProgressBarItem';
import { isEmpty, map, get, snakeCase } from 'lodash';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { Translation } from 'src/app/i18n';
import { ConfirmModalWithReasonView } from './modals/confirm-with-reason-view';

type ApprovalFormProps = {
  approvalItem: ApprovalCampaignItem;
  approvalResources: ResourceItem[];
  user?: TokenInfo | undefined;
  userRoleList: string[];
  participantList?: PaginateList<ParticipantIndividualItem>;
  louTemplateList: LouTemplateItem[];
  reloadResources: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onCancel?: (data?: any) => void;
};

type ApprovalDialogState = {
  open: boolean;
  _id: string | undefined;
  campaignId: string | undefined;
  newStatus: WorkflowApprovalStatusEnum | undefined;
};

type AttachmentDialogState = {
  open: boolean;
  action: string;
  remarkIndex: number | undefined;
};

const initialApprovalDialogState: ApprovalDialogState = {
  open: false,
  _id: undefined,
  campaignId: undefined,
  newStatus: undefined,
};

const initialAttachmentDialogState: AttachmentDialogState = {
  open: false,
  action: '',
  remarkIndex: undefined,
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    marginBottom: 25,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  basicInfoContainer: {
    padding: 16,
    backgroundColor: theme.palette.common.white,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  bottomSpacing: {
    marginBottom: 5,
  },
  largerBottomSpacing: {
    marginBottom: 15,
  },
  sectionTitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
  sectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  fieldContainer: {
    width: '50%',
    boxSizing: 'border-box',
    marginBottom: 16,
  },
  participantRowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  participantCellContainer: {
    paddingLeft: 0,
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  olderRecord: {
    color: '#C1C1C1',
  },
  iconButton: {
    padding: 8,
    marginTop: -8,
  },
  dialogPaper: {
    width: 400,
    padding: '16px 8px',
    background: 'white',
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
}));

const getTranslations = () => {
  return {
    are_you_sure_to_cancel_the_campaign: Translation('are_you_sure_to_cancel_the_campaign'),
  };
};

const ApprovalForm: FC<ApprovalFormProps> = ({
  approvalItem,
  approvalResources,
  user,
  userRoleList,
  participantList,
  louTemplateList,
  reloadResources,
  onChangePage,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const onPressCancel = () => {
    ModalService.open({
      title: getTranslations().are_you_sure_to_cancel_the_campaign,
      children: <ConfirmModalWithReasonView onConfirm={onCancel} />,
      classes: { paper: classes.dialogPaper },
      customHeader: (
        <div className={classes.dialogTitle}>
          <span>{getTranslations().are_you_sure_to_cancel_the_campaign}</span>
        </div>
      ),
    });
  };

  const {
    typeId,
    agentLeadSource,
    agentLeadSubSource,
    applicant,
    campaign,
    attachments,
    approvalHistoryList,
    remark,
    submitDate,
    postDocuments,
  } = approvalItem.agencyCampaign;
  const hasRsvp = typeId.sections
    .find((item) => item.key === 'participant')
    ?.sectionFields.find((item) => item.key === 'rsvpResponse');

  const [remarkList, setRemarkList] = useState<RemarkItem[]>(remark ? [...remark] : []);

  const [approvalDialogState, setApprovalDialogState] = useState<ApprovalDialogState>(initialApprovalDialogState);
  const onApprovalCancel = () => {
    setApprovalDialogState(initialApprovalDialogState);
  };
  const onApprovalConfirm = async (comment: string) => {
    if (approvalDialogState._id && approvalDialogState.newStatus) {
      const body: UpdateApprovalBody = {
        assignee: user?.sub || '',
        assigneeName: user?.username || '',
        role: userRoleList,
        agencyCampaignId: approvalDialogState._id,
        status: approvalDialogState.newStatus,
        comment: comment,
      };
      await modifyApproval(body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Campaign Approval status updated successfully`,
          },
        ]),
      );
      onApprovalCancel();
      history.push(approvalPath);
    }
  };

  const [attachmentDialogState, setAttachmentDialogState] =
    useState<AttachmentDialogState>(initialAttachmentDialogState);
  const onAttachmentCancel = () => {
    setAttachmentDialogState(initialAttachmentDialogState);
  };
  const onAttachmentConfirm = (newRemarkItem: RemarkItem) => {
    const newRemarkList = [...remarkList];
    if (attachmentDialogState.remarkIndex !== undefined) {
      newRemarkList[attachmentDialogState.remarkIndex] = newRemarkItem;
    } else {
      newRemarkList.push(newRemarkItem);
    }
    setRemarkList(newRemarkList);
    onUpdateRemark(newRemarkList);
    onAttachmentCancel();
  };
  const onDeleteRemarkItem = (remarkIndex: number) => {
    const newRemarkList = [...remarkList];
    newRemarkList.splice(remarkIndex, 1);
    setRemarkList(newRemarkList);
    onUpdateRemark(newRemarkList);
  };
  const onUpdateRemark = async (newRemarkList: RemarkItem[]) => {
    const body: UpdateRemarkBody = {
      id: approvalItem._id,
      remark: newRemarkList,
    };
    await modifyRemark(body, dispatch);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Campaign remark updated successfully`,
        },
      ]),
    );
    reloadResources();
  };

  const renderSection = (section: Record<string, any>) => (
    <div className={classes.sectionContainer}>
      {Object.keys(section).map((key) => (
        <div key={key} className={classes.fieldContainer}>
          <div className={classes.bottomSpacing}>
            <span className={classes.disabled}>{Translation(`agencyCampaign.field.${key}`)}</span>
          </div>
          {['campaignStartDate', 'campaignEndDate'].includes(key) ? (
            <div style={{ flexGrow: 1 }}>
              {typeof section[key] === 'string' && getDefaultDisplayDate(new Date(section[key]), 'datetime')}
              {section[key] instanceof Date && getDefaultDisplayDate(section[key], 'datetime')}
            </div>
          ) : (
            <div style={{ flexGrow: 1 }}>
              {typeof section[key] === 'string' && section[key]}
              {section[key] instanceof Date && getDefaultDisplayDate(section[key], 'datetime')}
              {Array.isArray(section[key]) && section[key].join(', ')}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const renderAttachment = (attachments: any) => {
    return map(attachments, (item, key) => {
      const arrResourceIds = get(item, 'resourceIds', []);
      if (!arrResourceIds || arrResourceIds.length === 0) {
        return null;
      }
      const campaignName = approvalItem.name;
      const campaignId = approvalItem.campaignId;
      const keySnakeCase = snakeCase(key);
      return (
        <div key={key} className="tw-mt-5 tw-grid tw-grid-cols-12">
          <span className="tw-text-sm tw-col-span-3">{Translation(`agencyCampaign.field.${key}`)}</span>
          <span className=" tw-col-span-9">
            {arrResourceIds.map((resourceId: any, index: number) => {
              const file = approvalResources.find((item) => item.blobId === resourceId);
              if (!file) {
                return null;
              }
              const { originalFilename, blobName, url } = file;
              const fileName = originalFilename || blobName;
              const rename = `${campaignName}_${keySnakeCase}_${campaignId}_${fileName}`.replaceAll(' ', '_');
              return (
                <span key={index} className="tw-ml-4">
                  <Link color="secondary" onClick={() => downloadAs(url, rename)}>
                    {fileName}
                  </Link>
                </span>
              );
            })}
          </span>
        </div>
      );
    });
  };

  const renderPostEventDocuments = (eventDocuments: any) => {
    return map(eventDocuments, (item, key) => {
      const arrResourceIds = get(item, 'resourceIds', []);
      if (!arrResourceIds || arrResourceIds.length === 0) {
        return null;
      }
      const campaignName = approvalItem.name;
      const campaignId = approvalItem.campaignId;
      const keySnakeCase = snakeCase(key);
      return (
        <div key={key} className="tw-mt-5 tw-grid tw-grid-cols-12">
          <span className="tw-text-xl tw-font-bold tw-col-span-3">{Translation(`agencyCampaign.field.${key}`)}</span>
          <span className=" tw-col-span-9">
            {arrResourceIds.map((resource: any, index: number) => {
              if (resource?.submitDate) {
                const file = approvalResources.find((item) => item.blobId === resource.id);
                if (!file) {
                  return null;
                }
                const { originalFilename, blobName, url } = file;
                const fileName = originalFilename || blobName;
                const rename = `${campaignName}_${keySnakeCase}_${campaignId}_${fileName}`.replaceAll(' ', '_');
                return (
                  <span key={index} className="tw-ml-4">
                    <Link color="secondary" onClick={() => downloadAs(url, rename)}>
                      {fileName}
                    </Link>
                  </span>
                );
              }
            })}
          </span>
        </div>
      );
    });
  };
  const getLouTemplateContent = async (templateId: string) => {
    const body: GenerateLouTemplateBody = { id: approvalItem._id, templateId };
    const templateContent = await generateLouTemplate(body, dispatch);
    const newTab = window.open('', '_blank');
    if (newTab) {
      newTab.document.body.innerHTML = templateContent;
    }
  };

  return (
    <>
      {approvalDialogState._id !== undefined &&
        approvalDialogState.campaignId !== undefined &&
        approvalDialogState.newStatus !== undefined && (
          <ApprovalDialog
            open={approvalDialogState.open}
            campaignId={approvalDialogState.campaignId}
            newStatus={approvalDialogState.newStatus}
            onConfirm={onApprovalConfirm}
            onCancel={onApprovalCancel}
          />
        )}

      {(attachmentDialogState.action === 'create' ||
        (attachmentDialogState.action === 'edit' && attachmentDialogState.remarkIndex !== undefined)) && (
        <AttachmentDialog
          open={attachmentDialogState.open}
          action={attachmentDialogState.action}
          remarkList={remarkList}
          remarkIndex={attachmentDialogState.remarkIndex}
          resources={approvalResources}
          louTemplateList={louTemplateList}
          approverName={user?.username || ''}
          onConfirm={onAttachmentConfirm}
          onCancel={onAttachmentCancel}
          getLouTemplateContent={getLouTemplateContent}
        />
      )}

      <div>
        <div className={`${classes.rowContainer} ${classes.basicInfoContainer}`}>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.campaignType')}</div>
            <div>{typeId.name}</div>
          </div>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.campaignId')}</div>
            <div>{approvalItem.campaignId}</div>
          </div>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.status')}</div>
            <div>{Translation(`agencyCampaign.common.status.${approvalItem.status}`)}</div>
          </div>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.startDate')}</div>
            <div>{getDefaultDisplayDate(approvalItem.startDate)}</div>
          </div>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.leadSource')}</div>
            <div>{agentLeadSource.sourceName}</div>
          </div>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.leadSubSource')}</div>
            <div>{agentLeadSubSource.map((subSource) => subSource.sourceName).join(', ')}</div>
          </div>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <div className={`${commonClasses.header} ${classes.sectionTitle}`}>
              {Translation('agencyCampaign.approval.applicationDetails')}
            </div>
          </div>

          <div className={classes.container}>
            <div className={classes.rowContainer}>
              <div className="col-3">
                <span className={classes.sectionTitle}>{Translation('agencyCampaign.approval.applicantInfo')}</span>
              </div>
              <div className="col-9">{renderSection(applicant)}</div>
            </div>

            <div className={classes.rowContainer}>
              <div className="col-3">
                <span className={classes.sectionTitle}>{Translation('agencyCampaign.approval.campaignInfo')}</span>
              </div>
              <div className="col-9">{renderSection(campaign)}</div>
            </div>

            <div className={classes.rowContainer}>
              <div className="col-3">
                <span className={classes.sectionTitle}>{Translation('agencyCampaign.approval.participant')}</span>
              </div>
              <div className="col-9">
                <PruTable
                  disableBulkSelect
                  disableRefresh
                  disableToolbar
                  headerBtnDef={[]}
                  operationDef={[]}
                  columnDef={[
                    {
                      keyIndex: 'designation',
                      displayName: Translation('agencyCampaign.approval.participant.designation'),
                      renderData: (row) => row.unit || '-',
                    },
                    {
                      keyIndex: 'businessName',
                      displayName: Translation('agencyCampaign.approval.participant.businessName'),
                      renderData: (row) => row.name?.enUs?.displayName || '-',
                    },
                    {
                      keyIndex: 'agentCode',
                      displayName: Translation('agencyCampaign.approval.participant.agentCode'),
                      renderData: (row) => row.agentCode || '-',
                    },
                    ...(hasRsvp
                      ? [
                          {
                            keyIndex: 'rsvp',
                            displayName: Translation('agencyCampaign.approval.participant.rsvp'),
                            renderData: (row: ParticipantIndividualItem) => row.rsvpIndicator || '-',
                          },
                        ]
                      : []),
                  ]}
                  isLoading={false}
                  dataSource={participantList?.docs}
                  totalPages={participantList?.totalPages}
                  totalRecords={participantList?.totalDocs}
                  onChangePage={onChangePage}
                />
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className="col-3">
                <span className={classes.sectionTitle}>{Translation('agencyCampaign.approval.attachment')}</span>
              </div>
              <div className="col-9">{attachments && renderAttachment(attachments)}</div>
            </div>
          </div>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <div className={`${commonClasses.header} ${classes.sectionTitle}`}>
              {Translation('agencyCampaign.approval.approvalRecord')}
            </div>
          </div>

          <div className={classes.container}>
            <div style={{ display: 'flex' }}>
              <ProgressBarItem isFinished={true} disableLine={approvalHistoryList.length === 0} />
              <div
                className={`
                  ${classes.largerBottomSpacing}
                  ${approvalHistoryList.length > 0 ? classes.olderRecord : ''}
                `}
              >
                <div>{Translation('agencyCampaign.approval.submit')}</div>
                <div>
                  {Translation('agencyCampaign.approval.submittedBy')}{' '}
                  {`${applicant.applicantName} ${getDefaultDisplayDate(
                    approvalItem.agencyCampaign.submitDate,
                    'datetime',
                  )}`}
                </div>
              </div>
            </div>
            {approvalHistoryList.map((approvalHistory, index) => (
              <div style={{ display: 'flex' }}>
                <ProgressBarItem
                  isFinished={approvalHistory.status === WorkflowApprovalStatusEnum.APPROVED}
                  disableLine={index === approvalHistoryList.length - 1}
                />
                <div
                  className={`
                    ${index !== approvalHistoryList.length - 1 ? classes.largerBottomSpacing : ''}
                    ${
                      index !== approvalHistoryList.length - 1 &&
                      approvalHistory.status === WorkflowApprovalStatusEnum.APPROVED
                        ? classes.olderRecord
                        : ''
                    }
                  `}
                >
                  <div>
                    {approvalHistory.role.map((roleStr) => trimRoleName(roleStr)).join(', ')}{' '}
                    {Translation('agencyCampaign.approval')}
                  </div>
                  <div>
                    {Translation(`agencyCampaign.common.status.${approvalHistory.status}`)}
                    {approvalHistory.status !== WorkflowApprovalStatusEnum.PENDING &&
                      ` by ${approvalHistory.assigneeName} ${getDefaultDisplayDate(
                        approvalHistory.submitDate,
                        'datetime',
                      )}`}
                  </div>
                  {approvalHistory.comment && (
                    <div>
                      {Translation('agencyCampaign.approval.comment')}: {approvalHistory.comment}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <div className={`${commonClasses.header} ${classes.sectionTitle}`}>
              {Translation('agencyCampaign.approval.remark')}
            </div>
          </div>

          <div className={classes.container}>
            {remarkList.map((remarkItem, remarkIndex) => (
              <div className={classes.largerBottomSpacing}>
                <div>
                  <span>{`${remarkItem.updatedBy} ${getDefaultDisplayDate(remarkItem.updatedAt, 'datetime')}`}</span>
                  <IconButton
                    className={classes.iconButton}
                    style={{ marginLeft: 5 }}
                    onClick={() => setAttachmentDialogState({ open: true, action: 'edit', remarkIndex })}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton className={classes.iconButton} onClick={() => onDeleteRemarkItem(remarkIndex)}>
                    <Delete />
                  </IconButton>
                </div>
                {remarkItem.type === ContentEnum.FIXED && remarkItem.resourceIds.length > 0 && (
                  <div>
                    {`${Translation('agencyCampaign.approval.attachment')}: `}
                    {remarkItem.resourceIds.map((resourceId) => {
                      const file = approvalResources.find((item) => item.blobId === resourceId);
                      return file ? (
                        <Link color="secondary" target="_blank" href={file.url} style={{ marginRight: 5 }}>
                          {file.originalFilename}
                        </Link>
                      ) : (
                        <></>
                      );
                    })}
                  </div>
                )}
                {remarkItem.type === ContentEnum.VARIABLE && remarkItem.templateId !== '' && (
                  <div>
                    {`${Translation('agencyCampaign.approval.attachment')}: `}
                    <Link
                      color="secondary"
                      target="_blank"
                      onClick={(event: React.MouseEvent<any>) => {
                        event.preventDefault();
                        getLouTemplateContent(remarkItem.templateId);
                      }}
                    >
                      {Translation(`agencyCampaign.approval.remark.louTemplate.${remarkItem.templateId}`)}
                    </Link>
                  </div>
                )}
              </div>
            ))}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setAttachmentDialogState({ open: true, action: 'create', remarkIndex: undefined })}
            >
              {Translation('agencyCampaign.approval.attachment')}
            </Button>
          </div>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <div className={`${commonClasses.header} ${classes.sectionTitle}`}>
              {Translation('agencyCampaign.approval.postDocuments')}
            </div>
          </div>

          <div className={classes.container}>
            <div>{!isEmpty(postDocuments) && renderPostEventDocuments(postDocuments)}</div>
            {isEmpty(postDocuments) && <div className="col-9"> {'No post event document required'}</div>}
          </div>
        </div>

        <div className={classes.footerContainer}>
          <Button variant="contained" style={{ backgroundColor: 'white', marginLeft: 20 }} onClick={onPressCancel}>
            {Translation('app.button.cancel')}
          </Button>
          {approvalItem.status === AgencyCampaignStatusEnum.PENDING && (
            <>
              <Button
                variant="contained"
                style={{ backgroundColor: 'white', marginLeft: 20 }}
                onClick={() =>
                  setApprovalDialogState({
                    open: true,
                    _id: approvalItem._id,
                    campaignId: approvalItem.campaignId,
                    newStatus: WorkflowApprovalStatusEnum.REJECTED,
                  })
                }
              >
                {Translation('section.common.operation.reject')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: 20 }}
                onClick={() =>
                  setApprovalDialogState({
                    open: true,
                    _id: approvalItem._id,
                    campaignId: approvalItem.campaignId,
                    newStatus: WorkflowApprovalStatusEnum.APPROVED,
                  })
                }
              >
                {Translation('section.common.operation.approve')}
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ApprovalForm;
