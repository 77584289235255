import React, { FC, useCallback } from 'react';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { utcToLocalDate } from '../../../utils';
import { BasePath } from './routes';
import { IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useDispatch } from 'react-redux';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { moveOnboardingCourse } from '../../../network/onboarding-course-crud';
import { useLang } from 'src/app/i18n';
type CourseListProps = {
  isLoading: boolean;
  courseList: any;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
};

const CourseList: FC<CourseListProps> = ({ isLoading, courseList, onRefresh, onChangePage, onSort }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const locale = useLang()

  const onMoveButtonClicked = async (id: string, type: string) => {
    // Call Api and move the category row downward
    try {
      await moveOnboardingCourse(id, type, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `moved ${type} successfully `,
          },
        ]),
      );
      setTimeout(() => {
        onRefresh();
      }, 100);
    } catch (err) { }
  };


  const operationDef = useCallback(() => {
    return [
      {
        title: Translation('section.common.operation.view'),
        tooltipText: `View`,
        onClick: (row: any) => history.push(`${BasePath}/view/${row.id}`, { params: { type: 'view' } }),
        condition: (row: any) => row.status === 'PUBLISHED'
      },
      {
        title: Translation('section.common.operation.edit'),
        tooltipText: `Edit`,
        onClick: (row: any) => history.push(`${BasePath}/edit/${row.id}`, { params: { type: 'edit' } }),
        condition: (row: any) => row.status === 'UNPUBLISHED'
      },
      {
        title: (
          <IconButton size={'small'}>
            <ArrowUpwardIcon />
          </IconButton>
        ),
        tooltipText: 'Move Category Upward',
        onClick: (row: any) => {
          onMoveButtonClicked(row.id as string, 'UP');
        },
      },
      {
        title: (
          <IconButton size={'small'}>
            <ArrowDownwardIcon />
          </IconButton>
        ),
        tooltipText: 'Move Category Downward',
        onClick: (row: any) => {
          onMoveButtonClicked(row.id as string, 'DOWN');
        },
      },
    ]
  }, [])


  return (
    <>
      <PruTable
        disableBulkSelect
        isLoading={isLoading}
        onRefresh={onRefresh}
        onChangePage={onChangePage}
        title={Translation('recruitment.course.list.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('recruitment.course.add'),
            onClick: () => history.push(`${BasePath}/create`, { params: { type: 'add' } }),
          },
        ]}
        operationDef={operationDef()}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: 'id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'name',
            displayName: `${Translation('recruitment.course.name')}`,
            renderData: (row) => `${row?.courseName?.[locale]}` || '-',
          },
          {
            keyIndex: 'type',
            displayName: `${Translation('recruitment.course.type')}`,
            renderData: (row) => `${row.courseType}` || '-',
          },
          {
            keyIndex: 'code',
            displayName: `${Translation('recruitment.course.code')}`,
            renderData: (row) => `${row.courseCode}` || '-',
          },
          {
            keyIndex: 'status',
            displayName: `${Translation('recruitment.common.status')}`,
            renderData: (row) => `${row.status}` || '-',
          },
          {
            keyIndex: 'lastUpdatedAt',
            displayName: `${Translation('recruitment.common.lastUpdatedAt')}`,
            renderData: (row: any) => `${utcToLocalDate(row?.audit?.lastUpdatedAt)}` || '-',
          },
          {
            keyIndex: 'lastUpdatedBy',
            displayName: `${Translation('recruitment.common.lastUpdatedBy')}`,
            renderData: (row: any) => `${row?.audit?.lastUpdatedBy}` || '-',
          },
        ]}
        dataSource={courseList?.data}
        totalPages={courseList?.totalPages}
        totalRecords={courseList?.totalNumbers}
      />
    </>
  );
};

export default CourseList;
