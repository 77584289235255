import React, { FC } from 'react';
import ResourceLibraryCreate from '../../../components/Library/pages/Create';
import { SALESKIT_BASE_PATH, RECRUIT_BASE_PATH, RECRUIT_LIBRARY_BASE_PATH } from '../../../constants';

export const SystemConfig =  {
  blockName: 'component.formTitle.recruit-table',
  blockType:  'resource',
  // baseUrl: RECRUIT_BASE_PATH,
  libraryBasePath: `${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}`,
  categoryType: 'resourceCategory',
  columnType: 'resourceColumn',
  addSuccessText: 'global.submit.success',
  columnModuleType: 'recruitment',
  moduleType: 'Recruit',
}

const RecruitLibraryCreatePage: FC<any> = (props) => {
  return (
    <ResourceLibraryCreate { ...props } { ...SystemConfig } />
  )
};

export default RecruitLibraryCreatePage;
