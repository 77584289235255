import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { INCENTIVE_BASE_PATH, INCENTIVE_KPI_PATH } from '../../constants';
import KpiListingPage, { kpiFilterKeys } from './List/KpiListingPage';
import KpiDetailPage from './Form/KpiDetailPage';

export const kpiPath = `${INCENTIVE_BASE_PATH}${INCENTIVE_KPI_PATH}`;

const KpiRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${kpiPath}`} to={`${kpiPath}/list`} />
      <PruRoute
        exact
        path={`${kpiPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={kpiFilterKeys}>
            <KpiListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${kpiPath}/edit/:id`} component={KpiDetailPage} />
      <PruRoute exact path={`${kpiPath}/create`} component={KpiDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default KpiRoutes;
