import { makeStyles } from 'tss-react/mui';
import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';
import { Translation } from 'src/app/i18n';
import { PruModalProps } from '../modal-context';

export interface ConfirmModalProps extends Omit<PruModalProps, 'onClose' | 'open' | 'isTransparent'> {
  onClose?: Function;
  description?: string | React.ReactNode;
  onConfirm: Function;
  confirmText?: string;
  cancelText?: string;
  hideCancelButton?: boolean;
}

export const ConfirmModalView = (props: ConfirmModalProps) => {
  const { onClose, onConfirm, description, confirmText, cancelText, hideCancelButton } = props;
  return (
    <div className="tw-rounded-lg">
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions
        style={{
          margin: 10,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {hideCancelButton ? null : (
          <Button
            onClick={() => onClose?.({ type: 'reject' }, 'userCancelled' as any)}
            variant="outlined"
            color="inherit"
          >
            {cancelText ?? Translation('app.button.cancel')}
          </Button>
        )}
        <Button
          onClick={() => {
            const promiseInstance = onConfirm?.();
            onClose?.({ type: 'resolve' }, promiseInstance);
          }}
          variant="contained"
          color="secondary"
          autoFocus
        >
          {confirmText ?? Translation('app.button.confirm')}
        </Button>
      </DialogActions>
    </div>
  );
};
