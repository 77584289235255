import { makeStyles } from 'tss-react/mui';

const LABEL_CONTAINER_WIDTH = 180;
export const styles = {
  dialogTitle: {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: 15,
  },
  dialogSubTitle: {
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: 5,
  },
  dialogContent: {
    marginBottom: 15,
    fontWeight: '400',
  },
};
export const useStyles = makeStyles()(() => ({
  dialogContainer: {
    width: '100%',
    padding: '20px',
  },
  btnContainer: {
    padding: '30px 20px 20px 0',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
  },
  errorContainer: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    // backgroundColor: theme.palette.common.white,
  },
  errorHeader: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  errorFooter: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  segmentContainer: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  dialogPaper: {
    width: 700,
    maxWidth: 700,
  },
  reminderDialogPaper: {
    width: 450,
    maxWidth: 450,
  },
  container: {
    padding: '30px 50px',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpacing: {
    marginBottom: 20,
  },
  labelContainer: {
    width: LABEL_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  label: {
    fontSize: '1rem',
  },
  fieldContainer: {
    flexGrow: 1,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  radioGroup: {
    flexDirection: 'row',
  },
  noMargin: {
    margin: 0,
  },
  formControlLabel: {
    marginBottom: 0,
  },
  mandatory: {
    color: 'red',
  },
  blueText: {
    color: '#1976D2',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: 20,
  },
}));
