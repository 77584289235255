import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import {
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_ONBOARDING_TRAINING,
  RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION,
} from '../../../constants';
import { trainingFeeVerificationFilterKeys } from './list/training-fee-verification-listing-page.hook';
import { TrainingFeeVerificationListingPage } from './list/training-fee-verification-listing-page.screen';
import { TrainingFeeDetailPage } from './training-fee-detail/training-fee-detail.screen';
import { ModulePermissionProps } from 'src/app/common/module/types';

export const trainingFeeVerificationPath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_TRAINING}${RECRUITMENT_ONBOARDING_TRAINING_FEE_VERIFICATION}`;

const TrainingFeeVerificationRoutes: FC<RouteComponentProps & ModulePermissionProps> = (routeProps) => {
  return (
    <Switch>
      <Redirect exact from={trainingFeeVerificationPath} to={`${trainingFeeVerificationPath}/list`} />
      <PruRoute
        exact
        path={`${trainingFeeVerificationPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={trainingFeeVerificationFilterKeys}>
            <TrainingFeeVerificationListingPage {...routeProps} />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${trainingFeeVerificationPath}/view/:id`}
        render={() => <TrainingFeeDetailPage {...routeProps} />}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default TrainingFeeVerificationRoutes;
