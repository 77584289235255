import React, { memo } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { Dialog } from 'src/app/modules/Recruitment/components/dialog/dialog.component';
import { AssigneeSelector } from '../assignee-selector/assignee-selector.component';
import { ReturnCategories } from 'src/app/modules/Recruitment/types/approval-types';
import { SelectAssigneeDialogProps, useSelectAssigneeDialog } from './select-assignee-dialog.hook';
import { SELECT_ASSIGNEE_MODAL_I18N } from '../../../util/constant';

export const SelectAssigneeDialog: React.FC<SelectAssigneeDialogProps> = memo((props: SelectAssigneeDialogProps) => {
  const Translation = useTranslation();

  const { onClose, open, showApplicant = true, showAgentLeader = true } = props;

  const {
    applicantOptions,
    recruiterOptions,
    agentLeaderOptions,
    confirmLoading,
    onConfirm,
    onChange,
    isMultipleSelect,
    selected,
    disabledConfirmBtn,
  } = useSelectAssigneeDialog(props);

  return (
    <Dialog
      open={open}
      title={Translation('onboarding.application.approval.detail.dialog.select.assignee.title')}
      disabledConfirmBtn={disabledConfirmBtn}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={confirmLoading}
    >
      <Box width={'528px'}>
        <Grid container spacing={1}>
          {showApplicant ? (
            <AssigneeSelector
              label={Translation(SELECT_ASSIGNEE_MODAL_I18N[ReturnCategories.applicant])}
              value={selected?.[ReturnCategories.applicant]}
              onChange={(val) => onChange(ReturnCategories.applicant, val)}
              options={applicantOptions}
              isMultipleSelect={isMultipleSelect}
            />
          ) : null}
          <AssigneeSelector
            label={Translation(SELECT_ASSIGNEE_MODAL_I18N[ReturnCategories.recruiter])}
            value={selected?.[ReturnCategories.recruiter]}
            onChange={(val) => onChange(ReturnCategories.recruiter, val)}
            options={recruiterOptions}
            isMultipleSelect={isMultipleSelect}
          />
          {showAgentLeader ? (
            <AssigneeSelector
              label={Translation(SELECT_ASSIGNEE_MODAL_I18N[ReturnCategories.agentLeader])}
              value={selected?.[ReturnCategories.agentLeader]}
              onChange={(val) => onChange(ReturnCategories.agentLeader, val)}
              options={agentLeaderOptions}
              isMultipleSelect={isMultipleSelect}
            />
          ) : null}
        </Grid>
      </Box>
    </Dialog>
  );
});
