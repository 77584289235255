import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import {
  LicenseVerificationItem,
  LicenseVerificationListParam,
  PaginateList,
  statusType,
} from '../../../../types/license-verification-types';
import { dateFormatToLocal, handleDowloadFileError, utcToLocalDate } from 'src/app/modules/Recruitment/utils';
import { licenseVerificationPath } from '../../license-verification-routes';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LicenseVerificationStatusEnum } from 'src/app/modules/Recruitment/enum/license-exam.enum';
import { downloadVerification } from 'src/app/modules/Recruitment/network/license-verification-crud';
import moment from 'moment';

type LicenseVerificationListProps = {
  isLoading: boolean;
  licenseVerificationList?: PaginateList<LicenseVerificationItem>;
  formState: LicenseVerificationListParam;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
};

export const LicenseVerificationList: FC<LicenseVerificationListProps> = ({
  isLoading,
  licenseVerificationList,
  formState,
  onRefresh,
  onChangePage,
  onSort,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();

  const downloadLicenseVerification = useCallback(async () => {
    try {
      await downloadVerification({
        ...formState,
        examDateFrom: formState.examDateFrom ? moment(formState.examDateFrom).format('YYYY-MM-DD') : undefined,
        examDateTo: formState.examDateTo ? moment(formState.examDateTo).format('YYYY-MM-DD') : undefined,
        submissionDateFrom: formState.submissionDateFrom
          ? moment(formState.submissionDateFrom).format('YYYY-MM-DD')
          : undefined,
        submissionDateTo: formState.submissionDateTo
          ? moment(formState.submissionDateTo).format('YYYY-MM-DD')
          : undefined,
      });
    } catch (err) {
      handleDowloadFileError(err, dispatch);
    }
  }, [formState, dispatch]);

  return (
    <PruTable
      title={Translation('recruitment.license.verification.list')}
      disableBulkSelect
      operationSticky
      emptyColSpan={13}
      headerBtnDef={[
        {
          color: 'secondary',
          title: Translation('app.button.download'),
          onClick: downloadLicenseVerification,
        },
      ]}
      operationDef={[
        {
          title: Translation('section.common.operation.view'),
          tooltipText: Translation('section.common.operation.view'),
          onClick: (row) => history.push(`${licenseVerificationPath}/view/${row.verificationId}`),
        },
      ]}
      columnDef={[
        {
          keyIndex: 'verificationId',
          displayName: Translation('recruitment.license.verification.id'),
          renderData: (row) => row.verificationId || '-',
        },
        {
          keyIndex: 'applicantId',
          displayName: Translation('recruitment.exam.registration.applicantId'),
          renderData: (row) => row.applicantId || '-',
        },
        {
          keyIndex: 'firstName',
          displayName: Translation('recruitment.exam.registration.firstName'),
          renderData: (row) => row.firstName || '-',
        },
        {
          keyIndex: 'lastName',
          displayName: Translation('recruitment.exam.registration.lastName'),
          renderData: (row) => row.lastName || '-',
        },
        {
          keyIndex: 'examDate',
          displayName: Translation('recruitment.exam.examDate'),
          renderData: (row) => dateFormatToLocal(row.examDate as string) || '-',
        },
        {
          keyIndex: 'proofOfPassedExam',
          displayName: Translation('recruitment.license.proofOfPassedExam'),
          renderData: (row) =>
            row.hasLicenseProof
              ? Translation('recruitment.license.hasProof.true')
              : Translation('recruitment.license.hasProof.false'),
        },
        {
          keyIndex: 'examType',
          displayName: Translation('recruitment.exam.examType'),
          renderData: (row) => row.examType || '-',
        },
        {
          keyIndex: 'examScore',
          displayName: Translation('recruitment.exam.score'),
          renderData: (row) => row.examScore || '-',
        },
        {
          keyIndex: 'status',
          displayName: Translation('recruitment.license.verification.status'),
          renderData: (row) =>
            row.licenseApprovalStates
              ? Translation(
                  `recruitment.license.verification.status.${
                    LicenseVerificationStatusEnum[row.licenseApprovalStates as statusType]
                  }`,
                )
              : '-',
        },
        {
          keyIndex: 'submissionTime',
          displayName: Translation('recruitment.exam.registration.submissionTime'),
          renderData: (row) => utcToLocalDate(row.submissionTime) || '-',
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'SUBMITTED_DATE', value: sort['submissionTime']?.toLocaleUpperCase() });
          },
        },
        {
          keyIndex: 'lastUpdatedBy',
          displayName: Translation('recruitment.common.lastUpdatedBy'),
          renderData: (row) => row.lastUpdatedBy || '-',
        },
        {
          keyIndex: 'lastUpdatedTime',
          displayName: Translation('recruitment.common.lastUpdatedAt'),
          renderData: (row) => utcToLocalDate(row.lastUpdatedTime) || '-',
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'LAST_UPDATED_AT', value: sort['lastUpdatedTime']?.toLocaleUpperCase() });
          },
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={licenseVerificationList?.data}
      totalPages={licenseVerificationList?.totalPages}
      totalRecords={licenseVerificationList?.totalNumbers}
      onChangePage={onChangePage}
      defaultRowsPerPage={20}
    />
  );
};
