import { useCallback, useState, useEffect, useRef, RefObject } from 'react';
import { useIntl } from 'react-intl';
import { useRequest } from 'ahooks';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';

import { RecruitmentPdfTemplate, RecruitmentTemplate } from './template-detail-page.type';
import { useHistory, useParams } from 'react-router-dom';
import { formTemplatePath } from '../onboarding-application/form-template/form-template-routes';

import {
  fetchFormTemplateList,
  getTemplateById,
  getTemplateVersion,
  saveFormTemplate,
} from '../../network/form-template-crud';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { getNextDay } from '../../utils';
import { get, isEmpty } from 'lodash';
import { onboardingApplicationBasePath } from '../onboarding-application/onboarding-application-routes';
import { FORM_TEMPLATE_PATH } from '../../constants';
import { fetchApplicationFormSetting } from '../../network/application-form-crud';
import moment from 'moment';
import { RecruitmentTemplatePublishStatusEnum } from './template-detail-page.enum';
import { is } from 'date-fns/locale';

// const defaultFormType = 'CONVENTIONAL';

export function useTemplateDetailPage(refNext: RefObject<HTMLButtonElement | null>) {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation();
  // url param
  const { templateId } = useParams<{ templateId: string; isDuplicate: string }>();
  // url query
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const queryAction = queryParams.action?.toString().toLowerCase();
  const isView = queryAction === 'view';
  const isCreate = !templateId || queryAction === 'create';
  const isDuplicate = queryAction === 'duplicate';
  const queryFormType = queryParams.type?.toString();
  const queryStepIndex = queryParams.stepIndex ? parseInt(queryParams.stepIndex?.toString()) : 0;
  const onboardingTemplate = useSelector((state: any) => get(state.config.data, 'Recruitment.onboardingTemplate', []));
  const formTypeOptions = onboardingTemplate.map((item: any) => item.type);
  const minDate = getNextDay();

  const initData: RecruitmentTemplate = {
    queryAction,
    type: queryFormType || formTypeOptions[0],
    validDateFrom: minDate,
  };

  const [stepIndex, setStepIndex] = useState(queryStepIndex);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [validate, setValidate] = useState(true); // from validation status
  const [version, setVersion] = useState<undefined | string>();
  const [isValidFrom, setIsValidFrom] = useState(true); // valid-from-date is correct or not

  // const refTemplateId = useRef<string>(isDuplicate ? '' : templateId);
  const refTemplate = useRef(initData);
  const setTemplate = useCallback((value: any) => {
    // console.log('setTemplate value=====:', value);
    // setTemplateData({ ...templateData, ...value });
    refTemplate.current = { ...refTemplate.current, ...value };
  }, []);
  // get version id
  const getVersion = useCallback(
    async (formType?: string) => {
      try {
        const response = await getTemplateVersion(formType || refTemplate.current.type);
        const { version: versionNum, earliestValidFrom, earliestValidTo } = response;
        setVersion(versionNum);
        if (earliestValidFrom) {
          refTemplate.current.validDateFrom = moment(earliestValidFrom, 'YYYY-MM-DD').utc().toDate();
        }
        if (earliestValidTo) {
          refTemplate.current.validDateTo = moment(earliestValidTo, 'YYYY-MM-DD').utc().toDate();
        }
        return response;
      } catch (err) {
        setVersion('');
        throw err;
      }
    },
    [refTemplate],
  );

  // get template by id
  const { loading: loadingGet, runAsync: getTemplate } = useRequest(
    () => {
      return getTemplateById(templateId);
    },
    { manual: true },
  );

  // save template
  const { loading: loadingSave, runAsync: saveTemplateAsync } = useRequest(
    (saveAsDraft = false, isNext = false) => {
      // return new Promise((res) => res(true));
      const body = {
        ...refTemplate.current,
        version: version, // put it here to override refTemplate.current.version
        // saving without templateId will create a new form
        templateId: isDuplicate ? null : templateId,
        saveAsDraft: isDuplicate ? false : saveAsDraft,
      };
      if (!isEmpty(body.pdfTemplates)) {
        body.pdfTemplates = body.pdfTemplates?.filter((pdf) => pdf.blobId); // remove user deleted-item(no blobId)
      }

      return saveFormTemplate(body, dispatch, t).then((res) => {
        if (!templateId || isDuplicate) {
          if (!res.templateId) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: t('Unable to Save'),
                  content: '',
                },
              ]),
            );
            return;
          }
          // const pathName = location.pathname.replace(/\/$/, '');
          const action = isCreate ? 'create' : 'edit';
          const targetUrl = `${formTemplatePath}/detail/${res.templateId}?action=${action}&step=${isNext ? 1 : 0}`;
          history.replace(targetUrl);
        }
      });
    },
    { manual: true },
  );

  useEffect(() => {
    if (templateId) {
      // edit or duplicate
      getTemplate().then((templateDetail) => {
        if (isDuplicate) {
          delete templateDetail.templateId;
          delete templateDetail.validDateTo;
          delete templateDetail.validDateFrom;
          templateDetail.publishStatus = RecruitmentTemplatePublishStatusEnum.DRAFT;
        }
        setTemplate({ ...templateDetail });
        if (!isDuplicate) {
          // view/edit: display current version
          setVersion(templateDetail.version || '');
        }
        if (queryAction === 'edit' && queryParams.step?.toString() === '1') {
          setStepIndex(1);
        }
      });
    }
  }, [templateId]);

  const handleTypeChange = useCallback(
    async (formType: string) => {
      const res = await getVersion(formType);
      return res;
      // if (!templateId) {
      //   // get version to see if it's able to create form
      //   // getVersion(formType);
      // }
    },
    [templateId],
  );

  const handleSaveDraft = useCallback(() => {
    const saveAsDraft = true;
    saveTemplateAsync(saveAsDraft);
  }, []);

  const handleNext = useCallback(() => {
    if (isView) {
      return setStepIndex((next) => next + 1);
    }
    // if step is not equal to 2 and form publish-status is not published, the case is save draft
    if (stepIndex !== 2 && refTemplate.current.publishStatus !== RecruitmentTemplatePublishStatusEnum.PUBLISHED) {
      // save draft
      const saveAsDraft = true;
      const isNext = true;
      saveTemplateAsync(saveAsDraft, isNext).then(() => {
        return setStepIndex((next) => next + 1);
      });

      // return setStepIndex((next) => next + 1);
    } else {
      // last step: validate PDF and submit
      const saveAsDraft = false;
      saveTemplateAsync(saveAsDraft)
        .then(() => {
          const oriPdfTemplates = refTemplate.current.pdfTemplates || [];
          // set pdf-list-item is valid when submit done
          const newPdfTemplates = oriPdfTemplates.map((item) => {
            item.isValid = true;
            item.errorMsg = '';
            return item;
          });
          setTemplate({ pdfTemplates: newPdfTemplates });
        })
        .catch((submitErr) => {
          if (submitErr.pdfTemplates) {
            // pdf validation error
            setTemplate({ pdfTemplates: submitErr.pdfTemplates });
          }
        });
    }
    // handle save
  }, [stepIndex, isView, handleSaveDraft]);

  const handlePrevious = useCallback(() => {
    if (stepIndex > 0) {
      return setStepIndex((next) => next - 1);
    }
    // handle save
  }, [stepIndex]);

  const showCancelDialog = useCallback(() => {
    if (isView) {
      return goBack();
    }
    setCancelDialog(true);
  }, [version]);

  const hideCancelDialog = useCallback(() => {
    setCancelDialog(false);
  }, []);

  // confirm to goback on modal
  const goBack = useCallback(() => {
    setCancelDialog(false);
    const routeList = `${onboardingApplicationBasePath}${FORM_TEMPLATE_PATH}/list`;
    history.push(routeList);
  }, []);

  return {
    // version,
    // version: value from get-version or get-template-detail
    version: version,
    loading: loadingGet, // loading template detail
    loadingSave,
    stepIndex,
    isView,
    isValidFrom,
    setStepIndex,
    validate,
    setValidate,
    cancelDialog,
    showCancelDialog,
    hideCancelDialog,
    goBack,
    template: refTemplate.current,
    setTemplate,
    handleSaveDraft,
    handleNext,
    handlePrevious,
    handleTypeChange,
    setIsValidFrom,
  };
}
