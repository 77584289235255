import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import {
  RegistrationItem,
  RegistrationListParam,
  PaginateList,
  resultType,
} from 'src/app/modules/Recruitment/types/license-exam-types';
import { dateFormatToLocal, handleDowloadFileError, utcToLocalDate } from 'src/app/modules/Recruitment/utils';
import { useLang } from 'src/app/i18n';
import { exportRegistration } from 'src/app/modules/Recruitment/network/license-exam-crud';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { examRegistrationBasePath } from '../registration-routes';
import { getStatusLabel } from '../../util/registration.util';
import { ResultTypeEnum } from 'src/app/modules/Recruitment/enum/license-exam.enum';
import moment from 'moment';

type RegistrationListProps = {
  isLoading: boolean;
  registrationList?: PaginateList<RegistrationItem>;
  formState: RegistrationListParam;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
  enableUpdate: boolean;
  enableApproval: boolean;
};

export const RegistrationList: FC<RegistrationListProps> = ({
  isLoading,
  registrationList,
  onRefresh,
  onChangePage,
  onSort,
  formState,
  enableUpdate,
  enableApproval,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();

  const downloadRegistration = useCallback(async () => {
    try {
      await exportRegistration(
        {
          ...formState,
          examDateFrom: formState.examDateFrom ? moment(formState.examDateFrom).format('YYYY-MM-DD') : undefined,
          examDateTo: formState.examDateTo ? moment(formState.examDateTo).format('YYYY-MM-DD') : undefined,
          examName: formState.examName as string,
          lang: locale ?? 'en',
        },
        dispatch,
      );
    } catch (err) {
      handleDowloadFileError(err, dispatch);
    }
  }, [formState, dispatch]);

  return (
    <PruTable
      title={Translation('recruitment.exam.registration.list')}
      disableBulkSelect
      emptyColSpan={19}
      operationSticky={true}
      headerBtnDef={[
        {
          color: 'primary',
          title: Translation('recruitment.exam.registration.uploadResult'),
          onClick: () => history.push(`${examRegistrationBasePath}/upload`),
          condition: () => enableUpdate,
        },
        {
          color: 'primary',
          title: Translation('app.button.download'),
          onClick: downloadRegistration,
        },
      ]}
      operationDef={[
        {
          title: Translation('section.common.operation.view'),
          tooltipText: 'View',
          onClick: (row) => history.push(`${examRegistrationBasePath}/view/${row.registrationId}`),
        },
      ]}
      columnDef={[
        {
          keyIndex: 'registrationId',
          displayName: Translation('recruitment.exam.registration.id'),
          renderData: (row) => row.registrationId || '-',
        },
        {
          keyIndex: 'applicantId',
          displayName: Translation('recruitment.exam.registration.applicantId'),
          renderData: (row) => row.applicantId || '-',
        },
        {
          keyIndex: 'firstName',
          displayName: Translation('recruitment.exam.registration.firstName'),
          renderData: (row) => row.firstname || '-',
        },
        {
          keyIndex: 'lastName',
          displayName: Translation('recruitment.exam.registration.lastName'),
          renderData: (row) => row.lastname || '-',
        },
        {
          keyIndex: 'examCode',
          displayName: Translation('recruitment.exam.examCode'),
          renderData: (row) => row.examCode || '-',
        },
        {
          keyIndex: 'examType',
          displayName: Translation('recruitment.exam.examType'),
          renderData: (row) => row.examType || '-',
        },
        {
          keyIndex: 'examName',
          displayName: Translation('recruitment.exam.examName'),
          renderData: (row) => row.examName?.[locale] || row.examName?.['en'] || '-',
        },
        {
          keyIndex: 'examDate',
          displayName: Translation('recruitment.exam.examDate'),
          renderData: (row) => dateFormatToLocal(row.examDate as string) || '-',
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'EXAM_DATE', value: sort['examDate']?.toLocaleUpperCase() });
          },
        },
        {
          keyIndex: 'examDateStartTime',
          displayName: Translation('recruitment.exam.examDateStartTime'),
          renderData: (row) => row.startTime || '-',
        },
        {
          keyIndex: 'examDateEndTime',
          displayName: Translation('recruitment.exam.examDateEndTime'),
          renderData: (row) => row.endTime || '-',
        },
        {
          keyIndex: 'examCentre',
          displayName: Translation('recruitment.exam.examCentre'),
          renderData: (row) => row.examCentre || '-',
        },
        {
          keyIndex: 'status',
          displayName: Translation('recruitment.exam.registration.status'),
          renderData: (row) => getStatusLabel(row.registrationApprovalStates, enableApproval),
        },
        {
          keyIndex: 'examFeeReceiptNo',
          displayName: Translation('recruitment.exam.fee.or'),
          renderData: (row) => row.examFeeReceiptNo || '-',
        },
        {
          keyIndex: 'licenseFeeReceiptNo',
          displayName: Translation('recruitment.license.fee.or'),
          renderData: (row) => row.licenseFeeReceiptNo || '-',
        },
        {
          keyIndex: 'result',
          displayName: Translation('recruitment.exam.registration.examResult'),
          renderData: (row) =>
            row.result
              ? Translation(`recruitment.exam.registration.result.${ResultTypeEnum[row.result as resultType]}`)
              : '-',
        },
        {
          keyIndex: 'submissionTime',
          displayName: Translation('recruitment.exam.registration.submissionTime'),
          renderData: (row) => utcToLocalDate(row.submissionTime) || '-',
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'SUBMISSION_TIME', value: sort['submissionTime']?.toLocaleUpperCase() });
          },
        },
        {
          keyIndex: 'lastUpdatedBy',
          displayName: Translation('recruitment.common.lastUpdatedBy'),
          renderData: (row) => row.lastUpdatedBy || '-',
        },
        {
          keyIndex: 'lastUpdatedAt',
          displayName: Translation('recruitment.common.lastUpdatedAt'),
          renderData: (row) => utcToLocalDate(row.lastUpdatedAt) || '-',
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'LAST_UPDATED_AT', value: sort['lastUpdatedAt']?.toLocaleUpperCase() });
          },
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={registrationList?.data}
      totalPages={registrationList?.totalPages}
      totalRecords={registrationList?.totalNumbers}
      onChangePage={onChangePage}
      defaultRowsPerPage={20}
    />
  );
};
