import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { regionLocale } from 'src/app/i18n';
import { PaginateList } from 'src/app/common/types';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { incentiveSetPath } from '../../IncentiveSetRoutes';
import { PublishStatusEnum, IncentiveListItem } from '../../../../types/incentive-types';
import { publishIncentive, unpublishIncentive } from '../../../../network/incentiveCrud';

type IncentiveSetListProps = {
  isLoading: boolean;
  incentiveSetList?: PaginateList<IncentiveListItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const IncentiveSetList: FC<IncentiveSetListProps> = ({ isLoading, incentiveSetList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const onPublish = async (code: string) => {
    try {
      await publishIncentive(code, undefined, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Published successfully - ${code}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onUnpublish = async (code: string) => {
    try {
      await unpublishIncentive(code, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unpublished successfully - ${code}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <PruTable
        title={Translation('incentive.set.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('incentive.set.new_incentive_set'),
            onClick: () => history.push(`${incentiveSetPath}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Incentive',
            onClick: (row) => history.push(`${incentiveSetPath}/edit/${row.code}`),
            condition: (row) => row.incentiveStatus === PublishStatusEnum.DRAFT,
          },
          {
            title: Translation('section.common.operation.view'),
            tooltipText: 'View Incentive',
            onClick: (row) => history.push(`${incentiveSetPath}/edit/${row.code}`),
            condition: (row) => row.incentiveStatus !== PublishStatusEnum.DRAFT,
          },
          {
            title: Translation('section.common.operation.publish'),
            tooltipText: 'Publish Incentive',
            onClick: (row) => onPublish(row.code),
            condition: (row) => row.incentiveStatus !== PublishStatusEnum.PUBLISHED,
          },
          {
            title: Translation('section.common.operation.unpublish'),
            tooltipText: 'Unpublish Incentive',
            onClick: (row) => onUnpublish(row.code),
            condition: (row) => row.incentiveStatus === PublishStatusEnum.PUBLISHED,
          },
        ]}
        columnDef={[
          {
            isId: true,
            keyIndex: 'code',
            displayName: Translation('incentive.set.common.code'),
            renderData: (row) => row.code,
          },
          ...(() =>
            regionLocale.map((locale) => ({
              keyIndex: `name-${locale}`,
              displayName: Translation(`incentive.set.common.name.${locale}`),
              renderData: (row: IncentiveListItem) => row.name[locale] || '-',
            })))(),
          {
            keyIndex: 'startDate',
            align: 'center',
            displayName: Translation('incentive.common.startDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.startDate)),
          },
          {
            keyIndex: 'endDate',
            align: 'center',
            displayName: Translation('incentive.common.endDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.endDate)),
          },
          {
            keyIndex: 'incentiveStatus',
            align: 'center',
            displayName: Translation('incentive.set.common.status'),
            renderData: (row) => (row.incentiveStatus || '-').toUpperCase(),
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('incentive.common.createdDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.createdAt)),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={incentiveSetList?.docs}
        totalPages={incentiveSetList?.totalPages}
        totalRecords={incentiveSetList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default IncentiveSetList;
