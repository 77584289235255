import { Dispatch } from 'react';
import { I18nGenericData } from 'src/app/i18n';
import { getQueryPath } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import {
  EventAccessibilityEnum,
  EventAudienceEnum,
  EventCategoryStatusEnum,
  EventCategoryItem,
  EventHostEnum,
  EventCategoryMaterials,
  EventCategoryMaterialsParams,
} from '../types';
import { eventCategoryEndpoint } from './constants';

export type EventCategoryListParam = {
  containHosts?: string;
  accessibility?: string;
  audience?: string;
  status?: string;
  pagination?: boolean;
  page?: number;
  limit?: number;
  sort?: string;
};

export type CreateUpdateEventCategoryBody = {
  name: I18nGenericData<string>;
  description?: string;
  accessibility: EventAccessibilityEnum;
  audience: EventAudienceEnum;
  status: EventCategoryStatusEnum;
  host?: EventHostEnum[];
  approvalRequired?: boolean;
};

export const fetchEventCategoryList = async (
  params: EventCategoryListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<EventCategoryItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${eventCategoryEndpoint}?`, {
    containHosts: EventHostEnum.COMPANY,
    ...params,
  });
  return apiClient
    .get<PaginateList<EventCategoryItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createEventCategoryItem = async (
  body: CreateUpdateEventCategoryBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${eventCategoryEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyEventCategoryItem = async (
  id: string,
  body: CreateUpdateEventCategoryBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${eventCategoryEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEventCategoryMaterialsList = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<EventCategoryMaterials> => {
  return apiClient
    .get<EventCategoryMaterials>(`${ADMIN_URL}/${eventCategoryEndpoint}/${id}/materials`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateEventCategoryMaterials = async (
  id: string,
  body: { materials: EventCategoryMaterialsParams[] },
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${eventCategoryEndpoint}/${id}/materials`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
