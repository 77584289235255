import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Translation, TranslationWithParams } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import {
  fetchTrainingFeeDetail,
  updateTrainingFeeVerificationStatus,
} from 'src/app/modules/Recruitment/network/training-fee-verification-crud';
import {
  TrainingFeeVerificationEventEnum,
  TrainingFeeVerificationStatusEnum,
} from 'src/app/modules/Recruitment/enum/license-exam.enum';
import { useHistory } from 'react-router-dom';

interface HookProps {
  id: string;
  enableUpdate: boolean;
}

export const useTrainingFeeDetail = ({ id, enableUpdate }: HookProps) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState<{ open: boolean; header: string }>({ open: false, header: '' });
  const [action, setAction] = useState<TrainingFeeVerificationEventEnum>(TrainingFeeVerificationEventEnum.approve);
  const [remark, setRemark] = useState<string>('');

  const { data, run } = useRequest(() => fetchTrainingFeeDetail(id, dispatch), {
    manual: true,
  });

  const { run: runUpdateVerificationStatus, loading: loadingUpdate } = useRequest(
    () =>
      updateTrainingFeeVerificationStatus(
        {
          approvalEvents: action,
          remark: remark,
        },
        id,
        dispatch,
      )
        .then(() => {
          handleCloseDialog();
          history.goBack();
        })
        .catch((error) => {
          console.log({ error });
          handleCloseDialog();
        }),
    {
      manual: true,
    },
  );

  useEffect(() => {
    // manual call api request
    run();
  }, []);

  const showApprovalButtons =
    data?.paymentStatus === TrainingFeeVerificationStatusEnum.PENDING_APPROVAL.toUpperCase() && enableUpdate;

  const handleCloseDialog = useCallback(() => {
    setDialogState({ open: false, header: '' });
    setRemark('');
  }, []);

  const handlePress = useCallback((action: TrainingFeeVerificationEventEnum) => {
    setDialogState({
      open: true,
      header: TranslationWithParams('onboarding.training.fee.verification.dialogTitle', {
        action: Translation(`onboarding.training.fee.verification.${action.toLowerCase()}`).toLowerCase(),
      }),
    });
    setAction(action);
  }, []);

  const handleConfirm = useCallback(async () => {
    runUpdateVerificationStatus();
  }, []);

  return {
    data,
    dialogState,
    remark,
    loadingUpdate,
    showApprovalButtons,
    run,
    handlePress,
    handleCloseDialog,
    setRemark,
    handleConfirm,
  };
};
