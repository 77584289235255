import { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import {
  PDF_TEMPLATE_PATH,
  RECRUITMENT_APPLICATION_FORM_PATH,
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_ONBOARDING_APPLICATION_PATH,
} from '../../../constants';
import { PDFTemplateFilterKeys } from './list/pdf-template-listing-page.hook';
import { PDFTemplateListingPage } from './list/pdf-template-listing-page.screen';

export const PDFTemplatePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_APPLICATION_PATH}${RECRUITMENT_APPLICATION_FORM_PATH}${PDF_TEMPLATE_PATH}`;

const PDFTemplateRoutes: FC = () => {
  return (
    <Switch>
      <Redirect exact from={PDFTemplatePath} to={`${PDFTemplatePath}/list`} />
      <PruRoute
        exact
        path={`${PDFTemplatePath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={PDFTemplateFilterKeys}>
            <PDFTemplateListingPage />
          </ParamsProvider>
        )}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default PDFTemplateRoutes;
