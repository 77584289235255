import { FC, useState, useReducer, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Info, CheckCircle, RadioButtonUnchecked, VerticalAlignTop } from '@mui/icons-material';
import { PruDatePicker, PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { regionLocale, initI18nGenericData, I18nGenericData, RegionLocale } from 'src/app/i18n';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { AttachmentDef } from 'src/app/common/types';
import {
  getDefaultDisplayDate,
  getDayStart,
  getDayEnd,
  fileUpload,
  useErrorHandler,
  ErrorFieldType,
  ErrorFieldDef,
} from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { createBlob, getBlob } from 'src/app/common/network';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { incentiveSetPath } from '../../IncentiveSetRoutes';
import {
  IncentiveTypeEnum,
  PublishStatusEnum,
  IncentiveFormMode,
  IncentiveDetail,
  IncentiveListItem,
} from '../../../../types/incentive-types';
import {
  CreateUpdateIncentiveBody,
  createNewIncentive,
  modifyIncentive,
  unpublishIncentive,
} from '../../../../network/incentiveCrud';
import { IncentiveSetFormState, incentiveSetFormReducer } from '../reducers';
import SelectIncentiveDialog from './SelectIncentiveDialog';

type IncentiveSetFormProps = {
  code?: string;
  formMode: IncentiveFormMode;
  incentiveSetItem?: IncentiveDetail;
};

const detailToStateConvertor = (regionLocale: RegionLocale[], detail?: IncentiveDetail): IncentiveSetFormState => {
  const initI18nStringData = initI18nGenericData<string>(regionLocale);
  const initI18nFileData = initI18nGenericData<AttachmentDef>(regionLocale);
  if (detail) {
    return {
      code: detail.code,
      name: detail.name,
      status: detail.status,
      subIncentiveItems: detail.subIncentiveItems || [],
      detail: detail.detail,
      award: detail.award || initI18nStringData,
      incentiveImage: detail.incentiveImage || initI18nFileData,
      stickOnTop: detail.stickOnTop ? true : false,
      startDate: detail.startDate,
      endDate: detail.endDate,
      publishDate: detail.publishDate,
      archiveDate: detail.archiveDate,
    };
  } else {
    return {
      code: undefined,
      name: initI18nStringData,
      status: PublishStatusEnum.DRAFT,
      subIncentiveItems: [],
      detail: initI18nStringData,
      award: initI18nStringData,
      incentiveImage: initI18nFileData,
      stickOnTop: false,
      startDate: null,
      endDate: null,
      publishDate: null,
      archiveDate: null,
    };
  }
};

const FIELD_CONTAINER_WIDTH = 160;
const TEXT_FIELD_WIDTH = 200;
const DATE_PICKER_WIDTH = 240;

const useStyles = makeStyles()((theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 18,
  },
  section: {
    padding: 32,
    marginBottom: 32,
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
  },
  subHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  selectIncentiveSubHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpacing: {
    marginBottom: 16,
  },
  detailSpacing: {
    marginBottom: 50,
  },
  detailTextContainer: {
    marginBottom: 24,
  },
  progressSpacing: {
    marginTop: 16,
    marginBottom: 16,
  },
  fillInContainer: {
    marginLeft: 30,
  },
  fieldContainer: {
    minWidth: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  valueOnlyContainer: {
    marginLeft: FIELD_CONTAINER_WIDTH,
  },
  textField: {
    minWidth: TEXT_FIELD_WIDTH,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  datePicker: {
    minWidth: DATE_PICKER_WIDTH,
    margin: 0,
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  noMargin: {
    margin: 0,
  },
  checkboxPadding: {
    paddingLeft: 0,
  },
  chooseFileSpacing: {
    marginLeft: 24,
  },
  radioButtonSpacing: {
    marginLeft: 32,
  },
  mandatory: {
    color: 'red',
  },
  errorText: {
    fontSize: 10,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#F018A6',
    marginBottom: 4,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 70,
  },
}));

const IncentiveSetForm: FC<IncentiveSetFormProps> = ({ code, formMode, incentiveSetItem }) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const initialUploadProgress = initI18nGenericData<number>(regionLocale);
  const [uploadImageProgress, setUploadImageProgress] = useState<I18nGenericData<number>>(initialUploadProgress);

  const [formState, formDispatch] = useReducer(
    incentiveSetFormReducer,
    detailToStateConvertor(regionLocale, incentiveSetItem),
  );
  const [showSelectDialog, setShowSelectDialog] = useState<boolean>(false);

  const isDisabled = useMemo(() => {
    return formState.status && formState.status === PublishStatusEnum.PUBLISHED ? true : false;
  }, [formState.status]);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'code',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'subIncentiveItems',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => formState.subIncentiveItems.length === 0,
    },
    ...(() => {
      let errorFieldDef: ErrorFieldDef[] = [];
      regionLocale.forEach((locale) => {
        errorFieldDef = [
          ...errorFieldDef,
          {
            name: `name-${locale}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () => !!!formState.name[locale],
          },
          {
            name: `detail-${locale}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () => !!!formState.detail[locale],
          },
        ];
      });
      return errorFieldDef;
    })(),
  ]);

  const handleImageFile = async (e: React.ChangeEvent<HTMLInputElement>, locale: string) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.type.includes('image')) {
        const fileSize = file.size / 1024 / 1024;
        const errMsg = Translation('global.max.file.size.allow');
        if (fileSize > 2) {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: '',
                content: errMsg,
              },
            ]),
          );
          return;
        }

        try {
          const createBlobRes = await createBlob(
            { mimeType: file.type, accessLevel: 'public', module: 'incentive' },
            dispatch,
          );
          await fileUpload(createBlobRes.url, file, (value) => {
            setUploadImageProgress({ ...uploadImageProgress, [locale]: value });
          });
          const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
          const result = blobDetail[0];
          if (result) {
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: {
                field: 'incentiveImage',
                value: {
                  ...formState.incentiveImage,
                  [locale]: { blobId: result.blobId, filename: file.name, url: result.url },
                },
              },
            });
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Upload Image successfully - ${file.name}`,
                },
              ]),
            );
          }
        } catch (err) {
        } finally {
          setUploadImageProgress({ ...uploadImageProgress, [locale]: undefined });
        }
      } else {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: 'Please upload an image',
            },
          ]),
        );
      }
    }
  };

  const removeImageFile = (locale: string) => {
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: { field: 'incentiveImage', value: { ...formState.incentiveImage, [locale]: undefined } },
    });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Remove image file successfully`,
        },
      ]),
    );
  };

  const onUnpublish = async () => {
    if (code) {
      try {
        await unpublishIncentive(code, dispatch);
        window.location.reload();
      } catch (err) {}
    }
  };

  const onSubmitForm = async (mode: PublishStatusEnum) => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const isPublishNow = mode === PublishStatusEnum.PUBLISHED && !formState.publishDate;
      const details: CreateUpdateIncentiveBody = {
        type: IncentiveTypeEnum.INCENTIVE_SET,
        code: formState.code ? formState.code.trim() : '',
        name: formState.name,
        subIncentiveIds: formState.subIncentiveItems.map((item) => item._id),
        detail: formState.detail,
        award: formState.award,
        incentiveImage: formState.incentiveImage,
        stickOnTop: formState.stickOnTop,
        startDate: formState.startDate ? getDayStart(formState.startDate) : null,
        endDate: formState.endDate ? getDayEnd(formState.endDate) : null,
        publishDate: formState.publishDate,
        archiveDate: formState.archiveDate ? getDayEnd(formState.archiveDate) : null,
      };
      if (isPublishNow) {
        details['publishDate'] = new Date();
      }
      try {
        if (formMode === IncentiveFormMode.CREATE) {
          await createNewIncentive(details, dispatch);
          if (isPublishNow) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Record saved and published successfully - ${details.code}`,
                },
              ]),
            );
          } else {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Record saved successfully - ${details.code}`,
                },
              ]),
            );
          }
        } else if (code && formMode === IncentiveFormMode.EDIT) {
          await modifyIncentive(code, details, dispatch);
          if (isPublishNow) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Record updated and published successfully - ${code}`,
                },
              ]),
            );
          } else {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Record updated successfully - ${code}`,
                },
              ]),
            );
          }
        }
        history.push(incentiveSetPath);
      } catch (err) {}
    }
  };

  return (
    <>
      <div>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {formMode === IncentiveFormMode.CREATE ? 'Add new' : isDisabled ? 'View' : 'Edit'} Incentive Set
            </div>
          </div>
          <Button variant="contained" color="inherit" onClick={() => history.push(incentiveSetPath)}>
            {Translation('app.button.back')}
          </Button>
        </div>
        {/* Basic Information */}
        <div className={classes.section}>
          <div className={classes.subHeaderContainer}>
            <span className={classes.subHeader}>{Translation('incentive.common.basic_information')}</span>
          </div>
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('incentive.set.common.code')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={isDisabled || formMode === IncentiveFormMode.EDIT}
                error={errorState.mandatory.code}
                className={`${classes.textField} ${classes.noMargin}`}
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory.code && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.code || ''}
                onChange={(e) => {
                  const onlyAlphNums = e.target.value.replace(/[^A-Za-z0-9]/g, '');
                  onDismissErrorHandler('code', onlyAlphNums);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'code', value: onlyAlphNums } });
                }}
              />
            </div>
          </div>
          {regionLocale.map((locale) => (
            <div key={`incentive-name-${locale}`} className={`${classes.rowContainer} ${classes.rowSpacing}`}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation(`incentive.set.common.name.${locale}`)}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <TextField
                  disabled={isDisabled}
                  error={errorState.mandatory[`name-${locale}`]}
                  className={classes.noMargin}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  helperText={errorState.mandatory[`name-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState.name[locale] || ''}
                  onChange={(e) => {
                    onDismissErrorHandler(`name-${locale}`, e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'name', value: { ...formState.name, [locale]: e.target.value } },
                    });
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Select Incentives */}
        <div className={classes.section}>
          <div className={classes.selectIncentiveSubHeader}>
            <span className={classes.subHeader}>{Translation('incentive.set.common.select_incentives')}</span>
            <Button
              disabled={isDisabled}
              variant="contained"
              color="secondary"
              onClick={() => setShowSelectDialog(true)}
            >
              {Translation('incentive.set.common.select_incentive')}
            </Button>
          </div>
          {errorState.mandatory.subIncentiveItems && (
            <div className={classes.errorText}>Select Incentives are mandatory</div>
          )}
          <div className={`${classes.rowContainer}`}>
            <PruTable
              disableBulkSelect
              disablePagination
              disableToolbar
              operationDef={
                isDisabled
                  ? []
                  : [
                      {
                        title: (
                          <span>
                            <VerticalAlignTop style={{ marginRight: 4 }} />
                            {Translation('incentive.set.common.top')}
                          </span>
                        ),
                        tooltipText: 'Move to Top',
                        onClick: (_, index) =>
                          formDispatch({ type: 'MOVE_SUB_INCENTIVE_ROW_TO_TOP', payload: { index } }),
                      },
                      {
                        title: Translation('incentive.set.common.remove'),
                        tooltipText: 'Remove',
                        onClick: (_, index) => formDispatch({ type: 'DELETE_SUB_INCENTIVE_ROW', payload: { index } }),
                      },
                    ]
              }
              columnDef={[
                {
                  isId: true,
                  keyIndex: 'code',
                  displayName: Translation('incentive.common.code'),
                  renderData: (row) => row.code,
                },
                ...(() =>
                  regionLocale.map((locale) => ({
                    keyIndex: `name-${locale}`,
                    displayName: Translation(`incentive.common.name.${locale}`),
                    renderData: (row: IncentiveListItem) => row.name[locale] || '-',
                  })))(),
                {
                  keyIndex: 'startDate',
                  align: 'center',
                  displayName: Translation('incentive.common.startDate'),
                  renderData: (row) => getDefaultDisplayDate(new Date(row.startDate)),
                },
                {
                  keyIndex: 'endDate',
                  align: 'center',
                  displayName: Translation('incentive.common.endDate'),
                  renderData: (row) => getDefaultDisplayDate(new Date(row.endDate)),
                },
                {
                  keyIndex: 'incentiveStatus',
                  align: 'center',
                  displayName: Translation('incentive.common.status'),
                  renderData: (row) => (row.incentiveStatus || '-').toUpperCase(),
                },
                {
                  keyIndex: 'createdAt',
                  align: 'center',
                  displayName: Translation('incentive.common.createdDate'),
                  renderData: (row) => (row.createdAt ? getDefaultDisplayDate(new Date(row.createdAt)) : '-'),
                },
              ]}
              isLoading={false}
              dataSource={formState.subIncentiveItems}
            />
          </div>
        </div>

        {/* Incentive Details */}
        <div className={classes.section}>
          {regionLocale.map((locale, index) => (
            <div
              key={`incentive-detail-${locale}`}
              className={`${index !== regionLocale.length - 1 ? classes.detailSpacing : undefined}`}
            >
              <div className={classes.detailTextContainer}>
                <div className={classes.subHeaderContainer}>
                  <div className={classes.subHeader}>
                    {Translation(`incentive.set.common.incentive_details.${locale}`)}
                  </div>
                  {index > 0 && (
                    <div className={classes.fillInContainer}>
                      <FormControlLabel
                        className={classes.noMargin}
                        disabled={isDisabled}
                        control={
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked) {
                                onDismissErrorHandler(`detail-${locale}`, true);
                                formDispatch({
                                  type: 'FILL_IN_DETAIL',
                                  payload: { fromLocale: regionLocale[0], toLocale: locale },
                                });
                              }
                            }}
                          />
                        }
                        label={Translation('incentive.common.use_lang_to_fill_in', {
                          lang: Translation(`navBar.lang.${regionLocale[0]}`),
                        })}
                        labelPlacement="end"
                      />
                    </div>
                  )}
                </div>
                <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {Translation('incentive.set.common.description')}
                      <span className={classes.mandatory}>*</span> :
                    </span>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <TextField
                      disabled={isDisabled}
                      InputProps={{
                        classes: {
                          input: classes.textArea,
                        },
                      }}
                      error={errorState.mandatory[`detail-${locale}`]}
                      className={classes.noMargin}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      multiline
                      helperText={errorState.mandatory[`detail-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                      value={formState.detail[locale] || ''}
                      onChange={(e) => {
                        onDismissErrorHandler(`detail-${locale}`, e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'detail', value: { ...formState.detail, [locale]: e.target.value } },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {Translation('incentive.common.award_description')}
                      <Tooltip title={Translation('incentive.common.award_description_tooltip')}>
                        <IconButton size={'small'} style={{ padding: 5, color: '#BBBBBB' }}>
                          <Info />
                        </IconButton>
                      </Tooltip>
                      :
                    </span>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <TextField
                      disabled={isDisabled}
                      inputProps={{ maxLength: 30 }}
                      InputProps={{
                        classes: {
                          input: classes.textArea,
                        },
                      }}
                      className={classes.noMargin}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      multiline
                      value={formState.award[locale] || ''}
                      onChange={(e) =>
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'award', value: { ...formState.award, [locale]: e.target.value } },
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>{Translation('incentive.common.upload_image')} :</span>
                  </div>
                  <div style={{ flexGrow: 1 }} className={classes.rowContainer}>
                    <input
                      id={`upload-image-${locale}`}
                      hidden
                      type="file"
                      accept="image/*"
                      disabled={!!uploadImageProgress[locale]}
                      onClick={(e) => {
                        const element = e.target as HTMLInputElement;
                        element.value = '';
                      }}
                      onChange={(e) => handleImageFile(e, locale)}
                    />
                    <Button
                      disabled={isDisabled}
                      variant="contained"
                      color="secondary"
                      onClick={() => document.getElementById(`upload-image-${locale}`)!.click()}
                    >
                      {Translation('app.button.chooseFile')}
                    </Button>
                    {formState.incentiveImage[locale]?.url ? (
                      <Button
                        className={classes.chooseFileSpacing}
                        disabled={isDisabled}
                        variant="contained"
                        color="secondary"
                        onClick={() => removeImageFile(locale)}
                      >
                        {Translation('app.button.removeFile')}
                      </Button>
                    ) : (
                      <div className={classes.chooseFileSpacing}>
                        {Translation('common.image_upload_recommendation')}
                      </div>
                    )}
                  </div>
                </div>
                {uploadImageProgress[locale] && (
                  <div className={`${classes.valueOnlyContainer} ${classes.progressSpacing}`}>
                    <LinearProgress variant="determinate" value={uploadImageProgress[locale]} />
                  </div>
                )}
                {formState.incentiveImage[locale]?.url && (
                  <div className={`${classes.valueOnlyContainer} ${classes.rowSpacing}`}>
                    <div className={classes.rowSpacing}>
                      <div>{formState.incentiveImage[locale]?.filename || ''}</div>
                    </div>
                    <div>
                      <img
                        style={{ width: 'auto', height: '150px' }}
                        src={formState.incentiveImage[locale]?.url}
                        alt="Incentive"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Publish Settings */}
        <div className={classes.section}>
          <div className={classes.subHeaderContainer}>
            <span className={classes.subHeader}>{Translation('incentive.common.publish_settings')}</span>
          </div>
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('incentive.common.stick_on_top')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FormControlLabel
                className={classes.noMargin}
                control={
                  <Checkbox
                    className={classes.checkboxPadding}
                    disabled={isDisabled}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<CheckCircle />}
                    checked={formState.stickOnTop}
                    onChange={(e) =>
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'stickOnTop', value: e.target.checked } })
                    }
                  />
                }
                label="Yes"
                labelPlacement="end"
              />
              <FormControlLabel
                className={`${classes.noMargin} ${classes.radioButtonSpacing}`}
                control={
                  <Checkbox
                    className={classes.checkboxPadding}
                    disabled={isDisabled}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<CheckCircle />}
                    checked={!formState.stickOnTop}
                    onChange={(e) =>
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'stickOnTop', value: !e.target.checked } })
                    }
                  />
                }
                label="No"
                labelPlacement="end"
              />
            </div>
          </div>
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('incentive.set.common.duration')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }} className={classes.rowContainer}>
              <PruDatePicker
                className={classes.datePicker}
                disabled={true}
                format="DD/MM/YYYY"
                value={formState.startDate}
              />
              <div className={classes.dateDivider}>-</div>
              <PruDatePicker
                className={classes.datePicker}
                disabled={true}
                format="DD/MM/YYYY"
                value={formState.endDate}
              />
            </div>
          </div>
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('incentive.common.publish_date')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <PruDateTimePicker
                className={classes.datePicker}
                ampm={false}
                disabled={true}
                format="DD/MM/YYYY HH:mm"
                value={formState.publishDate}
              />
            </div>
          </div>
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('incentive.common.archive_date')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <PruDatePicker
                className={classes.datePicker}
                disabled={true}
                format="DD/MM/YYYY"
                value={formState.archiveDate}
              />
            </div>
          </div>
          <div className={classes.footerContainer}>
            {isDisabled ? (
              <Button variant="contained" color="secondary" onClick={onUnpublish}>
                {Translation('app.button.unpublish')}
              </Button>
            ) : (
              <>
                <Button variant="contained" color="secondary" onClick={() => onSubmitForm(PublishStatusEnum.DRAFT)}>
                  {Translation('app.button.saveAsDraft')}
                </Button>
                <Button
                  style={{ marginLeft: 25 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => onSubmitForm(PublishStatusEnum.PUBLISHED)}
                >
                  {Translation('app.button.publish')}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {showSelectDialog && (
        <SelectIncentiveDialog
          open={showSelectDialog}
          selectedIncentives={formState.subIncentiveItems}
          onConfirm={(selected) => {
            onDismissErrorHandler('subIncentiveItems', selected);
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'subIncentiveItems', value: [...formState.subIncentiveItems, ...selected] },
            });
          }}
          onClose={() => setShowSelectDialog(false)}
        />
      )}
    </>
  );
};

export default IncentiveSetForm;
