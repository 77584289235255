import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { Dispatch } from 'react';

type TimeRange = {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
};

export type LeadGoalListParam = {
  page?: number;
  limit?: number;
  active?: boolean;
};

export type CreateLeadGoalInfo = {
  name: string;
  targetPoint: number;
  timeRange: TimeRange;
  channel?: string;
  region?: string;
  createdBy?: string;
  updatedBy?: string;
};

export const fetchLeadGoalList = async (params?: LeadGoalListParam, dispatch?: Dispatch<any>): Promise<any> => {
  // const queryPath = `http://localhost:3000/activity-point/lead-goal`;
  const queryPath = `${ADMIN_URL}/activity-point/lead-goal`;
  return apiClient
    .get<any>(queryPath, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createLeadGoal = async (data: CreateLeadGoalInfo, dispatch?: Dispatch<any>): Promise<any> => {
  // const queryPath = `http://localhost:3000/activity-point/lead-goal/create`;
  const queryPath = `${ADMIN_URL}/activity-point/lead-goal/create`;
  return apiClient
    .post<any>(queryPath, data)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateLeadGoal = async (id: string, data: CreateLeadGoalInfo, dispatch?: Dispatch<any>): Promise<any> => {
  // const queryPath = `http://localhost:3000/activity-point/lead-goal/update/${id}`;
  const queryPath = `${ADMIN_URL}/activity-point/lead-goal/update/${id}`;
  return apiClient
    .put<any>(queryPath, data)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
