import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Box, MenuItem, Select, TextField } from '@mui/material';
import { REMARK_OTHERS } from '../../../util/constant';

export type InputValue = { key?: string; remark?: string };
export type RemarkInputOptions = { label: string; value: string }[];

export interface RemarkInputProps {
  value?: InputValue;
  onChange?: (val?: InputValue) => void;
  options?: RemarkInputOptions;
}

export const RemarkInput: React.FC<RemarkInputProps> = memo(({ value, onChange, options }: RemarkInputProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <Box flex={1} flexDirection={'column'} display="flex">
      <Select
        labelId="remark-input-select-label"
        id="remark-input-select"
        value={value?.key ?? ''}
        onChange={(event) => onChange?.({ key: event.target.value as string, remark: undefined })}
      >
        {options?.map((o) => (
          <MenuItem value={o.value} key={o.value}>
            {Translation(o.label)}
          </MenuItem>
        ))}
        <MenuItem value={REMARK_OTHERS}>
          {Translation('onboarding.application.approval.detail.dialog.approve.placeholder.others')}
        </MenuItem>
      </Select>
      {value?.key === REMARK_OTHERS ? (
        <TextField
          sx={{ marginTop: '16px' }}
          placeholder={Translation('app.input.placeholder.please-enter')}
          id="outlined-multiline-flexible"
          multiline
          variant="outlined"
          maxRows={4}
          value={value?.remark ?? ''}
          onChange={(event) => onChange?.({ ...value, remark: event.target.value })}
        />
      ) : null}
    </Box>
  );
});
