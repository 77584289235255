import { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { fetchAgentSegmentItem } from '../../../network/agentSegmentCrud';
import { AgentSegmentItem } from 'src/app/common/types';
import { AgentSegmentFormMode } from '../../../types/agent-segment-type';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { useDispatch } from 'react-redux';
import { ApiErrorResponse } from 'src/app/common/network';
import { LayoutSplashScreen } from 'src/app/layout';
import AgentSegmentForm from './components/AgentSegmentForm';

const ConfigAgentSegmentDetailPage: FC<RouteComponentProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode, code } = useMemo(
    () => ({
      formMode: id ? AgentSegmentFormMode.EDIT : AgentSegmentFormMode.CREATE,
      code: id ? id : undefined,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agentSegment, setAgentSegment] = useState<AgentSegmentItem>();
  const [lastFileName, setLastFileName] = useState<Pick<AgentSegmentItem, 'agentListFileName'>>({
    agentListFileName: undefined,
  });

  const reloadData = () => {
    if (code) {
      setIsLoading(true);
      fetchAgentSegmentItem(code, dispatch)
        .then((result) => {
          setAgentSegment(result);
          setLastFileName({
            agentListFileName: result.agentListFileName,
          });
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [code]);

  return (
    <>
      {formMode === AgentSegmentFormMode.CREATE && (
        <AgentSegmentForm
          formMode={formMode}
          onReload={reloadData}
          lastFileName={lastFileName}
          onRouteTo={(route) => history.push(route)}
        />
      )}
      {formMode === AgentSegmentFormMode.EDIT &&
        (isLoading || !agentSegment ? (
          <LayoutSplashScreen />
        ) : (
          <AgentSegmentForm
            code={code}
            formMode={formMode}
            agentSegment={agentSegment}
            lastFileName={lastFileName}
            onReload={reloadData}
            onRouteTo={(route) => history.push(route)}
          />
        ))}
    </>
  );
};

export default ConfigAgentSegmentDetailPage;
