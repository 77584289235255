export const AGENCY_CAMPAIGN_BASE_PATH = '/agency-campaign';
export const AGENCY_CAMPAIGN_CAMPAIGN_TYPE_PATH = '/agency-campaign-type';
export const AGENCY_CAMPAIGN_APPROVAL_PATH = '/approval';
export const AGENCY_CAMPAIGN_STAFF_CAMPAIGN_PATH = '/staff-campaign';
export const AGENCY_CAMPAIGN_LM_DASHBOARD_PATH = '/lm-dashboard';
export const AGENCY_CAMPAIGN_BDM_DASHBOARD_PATH = '/bdm-dashboard';

export const AGENCY_CAMPAIGN_TITLE = 'title.agencyCampaign';
export const AGENCY_CAMPAIGN_CAMPAIGN_TYPE_TITLE = 'title.agencyCampaign.campaignType';
export const AGENCY_CAMPAIGN_APPROVAL_TITLE = 'title.agencyCampaign.approval';
export const AGENCY_CAMPAIGN_STAFF_CAMPAIGN_TITLE = "title.agencyCampaign.staffCampaign";
export const AGENCY_CAMPAIGN_LM_DASHBOARD_TITLE = "title.agencyCampaign.lmDashboard";
export const AGENCY_CAMPAIGN_BDM_DASHBOARD_TITLE = "title.agencyCampaign.bdmDashboard";

export const AGENCY_CAMPAIGN_CAMPAIGN_TYPE_NAMESPACE = "AGENCY-CAMPAIGN-CAMPAIGN-TYPE";
export const AGENCY_CAMPAIGN_APPROVAL_NAMESPACE = "AGENCY-CAMPAIGN-APPROVAL";
export const AGENCY_CAMPAIGN_STAFF_CAMPAIGN_NAMESPACE = "AGENCY-CAMPAIGN-STAFF-CAMPAIGN";
export const AGENCY_CAMPAIGN_LM_DASHBOARD_NAMESPACE = "AGENCY-CAMPAIGN-LM-DASHBOARD";
export const AGENCY_CAMPAIGN_BDM_DASHBOARD_NAMESPACE = "AGENCY-CAMPAIGN-BDM-DASHBOARD";

export enum AdminAgencyCampaignPermissionType {
  AGENCY_CAMPAIGN_CAMPAIGN_TYPE_READ = "READ_ANY_AGENCY-CAMPAIGN-CAMPAIGN-TYPE",
  AGENCY_CAMPAIGN_CAMPAIGN_TYPE_CREATE = "CREATE_ANY_AGENCY-CAMPAIGN-CAMPAIGN-TYPE",
  AGENCY_CAMPAIGN_CAMPAIGN_TYPE_UPDATE = "UPDATE_ANY_AGENCY-CAMPAIGN-CAMPAIGN-TYPE",
  AGENCY_CAMPAIGN_APPROVAL_READ = "READ_ANY_AGENCY-CAMPAIGN-APPROVAL",
  AGENCY_CAMPAIGN_APPROVAL_CREATE = "CREATE_ANY_AGENCY-CAMPAIGN-APPROVAL",
  AGENCY_CAMPAIGN_APPROVAL_UPDATE = "UPDATE_ANY_AGENCY-CAMPAIGN-APPROVAL",
  AGENCY_CAMPAIGN_STAFF_CAMPAIGN_READ = "READ_ANY_AGENCY-CAMPAIGN-STAFF-CAMPAIGN",
  AGENCY_CAMPAIGN_STAFF_CAMPAIGN_CREATE = "CREATE_ANY_AGENCY-CAMPAIGN-STAFF-CAMPAIGN",
  AGENCY_CAMPAIGN_STAFF_CAMPAIGN_UPDATE = "UPDATE_ANY_AGENCY-CAMPAIGN-STAFF-CAMPAIGN",
  AGENCY_CAMPAIGN_LM_DASHBOARD_READ = "READ_ANY_AGENCY-CAMPAIGN-LM-DASHBOARD",
  AGENCY_CAMPAIGN_LM_DASHBOARD_CREATE = "CREATE_ANY_AGENCY-CAMPAIGN-LM-DASHBOARD",
  AGENCY_CAMPAIGN_LM_DASHBOARD_UPDATE = "UPDATE_ANY_AGENCY-CAMPAIGN-LM-DASHBOARD",
  AGENCY_CAMPAIGN_BDM_DASHBOARD_READ = "READ_ANY_AGENCY-CAMPAIGN-BDM-DASHBOARD",
  AGENCY_CAMPAIGN_BDM_DASHBOARD_CREATE = "CREATE_ANY_AGENCY-CAMPAIGN-BDM-DASHBOARD",
  AGENCY_CAMPAIGN_BDM_DASHBOARD_UPDATE = "UPDATE_ANY_AGENCY-CAMPAIGN-BDM-DASHBOARD",
}

export const CAMPAIGN_TYPE_CODE_ERROR_TEXT = 'Please input two capital letters';

export const ELIGIBILITY_CHECK_BATCH_SIZE = 200;

export enum AgencyCampaignTypeStatusEnum {
  PENDING = 'pendingApproval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PAUSED = "paused",
  CANCELED = 'canceled',
  DRAFT = 'draft'
}

export enum WorkflowApprovalStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}