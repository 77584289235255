import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Translation, TranslationWithParams } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import { getConfigurations } from 'src/app/common/utils';
import {
  fetchRegistrationDetail,
  updateRegistrationStatus,
  editRegistrationResult,
} from '../../../network/license-exam-crud';
import { examRegistrationBasePath } from '../registration/registration-routes';
import { useHistory } from 'react-router-dom';
import { RegistrationApprovalEventsEnum, RegistrationApprovalStatesEnum } from '../../../enum/license-exam.enum';
import { useForm } from 'src/app/common/components/Form';
import { UpdateExamResult } from '../../../types/license-exam-types';
import { getStatusLabel } from '../util/registration.util';

interface HookProps {
  id: string;
  enableUpdate: boolean;
}

export const useRegistrationDetail = ({ id, enableUpdate }: HookProps) => {
  const history = useHistory();
  const [form] = useForm();

  // redux
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState<{ open: boolean; header: string }>({ open: false, header: '' });
  const [action, setAction] = useState<RegistrationApprovalEventsEnum>(RegistrationApprovalEventsEnum.approve);
  const [remark, setRemark] = useState<string>('');
  const [editing, setEditing] = useState<boolean>(false);

  // get runtime-config
  const enableApproval: boolean = useMemo(() => {
    const config = getConfigurations();
    const enableApproval = get(config, 'Recruitment.enableExamApproval', false);
    return enableApproval;
  }, []);

  const {
    data,
    loading: loadingRegistrationDetail,
    run: runFetchDetail,
  } = useRequest(() => fetchRegistrationDetail(id, dispatch), {
    manual: true,
  });

  const { loading: loadingApprove, run: runUpdateRegistrationStatus } = useRequest(
    () =>
      updateRegistrationStatus(
        {
          registrationApprovalEvents: action,
          remark: remark,
        },
        id,
        dispatch,
      )
        .then(() => {
          handleCloseDialog();
          history.push(`${examRegistrationBasePath}/list`);
        })
        .catch(() => {
          handleCloseDialog();
        }),
    {
      manual: true,
    },
  );

  const { loading: loadingUpdateResult, run: runEditRegistrationResult } = useRequest(
    (values: UpdateExamResult) =>
      editRegistrationResult(values, id).then(() => {
        runFetchDetail();
        setEditing(false);
      }),
    {
      manual: true,
    },
  );

  useEffect(() => {
    runFetchDetail();
  }, [id]);

  const initialValues = useMemo(
    () => ({
      result: data?.result || null,
      score: data?.score || '',
    }),
    [data],
  );

  const statusLabel = useMemo(() => {
    if (data && data.registrationApprovalStates) {
      return getStatusLabel(data.registrationApprovalStates, enableApproval);
    }
    return '-';
  }, [data]);

  const showResult = useMemo(
    () =>
      data?.registrationApprovalStates === RegistrationApprovalStatesEnum.REGISTERED.toUpperCase() ||
      data?.registrationApprovalStates === RegistrationApprovalStatesEnum.COMPLETED.toUpperCase() ||
      data?.result != null ||
      data?.score != null,
    [data],
  );

  const showCancelButton = useMemo(
    () =>
      enableUpdate &&
      data?.registrationApprovalStates !== RegistrationApprovalStatesEnum.COMPLETED.toUpperCase() &&
      data?.registrationApprovalStates !== RegistrationApprovalStatesEnum.CANCELLED.toUpperCase() &&
      data?.registrationApprovalStates !== RegistrationApprovalStatesEnum.REJECTED.toUpperCase(),
    [data],
  );

  const showApprovalButtons = useMemo(
    () =>
      enableUpdate &&
      enableApproval &&
      data?.registrationApprovalStates === RegistrationApprovalStatesEnum.PENDING_APPROVAL.toUpperCase(),
    [data],
  );

  const handleCloseDialog = useCallback(() => {
    setDialogState({ open: false, header: '' });
    setRemark('');
  }, []);

  const handlePress = useCallback((action: RegistrationApprovalEventsEnum) => {
    setDialogState({
      open: true,
      header: TranslationWithParams('recruitment.exam.registration.dialogTitle', {
        action: Translation(`recruitment.exam.registration.${action.toLowerCase()}`).toLowerCase(),
      }),
    });
    setAction(action);
  }, []);

  const handleSaveEditResult = useCallback((values: UpdateExamResult) => {
    runEditRegistrationResult(values);
  }, []);

  const handleConfirm = useCallback(() => {
    runUpdateRegistrationStatus();
  }, []);

  return {
    loadingRegistrationDetail,
    data,
    enableApproval,
    handlePress,
    dialogState,
    handleCloseDialog,
    action,
    showResult,
    showCancelButton,
    showApprovalButtons,
    editing,
    setEditing,
    handleSaveEditResult,
    handleConfirm,
    remark,
    setRemark,
    form,
    initialValues,
    loadingUpdateResult,
    loadingApprove,
    statusLabel,
  };
};
