import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_LICENSE_EXAM_PATH,
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_PATH,
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_UPLOAD_PATH,
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_ADD_PATH,
} from '../../../constants';
import ScheduleListPage, { examScheduleFilterKeys } from './schedule-listing-page';
import ExamScheduleUploadPage from './schedule-upload-page';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import PruRoute from '../../../../../common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { AddSchedulePage } from './add-schedule/add-schedule.screen';
import { EditSchedulePage } from './edit-schedule/edit-schedule.screen';
import { ModulePermissionProps } from 'src/app/common/module/types';

export const scheduleBasePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_LICENSE_EXAM_PATH}${RECRUITMENT_LICENSE_EXAM_SCHEDULE_PATH}`;
const LicenseExamScheduleRoutes: FC<RouteComponentProps & ModulePermissionProps> = (routeProps) => {
  return (
    <Switch>
      <Redirect exact from={`${scheduleBasePath}`} to={`${scheduleBasePath}/list`} />
      <PruRoute
        exact
        path={`${scheduleBasePath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={examScheduleFilterKeys}>
            <ScheduleListPage {...routeProps} />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${scheduleBasePath}${RECRUITMENT_LICENSE_EXAM_SCHEDULE_UPLOAD_PATH}`}
        render={() => <ExamScheduleUploadPage {...routeProps} />}
      />
      <PruRoute
        exact
        path={`${scheduleBasePath}${RECRUITMENT_LICENSE_EXAM_SCHEDULE_ADD_PATH}`}
        render={() => <AddSchedulePage {...routeProps} />}
      />
      <PruRoute exact path={`${scheduleBasePath}/edit/:id`} render={() => <EditSchedulePage {...routeProps} />} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default LicenseExamScheduleRoutes;
