import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from '../../../../../common/components/PruRoute';
import IncentiveResultListingPage from './List/IncentiveResultListingPage';
import IncentiveResultReportPage from './Create/IncentiveResultReportPage';
import ParamsProvider from '../../../../../common/components/ParamsProvider';
import { reportFilterKeys } from '../../../constants';

const IncentiveResultPage: FC<RouteComponentProps> = ({ history, location, match }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`}/>
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={reportFilterKeys}>
            <IncentiveResultListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${match.path}/create`}
        component={IncentiveResultReportPage}
      />
    </Switch>
  )
};

export default IncentiveResultPage;
