import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_LICENSE_EXAM_PATH,
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_PATH,
} from '../../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import PruRoute from '../../../../../common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { registrationFilterKeys } from './registration-listing-page.hook';
import { RegistrationListingPage } from './registration-listing-page.screen';
import { RegistrationDetailPage } from '../registration-detail/registration-detail.screen';
import ExamResultsUploadPage from '../results/results-upload-page';
import { ModulePermissionProps } from 'src/app/common/module/types';

export const examRegistrationBasePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_LICENSE_EXAM_PATH}${RECRUITMENT_LICENSE_EXAM_REGISTRATION_PATH}`;
const LicenseExamRegistrationRoutes: FC<RouteComponentProps & ModulePermissionProps> = (routeProps) => {
  return (
    <Switch>
      <Redirect exact from={`${examRegistrationBasePath}`} to={`${examRegistrationBasePath}/list`} />
      <PruRoute
        exact
        path={`${examRegistrationBasePath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={registrationFilterKeys}>
            <RegistrationListingPage {...routeProps} />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${examRegistrationBasePath}/view/:id`}
        render={() => <RegistrationDetailPage {...routeProps} />}
      />
      <PruRoute
        exact
        path={`${examRegistrationBasePath}/upload`}
        render={() => <ExamResultsUploadPage {...routeProps} />}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default LicenseExamRegistrationRoutes;
