import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  FormControl,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useLang, regionLocale } from 'src/app/i18n';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import Form, { useForm } from 'src/app/common/components/Form';
import { OnboardingLessonFormState } from 'src/app/modules/Recruitment/types/courses-types';
import {
  RECRUITMENT_APPLICATION_COURSES,
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_ONBOARDING_TRAINING,
} from 'src/app/modules/Recruitment/constants';
import FileUploader from 'src/app/common/components/FileUploader';
import { createBlob, getBlob } from 'src/app/common/network';
import { useDispatch } from 'react-redux';
import { fileUpload } from 'src/app/common/utils';
import { YesAndNo } from 'src/app/modules/Recruitment/utils';
import PruDialog from 'src/app/common/components/PruDialog';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  accordContainer: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  divideMargin: {
    // marginBottom: 10
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  accordHeading: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
  accordSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addNewMaterialSetBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBg: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'scroll',
  },
  modalButtons: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  operationBtn: {
    color: '#3994F2',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
    marginLeft: '1.6rem',
  },
}));

const StyledRadioGroup = withStyles(RadioGroup, {
  root: {
    flexDirection: 'row',
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: '15px',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
});

const generateDefaultExpandPanel = (regionLocales: string[]) => {
  const expandPanel: any = {};
  regionLocales.map((item) => {
    return (expandPanel[item] = true);
  });
  return expandPanel;
};

interface LessonFormListProp {
  id: string;
  disabled: boolean;
  onSave: (formData: OnboardingLessonFormState, type: string) => void;
  onDelete: () => void;
  initialValues?: Partial<OnboardingLessonFormState>;
  isEdit: boolean;
  history: any;
  location?: any;
  match?: any;
  type: string;
  courseId: string;
  loadingFun?: () => void;
  linkedCourse?: {
    [key: string]: string;
  };
}

export type ColumnOptions = {
  label: string;
  value: string;
  id: string;
}[];

export enum PublishStatus {
  'unpublished' = 'Unpublished',
  'published' = 'Published',
}

const initialDialogState = {
  status: false,
  type: 'delete',
  text: '',
};

const LessonFormList: React.FC<LessonFormListProp> = (props) => {
  const locale = useLang();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { id, disabled, onSave, initialValues, isEdit, history, type, linkedCourse, courseId, onDelete } = props;
  const [form] = useForm();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const TranslationWithVariable = (key: string, type: string) => intl.formatMessage({ id: key }, { type });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('field_mandatory_helper_label') };
  const [loading, setIsLoading] = useState<boolean>(false);
  const [dialog, setDialog] = useState(initialDialogState);

  const onSaveDraft = () => {
    const formData = form.getFieldsValue(true);
    // const validationList = regionLocales.map((localeItem) => { return [localeItem, 'name'] });
    // set validate for all mandatroy fields not only information
    form
      .validateFields()
      .then(async (res) => {
        if (formData.systemReferral) {
          await onSave({ ...formData }, type);
          history.goBack();
        }
      })
      .catch(async (err) => {
        console.log(err);
      });
    return false;
  };

  const onCancel = () => {
    history.goBack();
  };


  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
    // TODO: get course detail here
  }, [initialValues]);

  const placeEnter = Translation('app.input.placeholder.please-enter');

  const onSubmitForm = (getFieldValue: any) => {
    submitFormAnyWay();
  };
  const submitFormAnyWay = () => {
    onSaveDraft();
  };

  const uploadFileMockStatus = async ({
    file,
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
    setLoadPercentage: (p: number) => void;
  }) => {
    const createBlobRes = await createBlob(
      { mimeType: file.type, accessLevel: 'anonymous', module: 'training' },
      dispatch,
    );
    await fileUpload(createBlobRes.url, file);
    const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
    const result = blobDetail[0];
    if (result) {
      return result;
    } else {
      throw new Error('upload failed');
    }
  };

  const setData = (key: string, value: any, locale: string) => {
    setIsLoading(true)
    const prevFormValue = form.getFieldValue(key)
    const curFormValue = Object.assign({}, prevFormValue, { [locale]: value });
    form.setFieldsValue({ [`${key}`]: curFormValue });
    setIsLoading(false)
  };

  const handleQuiz = useCallback(() => {
    const quizId = initialValues?.quiz?.id
    if (type === 'edit') {
      history.push(`${BasePath}/quiz/edit/${quizId}`, { lessonId: id, courseId: courseId, courseName: linkedCourse, lessonName: initialValues?.lessonName });
    } else if (type === 'view') {
      history.push(`${BasePath}/quiz/view/${quizId}`, { lessonId: id, courseId: courseId, courseName: linkedCourse, lessonName: initialValues?.lessonName });
    }
  }, [type, initialValues]);

  const addQuizButton = useMemo(() => {
    return (
      (type !== 'view' && !initialValues?.quiz?.id) && <Button variant='contained' color='secondary'
        onClick={() => history.push(`${BasePath}/quiz/create`, { lessonId: id, courseId: courseId, courseName: linkedCourse, lessonName: initialValues?.lessonName })}
        disabled={!id}
      >{Translation('recruitment.lesson.addQuiz')}</Button>
    )
  }, [initialValues, type, id, courseId, linkedCourse])

  const handleOk = useCallback(async () => {
    switch (dialog.type) {
      case 'delete':
        onDelete();
        break;
      default:
        break;
    }
  }, [dialog]);

  const openDialog = useCallback(
    (dialogType: string, text: string) => {
      const dialogState = {
        status: true,
        type: dialogType,
        text,
      };
      setDialog(dialogState);
    },
    [type],
  );

  const handleDelete = useCallback(async () => {
    const isLessonHasQuiz = !!initialValues?.quiz?.id;
    const text = isLessonHasQuiz ? Translation('recruitment.lesson.delete') : Translation('recruitment.quiz.delete');
    openDialog('delete', text);
  }, [initialValues?.quiz?.id]);

  const MemoQuizOperationText = useMemo(() => {
    return TranslationWithVariable(`recruitment.quiz.${type}`, '');
  }, [type]);

  const closeWaringDialog = () => {
    setDialog({ ...dialog, status: false });
  };

  const MemoLessonPageTitle = useMemo(() => {
    let title = ''
    switch (type) {
      case 'create':
        title = Translation('recruitment.lesson.add');
        break;
      case 'edit':
        title = Translation('recruitment.lesson.edit');
        break;
      case 'view':
        title = Translation('recruitment.lesson.view');
        break;
      default:
        break;
    }
    return title;
  }, [type])

  const BasePath = useMemo(() => {
    return `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_TRAINING}${RECRUITMENT_APPLICATION_COURSES}/${courseId}/lesson/${id}`;
  }, [id]);
  return (
    <>
      <PruDialog
        dialogTitle={Translation('reminder_title')}
        open={dialog.status}
        canCloseDialog={true}
        onCancel={closeWaringDialog}
        onOk={handleOk}
        confirmBtnText={Translation('app.button.confirm')}
        canncelBtnText={Translation('app.button.cancel')}
      >
        {dialog.text}
      </PruDialog>
      <div className="tw-bg-white tw-rounded-lg tw-h-full tw-relative">
        <Form form={form}>
          <div className={classes.container}>
            <div className={classes.headerContainer}>
              <div className={classes.rowContainer}>
                <div className={commonClasses.header}>
                  {MemoLessonPageTitle}
                </div>
              </div>
              <Button variant="contained" color="inherit" onClick={() => history.goBack()}>
                {Translation('global.back.btnText')}
              </Button>
            </div>
            {regionLocale
              .sort((lng1, _) => (lng1 === 'en' ? 1 : -1))
              .map((locale) => {
                const labelText = locale === 'en' ? `navBar.lang.${locale}` : 'local_language';
                return (
                  <Form.Item
                    name={['lessonName', locale]}
                    label={`${Translation('recruitment.lesson.name')}(${Translation(labelText)})`}
                    rules={[DEFAULT_REQUIRED_RULES]}
                  >
                    <TextField
                      disabled={disabled}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      placeholder={placeEnter}
                    />
                  </Form.Item>
                );
              })}
            <Form.Item
              name="lessonCode"
              label={Translation('recruitment.lesson.code')}
              rules={[DEFAULT_REQUIRED_RULES]}
            >
              <TextField
                disabled={type !== 'create'}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder={placeEnter}
              />
            </Form.Item>
            <Form.Item
              name={'systemReferral'}
              label={`${Translation('recruitment.course.source_system_referral')}?`}
              rules={[DEFAULT_REQUIRED_RULES]}
              shouldUpdate
              initialValue={YesAndNo.no}
            >
              <StyledRadioGroup aria-label="contentType" name="contentType">
                {Object.entries(YesAndNo).map(([label, value]) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Radio disabled={disabled} />}
                    label={Translation(`app.select.${label}`)}
                  />
                ))}
              </StyledRadioGroup>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues['systemReferral'] !== currentValues['systemReferral']
              }
            >
              {(_, meta, { getFieldValue }) => {
                const systemReferral = getFieldValue('systemReferral') === YesAndNo.yes;
                return (
                  <>
                    {systemReferral && (
                      <Form.Item
                        name={'referralCode'}
                        label={<span>{Translation('recruitment.course.referral_code')}</span>}
                        rules={[DEFAULT_REQUIRED_RULES]}
                        shouldUpdate
                      >
                        <TextField
                          disabled={disabled}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          placeholder={placeEnter}
                        />
                      </Form.Item>
                    )}
                  </>
                );
              }}
            </Form.Item>
            <Form.Item
              name={'linkedCourse'}
              initialValue={linkedCourse?.[locale]}
              label={Translation('recruitment.lesson.linkedCourse')}
            >
              <FormControl margin="dense" variant="outlined">
                <Select
                  readOnly
                  style={{ minWidth: 350 }}
                  value={linkedCourse?.[locale]}
                  disabled
                >
                  {<MenuItem value={linkedCourse?.[locale]}>{linkedCourse?.[locale]}</MenuItem>}
                </Select>
              </FormControl>
            </Form.Item>
            {initialValues?.quiz && (
              <>
                <Form.Item
                  name={'linkedQuiz'}
                  initialValue={initialValues?.quiz?.quizName?.[locale]}
                  label={Translation('recruitment.lesson.linkedQuiz')}
                >
                  <>
                    <TextField
                      value={initialValues?.quiz?.quizName?.[locale]}
                      disabled
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      placeholder={placeEnter}
                    />
                    <div className={classes.operationBtn} onClick={handleQuiz}>
                      {MemoQuizOperationText}
                    </div>
                  </>
                </Form.Item>
              </>
            )}
            {regionLocale
              .sort((lng1, _) => (lng1 === 'en' ? 1 : -1))
              .map((locale, index) => {
                const labelText = locale === 'en' ? `navBar.lang.${locale}` : 'local_language';
                return (
                  <Form.Item
                    name={['material', locale]}
                    label={`${Translation('recruitment.lesson.materialUpload')}(${Translation(labelText)})`}
                    rules={[DEFAULT_REQUIRED_RULES]}
                    key={`${index}-${locale}`}
                  >
                    {(_, meta, { getFieldValue }) => {
                      return (
                        <div className="tw-my-6" key={`${index}-${locale}`}>
                          <FileUploader
                            maxFileSize={10}
                            disabled={disabled}
                            upload={uploadFileMockStatus}
                            download={() => { }}
                            value={getFieldValue('material')?.[locale]}
                            allowedFileTypes={['pdf']}
                            onChange={(data) => {
                              setData(`material`, data, locale);
                            }}
                            key={`${index}-${locale}`}
                            allowDuplicateFile={true}
                          />
                          {!getFieldValue('material')?.[locale] && <label>(PDF format, 10MB maximum) </label>}
                        </div>
                      );
                    }}
                  </Form.Item>
                );
              })}
          </div>
        </Form>

        <div className="tw-mt-12 tw-ml-6">{addQuizButton}</div>

        <div className="tw-bottom-10 tw-right-10 tw-absolute">
          <div className="tw-w-full tw-flex tw-flex-row">
            {type === 'edit' && (
              <div className="tw-ml-6">
                <Button
                  variant="contained"
                  size="large"
                  style={{ background: '#D32F2F', color: '#ffffff' }}
                  onClick={handleDelete}
                >
                  {Translation('app.button.delete')}
                </Button>
              </div>
            )}
            <div className="tw-ml-6">
              <Button variant="contained" size="large" color="secondary" onClick={onCancel}>
                {Translation('app.button.back')}
              </Button>
            </div>
            {type !== 'view' && (
              <div className="tw-ml-6">
                <Button variant="contained" size="large" color="secondary" onClick={onSubmitForm}>
                  {Translation('app.button.save')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonFormList;
