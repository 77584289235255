import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Edit } from '@mui/icons-material';
import { updateDialogue } from '../../../../../network/talkbot-dialogue-crud';
import { useDispatch } from 'react-redux';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { DialogueItem } from '../../../../../types/talkbot-dialogue-type';
import { numberRegex } from 'src/app/common/constants';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  row: DialogueItem;
  existWeights: number[];
};

export const DialogueWeightComponent: React.FC<ComponentProps> = memo(({ row, existWeights }) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [value, setValue] = useState(row.weight);

  const handlerBlur = async (e: any) => {
    const newValue = e.target.value;

    // check valid weight
    if (newValue == value) {
      setEditable(false);
      setErrorMsg(undefined);
      return;
    }
    if (!numberRegex.test(newValue)) {
      setErrorMsg(Translation('value.need.greater.zero.msg'));
      return;
    }
    if (existWeights?.includes(Number(newValue))) {
      setErrorMsg(Translation('saleskit.companyPick.weight.isUsed'));
      return;
    }

    await updateDialogue(row._id, { weight: newValue });
    setEditable(false);
    setValue(newValue);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: 'Update Weight successfully',
        },
      ]),
    );
  };

  return editable ? (
    <TextField
      variant="outlined"
      margin="dense"
      onBlur={handlerBlur}
      defaultValue={value}
      error={!!errorMsg}
      helperText={errorMsg}
    />
  ) : (
    <div className="tw-flex tw-justify-between">
      <span>{value}</span>
      <Edit className="tw-cursor-pointer" onClick={() => setEditable(true)} />
    </div>
  );
});
