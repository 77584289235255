import {
  ApplicationStatus,
  ApplicationType,
  RecordStatus,
  ReturnCategories,
  TaskStatus,
} from 'src/app/modules/Recruitment/types/approval-types';

export const TASK_STATUS_MAPS = {
  [TaskStatus.assigned]: 'onboarding.application.approval.list.task_status.assigned',
  [TaskStatus.unassigned]: 'onboarding.application.approval.list.task_status.unassigned',
};

export const APPLICATION_STATUS_MAPS = {
  [ApplicationStatus.draft]: 'onboarding_form_status_draft',
  [ApplicationStatus.editing]: 'onboarding_form_status_editing',
  [ApplicationStatus.underReview]: 'onboarding_form_status_under_review',
  [ApplicationStatus.pendingSignature]: 'onboarding_form_status_pending_signature',
  [ApplicationStatus.submitted]: 'onboarding_form_status_submitted',
  [ApplicationStatus.rejected]: 'onboarding_form_status_rejected',
  [ApplicationStatus.screeningPendingApproval]: 'onboarding_form_status_l1_pending_approval',
  [ApplicationStatus.followUpRequired]: 'onboarding_form_status_follow_up_required',
  [ApplicationStatus.screeningApproved]: 'onboarding_form_status_l1_approved',
  [ApplicationStatus.icPendingApproval]: 'onboarding_form_status_l2_pending_approval',
  [ApplicationStatus.icApproved]: 'onboarding_form_status_l2_approved',
  [ApplicationStatus.agentCodePendingApproval]: 'onboarding_form_status_l3_pending_approval',
  [ApplicationStatus.agentCodeApproved]: 'onboarding_form_status_l3_approved',
  [ApplicationStatus.accepted]: 'onboarding_form_status_accepted',
  [ApplicationStatus.canceled]: 'onboarding_form_status_canceled',

  [ApplicationStatus.deleted]: 'onboarding_form_status_deleted',
  [ApplicationStatus.expired]: 'onboarding_form_status_expired',
  [ApplicationStatus.l4PendingApproval]: 'onboarding_form_status_l4_pending_approval',
  // [ApplicationStatus.l4Approved]: 'onboarding_form_status_l4_approved',
};

export const APPLICATION_TYPE_MAPS = {
  [ApplicationType.newRecruit]: 'onboarding.application.approval.list.application_type.new_recruit',
  [ApplicationType.reinstatement]: 'onboarding.application.approval.list.application_type.reinstatement',
};

export const REMARK_OTHERS = 'OTHERS';

export const RECORD = {
  [RecordStatus.submit]: 'onboarding.application.approval.record.submit',
  [RecordStatus.assign]: 'onboarding.application.approval.record.assign',
  [RecordStatus.l1Approve]: 'onboarding.application.approval.record.approve',
  [RecordStatus.l2Approve]: 'onboarding.application.approval.record.approve',
  [RecordStatus.l3Approve]: 'onboarding.application.approval.record.approve',
  [RecordStatus.l4Approve]: 'onboarding.application.approval.record.approve',
  [RecordStatus.reject]: 'onboarding.application.approval.record.reject',
  [RecordStatus.return]: 'onboarding.application.approval.record.return',
  [RecordStatus.updateRemark]: 'onboarding.application.approval.record.update_remark',
};

export const CATEGORY_MAP = {
  [ReturnCategories.applicant]: 'CANDIDATE',
  [ReturnCategories.recruiter]: 'AGENT',
  [ReturnCategories.agentLeader]: 'MANAGER',
};

// same as src/app/common/components/survey-v2/survey-creator.tsx here
export const LOCAL_MAP = {
  en: 'default',
  'zh-Hans': 'zh-cn',
  'zh-Hant': 'zh-tw',
};

export const SELECT_ASSIGNEE_MODAL_I18N = {
  [ReturnCategories.applicant]: 'onboarding.application.approval.detail.dialog.select.assignee.items.applicant',
  [ReturnCategories.recruiter]: 'onboarding.application.approval.detail.dialog.select.assignee.items.recruiter',
  [ReturnCategories.agentLeader]: 'onboarding.application.approval.detail.dialog.select.assignee.items.agent_leader',
};
