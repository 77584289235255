export const PULSELEADS_BASE_PATH = '/pulseleads';

export const PULSELEADS_LEAD_PATH = '/lead';
export const PULSELEADS_LEAD_CAMPAIGN_PATH = '/campaign';
export const PULSELEADS_LEAD_SOURCE_PATH = '/source';

export const PULSELEADS_SLA_PATH = '/sla';
export const PULSELEADS_ACCEPT_SLA_PATH = '/accept';
export const PULSELEADS_CONTACT_SLA_PATH = '/contact';
export const PULSELEADS_MEET_SLA_PATH = '/meet';
export const PULSELEADS_PROPOSAL_SLA_PATH = '/proposal';
export const PULSELEADS_APPLICATION_SLA_PATH = '/application';

export const PULSELEADS_RULE_PATH = '/rule';
export const PULSELEADS_LEAD_DEDUPLICATION_RULE_PATH = '/lead-deduplication';
export const PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_PATH = '/customer-deduplication';
export const PULSELEADS_DISTRIBUTION_RULE_PATH = '/distribution';
export const PULSELEADS_EXCLUSION_RULE_PATH = '/exclusion';
export const PULSELEADS_ELIGIBILITY_RULE_PATH = '/eligibility';

export const TIME_ERROR_TEXT = 'End time must be after Start time';
export const LAUNCH_DATE_ERROR_TEXT = 'Launch Date must be between Start Date and End Date';
export const NOT_VALID_URL_TEXT = 'Please enter a valid url';
export const SAME_PRIORITY_ERROR_TEXT = 'Priority cannot be the same with others';
export const AT_MOST_THREE_RULES_ERROR_TEXT = 'At most three rules can be included';

//  These constants map to aside menu, header menu to display title
export const PULSELEADS_TITLE = 'title.pulseleads';
export const PULSELEADS_LEAD_TITLE = 'title.pulseleadsLead';
export const PULSELEADS_SLA_TITLE = 'title.pulseleadsSLA';
export const PULSELEADS_RULE_TITLE = 'title.pulseleadsRule';

export const PULSELEADS_LEAD_CAMPAIGN_TITLE = 'title.pulseleadsLead.campaign';
export const PULSELEADS_LEAD_SOURCE_TITLE = 'title.pulseleadsLead.source';

export const PULSELEADS_ACCEPT_SLA_TITLE = 'title.pulseleadsSLA.accept';
export const PULSELEADS_CONTACT_SLA_TITLE = 'title.pulseleadsSLA.contact';
export const PULSELEADS_MEET_SLA_TITLE = 'title.pulseleadsSLA.meet';
export const PULSELEADS_PROPOSAL_SLA_TITLE = 'title.pulseleadsSLA.proposal';
export const PULSELEADS_APPLICATION_SLA_TITLE = 'title.pulseleadsSLA.application';

export const PULSELEADS_LEAD_DEDUPLICATION_RULE_TITLE = 'title.pulseleadsRule.leadDeduplication';
export const PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_TITLE = 'title.pulseleadsRule.customerDeduplication';
export const PULSELEADS_DISTRIBUTION_RULE_TITLE = 'title.pulseleadsRule.distribution';
export const PULSELEADS_EXCLUSION_RULE_TITLE = 'title.pulseleadsRule.exclusion';
export const PULSELEADS_ELIGIBILITY_RULE_TITLE = 'title.pulseleadsRule.eligibility';

export const NO_ROW_ERROR_TEXT = 'You must at least insert one item';
export const ALL_FIELDS_ERROR_TEXT = 'You must fill in all the fields';

//  These constants map to permission control, identical to permission enum defined below
export const PULSELEADS_LEAD_CAMPAIGN_NAMESPACE = 'PULSELEADS-CAMPAIGN';
export const PULSELEADS_LEAD_SOURCE_NAMESPACE = 'PULSELEADS-LEAD-SOURCE';

export const PULSELEADS_SLA_NAMESPACE = 'PULSELEADS-SLA-CONFIG';

export const PULSELEADS_LEAD_DEDUPLICATION_RULE_NAMESPACE = 'PULSELEADS-DEDUPLICATION-RULE';
export const PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_NAMESPACE = 'PULSELEADS-RULE';
export const PULSELEADS_DISTRIBUTION_RULE_NAMESPACE = 'PULSELEADS-DISTRIBUTION-RULE';
export const PULSELEADS_EXCLUSION_RULE_NAMESPACE = 'PULSELEADS-EXCLUSION-RULE';
export const PULSELEADS_ELIGIBILITY_RULE_NAMESPACE = 'PULSELEADS-ELIGIBILITY-RULE';

export enum AdminLeadsPermissionType {
  PULSELEADS_LEAD_CAMPAIGN_READ = 'READ_ANY_PULSELEADS-CAMPAIGN',
  PULSELEADS_LEAD_CAMPAIGN_CREATE = 'CREATE_ANY_PULSELEADS-CAMPAIGN',
  PULSELEADS_LEAD_CAMPAIGN_UPDATE = 'UPDATE_ANY_PULSELEADS-CAMPAIGN',
  PULSELEADS_LEAD_SOURCE_READ = 'READ_ANY_PULSELEADS-LEAD-SOURCE',
  PULSELEADS_LEAD_SOURCE_CREATE = 'CREATE_ANY_PULSELEADS-LEAD-SOURCE',
  PULSELEADS_LEAD_SOURCE_UPDATE = 'UPDATE_ANY_PULSELEADS-LEAD-SOURCE',
  PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_READ = 'READ_ANY_PULSELEADS-LEAD-FOLLOW-UP-MANUAL-UPDATE',
  PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_CREATE = 'CREATE_ANY_PULSELEADS-LEAD-FOLLOW-UP-MANUAL-UPDATE',
  PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_UPDATE = 'UPDATE_ANY_PULSELEADS-LEAD-FOLLOW-UP-MANUAL-UPDATE',
  PULSELEADS_SLA_CONFIG_READ = 'READ_ANY_PULSELEADS-SLA-CONFIG',
  PULSELEADS_SLA_CONFIG_CREATE = 'CREATE_ANY_PULSELEADS-SLA-CONFIG',
  PULSELEADS_SLA_CONFIG_UPDATE = 'UPDATE_ANY_PULSELEADS-SLA-CONFIG',
  PULSELEADS_LEAD_DEDUPLICATION_RULE_READ = 'READ_ANY_PULSELEADS-DEDUPLICATION-RULE',
  PULSELEADS_LEAD_DEDUPLICATION_RULE_CREATE = 'CREATE_ANY_PULSELEADS-DEDUPLICATION-RULE',
  PULSELEADS_LEAD_DEDUPLICATION_RULE_UPDATE = 'UPDATE_ANY_PULSELEADS-DEDUPLICATION-RULE',
  PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_READ = 'READ_ANY_PULSELEADS-RULE',
  PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_CREATE = 'CREATE_ANY_PULSELEADS-RULE',
  PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_UPDATE = 'UPDATE_ANY_PULSELEADS-RULE',
  PULSELEADS_DISTRIBUTION_RULE_READ = 'READ_ANY_PULSELEADS-DISTRIBUTION-RULE',
  PULSELEADS_DISTRIBUTION_RULE_CREATE = 'CREATE_ANY_PULSELEADS-DISTRIBUTION-RULE',
  PULSELEADS_DISTRIBUTION_RULE_UPDATE = 'UPDATE_ANY_PULSELEADS-DISTRIBUTION-RULE',
  PULSELEADS_EXCLUSION_RULE_READ = 'READ_ANY_PULSELEADS-EXCLUSION-RULE',
  PULSELEADS_EXCLUSION_RULE_CREATE = 'CREATE_ANY_PULSELEADS-EXCLUSION-RULE',
  PULSELEADS_EXCLUSION_RULE_UPDATE = 'UPDATE_ANY_PULSELEADS-EXCLUSION-RULE',
  PULSELEADS_ELIGIBILITY_RULE_READ = 'READ_ANY_PULSELEADS-ELIGIBILITY-RULE',
  PULSELEADS_ELIGIBILITY_RULE_CREATE = 'CREATE_ANY_PULSELEADS-ELIGIBILITY-RULE',
  PULSELEADS_ELIGIBILITY_RULE_UPDATE = 'UPDATE_ANY_PULSELEADS-ELIGIBILITY-RULE',
  PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_READ = 'READ_ANY_PULSELEADS-PROSPECT-MASTER-MATCHING-RULE',
  PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_UPDATE = 'UPDATE_ANY_PULSELEADS-PROSPECT-MASTER-MATCHING-RULE',
  PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_CREATE = 'CREATE_ANY_PULSELEADS-PROSPECT-MASTER-MATCHING-RULE',
  PULSELEADS_PROSPECT_CATEGORY_READ = 'READ_ANY_PULSELEADS-PROSPECT-CATEGORY',
  PULSELEADS_PROSPECT_CATEGORY_UPDATE = 'UPDATE_ANY_PULSELEADS-PROSPECT-CATEGORY',

  PULSELEADS_MARKETING_LEAD_LIMIT_RULE_READ = 'READ_ANY_PULSELEADS-MARKETING-LEAD-LIMIT-RULE',
  PULSELEADS_MARKETING_LEAD_LIMIT_RULE_UPDATE = 'UPDATE_ANY_PULSELEADS-MARKETING-LEAD-LIMIT-RULE',
  PULSELEADS_MARKETING_LEAD_LIMIT_RULE_CREATE = 'CREATE_ANY_PULSELEADS-MARKETING-LEAD-LIMIT-RULE',

  PULSELEADS_PROSPECT_CATEGORY_CREATE = 'CREATE_ANY_PULSELEADS-PROSPECT-CATEGORY',
  PULSELEADS_ENGAGEMENT_FIELDS_READ = 'READ_ANY_PULSELEADS-ENGAGEMENT-FIELDS',
  PULSELEADS_ENGAGEMENT_FIELDS_UPDATE = 'UPDATE_ANY_PULSELEADS-ENGAGEMENT-FIELDS',
  PULSELEADS_ENGAGEMENT_FIELDS_CREATE = 'CREATE_ANY_PULSELEADS-ENGAGEMENT-FIELDS',

  PULSELEADS_BULKUPLOAD_UPLOAD_READ = 'READ_ANY_PULSELEADS-BULKUPLOAD-UPLOAD',
  PULSELEADS_BULKUPLOAD_UPLOAD_UPDATE = 'UPDATE_ANY_PULSELEADS-BULKUPLOAD-UPLOAD',
  PULSELEADS_BULKUPLOAD_UPLOAD_CREATE = 'CREATE_ANY_PULSELEADS-BULKUPLOAD-UPLOAD',
  PULSELEADS_BULKUPLOAD_STATUS_READ = 'READ_ANY_PULSELEADS-BULKUPLOAD-STATUS',
  PULSELEADS_BULKUPLOAD_STATUS_UPDATE = 'UPDATE_ANY_PULSELEADS-BULKUPLOAD-STATUS',
  PULSELEADS_BULKUPLOAD_STATUS_CREATE = 'CREATE_ANY_PULSELEADS-BULKUPLOAD-STATUS',
  PULSELEADS_LEADS_NOT_DISTRIBUTED_READ = 'READ_ANY_PULSELEADS-LEADS-NOT-DISTRIBUTED',
  PULSELEADS_LEADS_NOT_DISTRIBUTED_UPDATE = 'UPDATE_ANY_PULSELEADS-LEADS-NOT-DISTRIBUTED',
  PULSELEADS_LEADS_NOT_DISTRIBUTED_CREATE = 'CREATE_ANY_PULSELEADS-LEADS-NOT-DISTRIBUTED',

  PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_READ = 'READ_ANY_PULSELEADS-STRAIGHT-THROUGH-EVENT-CAMPAIGN',
  PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_CREATE = 'CREATE_ANY_PULSELEADS-STRAIGHT-THROUGH-EVENT-CAMPAIGN',
  PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_UPDATE = 'UPDATE_ANY_PULSELEADS-STRAIGHT-THROUGH-EVENT-CAMPAIGN',
  PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_READ = 'READ_ANY_PULSELEADS-STRAIGHT-THROUGH-PRODUCT-CAMPAIGN',
  PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_CREATE = 'CREATE_ANY_PULSELEADS-STRAIGHT-THROUGH-PRODUCT-CAMPAIGN',
  PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_UPDATE = 'UPDATE_ANY_PULSELEADS-STRAIGHT-THROUGH-PRODUCT-CAMPAIGN',
  PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_READ = 'READ_ANY_PULSELEADS-STRAIGHT-THROUGH-CHANNEL-CAMPAIGN',
  PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_CREATE = 'CREATE_ANY_PULSELEADS-STRAIGHT-THROUGH-CHANNEL-CAMPAIGN',
  PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_UPDATE = 'UPDATE_ANY_PULSELEADS-STRAIGHT-THROUGH-CHANNEL-CAMPAIGN',
  PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_READ = 'READ_ANY_PULSELEADS-STRAIGHT-THROUGH-STAGING-LEAD',
  PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_CREATE = 'CREATE_ANY_PULSELEADS-STRAIGHT-THROUGH-STAGING-LEAD',
  PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_UPDATE = 'UPDATE_ANY_PULSELEADS-STRAIGHT-THROUGH-STAGING-LEAD',

  PULSELEADS_AGENT_LEAD_SOURCE_READ = 'READ_ANY_PULSELEADS-AGENT-LEAD-SOURCE',
  PULSELEADS_AGENT_LEAD_SOURCE_UPDATE = 'UPDATE_ANY_PULSELEADS-AGENT-LEAD-SOURCE',
  PULSELEADS_AGENT_LEAD_SOURCE_CREATE = 'CREATE_ANY_PULSELEADS-AGENT-LEAD-SOURCE',

  PULSELEADS_AGENT_REASSIGN_LEAD_READ = 'READ_ANY_PULSELEADS-AGENT-REASSIGN-LEAD',
  PULSELEADS_AGENT_REASSIGN_LEAD_UPDATE = 'UPDATE_ANY_PULSELEADS-AGENT-REASSIGN-LEAD',
  PULSELEADS_AGENT_REASSIGN_LEAD_CREATE = 'CREATE_ANY_PULSELEADS-AGENT-REASSIGN-LEAD',
  PULSELEADS_AGENT_REDISTRIBUTE_LEAD_READ = 'READ_ANY_PULSELEADS-AGENT-REDISTRIBUTE-LEAD',
  PULSELEADS_AGENT_REDISTRIBUTE_LEAD_UPDATE = 'UPDATE_ANY_PULSELEADS-AGENT-REDISTRIBUTE-LEAD',
  PULSELEADS_AGENT_REDISTRIBUTE_LEAD_CREATE = 'CREATE_ANY_PULSELEADS-AGENT-REDISTRIBUTE-LEAD',
  PULSELEADS_AGENT_AUTO_DROP_LEAD_READ = 'READ_ANY_PULSELEADS-AGENT-AUTO-DROP-LEAD',
  PULSELEADS_AGENT_AUTO_DROP_LEAD_UPDATE = 'UPDATE_ANY_PULSELEADS-AGENT-AUTO-DROP-LEAD',
  PULSELEADS_AGENT_AUTO_DROP_LEAD_CREATE = 'CREATE_ANY_PULSELEADS-AGENT-AUTO-DROP-LEAD',


  PULSELEADS_CONFIGURATION_LOCATION_READ = 'READ_ANY_PULSELEADS-CONFIGURATION-LOCATION',
  PULSELEADS_CONFIGURATION_LOCATION_UPDATE = 'UPDATE_ANY_PULSELEADS-CONFIGURATION-LOCATION',
  PULSELEADS_CONFIGURATION_LOCATION_CREATE = 'CREATE_ANY_PULSELEADS-CONFIGURATION-LOCATION',
  PULSELEADS_CONFIGURATION_LANGUAGE_READ = 'READ_ANY_PULSELEADS-CONFIGURATION-LANGUAGE',
  PULSELEADS_CONFIGURATION_LANGUAGE_UPDATE = 'UPDATE_ANY_PULSELEADS-CONFIGURATION-LANGUAGE',
  PULSELEADS_CONFIGURATION_LANGUAGE_CREATE = 'CREATE_ANY_PULSELEADS-CONFIGURATION-LANGUAGE',
  PULSELEADS_CONFIGURATION_PRODUCT_READ = 'READ_ANY_PULSELEADS-CONFIGURATION-PRODUCT',
  PULSELEADS_CONFIGURATION_PRODUCT_UPDATE = 'UPDATE_ANY_PULSELEADS-CONFIGURATION-PRODUCT',
  PULSELEADS_CONFIGURATION_PRODUCT_CREATE = 'CREATE_ANY_PULSELEADS-CONFIGURATION-PRODUCT',

  PULSELEADS_ENGAGEMENT_TOPIC_READ = 'READ_ANY_PULSELEADS-ENGAGEMENT-TOPIC',
  PULSELEADS_ENGAGEMENT_TOPIC_UPDATE = 'UPDATE_ANY_PULSELEADS-ENGAGEMENT-TOPIC',
  PULSELEADS_ENGAGEMENT_TOPIC_CREATE = 'CREATE_ANY_PULSELEADS-ENGAGEMENT-TOPIC',
  PULSELEADS_ENGAGEMENT_TASK_READ = 'READ_ANY_PULSELEADS-ENGAGEMENT-TASK',
  PULSELEADS_ENGAGEMENT_TASK_UPDATE = 'UPDATE_ANY_PULSELEADS-ENGAGEMENT-TASK',
  PULSELEADS_ENGAGEMENT_TASK_CREATE = 'CREATE_ANY_PULSELEADS-ENGAGEMENT-TASK',

  PULSELEADS_CAMPAIGN_FEEDBACK_READ = 'READ_ANY_PULSELEADS-CAMPAIGN-FEEDBACK',
  PULSELEADS_CAMPAIGN_FEEDBACK_CREATE = 'CREATE_ANY_PULSELEADS-CAMPAIGN-FEEDBACK',
  PULSELEADS_CAMPAIGN_FEEDBACK_UPDATE = 'UPDATE_ANY_PULSELEADS-CAMPAIGN-FEEDBACK',

  PULSELEADS_POINT_RULE_SETTINGS_READ = 'READ_ANY_PULSELEADS-POINT-RULE-SETTINGS',
  PULSELEADS_POINT_RULE_SETTINGS_UPDATE = 'UPDATE_ANY_PULSELEADS-POINT-RULE-SETTINGS',
  PULSELEADS_POINT_RULE_SETTINGS_CREATE = 'CREATE_ANY_PULSELEADS-POINT-RULE-SETTINGS',
  PULSELEADS_POINT_RULE_NAME_READ = 'READ_ANY_PULSELEADS-POINT-RULE-NAME',
  PULSELEADS_POINT_RULE_NAME_UPDATE = 'UPDATE_ANY_PULSELEADS-POINT-RULE-NAME',
  PULSELEADS_POINT_RULE_NAME_CREATE = 'CREATE_ANY_PULSELEADS-POINT-RULE-NAME',
}
