import { I18nGenericData } from 'src/app/i18n';
import { FormMode } from 'src/app/common/types';

export enum EventHostEnum {
  COMPANY = 'COMPANY',
  AGENCY = 'AGENCY',
}

export enum EventAccessibilityEnum {
  PUBLIC = 'PUBLIC',
  INTERNAL = 'INTERNAL',
}

export enum EventAudienceEnum {
  SALES_PROSPECT = 'SALES_PROSPECT',
  RECRUITMENT_PROSPECT = 'RECRUITMENT_PROSPECT',
  AGENT = 'AGENT',
}

export enum EventCategoryStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EventMaterialTypeEnum {
  IMAGE = 'EVENT_IMAGE',
  TEMPLATE = 'DETAIL_TEMPLATE',
}

export type FormDialogState = {
  open: boolean;
  formMode?: FormMode;
  eventCategoryItem?: EventCategoryItem;
};

export type ReminderDialogState = {
  open: boolean;
};

export type EventCategoryItem = {
  _id: string;
  name: I18nGenericData<string>;
  description?: string;
  host: EventHostEnum[];
  accessibility: EventAccessibilityEnum;
  audience: EventAudienceEnum;
  status: EventCategoryStatusEnum;
  lastUpdatedBy: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type EventCategoryMaterialItem = {
  _id: string;
  type: EventMaterialTypeEnum;
  lang: string;
  blob: {
    blobId: string;
    filename: string;
    url: string;
    type: string;
  };
  isActive: boolean;
};

export type EventCategoryMaterials = {
  materials: EventCategoryMaterialItem[];
};

export type EventCategoryMaterialsParams = Omit<EventCategoryMaterialItem, '_id'> & { _id?: string };
