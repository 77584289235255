import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { LayoutSplashScreen } from 'src/app/layout';
import QuizFormList from './quiz-main-form';
import { OnboardingQuizFormState } from '../../../../types/courses-types';
import {
  createOnboardingTrainingQuiz,
  deleteOnboardingTrainingQuiz,
  fetchOnboardingTrainingQuizDetail,
  updateOnboardingTrainingQuiz,
} from 'src/app/modules/Recruitment/network/onboarding-course-crud';
import { cloneDeep } from 'lodash';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';

const OnboardingLessonDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { linkedId, id } = useParams<{ linkedId: string; id: string }>();

  const { courseName = { en: '' }, lessonName = { en: '' }, courseId = '', lessonId } = (location.state || {}) as {
    courseName: {
      [key: string]: string
    },
    lessonName: {
      [key: string]: string
    },
    courseId: string,
    lessonId?: string
  }
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const pageType = useMemo(() => {
    if (location.pathname.includes('create')) {
      return 'create';
    } else if (location.pathname.includes('edit')) {
      return 'edit'
    } else {
      return 'view'
    }
  }, [location.pathname])

  const [initialValues, setInitialValues] = useState({})
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (courseId && id) {
      refreshData();
    }
  }, [courseId, lessonId, id]);


  const refreshData = useCallback(async () => {
    if (pageType !== 'create') {
      try {
        setIsLoading(true);
        const res = await fetchOnboardingTrainingQuizDetail(courseId, id, { lessonId }, dispatch)
        const data = formatServerToLocal(res)
        setInitialValues(data);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  }, [id, courseId, lessonId])





  const formatData = (data: OnboardingQuizFormState) => {
    //avoid affect original data
    const reqData = cloneDeep(data);
    const oldNames = ['blobId', 'fileName', 'url', 'materialContent'];
    const newNames = ['materialId', 'materialName', 'materialLink', 'materialContent'];
    for (const key in reqData.material) {
      if (!reqData.material[key]?.materialContent) {
        delete reqData.material[key];
      } else {
        reqData.material[key] = renameProperties(reqData.material[key], oldNames, newNames);
      }
    }
    return reqData;
  };

  const renameProperties = useCallback((obj: any, oldNames: string[], newNames: string[]) => {
    const newObj: any = {};
    for (let i = 0; i < oldNames.length; i++) {
      const oldName = oldNames[i];
      const newName = newNames[i];
      if (obj.hasOwnProperty(oldName)) {
        newObj[newName] = obj[oldName];
      }
    }
    return newObj;
  }, []);

  const formatServerToLocal = (data: any) => {
    const newNames = ["blobId", "fileName", "url", "materialContent"]
    const oldNames = ["materialId", "materialName", "materialLink", "materialContent"]
    for (const key in data.material) {
      data.material[key] = renameProperties(data.material[key], oldNames, newNames);
    }
    return data
  };

  const onDelete = useCallback(async () => {
    await deleteOnboardingTrainingQuiz(courseId, id, { lessonId });
    history.goBack();
  }, [id]);
  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <QuizFormList
          id={id}
          disabled={pageType === 'view'}
          initialValues={initialValues}
          onSave={async (formData: OnboardingQuizFormState, courseId: string) => {
            const reqData = formatData(formData)
            if (pageType === 'create') {
              await createOnboardingTrainingQuiz(reqData, courseId, dispatch);
            } else if (pageType === 'edit') {
              await updateOnboardingTrainingQuiz(reqData, courseId, id, dispatch);
            }
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: Translation('global.submit.success'),
                  content: ``,
                },
              ]),
            );
            history.goBack();
          }}
          type={pageType}
          isEdit={false}
          history={history}
          location={location}
          onDelete={onDelete}
          linkedCourse={courseName}
          linkedLesson={lessonName}
        />
      )}
    </>
  );
};

export default OnboardingLessonDetailPage;
