import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from '../../../common/components/PruRoute';
import ParamsProvider from '../../../common/components/ParamsProvider';
import NewsListingPage, { newsArticleFilterKeys } from './List/ScheduleJobListingPage';
import ScheduleJobHistoryListingPage from './List/ScheduleJobHistoryListingPage';
// import NewsDetailPage from './View/NewsDetailPage';
const ScheduleJobListPage: FC<RouteComponentProps> = ({ history, location, match }) => {
  console.log('ScheduleJobPage.tsx', match.path);
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`} />
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={newsArticleFilterKeys}>
            <NewsListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${match.path}/list/history`} component={ScheduleJobHistoryListingPage} />
    </Switch>
  );
};

export default ScheduleJobListPage;
